import { React, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import settingIcon from '../asset/image/Setting_fill.svg';
import { Button, Input, message, Select, Modal, Popover } from 'antd'
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { insertCurriculumDetails, updateCurriculumDetails, deleteCurriculum, getCurriculumDetailsById, getTestSeriesExamFilter, getTestSeriesExam, removeCurriculumDetails } from './reducers/CurriculumSlice';
import { getPricingDetailsById } from '../components/reducers/PricingSlice';
import { setSaveStatus } from './reducers/clusterCourseSlice';
import { getFrequency } from "./reducers/ClusterGroupMasterSlice.js";

import topicImage from '../asset/image/topicImage.svg';
import subTopicImg from '../asset/image/subTopicimg.svg';
import classImg from '../asset/image/classImage.svg';
import contentImg from '../asset/image/contentImg.svg';

import '../components/styles/curriculam.scss';
import BeforeUnloadHandler from './Table/BeforeUnloadHandler';
import DrawerForm from "./Table/VideoContentDrawer";
import PricingPlanDrawer from "./Table/PricingPlanDrawer";

import { handleToast, filehandleChangeHelper, handleFileRemoveHelper, formValidationSubjects, deletePricingPlanHelper, constructFrequencyHelper, renderPublishStatusHelper, updateFieldStatusHelper, extractVideoDurationHelper } from '../utils/HelperFunction';
import { EQuickActions, EQuickActionsType, EFieldStatus, videoFilter, videoFilterMessage, VIDEO_STREAM_MAX_SIZE, videoSizeErrorMessage, MaxFileCount, ChunkSize, DefaultPublishStatus, imageAndPdfFilter, imageAndPdfFilterMessage } from "../utils/Variable.js";
import { createMultipartUpload, resetState, uploadObject, setTotalParts } from './reducers/MultipartUploadSlice.js';
import { uploadFilePartsHelper, completeFileUploadHelper, updateFileLocationHelper, removePrefix, extractFileLocations } from '../utils/MultipartUploadHelper.js';
import Loading from './Loading';

const Curriculum = forwardRef(({ tempCourseMappingId, handleSaveAndNextChanges }, ref) => {

  useImperativeHandle(ref, () => ({
    insertCurriculum,
    updateCurriculum
  }));

  const dispatch = useDispatch();
  const { courseMappingId } = useParams();
  const [isEditAction, setEditAction] = useState(false);
  const { isLoading, curriculumDatails, getTestSeriesExamData, educatorDetails } = useSelector((state) => state.CurriculumSlice);
  const { curriculumSaveStatus } = useSelector(
    (state) => state.ClusterCourseSlice
  );
  const { frequencyData } = useSelector((state) => state.ClusterGroupMasterSlice);
  const { parts, uploadId, uploading, progress } = useSelector(state => state.MultipartUpload);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [communityFree, setCommunityFree] = useState(false);
  const [freeTrial, setFreeTrial] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [isCancelModalVisible, setCancelModalVisible] = useState(false);
  const [uploadPartCompleted, setUploadPartCompleted] = useState(false);
  const [drawerIsEdit, setDrawerIsEdit] = useState(false);
  const [currentTopicId, setTopicCurrentId] = useState(null);
  const [currentSubTopicId, setSubTopicCurrentId] = useState(null);
  const [currentClassId, setClassCurrentId] = useState(null);
  const [currentContentId, setContentCurrentId] = useState(null);
  const [currentSelectedType, setCurrentSelectedType] = useState(null);
  const [popoverOpenIndex, setPopoverOpenIndex] = useState(null);
  const [mappingId, setMappingId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [duration, setDuration] = useState(null);
  const [contentFieldStatus, setContentFieldStatus] = useState(EFieldStatus.NONE);
  const [selectedFile, setSelectedFile] = useState('')
  const [tempObjectKey, setObjectKey] = useState('');
  const [pricingVisible, setPricingVisible] = useState(false);
  const [currentPricingTitle, setCurrentPricingTitle] = useState("Set a Pricing Plan");
  const [modalVisible, setModalVisible] = useState(false);
  const [action, setAction] = useState('Save the content');
  const [isContentEdit, setIsContentEdit] = useState(false);
  const [prices, setPrices] = useState([]);
  const [youtubeLinkMapping, setYoutubeLinkMapping] = useState([]);
  const [frequencyMap, setFrequencyMap] = useState({});

  const [courseDeletedPricing, setCourseDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [topicDeletedPricing, setTopicDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [subTopicDeletedPricing, setSubTopicDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [classDeletedPricing, setClassDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [contentDeletedPricing, setContentDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [contentDeletedYoutubeLink, setContentDeletedYoutubeLink] = useState({ mappingIds: [], pricingIds: [] });
  const [formikValues, setFormikValues] = useState({});
  const [pricingValues, setPricingValues] = useState({});
  const [imagePreview, setImagePreview] = useState(null);



  const [testMiData, setTestMiData] = useState([]);

  const quickActionsOptions = [
    {
      value: EQuickActions.edit,
      label: 'Edit',
    },
    // {
    //   value: EQuickActions.paymentConfig,
    //   label: 'Payment Configuration',
    // },
    // {
    //   value: EQuickActions.deleteContent,
    //   label: 'Delete Content',
    // },
  ];

  const [selectedCards, setSelectedCards] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [fileNotesOthers, setFileNotesOthers] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [deletedAttachment, setDeletedAttachment] = useState([]);
  const [deletedFilesNotes, setDeletedFilesNotes] = useState([]);
  const [deletedFilesUpload, setDeletedFilesUpload] = useState([]);
  const [deletedYoutubeLink, setDeletedYoutubeLink] = useState([]);
  const [attachmentFileUid, setAttachmentFileUid] = useState(null);
  const [file, setFile] = useState(null);


  const [currentTitle, setCurrentTitle] = useState("");
  const initialTopic = { topicName: "", subTopicArray: [], status: false, addSubtopicStatus: false, fieldStatus: EFieldStatus.NEW, planPricing: [], isPublish: DefaultPublishStatus };

  const [data, setData] = useState([initialTopic]);

  useEffect(() => {
    setEditAction(!!courseMappingId);
  }, [courseMappingId]);

  useEffect(() => {
    dispatch(getFrequency())
      .unwrap()
      .then((data) => {
        constructFrequencyHelper(data, setFrequencyMap)
      });
    dispatch(getTestSeriesExamFilter());
    dispatch(getTestSeriesExam()).unwrap().then((data) => {
      setTestMiData(data?.data);
    });
  }, []);

  useEffect(() => {
    if (!isEditAction) {
      dispatch(removeCurriculumDetails());
    };
  }, [!isEditAction, dispatch]);

  useEffect(() => {
    if (courseMappingId && isEditAction) {
      dispatch(getCurriculumDetailsById(courseMappingId || tempCourseMappingId));
    }
  }, [courseMappingId, isEditAction, tempCourseMappingId, dispatch]);

  useEffect(() => {
    if (curriculumDatails?.length > 0) {
      setData(curriculumDatails);
    }
  }, [curriculumDatails]);

  useEffect(() => {
    if (uploadPartCompleted && uploadId && tempObjectKey && parts?.length) {
      completeUpload(tempObjectKey, uploadId, selectedFile, parts);
    }
  }, [parts, uploadPartCompleted]);

  const drawerFormInitialValues = {
    videoName: '',
    videoDescription: '',
    videoLink: '',
    orderId: '',
    fileUpload: [],
    videoLinkMapping: [],
    attachment: [],
    fileOtherNotes: [],
    testmiMapping: [],
    educatorMapping: [],
    isPublish: DefaultPublishStatus
  };

  const drawerFormvalidationSchema = yup.object({
    videoName: yup
      .string()
      .required("Video name is required")
      .min(2, "Video name be at least 2 characters long")
      .max(255, "Video name be at most 255 characters long"),
      orderId: yup
      .number()
      .typeError("Order Number must be a number") 
      .required("Order Number is Required"),
      videoDescription: yup
      .string()
      .required("Description is required")
      .min(2, "Description must be at least 2 characters long"),
    // .max(100, "Description must be at most 100 characters long"),
    attachment: yup
      .array()
      .of(yup.object())
      .test(
        "at-least-one-required",
        "Either attachment or video link is required",
        function (value) {
          return (value && value.length > 0) || this.parent.videoLink;
        }
      ),
    videoLink: yup
      .string()
      .test(
        "at-least-one-required",
        "Either video link or attachment is required",
        function (value) {
          return (
            value ||
            (this.parent.attachment && this.parent.attachment.length > 0)
          );
        }
      ),
    testmiMapping: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required("ID is required"),
          examId: yup.number().nullable(),
          examGroupId: yup.number().nullable(),
        })
      )
      .min(1, "At least one testmi mapping is required"),
    educatorMapping: yup
      .array()
      .of(yup.string().required("Each educator mapping is required"))
      .min(1, "At least one educator mapping is required"),
    price: yup
      .number()
      .positive("Price must be a positive integer")
      .integer("Price must be an integer"),
    frequencyId: yup.string(),
  });

  const drawerFormFormik = useFormik({
    initialValues: drawerFormInitialValues,
    validationSchema: drawerFormvalidationSchema,
  });

  // previous code

  // const handleContent = async (values, isEditAction = false) => {

  //   const newData = data?.map?.((topic, topicIndex) => {
  //     if (topicIndex !== currentTopicId) return topic;

  //     const updatedSubTopics = topic.subTopicArray?.map?.((subTopic, subIndex) => {
  //       if (subIndex !== currentSubTopicId) return subTopic;

  //       const updatedClasses = subTopic.classArray?.map?.((cls, classIndex) => {
  //         if (classIndex !== currentClassId) return cls;

  //         const updatedContentArray = cls.contentArray?.map?.((content, contentIndex) => {
  //           if (contentIndex !== currentContentId) return content;

  //           return isEditAction ? { ...content, ...values } : content;
  //         });

  //         return {
  //           ...cls,
  //           contentArray: isEditAction ? updatedContentArray : [...cls?.contentArray, values],
  //         };
  //       });

  //       return {
  //         ...subTopic,
  //         classArray: updatedClasses,
  //       };
  //     });

  //     return {
  //       ...topic,
  //       subTopicArray: updatedSubTopics,
  //     };
  //   });

  //   setData(newData);
  // };

  const handleContent = async (values, isEditAction = false) => {
    const newData = data?.map?.((topic, topicIndex) => {
      if (topicIndex !== currentTopicId) return topic;

      const updatedSubTopics = topic['subTopicArray']?.map?.((subTopic, subIndex) => {
        if (subIndex !== currentSubTopicId) return subTopic;

        const updatedContentArray = subTopic['contentArray']?.map?.((content, contentIndex) => {
          if (contentIndex !== currentContentId) return content;

          return isEditAction ? { ...content, ...values } : content;
        });

        return {
          ...subTopic,
          contentArray: isEditAction ? updatedContentArray : [...subTopic?.contentArray, values],
        };
      });

      return {
        ...topic,
        subTopicArray: updatedSubTopics,
      };
    });

    setData(newData);
  };

  const showDrawer = () => setDrawerVisible(true);

  const closeCancelModel = () => {
    setCancelModalVisible(false);
  };

  const resetDrawerForm = async () => {
    closeCancelModel();
    drawerFormFormik.resetForm({ values: drawerFormInitialValues });
    pricingFormik.resetForm({ values: pricingInitialValues });
    setTopicCurrentId(null);
    setSubTopicCurrentId(null);
    // setClassCurrentId(null);
    setContentCurrentId(null);
    setAttachmentFile([]);
    setFileNotesOthers([]);
    setSelectedCards([]);
    setCommentsOpen(false);
    setCommunityFree(false);
    setFreeTrial(false);
    setDrawerVisible(false);
    setContentFieldStatus(EFieldStatus.NONE);
    setDrawerIsEdit(false);
    setPrices([]);
    setYoutubeLinkMapping([]);
    setIsContentEdit(false);
    setFormikValues({});
    setPricingValues({});
  };

  const closeDrawer = async () => {
    if (drawerFormFormik.dirty) {
      setCancelModalVisible(true);
    } else {
      await resetDrawerForm();
    }
  };

  const handleCommentsChange = (checked) => {
    setCommentsOpen(checked);
  };

  const handleCommunityFreeChange = (checked) => {
    setCommunityFree(checked);
  };
  const handleFreeTrialChange = (checked) => {
    setFreeTrial(checked);
  };

  const contentChanges = async (values, pricingValues, status) => {
    confirmModalOpen(status);
    setFormikValues(values);
    setPricingValues(pricingValues);
  };

  const updateContentSave = async (values, pricingValues) => {

    let { fieldStatus, videoLink, ...tempContent } = values;

    let fieldStatusArray = [EFieldStatus.NONE];

    if (fieldStatusArray.includes(contentFieldStatus) && drawerFormFormik?.dirty) {
      fieldStatus = EFieldStatus.EDITED;
    };

    const { isFree, ...rest } = pricingValues;

    let planPricing = [
      ...prices
    ];

    if (rest?.price && rest?.frequencyId) {
      planPricing = [
        ...planPricing,
        rest,
      ]
    };

    planPricing = planPricing?.map(item => {

      let newItem = { ...item };

      if (newItem.fieldStatus === EFieldStatus.NEW) {
        delete newItem.pricingId;
      };

      return newItem;
    });

    let linkMapping = [
      { link: videoLink },
      ...youtubeLinkMapping
    ];

    linkMapping = linkMapping?.map(item => {

      let newItem = { ...item };

      if (newItem.fieldStatus === EFieldStatus.NEW) {
        // delete newItem?.pricingId;
      };

      return newItem;
    });

    const contentValue = {
      ...tempContent,
      allComments: commentsOpen,
      freeForCommunity: communityFree,
      freeTrial: freeTrial,
      videoLinkMapping: linkMapping,
      fieldStatus,
      planPricing,
      attachment: attachmentFile,
      fileOtherNotes: fileNotesOthers,
      fileUpload: fileUpload,
    };

    await handleContent(contentValue, true);
    await resetDrawerForm();
    setDrawerIsEdit(false);
    setPrices([]);
  };

  const saveContent = async (values, pricingValues) => {
    const { isFree, ...rest } = pricingValues;
    const { videoLink } = values;

    let planPricing = [
      ...prices
    ];

    if (rest?.price && rest?.frequencyId) {
      planPricing = [
        ...planPricing,
        rest,
      ]
    };

    planPricing = planPricing?.map(item => {

      let newItem = { ...item };

      if (newItem.fieldStatus === EFieldStatus.NEW) {
        delete newItem.pricingId;
      };

      return newItem;
    });

    let linkMapping = [
      { link: videoLink },
      ...youtubeLinkMapping
    ];

    linkMapping = linkMapping?.map(item => {

      let newItem = { ...item };

      if (newItem.fieldStatus === EFieldStatus.NEW) {
        // delete newItem?.pricingId;
      };

      return newItem;
    });

    values.allComments = commentsOpen;
    values.freeForCommunity = communityFree;
    values.freeTrial = freeTrial;
    values.fieldStatus = EFieldStatus.NEW;
    values.planPricing = planPricing;
    values.videoLinkMapping = linkMapping;
    values.attachment = attachmentFile;
    values.fileOtherNotes = fileNotesOthers;
    values.fileUpload = fileUpload;
    await handleContent(values);
    await resetDrawerForm();
  };

  const savePricing = async () => {
    pricingFormik.handleSubmit();
  };

  const handleCheckboxChange = (event, checked) => {

    const { examGroupId = null, id, examId = null,testSeriesType = null } = event || {};

    setSelectedCards((prevSelectedCards) => {
      const updatedSelectedCards = checked
        ? [...prevSelectedCards, { id, examId, examGroupId,testSeriesType:Number(testSeriesType) }]
        : prevSelectedCards.filter((i) => i?.id !== id);

      drawerFormFormik.setFieldValue('testmiMapping', updatedSelectedCards);

      return updatedSelectedCards;
    });
  };

  const handleAttachmentChange = async ({ file, fileList }) => {
    setSelectedFile('');
    setUploadPartCompleted(false);
    dispatch(resetState());
    const fileResponse = await filehandleChangeHelper(videoFilter, videoFilterMessage, file, attachmentFile, setAttachmentFile, drawerFormFormik, 'attachment', MaxFileCount.curriculumAttachment, true, VIDEO_STREAM_MAX_SIZE, videoSizeErrorMessage);
    if (fileResponse) {
      const fileDuration = await extractVideoDurationHelper(file);
      setDuration(fileDuration);
      setAttachmentFileUid(file.uid);
      await startUpload(file);
    }
  };

  const handleAttachmentFileRemove = async (file) => {
    await handleFileRemoveHelper(file, setDeletedAttachment, deletedAttachment, setAttachmentFile, drawerFormFormik, 'attachment');
  };

  const handleFilesNotesChange = async ({ file, fileList }) => {
    const fileResponse = await filehandleChangeHelper(imageAndPdfFilter, imageAndPdfFilterMessage, file, fileNotesOthers, setFileNotesOthers, drawerFormFormik, 'fileOtherNotes', MaxFileCount.curriculumOtherNotes, false);
    if (fileResponse) {

      const updatedFileNotesOthers = await getFileNotesOther();

      let formData = new FormData();
      formData.append('file', file);
      let response = await dispatch(uploadObject(formData)).unwrap();
      const { data, success } = response;
      if (success) {

        const fileIndex = updatedFileNotesOthers?.findIndex(f => f?.uid === file?.uid);

        if (fileIndex !== -1) {

          const updatedFile = {
            uid: updatedFileNotesOthers[fileIndex]?.uid,
            name: updatedFileNotesOthers[fileIndex]?.name,
            size: updatedFileNotesOthers[fileIndex]?.size,
            type: updatedFileNotesOthers[fileIndex]?.type,
            fileLocation: { ...data }
          };

          await new Promise(resolve => {
            setFileNotesOthers(prevFiles => {
              const updatedFiles = [...prevFiles];
              updatedFiles[fileIndex] = updatedFile;
              resolve(updatedFiles);
              return updatedFiles;
            });
          });

          const getFileNotes = await getFileNotesOther();
          await drawerFormFormik.setFieldValue('fileOtherNotes', getFileNotes);
        };
      };
    };
  };

  
  const handleFilesNotesRemove = async (file) => {
    await handleFileRemoveHelper(file, setDeletedFilesNotes, deletedFilesNotes, setFileNotesOthers, drawerFormFormik, 'fileOtherNotes');
  };

  const addTopic = () => {
    const newTopic = { topicName: "", subTopicArray: [], status: false, addSubtopicStatus: false, fieldStatus: EFieldStatus.NEW, planPricing: [], isPublish: DefaultPublishStatus };
    setData([...data, newTopic]);
  };

  const updateTopicName = (index, name) => {
    const newData = [...data];
    newData[index].topicName = name;
    setData(newData);
  };

  const addSubtopic = (topicIndex) => {
    const newSubtopic = {
      subTopicName: "",
      // classArray: [],
      // addClassStatus: false,
      contentArray: [],
      status: false,
      addContentStatus: false,
      fieldStatus: EFieldStatus.NEW,
      planPricing: [],
      isPublish: DefaultPublishStatus
    };

    const newData = [...data];
    if (newData[topicIndex]?.topicName?.trim()?.length === 0) {
      message.error('Must be fill the topic');
    } else {
      const newSubTopicArray = [...newData[topicIndex].subTopicArray, newSubtopic];
      newData[topicIndex] = {
        ...newData[topicIndex],
        subTopicArray: newSubTopicArray,
        status: true,
        addSubtopicStatus: true
      };

      setData(newData);
    }
  };

  const updateSubtopicName = (topicIndex, index, name) => {
    const newData = [...data];
    newData[topicIndex].subTopicArray[index].subTopicName = name;
    setData(newData);
  };

  const addContentBtn = (topicIndex, subtopicIndex) => {
    const newData = [...data];

    if (newData[topicIndex]?.subTopicArray[subtopicIndex]?.subTopicName?.trim()?.length === 0) {
      message.error('Must fill the subtopic');
      return;
    }

    const updatedSubTopicArray = [...newData[topicIndex].subTopicArray];
    const updatedSubTopic = {
      ...updatedSubTopicArray[subtopicIndex],
      contentArray: [...updatedSubTopicArray[subtopicIndex]?.contentArray],
      status: true,
      addContentStatus: true
    };

    updatedSubTopicArray[subtopicIndex] = updatedSubTopic;
    newData[topicIndex] = {
      ...newData[topicIndex],
      subTopicArray: updatedSubTopicArray
    };

    setData(newData);
    setTopicCurrentId(topicIndex);
    setSubTopicCurrentId(subtopicIndex);
    showDrawer();
  };

  // previous code
  // const addClass = (topicIndex, subtopicIndex) => {

  //   const newClass = { contentArray: [], status: false, addContentStatus: false, fieldStatus: EFieldStatus.NEW, planPricing: [], isPublish: DefaultPublishStatus };
  //   const newData = [...data];
  //   if (newData[topicIndex]?.subTopicArray[subtopicIndex]?.subTopicName.trim()?.length === 0) {
  //     message.error('Must be fill the subtopic')
  //   } else {

  //     const updatedSubTopicArray = [...newData[topicIndex].subTopicArray];
  //     const updatedSubTopic = {
  //       ...updatedSubTopicArray[subtopicIndex],
  //       classArray: [...updatedSubTopicArray[subtopicIndex].classArray, newClass],
  //       status: true,
  //       addClassStatus: true
  //     };

  //     updatedSubTopicArray[subtopicIndex] = updatedSubTopic;
  //     newData[topicIndex] = {
  //       ...newData[topicIndex],
  //       subTopicArray: updatedSubTopicArray
  //     };
  //     setData(newData);
  //   };
  // };

  // previous code
  // const addContentBtn = (topicIndex, subtopicIndex, classIndex) => {
  //   setTopicCurrentId(topicIndex);
  //   setSubTopicCurrentId(subtopicIndex);
  //   setClassCurrentId(classIndex);
  //   showDrawer();
  // };

  // previous code
  // const saveClassBtn = (topicIndex, subtopicIndex, classIndex) => {
  //   const newData = [...data];
  //   if (newData[topicIndex]?.subTopicArray?.[subtopicIndex]?.classArray?.[classIndex]?.className?.trim()?.length === 0) {
  //     message.error('Must be fill the class')
  //   } else {
  //     newData[topicIndex].subTopicArray[subtopicIndex].classArray[classIndex].status = true;
  //     newData[topicIndex].subTopicArray[subtopicIndex].classArray[classIndex].addContentStatus = true;
  //     setData(newData);
  //     setTopicCurrentId(topicIndex);
  //     setSubTopicCurrentId(subtopicIndex);
  //     setClassCurrentId(classIndex);
  //     setCurrentSelectedType(EQuickActionsType.content);
  //     setPrices([]);
  //     setYoutubeLinkMapping([]);
  //     showDrawer();
  //   };
  // };

  // const updateClassName = (topicIndex, subtopicIndex, index, name) => {
  //   const newData = [...data];
  //   newData[topicIndex].subTopicArray[subtopicIndex].classArray[index].className = name;
  //   setData(newData);
  // };

  const topicCancelBtn = (topicIndex) => {
    const newData = [...data];
    newData.splice(topicIndex, 1);
    setData(newData);
  };

  const subtopicCancelBtn = (topicIndex, subIndex) => {
    const newData = [...data];
    newData[topicIndex].subTopicArray.splice(subIndex, 1);
    setData(newData);
  };

  // previous code
  // const classCancelBtn = (topicIndex, subIndex, classIndex) => {
  //   const newData = [...data];
  //   newData[topicIndex].subTopicArray[subIndex].classArray.splice(classIndex, 1);
  //   setData(newData);
  // };

  const updateNestedArray = (array, index, updateFn) => {
    return array.map((item, i) => {
      if (i !== index) return item;
      return updateFn(item);
    });
  };

  // previous code
  // const handleDataUpdate = async (type, topicIndex, subTopicIndex = null, classIndex = null, contentIndex = null, editStatus = false, isCanceled = false) => {
  //   const newData = [...data];
  //   let isEmpty = false;
  //   let content = '';

  //   switch (type) {
  //     case EQuickActionsType.topic:
  //       content = 'topic';
  //       if (!editStatus && (newData[topicIndex]?.topicName?.trim()?.length === 0)) {
  //         isEmpty = true;
  //       } else {
  //         newData[topicIndex] = {
  //           ...newData[topicIndex], status: !editStatus, isEdit: editStatus,
  //           fieldStatus: !editStatus && newData[topicIndex]?.fieldStatus !== EFieldStatus.NEW && !isCanceled ? EFieldStatus.EDITED : newData[topicIndex]?.fieldStatus
  //         };
  //       }
  //       break;
  //     case EQuickActionsType.subTopic:
  //       content = 'subtopic';
  //       if (!editStatus && (newData[topicIndex]?.subTopicArray[subTopicIndex]?.subTopicName?.trim()?.length === 0)) {
  //         isEmpty = true;
  //       } else {
  //         newData[topicIndex] = {
  //           ...newData[topicIndex],
  //           subTopicArray: updateNestedArray(newData[topicIndex].subTopicArray, subTopicIndex, (subTopic) => ({
  //             ...subTopic,
  //             status: !editStatus,
  //             isEdit: editStatus,
  //             fieldStatus: !editStatus && newData[topicIndex]?.fieldStatus !== EFieldStatus.NEW && !isCanceled ? EFieldStatus.EDITED : newData[topicIndex]?.fieldStatus
  //           })),
  //         };
  //       }
  //       break;
  //     case EQuickActionsType.class:
  //       content = 'class';
  //       if (!editStatus && (newData[topicIndex]?.subTopicArray[subTopicIndex]?.classArray[classIndex]?.className?.trim()?.length === 0)) {
  //         isEmpty = true;
  //       } else {
  //         newData[topicIndex] = {
  //           ...newData[topicIndex],
  //           subTopicArray: updateNestedArray(newData[topicIndex].subTopicArray, subTopicIndex, (subTopic) => ({
  //             ...subTopic,
  //             classArray: updateNestedArray(subTopic.classArray, classIndex, (cls) => ({
  //               ...cls,
  //               status: !editStatus,
  //               isEdit: editStatus,
  //               fieldStatus: !editStatus && newData[topicIndex]?.fieldStatus !== EFieldStatus.NEW && !isCanceled ? EFieldStatus.EDITED : newData[topicIndex]?.fieldStatus
  //             })),
  //           })),
  //         };
  //       }
  //       break;
  //     case EQuickActionsType.content:
  //       content = 'content';
  //       const contentValue = newData[topicIndex].subTopicArray[subTopicIndex].classArray[classIndex].contentArray[contentIndex];
  //       let { attachment, fileOtherNotes, testmiMapping, allComments, freeForCommunity, videoLink, ...rest } = contentValue;
  //       updateContent({ attachment, fileOtherNotes, testmiMapping, allComments, freeForCommunity, ...rest, topicIndex, subTopicIndex, classIndex, contentIndex });
  //       let indexData = data[topicIndex]?.subTopicArray?.[subTopicIndex]?.classArray?.[classIndex]?.contentArray?.[contentIndex];
  //       if (indexData?.mappingId) {
  //         setMappingId(indexData?.mappingId)
  //       };

  //       let pricingData = indexData?.planPricing;
  //       let links = indexData?.videoLinkMapping;

  //       await handlePricingData(pricingData);
  //       await handleYoutubeLink(links);

  //       break;
  //     default:
  //       break;
  //   }

  //   if (isEmpty) {
  //     message.error(`Must fill the ${content}`);
  //   } else {
  //     setData(newData);
  //   }
  // };

  const handleDataUpdate = async (type, topicIndex, subTopicIndex = null, contentIndex = null, editStatus = false, isCanceled = false) => {
    const newData = [...data];
    let isEmpty = false;
    let content = '';

    switch (type) {
      case EQuickActionsType.topic:
        content = 'topic';
        if (!editStatus && (newData[topicIndex]?.topicName?.trim()?.length === 0)) {
          isEmpty = true;
        } else {
          newData[topicIndex] = {
            ...newData[topicIndex], status: !editStatus, isEdit: editStatus,
            fieldStatus: !editStatus && newData[topicIndex]?.fieldStatus !== EFieldStatus.NEW && !isCanceled ? EFieldStatus.EDITED : newData[topicIndex]?.fieldStatus
          };
        }
        break;
      case EQuickActionsType.subTopic:
        content = 'subtopic';
        if (!editStatus && (newData[topicIndex]?.subTopicArray[subTopicIndex]?.subTopicName?.trim()?.length === 0)) {
          isEmpty = true;
        } else {
          newData[topicIndex] = {
            ...newData[topicIndex],
            subTopicArray: updateNestedArray(newData[topicIndex].subTopicArray, subTopicIndex, (subTopic) => ({
              ...subTopic,
              status: !editStatus,
              isEdit: editStatus,
              fieldStatus: !editStatus && newData[topicIndex]?.fieldStatus !== EFieldStatus.NEW && !isCanceled ? EFieldStatus.EDITED : newData[topicIndex]?.fieldStatus
            })),
          };
        }
        break;

      case EQuickActionsType.content:
        content = 'content';
        const contentValue = newData[topicIndex].subTopicArray[subTopicIndex].contentArray[contentIndex];
        let { attachment, fileOtherNotes, fileUpload, testmiMapping, allComments, freeForCommunity, videoLink, ...rest } = contentValue;

        updateContent({
          attachment,
          fileOtherNotes,
          fileUpload,
          testmiMapping,
          allComments,
          freeForCommunity,
          ...rest,
          topicIndex,
          subTopicIndex,
          contentIndex
        });

        let indexData = newData[topicIndex]?.subTopicArray?.[subTopicIndex]?.contentArray?.[contentIndex];
        if (indexData?.mappingId) {
          setMappingId(indexData?.mappingId);
        }

        let pricingData = indexData?.planPricing;
        let links = indexData?.videoLinkMapping;

        await handlePricingData(pricingData);
        await handleYoutubeLink(links);

        break;
      default:
        break;
    }

    if (isEmpty) {
      message.error(`Must fill the ${content}`);
    } else {
      setData(newData);
    }
  };

  const updateContent = (updatedValues) => {

    drawerFormFormik.setValues({
      ...updatedValues,
      testmiMapping: updatedValues['testmiMapping'],
      educatorMapping: updatedValues?.educatorMapping || [],
      attachment: updatedValues['attachment'],
      fileOtherNotes: updatedValues['fileOtherNotes'],
      fileUpload: updatedValues['fileUpload']
    });

    setContentFieldStatus(updatedValues?.fieldStatus);
    setSelectedCards(updatedValues?.testmiMapping);
    setAttachmentFile(updatedValues['attachment']);
    setFileNotesOthers(updatedValues['fileOtherNotes']);
    setCommentsOpen(updatedValues?.allComments == true);
    setCommunityFree(updatedValues?.freeForCommunity == true);
    setFreeTrial(updatedValues?.freeTrial == true);
    setDrawerIsEdit(true);
    setDrawerVisible(true);
  };

  const handleQuickActionChange = async (value, type, topicIndex) => {
    await quickActionOnChange(value, type, topicIndex);
    setSelectedAction(null);
  };

  // previous code
  // const quickActionOnChange = async (value, type, topicIndex, subTopicIndex = null, classIndex = null, contentIndex = null) => {

  //   pricingFormik.resetForm({ values: pricingInitialValues })
  //   setTopicCurrentId(topicIndex);
  //   setSubTopicCurrentId(subTopicIndex);
  //   setClassCurrentId(classIndex);
  //   setContentCurrentId(contentIndex);
  //   setCurrentSelectedType(type);
  //   setPopoverOpenIndex(null);
  //   setPrices([]);
  //   setYoutubeLinkMapping([]);
  //   setMappingId(null);

  //   if (value === EQuickActions.edit) {
  //     await handleDataUpdate(type, topicIndex, subTopicIndex, classIndex, contentIndex, true);
  //   }
  //   else if (value === EQuickActions.paymentConfig) {

  //     let pricingData;
  //     let indexData;
  //     let links;

  //     switch (type) {
  //       case EQuickActionsType.content:
  //         indexData = data[topicIndex]?.subTopicArray?.[subTopicIndex]?.classArray?.[classIndex]?.contentArray?.[contentIndex];
  //         if (indexData?.mappingId) {
  //           setMappingId(indexData.mappingId)
  //         };

  //         pricingData = indexData.planPricing;
  //         break;

  //       case EQuickActionsType.class:
  //         indexData = data?.[topicIndex]?.subTopicArray?.[subTopicIndex]?.classArray?.[classIndex];
  //         if (indexData?.mappingId) {
  //           setMappingId(indexData.mappingId)
  //         };

  //         pricingData = indexData.planPricing;
  //         break;

  //       case EQuickActionsType.subTopic:
  //         indexData = data?.[topicIndex]?.subTopicArray?.[subTopicIndex];
  //         if (indexData?.mappingId) {
  //           setMappingId(indexData.mappingId)
  //         };

  //         pricingData = indexData.planPricing;
  //         break;

  //       case EQuickActionsType.topic:
  //         indexData = data?.[topicIndex];
  //         if (indexData?.mappingId) {
  //           setMappingId(indexData.mappingId)
  //         };

  //         pricingData = indexData.planPricing;
  //         links = indexData.youtubeLink;
  //         break;

  //       default:
  //         return;
  //     };

  //     await handlePricingData(pricingData);
  //     setPricingVisible(true);
  //   }
  //   else if (value === EQuickActions.publish) {
  //     updatePublishStatus(type, true, topicIndex, subTopicIndex, classIndex, contentIndex);
  //   }
  //   else if (value === EQuickActions.unPublish) {
  //     updatePublishStatus(type, false, topicIndex, subTopicIndex, classIndex, contentIndex);
  //   }
  // };

  const quickActionOnChange = async (value, type, topicIndex, subTopicIndex = null, contentIndex = null) => {

    pricingFormik.resetForm({ values: pricingInitialValues });
    setTopicCurrentId(topicIndex);
    setSubTopicCurrentId(subTopicIndex);
    setContentCurrentId(contentIndex);
    setCurrentSelectedType(type);
    setPopoverOpenIndex(null);
    setPrices([]);
    setYoutubeLinkMapping([]);
    setMappingId(null);

    if (value === EQuickActions.edit) {
      await handleDataUpdate(type, topicIndex, subTopicIndex, contentIndex, true);
    }
    else if (value === EQuickActions.paymentConfig) {

      let pricingData;
      let indexData;
      let links;

      switch (type) {
        case EQuickActionsType.content:
          indexData = data[topicIndex]?.subTopicArray?.[subTopicIndex]?.contentArray?.[contentIndex];
          if (indexData?.mappingId) {
            setMappingId(indexData.mappingId);
          }
          pricingData = indexData.planPricing;
          break;

        case EQuickActionsType.subTopic:
          indexData = data?.[topicIndex]?.subTopicArray?.[subTopicIndex];
          if (indexData?.mappingId) {
            setMappingId(indexData.mappingId);
          }
          pricingData = indexData.planPricing;
          break;

        case EQuickActionsType.topic:
          indexData = data?.[topicIndex];
          if (indexData?.mappingId) {
            setMappingId(indexData.mappingId);
          }
          pricingData = indexData.planPricing;
          links = indexData.youtubeLink;
          break;

        default:
          return;
      }

      await handlePricingData(pricingData);
      setPricingVisible(true);
    }
    else if (value === EQuickActions.publish) {
      updatePublishStatus(type, true, topicIndex, subTopicIndex, contentIndex);
    }
    else if (value === EQuickActions.unPublish) {
      updatePublishStatus(type, false, topicIndex, subTopicIndex, contentIndex);
    }
  };

  // previous code

  // const updateIsPublishStatus = (array, status, type) => {
  //   return array.map(item => {
  //     let updatedItem = updateFieldStatusHelper(item, status);

  //     switch (type) {
  //       case EQuickActionsType.topic:
  //         if (item.subTopicArray) {
  //           updatedItem.subTopicArray = updateIsPublishStatus(item.subTopicArray, status, EQuickActionsType.subTopic);
  //         }
  //         break;
  //       case EQuickActionsType.subTopic:
  //         if (item.classArray) {
  //           updatedItem.classArray = updateIsPublishStatus(item.classArray, status, EQuickActionsType.class);
  //         }
  //         break;
  //       case EQuickActionsType.class:
  //         if (item.contentArray) {
  //           updatedItem.contentArray = updateIsPublishStatus(item.contentArray, status, EQuickActionsType.content);
  //         }
  //         break;
  //       default:
  //         break;
  //     }

  //     return updatedItem;
  //   });
  // };

  // previous code

  // const updatePublishStatus = (type, status, topicIndex, subTopicIndex, classIndex, contentIndex) => {
  //   setData(prevData => {

  //     const updateContent = contentItem => updateFieldStatusHelper(contentItem, status);

  //     const updateClass = classItem => ({
  //       ...updateFieldStatusHelper(classItem, status),
  //       contentArray: updateIsPublishStatus(classItem.contentArray, status, EQuickActionsType.content),
  //     });

  //     const updateSubTopic = subTopic => ({
  //       ...updateFieldStatusHelper(subTopic, status),
  //       classArray: updateIsPublishStatus(subTopic.classArray, status, EQuickActionsType.class),
  //     });

  //     const updateTopic = topic => ({
  //       ...updateFieldStatusHelper(topic, status),
  //       subTopicArray: updateIsPublishStatus(topic.subTopicArray, status, EQuickActionsType.subTopic),
  //     });

  //     let updatedData = [...prevData];

  //     switch (type) {
  //       case EQuickActionsType.topic:
  //         updatedData = updateNestedArray(updatedData, topicIndex, updateTopic);
  //         break;
  //       case EQuickActionsType.subTopic:
  //         updatedData = updateNestedArray(updatedData, topicIndex, topic => ({
  //           ...topic,
  //           subTopicArray: updateNestedArray(topic.subTopicArray, subTopicIndex, updateSubTopic),
  //         }));
  //         break;
  //       case EQuickActionsType.class:
  //         updatedData = updateNestedArray(updatedData, topicIndex, topic => ({
  //           ...topic,
  //           subTopicArray: updateNestedArray(topic.subTopicArray, subTopicIndex, subTopic => ({
  //             ...subTopic,
  //             classArray: updateNestedArray(subTopic.classArray, classIndex, updateClass),
  //           })),
  //         }));
  //         break;
  //       case EQuickActionsType.content:
  //         updatedData = updateNestedArray(updatedData, topicIndex, topic => ({
  //           ...topic,
  //           subTopicArray: updateNestedArray(topic.subTopicArray, subTopicIndex, subTopic => ({
  //             ...subTopic,
  //             classArray: updateNestedArray(subTopic.classArray, classIndex, classItem => ({
  //               ...classItem,
  //               contentArray: updateNestedArray(classItem.contentArray, contentIndex, updateContent),
  //             })),
  //           })),
  //         }));
  //         break;
  //       default:
  //         return prevData;
  //     }

  //     return updatedData;
  //   });
  // };

  const updateIsPublishStatus = (array, status, type) => {
    return array.map(item => {
      let updatedItem = updateFieldStatusHelper(item, status);

      switch (type) {
        case EQuickActionsType.topic:
          if (item.subTopicArray) {
            updatedItem.subTopicArray = updateIsPublishStatus(item.subTopicArray, status, EQuickActionsType.subTopic);
          }
          break;
        case EQuickActionsType.subTopic:
          if (item.contentArray) {
            updatedItem.contentArray = updateIsPublishStatus(item.contentArray, status, EQuickActionsType.content);
          }
          break;
        default:
          break;
      }

      return updatedItem;
    });
  };

  const updatePublishStatus = (type, status, topicIndex, subTopicIndex, contentIndex) => {
    setData(prevData => {

      const updateContent = contentItem => updateFieldStatusHelper(contentItem, status);

      const updateSubTopic = subTopic => ({
        ...updateFieldStatusHelper(subTopic, status),
        contentArray: updateIsPublishStatus(subTopic.contentArray, status, EQuickActionsType.content),
      });

      const updateTopic = topic => ({
        ...updateFieldStatusHelper(topic, status),
        subTopicArray: updateIsPublishStatus(topic.subTopicArray, status, EQuickActionsType.subTopic),
      });

      let updatedData = [...prevData];

      switch (type) {
        case EQuickActionsType.topic:
          updatedData = updateNestedArray(updatedData, topicIndex, updateTopic);
          break;
        case EQuickActionsType.subTopic:
          updatedData = updateNestedArray(updatedData, topicIndex, topic => ({
            ...topic,
            subTopicArray: updateNestedArray(topic.subTopicArray, subTopicIndex, updateSubTopic),
          }));
          break;
        case EQuickActionsType.content:
          updatedData = updateNestedArray(updatedData, topicIndex, topic => ({
            ...topic,
            subTopicArray: updateNestedArray(topic.subTopicArray, subTopicIndex, subTopic => ({
              ...subTopic,
              contentArray: updateNestedArray(subTopic.contentArray, contentIndex, updateContent),
            })),
          }));
          break;
        default:
          return prevData;
      }

      return updatedData;
    });
  };

  const handlePricingData = async (pricingData) => {

    if (pricingData?.length) {
      const { actualPrice = '', price = '', frequencyId = '', isFree = false, pricingId = '', fieldStatus = EFieldStatus.NEW } = pricingData?.[0] || {};

      pricingFormik.setValues({
        actualPrice: actualPrice,
        price: price,
        frequencyId: frequencyId,
        isFree: isFree,
        pricingId: pricingId,
        fieldStatus: fieldStatus
      });

      const remainingPrices = pricingData?.slice(1)?.map?.(({ actualPrice = '', price = '', frequencyId = '', isFree = false, pricingId = '', fieldStatus = EFieldStatus.NEW }) => ({
        actualPrice,
        price,
        frequencyId,
        isFree,
        pricingId,
        fieldStatus
      }));

      setPrices(remainingPrices);
    }
  };

  const handleYoutubeLink = async (links) => {

    if (links?.length) {

      const { link = '' } = links?.[0];

      drawerFormFormik.setFieldValue('videoLink', link);

      const remainingLinks = links?.slice(1)?.map?.((item) => item);

      setYoutubeLinkMapping(remainingLinks);
    }
  };

  // previous code
  // const updateEditStatus = async (type, topicIndex, subTopicIndex = null, classIndex = null, contentIndex = null) => {
  //   await handleDataUpdate(type, topicIndex, subTopicIndex, classIndex, contentIndex, false);
  // };

  // const cancelEditStatus = async (type, topicIndex, subTopicIndex = null, classIndex = null, contentIndex = null) => {
  //   await handleDataUpdate(type, topicIndex, subTopicIndex, classIndex, contentIndex, false, true);
  // };

  const updateEditStatus = async (type, topicIndex, subTopicIndex = null, classIndex = null, contentIndex = null) => {
    await handleDataUpdate(type, topicIndex, subTopicIndex, classIndex, contentIndex, false);
  };

  const cancelEditStatus = async (type, topicIndex, subTopicIndex = null, classIndex = null, contentIndex = null) => {
    await handleDataUpdate(type, topicIndex, subTopicIndex, classIndex, contentIndex, false, true);
  };

  const insertCurriculum = async () => {
    let formData = await buildFormData();
    dispatch(insertCurriculumDetails(formData)).unwrap()
      .then(async (res) => {
        if (res?.resSuccess) {
          await dispatch(getCurriculumDetailsById(tempCourseMappingId)).unwrap();
          await dispatch(getPricingDetailsById(courseMappingId || tempCourseMappingId));
          await dispatch(setSaveStatus({ field: 'curriculumSaveStatus', value: false }));
          handleSaveAndNextChanges();
        };
      }).catch((error) => { });
  };

  const updateCurriculum = async () => {
    let formData = await buildFormData();
    let payload = {
      data: formData,
      id: courseMappingId || tempCourseMappingId,
    };
    dispatch(updateCurriculumDetails(payload)).unwrap()
      .then(async (res) => {
        if (res?.resSuccess) {
          await dispatch(getCurriculumDetailsById(courseMappingId || tempCourseMappingId));
          await dispatch(getPricingDetailsById(courseMappingId || tempCourseMappingId));
          handleSaveAndNextChanges();
        };
      }).catch((error) => { });
  };

  const buildFormData = async () => {

    // const keysToOmit = ['status', 'addContentStatus', 'addClassStatus', 'addSubtopicStatus', 'isEdit', 'videoLink'];
    const keysToOmit = ['status', 'addContentStatus', 'addClassStatus', 'addSubtopicStatus', 'isEdit', 'videoLink', 'planPricing'];

    const omitSpecifiedKeys = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map(omitSpecifiedKeys);
      } else if (obj && typeof obj === 'object') {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([key]) => !keysToOmit.includes(key))
            .map(([key, value]) => [key, omitSpecifiedKeys(value)])
        );
      }
      return obj;
    };

    let topicArray = await omitSpecifiedKeys(data);
    const transformedTopics = await transformTopics(topicArray);

    const tempData = {
      topicArray: transformedTopics,
      courseMappingId: !isEditAction ? tempCourseMappingId : courseMappingId,
      ...((isEditAction || !curriculumSaveStatus) && {
        ...(deletedAttachment?.length && { deletedAttachment }),
        ...(deletedFilesNotes?.length && { deletedFilesNotes }),
        // ...(topicDeletedPricing?.pricingIds?.length && { topicDeletedPricing }),
        // ...(subTopicDeletedPricing?.pricingIds?.length && { subTopicDeletedPricing }),
        // ...(classDeletedPricing?.pricingIds?.length && { classDeletedPricing }),
        // ...(contentDeletedPricing?.pricingIds?.length && { contentDeletedPricing })
      })
    };

    const formData = {
      data: JSON.stringify(tempData)
    };

    return formData;
  };

  // previous code

  // const transformTopics = (topics) => {
  //   return topics?.map((topic) => {
  //     const subTopicArray = topic?.subTopicArray?.map((subtopic) => {
  //       const classArray = subtopic?.classArray?.map((classObj) => {
  //         const contentArray = classObj?.contentArray?.map((content) => {
  //           const { attachment = [], fileOtherNotes = [], ...rest } = content;
  //           let attachments = extractFileLocations(attachment) || [];
  //           let notesAndOthers = extractFileLocations(fileOtherNotes) || [];
  //           return { ...rest, attachments, notesAndOthers };
  //         });
  //         return { ...classObj, contentArray };
  //       });
  //       return { ...subtopic, classArray };
  //     });
  //     return { ...topic, subTopicArray };
  //   });
  // };
  
    const transformTopics = async(topics) => {
      const transformedTopics = [];
    
      for (const topic of topics || []) {
          const subTopicArray = [];
      
          for (const subtopic of topic?.subTopicArray || []) {
              const contentArray = [];
          
              for (const content of subtopic?.contentArray || []) {
                  const { attachment = [], fileOtherNotes = [], ...rest } = content;
              
                  let attachments = await extractFileLocations(attachment) || [];
                  let notesAndOthers = await extractFileLocations(fileOtherNotes) || [];
                  
                  contentArray.push({ ...rest, attachments, notesAndOthers });
              }
            
              subTopicArray.push({ ...subtopic, contentArray });
          }
        
          transformedTopics.push({ ...topic, subTopicArray });
      }
    
      return transformedTopics;
  };
  
  useEffect(() => {
    formValidationSubjects.curriculum.next(drawerFormFormik.isValid);
  }, [drawerFormFormik.values, drawerFormFormik.errors, formValidationSubjects.curriculum]);

  // const handleOpenChange = (newOpen, topicIndex, subTopicIndex, classIndex = null, contentIndex = null) => {
  //   let uniqueIndex = `topic_${topicIndex}subTopic_${subTopicIndex}`;

  //   if (classIndex !== null) {
  //     uniqueIndex += `class_${classIndex}`;
  //   }

  //   if (contentIndex !== null) {
  //     uniqueIndex += `content_${contentIndex}`;
  //   }

  //   if (newOpen) {
  //     setPopoverOpenIndex(uniqueIndex);
  //   } else {
  //     setPopoverOpenIndex(null);
  //   }
  // };

  const handleOpenChange = (newOpen, topicIndex, subTopicIndex, contentIndex = null) => {
    let uniqueIndex = `topic_${topicIndex}subTopic_${subTopicIndex}`;

    if (contentIndex !== null) {
      uniqueIndex += `content_${contentIndex}`;
    }

    if (newOpen) {
      setPopoverOpenIndex(uniqueIndex);
    } else {
      setPopoverOpenIndex(null);
    }
  };

  const pricingInitialValues = {
    actualPrice: "",
    price: "",
    frequencyId: "",
    isFree: false,
    pricingId: '',
    fieldStatus: EFieldStatus.NEW
  };

  const PricingValidationSchema = yup.object({
    actualPrice: yup.number()
      .required("Actual price is Required")
      .positive('Actual price must be a positive integer')
      .integer('Actual price must be an integer'),
    price: yup.number()
      .required("Price is Required")
      .positive('Price must be a positive integer')
      .integer('Price must be an integer')
      .when('actualPrice', (actualPrice, schema) =>
        schema.max(actualPrice, 'Price must be less than or equal to the actual price')
      ),
    frequencyId: yup.string().required('Frequency is Required'),
    isFree: yup.boolean(),
  });


  const pricingFormik = useFormik({
    initialValues: pricingInitialValues,
    validationSchema: PricingValidationSchema,
    onSubmit: async (values) => {
      const { isFree, ...rest } = values;

      let planPricing = [
        rest,
        ...prices
      ];

      planPricing = planPricing?.map(item => {

        let newItem = { ...item };

        if (newItem.fieldStatus === EFieldStatus.NEW) {
          delete newItem.pricingId;
        };

        return newItem;
      });

      // await handlePricing(planPricing);
      resetPricingForm();
    },
  });

  const pricingDrawerCloser = () => {
    setPricingVisible(false);
    pricingFormik.resetForm({ values: pricingInitialValues });
  };

  const addPrice = (values) => {
    const { isFree, ...rest } = values;
    const newPrice = { ...rest };
    setPrices([...prices, newPrice]);
    pricingFormik.resetForm({ values: pricingInitialValues });
  };

  const addYoutubeLink = (values) => {
    const { videoLink = '-' } = values;
    setYoutubeLinkMapping([...youtubeLinkMapping, { link: videoLink }]);
    drawerFormFormik.setFieldValue('videoLink', '')
  };

  const pricingSwitchChange = (value, isDrawerForm) => {
    if (!isDrawerForm) {
      pricingFormik.setFieldValue("isFree", value);
    }
  };

  const resetPricingForm = () => {
    setTopicCurrentId(null);
    setSubTopicCurrentId(null);
    // setClassCurrentId(null);
    setContentCurrentId(null);
    setCurrentSelectedType(null);
    setPricingVisible(false);
    pricingFormik.resetForm({ values: pricingInitialValues })
  };

  // previous code

  // const handlePricing = async (values) => {
  //   let newData;

  //   switch (currentSelectedType) {
  //     case EQuickActionsType.topic:
  //       newData = data?.map((topic, topicIndex) => {
  //         if (topicIndex !== currentTopicId) return topic;
  //         return { ...topic, planPricing: values };
  //       });
  //       break;

  //     case EQuickActionsType.subTopic:
  //       newData = data?.map?.((topic, topicIndex) => {
  //         if (topicIndex !== currentTopicId) return topic;

  //         const updatedSubTopics = topic.subTopicArray.map?.((subTopic, subIndex) => {
  //           if (subIndex !== currentSubTopicId) return subTopic;
  //           return { ...subTopic, planPricing: values };
  //         });

  //         return {
  //           ...topic,
  //           subTopicArray: updatedSubTopics
  //         };
  //       });
  //       break;

  //     case EQuickActionsType.class:
  //       newData = data?.map((topic, topicIndex) => {
  //         if (topicIndex !== currentTopicId) return topic;

  //         const updatedSubTopics = topic.subTopicArray?.map?.((subTopic, subIndex) => {
  //           if (subIndex !== currentSubTopicId) return subTopic;

  //           const updatedClasses = subTopic.classArray?.map?.((cls, classIndex) => {
  //             if (classIndex !== currentClassId) return cls;
  //             return { ...cls, planPricing: values };
  //           });

  //           return {
  //             ...subTopic,
  //             classArray: updatedClasses
  //           };
  //         });

  //         return {
  //           ...topic,
  //           subTopicArray: updatedSubTopics
  //         };
  //       });
  //       break;

  //     case EQuickActionsType.content:
  //       newData = data?.map((topic, topicIndex) => {
  //         if (topicIndex !== currentTopicId) return topic;

  //         const updatedSubTopics = topic?.subTopicArray.map((subTopic, subIndex) => {
  //           if (subIndex !== currentSubTopicId) return subTopic;

  //           const updatedClasses = subTopic?.classArray.map((cls, classIndex) => {
  //             if (classIndex !== currentClassId) return cls;

  //             const updatedContentArray = cls.contentArray?.map((content, contentIndex) => {
  //               if (contentIndex !== currentContentId) return content;

  //               return { ...content, planPricing: values };
  //             });

  //             return {
  //               ...cls,
  //               contentArray: updatedContentArray
  //             };
  //           });

  //           return {
  //             ...subTopic,
  //             classArray: updatedClasses
  //           };
  //         });

  //         return {
  //           ...topic,
  //           subTopicArray: updatedSubTopics
  //         };
  //       });
  //       break;

  //     default:
  //       return;
  //   }

  //   setData(newData);
  // };

  const handlePricing = async (values) => {
    let newData;

    switch (currentSelectedType) {
      case EQuickActionsType.topic:
        newData = data?.map((topic, topicIndex) => {
          if (topicIndex !== currentTopicId) return topic;
          return { ...topic, planPricing: values };
        });
        break;

      case EQuickActionsType.subTopic:
        newData = data?.map((topic, topicIndex) => {
          if (topicIndex !== currentTopicId) return topic;

          const updatedSubTopics = topic.subTopicArray.map((subTopic, subIndex) => {
            if (subIndex !== currentSubTopicId) return subTopic;
            return { ...subTopic, planPricing: values };
          });

          return {
            ...topic,
            subTopicArray: updatedSubTopics
          };
        });
        break;

      case EQuickActionsType.content:
        newData = data?.map((topic, topicIndex) => {
          if (topicIndex !== currentTopicId) return topic;

          const updatedSubTopics = topic?.subTopicArray.map((subTopic, subIndex) => {
            if (subIndex !== currentSubTopicId) return subTopic;

            const updatedContentArray = subTopic?.contentArray.map((content, contentIndex) => {
              if (contentIndex !== currentContentId) return content;
              return { ...content, planPricing: values };
            });

            return {
              ...subTopic,
              contentArray: updatedContentArray
            };
          });

          return {
            ...topic,
            subTopicArray: updatedSubTopics
          };
        });
        break;

      default:
        return;
    }

    setData(newData);
  };

  const deletePricing = (values, index) => {

    const updatedPrices = prices.filter((_, i) => i !== index);
    setPrices(updatedPrices);

    if (values?.fieldStatus !== EFieldStatus.NEW && values?.pricingId && mappingId) {

      switch (currentSelectedType) {

        case EQuickActionsType.course:
          deletePricingPlanHelper(courseDeletedPricing, setCourseDeletedPricing, mappingId, values.pricingId);
          break;

        case EQuickActionsType.topic:
          deletePricingPlanHelper(topicDeletedPricing, setTopicDeletedPricing, mappingId, values.pricingId);
          break;

        case EQuickActionsType.subTopic:
          deletePricingPlanHelper(subTopicDeletedPricing, setSubTopicDeletedPricing, mappingId, values.pricingId);
          break;

        case EQuickActionsType.class:
          deletePricingPlanHelper(classDeletedPricing, setClassDeletedPricing, mappingId, values.pricingId);
          break;

        case EQuickActionsType.content:
          deletePricingPlanHelper(contentDeletedPricing, setContentDeletedPricing, mappingId, values.pricingId);
          break;
      };
    };
  };

  const deleteYoutubeLink = (values, index) => {

    const updatedLink = youtubeLinkMapping?.filter((_, i) => i !== index);
    setYoutubeLinkMapping(updatedLink);

    // if (values?.fieldStatus !== EFieldStatus.NEW) {
    //   setDeletedYoutubeLink([]);
    // };
  };

  const startUpload = async (selectedFile) => {

    const objectname = selectedFile.name;
    setSelectedFile(selectedFile);

    try {
      const response = await dispatch(createMultipartUpload(objectname)).unwrap();
      let { uploadId, objectKey } = response;
      objectKey = removePrefix(objectKey);
      setObjectKey(objectKey);
      const partSize = ChunkSize;
      const totalParts = Math.ceil(selectedFile.size / partSize);
      await dispatch(setTotalParts({ value: totalParts }));
      await uploadFilePartsHelper(selectedFile, objectKey, uploadId, totalParts, partSize, dispatch);
      setUploadPartCompleted(true);
    } catch (error) {
    }
  };

  const completeUpload = async (objectKey, uploadId, selectedFile, parts) => {
    try {
      let response = await completeFileUploadHelper(objectKey, uploadId, parts, dispatch);
      const { data, success } = response;
      if (success) {
        const updatedAttachment = await getAttachmentFiles();
        await updateFileLocationHelper(selectedFile, updatedAttachment, setAttachmentFile, data, drawerFormFormik, 'attachment');

        const fileIndex = updatedAttachment?.findIndex(f => f?.uid === selectedFile?.uid);

        if (fileIndex !== -1) {

          const updatedFile = {
            uid: updatedAttachment[fileIndex]?.uid,
            name: updatedAttachment[fileIndex]?.name,
            size: updatedAttachment[fileIndex]?.size,
            type: updatedAttachment[fileIndex]?.type,
            fileLocation: { ...data, duration }
          };

          await new Promise(resolve => {
            setAttachmentFile(prevFiles => {
              const updatedFiles = [...prevFiles];
              updatedFiles[fileIndex] = updatedFile;
              resolve(updatedFiles);
              return updatedFiles;
            });
          });

          const getAttachment = await getAttachmentFiles();
          await drawerFormFormik.setFieldValue('attachment', getAttachment);
        };
      };
    } catch (error) { }
    dispatch(resetState());
    setSelectedFile('');
    setUploadPartCompleted(false);
    setDuration(null);
  }

  const getFileNotesOther = async () => {
    return await new Promise(resolve => {
      setFileNotesOthers(prevFiles => {
        resolve(prevFiles);
        return prevFiles;
      });
    });
  };


  const getAttachmentFiles = async () => {
    return await new Promise(resolve => {
      setAttachmentFile(prevFiles => {
        resolve(prevFiles);
        return prevFiles;
      });
    });
  };

  const getQuickActionsOptions = (isPublish, isTopic = false) => {

    const specificOption = isPublish
      ? { value: EQuickActions.unPublish, label: isTopic ? 'Unpublish All' : 'Unpublish' }
      : { value: EQuickActions.publish, label: isTopic ? 'Publish All' : 'Publish' };

    return [...quickActionsOptions, specificOption];
  };

  const confirmModalOpen = (isEdit = false) => {

    if (isEdit) {
      setAction('Update the content');
      setIsContentEdit(true)
    } else {
      setAction('Save the content');
      setIsContentEdit(false);
    };
    setModalVisible(true);
  };

  const confirmContentChanges = () => {
    setModalVisible(false);
    isContentEdit ? updateContentSave(formikValues, pricingValues) : saveContent(formikValues, pricingValues);
  };

  return (
    <>
      <BeforeUnloadHandler isFormModified={isFormModified} />

      <div className='curriculam-content'>

        {/* previous code */}
        {/* <div>
          {data?.map?.((topic, topicIndex) => (
            <div key={`topic-${topicIndex}`} className='topic-curriculam'>
              {topic?.status === false ? (
                <div className='topic-content'>
                  <div className='topic-input-section btn-input-btn'>
                    <Input
                      placeholder="Enter the Topic"
                      value={topic.topicName}
                      onChange={e => updateTopicName(topicIndex, e.target.value)}
                    />
                    {topic?.isEdit == true ? (
                      <>
                        <Button className='save-btn' onClick={() => updateEditStatus(EQuickActionsType.topic, topicIndex)}>Update</Button>
                        <p className='cancel-btn' onClick={() => cancelEditStatus(EQuickActionsType.topic, topicIndex)}>Cancel</p>
                      </>
                    ) : (
                      <>
                        <Button className='save-btn' onClick={() => addSubtopic(topicIndex)}>Save</Button>
                        <p className='cancel-btn' onClick={() => topicCancelBtn(topicIndex)}>Cancel</p>
                      </>
                    )}
                  </div>
                </div>

              ) : (
                <div className='topic-content-setting'>
                  <div className='topic-name'>
                    <img src={topicImage} alt="Image Icon" className="icon" />
                    <p>{topic?.topicName}</p>
                  </div>
                  <div className="topic-settig">
                    <Select
                      className='quick-action'
                      showSearch
                      placeholder="Quick Action"
                      optionFilterProp="children"
                      value={selectedAction}
                      onChange={(value) => handleQuickActionChange(value, EQuickActionsType.topic, topicIndex)}
                      options={getQuickActionsOptions(topic?.isPublish, true)}
                    />
                  </div>
                </div>
              )}
              {topic?.subTopicArray?.map?.((subtopic, subIndex) => (
                <div key={`subtopic-${topicIndex}-${subIndex}`} className='sub-topic-input-content'>
                  {subtopic?.status === false ? (
                    <div className='btn-input-btn'>
                      <Input
                        placeholder="Enter the Subtopic"
                        value={subtopic.subTopicName}
                        onChange={e => updateSubtopicName(topicIndex, subIndex, e.target.value)}
                      />
                      {subtopic?.isEdit == true ? (
                        <>
                          <Button className='save-btn' onClick={() => updateEditStatus(EQuickActionsType.subTopic, topicIndex, subIndex)}>Update</Button>
                          <p className='cancel-btn' onClick={() => cancelEditStatus(EQuickActionsType.subTopic, topicIndex, subIndex)}>Cancel</p>
                        </>
                      ) : (
                        <>
                          <Button className='save-btn' onClick={() => addClass(topicIndex, subIndex)}>Save</Button>
                          <p className='cancel-btn' onClick={() => subtopicCancelBtn(topicIndex, subIndex)}>Cancel</p>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className='sub-topic-content-setting'>
                      <div className='sub-topic-name' >
                        <img src={subTopicImg} alt="Image Icon" className="icon" />
                        <p>{subtopic?.subTopicName}</p>
                      </div>
                      <div className="sub-topic-settig">
                        {renderPublishStatusHelper(subtopic?.isPublish)}
                        <div className='sub-topic-setting-icon'>
                          <Popover
                            content={
                              getQuickActionsOptions(subtopic?.isPublish).map((item) => (
                                <p key={item.value} onClick={() => quickActionOnChange(item.value, EQuickActionsType.subTopic, topicIndex, subIndex)}>{item.label}</p>
                              ))
                            }
                            open={popoverOpenIndex === (`topic_${topicIndex}subTopic_${subIndex}`)}
                            trigger="click"
                            onOpenChange={(newOpen) => handleOpenChange(newOpen, topicIndex, subIndex)}
                          >
                            <img src={settingIcon} alt="Image Icon" className="sub-settingIcon-icon icon" />
                          </Popover>
                        </div>
                      </div>
                    </div>
                  )}
                  {subtopic?.classArray?.map?.((cls, classIndex) => (
                    <div key={`class-${topicIndex}-${subIndex}-${classIndex}`} className='class-content'>
                      {cls.status === false ? (
                        <div className='btn-input-btn'>
                          <Input
                            placeholder="Enter the class"
                            value={cls.className}
                            onChange={e => updateClassName(topicIndex, subIndex, classIndex, e.target.value)}
                          />
                          {cls?.isEdit == true ? (
                            <>
                              <Button className='save-btn' onClick={() => updateEditStatus(EQuickActionsType.class, topicIndex, subIndex, classIndex)}>Update</Button>
                              <p className='cancel-btn' onClick={() => cancelEditStatus(EQuickActionsType.class, topicIndex, subIndex, classIndex)}>Cancel</p>
                            </>
                          ) : (
                            <>
                              <Button className='save-btn' onClick={() => saveClassBtn(topicIndex, subIndex, classIndex)}>Save</Button>
                              <p className='cancel-btn' onClick={() => classCancelBtn(topicIndex, subIndex, classIndex)}>Cancel</p>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className='class-content-setting'>
                          <div className='class-name'>
                            <img src={classImg} alt="Image Icon" className="icon" />
                            <p>{cls?.className}</p>
                          </div>
                          <div className="class-settig">
                            {renderPublishStatusHelper(cls?.isPublish)}
                            <div className='class-setting-icon'>
                              <Popover
                                content={
                                  getQuickActionsOptions(cls?.isPublish).map((item) => (
                                    <p key={item.value} onClick={() => quickActionOnChange(item.value, EQuickActionsType.class, topicIndex, subIndex, classIndex)}>{item.label}</p>
                                  ))
                                }
                                open={popoverOpenIndex === (`topic_${topicIndex}subTopic_${subIndex}class_${classIndex}`)}
                                trigger="click"
                                onOpenChange={(newOpen) => handleOpenChange(newOpen, topicIndex, subIndex, classIndex)}
                              >
                                <img src={settingIcon} alt="Image Icon" className="sub-settingIcon-icon icon" />
                              </Popover>
                            </div>
                          </div>
                        </div>
                      )}
                      {cls?.contentArray?.map((con, conIndex) => (
                        <div className='content-container'>
                          <div key={`content-${topicIndex}-${subIndex}-${classIndex}-${conIndex}`} className='sub-topic-content-setting'>
                            <div className='content-name' >
                              <img src={contentImg} alt="Image Icon" className="icon" />
                              <p>{con?.videoName}</p>
                            </div>
                            <div className="sub-topic-settig">
                              {renderPublishStatusHelper(con?.isPublish)}
                              <div className='sub-topic-setting-icon'>
                                <Popover
                                  content={
                                    getQuickActionsOptions(con?.isPublish).map((item) => (
                                      <p key={item.value} onClick={() => quickActionOnChange(item.value, EQuickActionsType.content, topicIndex, subIndex, classIndex, conIndex)}>{item.label}</p>
                                    ))
                                  }
                                  open={popoverOpenIndex === (`topic_${topicIndex}subTopic_${subIndex}class_${classIndex}content_${conIndex}`)}
                                  trigger="click"
                                  onOpenChange={(newOpen) => handleOpenChange(newOpen, topicIndex, subIndex, classIndex, conIndex)}
                                >
                                  <img src={settingIcon} alt="Image Icon" className="sub-settingIcon-icon icon" />
                                </Popover>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {
                        cls?.addContentStatus ? (
                          <div className='content-content-btn'>
                            <p className='add-content-btn add-btn' onClick={() => addContentBtn(topicIndex, subIndex, classIndex)}>+ Add Content</p>
                          </div>
                        ) : ''
                      }
                    </div>
                  ))}
                  {
                    subtopic?.addClassStatus ? (
                      <div className='class-content-btn'>
                        <p className='add-class-btn add-btn' onClick={() => addClass(topicIndex, subIndex)}>+ Add Class</p>
                      </div>
                    ) : ''
                  }
                </div>
              ))}
              {
                topic?.addSubtopicStatus ? (
                  <div className='add-subtopic-btn-divition'>
                    <p className='add-subtopic-btn add-btn' onClick={() => { addSubtopic(topicIndex) }}>+ Add Subtopic</p>
                  </div>
                ) : ''
              }
            </div>
          ))}
          <p className='add-topic-btn add-btn' onClick={addTopic}>+ Add Topic</p>
        </div> */}

        <div className='curriculum-main-container'>
          {data?.map((topic, topicIndex) => (
            <div key={`topic-${topicIndex}`} className="topic-curriculam">
              {topic?.status === false ? (
                <div className="topic-content">
                  <div className="topic-input-section btn-input-btn">
                    <Input
                      placeholder="Enter the Topic"
                      value={topic.topicName}
                      onChange={(e) => updateTopicName(topicIndex, e.target.value)}
                    />
                    {topic?.isEdit ? (
                      <>
                        <Button
                          className="save-btn"
                          onClick={() => updateEditStatus(EQuickActionsType.topic, topicIndex)}
                        >
                          Update
                        </Button>
                        <p
                          className="cancel-btn"
                          onClick={() => cancelEditStatus(EQuickActionsType.topic, topicIndex)}
                        >
                          Cancel
                        </p>
                      </>
                    ) : (
                      <>
                        <Button className="save-btn" onClick={() => addSubtopic(topicIndex)}>
                          Save
                        </Button>
                        <p className="cancel-btn" onClick={() => topicCancelBtn(topicIndex)}>
                          Cancel
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="topic-content-setting">
                  <div className="topic-name">
                    <img src={topicImage} alt="Image Icon" className="icon" />
                    <p>{topic?.topicName}</p>
                  </div>
                  <div className="topic-settig">
                    <Select
                      className="quick-action"
                      showSearch
                      placeholder="Quick Action"
                      optionFilterProp="children"
                      value={selectedAction}
                      onChange={(value) => handleQuickActionChange(value, EQuickActionsType.topic, topicIndex)}
                      options={getQuickActionsOptions(topic?.isPublish, true)}
                    />
                  </div>
                </div>
              )}

              {topic?.subTopicArray?.map((subtopic, subIndex) => (
                <div key={`subtopic-${topicIndex}-${subIndex}`} className="sub-topic-input-content">
                  {subtopic?.status === false ? (
                    <div className="btn-input-btn">
                      <Input
                        placeholder="Enter the Subtopic"
                        value={subtopic.subTopicName}
                        onChange={(e) => updateSubtopicName(topicIndex, subIndex, e.target.value)}
                      />
                      {subtopic?.isEdit ? (
                        <>
                          <Button
                            className="save-btn"
                            onClick={() => updateEditStatus(EQuickActionsType.subTopic, topicIndex, subIndex)}
                          >
                            Update
                          </Button>
                          <p className="cancel-btn" onClick={() => cancelEditStatus(EQuickActionsType.subTopic, topicIndex, subIndex)}>
                            Cancel
                          </p>
                        </>
                      ) : (
                        <>
                          <Button className="save-btn" onClick={() => addContentBtn(topicIndex, subIndex)}>
                            Save
                          </Button>
                          <p className="cancel-btn" onClick={() => subtopicCancelBtn(topicIndex, subIndex)}>
                            Cancel
                          </p>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="sub-topic-content-setting">
                      <div className="sub-topic-name">
                        <img src={subTopicImg} alt="Image Icon" className="icon" />
                        <p>{subtopic?.subTopicName}</p>
                      </div>
                      <div className="sub-topic-settig">
                        {renderPublishStatusHelper(subtopic?.isPublish)}
                        <div className="sub-topic-setting-icon">
                          <Popover
                            content={getQuickActionsOptions(subtopic?.isPublish).map((item) => (
                              <p
                                key={item.value}
                                onClick={() => quickActionOnChange(item.value, EQuickActionsType.subTopic, topicIndex, subIndex)}
                              >
                                {item.label}
                              </p>
                            ))}
                            open={popoverOpenIndex === `topic_${topicIndex}subTopic_${subIndex}`}
                            trigger="click"
                            onOpenChange={(newOpen) => handleOpenChange(newOpen, topicIndex, subIndex)}
                          >
                            <img src={settingIcon} alt="Image Icon" className="sub-settingIcon-icon icon" />
                          </Popover>
                        </div>
                      </div>
                    </div>
                  )}
                  {subtopic?.contentArray?.map((con, conIndex) => (
                    <div className="content-container" key={`content-${topicIndex}-${subIndex}-${conIndex}`}>
                      <div className="sub-topic-content-setting">
                        <div className="content-name">
                          <img src={contentImg} alt="Image Icon" className="icon" />
                          <p>{con?.videoName}</p>
                        </div>
                        <div className="sub-topic-settig">
                          {renderPublishStatusHelper(con?.isPublish)}
                          <div className="sub-topic-setting-icon">
                            <Popover
                              content={getQuickActionsOptions(con?.isPublish).map((item) => (
                                <p
                                  key={item.value}
                                  onClick={() => quickActionOnChange(item.value, EQuickActionsType.content, topicIndex, subIndex, conIndex)}
                                >
                                  {item.label}
                                </p>
                              ))}
                              open={popoverOpenIndex === `topic_${topicIndex}subTopic_${subIndex}content_${conIndex}`}
                              trigger="click"
                              onOpenChange={(newOpen) => handleOpenChange(newOpen, topicIndex, subIndex, conIndex)}
                            >
                              <img src={settingIcon} alt="Image Icon" className="sub-settingIcon-icon icon" />
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {subtopic?.addContentStatus ? (
                    <div className="content-content-btn">
                      <p className="add-content-btn add-btn" onClick={() => addContentBtn(topicIndex, subIndex)}>
                        + New Content
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
              {topic?.addSubtopicStatus ? (
                <div className="add-subtopic-btn-divition">
                  <p className="add-subtopic-btn add-btn" onClick={() => addSubtopic(topicIndex)}>
                    + New Subtopic
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
          <p className="add-topic-btn add-btn" onClick={addTopic}>
            + New Topic
          </p>
        </div>

        <DrawerForm
          title={currentTitle}
          formik={drawerFormFormik}
          pricingFormik={pricingFormik}
          visible={isDrawerVisible}
          commentsOpen={commentsOpen}
          communityFree={communityFree}
          freeTrial={freeTrial}
          attachmentFile={attachmentFile}
          setAttachmentFile={setAttachmentFile}
          fileNotesOthers={fileNotesOthers}
          testMiData={testMiData}
          educatorDetails={educatorDetails}
          selectedCards={selectedCards}
          drawerIsEdit={drawerIsEdit}
          frequencyData={frequencyData}
          frequencyMap={frequencyMap}
          prices={prices}
          youtubeLinkMapping={youtubeLinkMapping}
          uploading={uploading}
          progress={progress}
          fileUpload={fileUpload}
          attachmentFileCount={MaxFileCount.curriculumAttachment}
          otherNotesFileCount={MaxFileCount.curriculumOtherNotes}
          handleCommunity={handleCommunityFreeChange}
          handleFreeTrial={handleFreeTrialChange}
          handleComments={handleCommentsChange}
          onClose={closeDrawer}
          submit={contentChanges}
          update={contentChanges}
          testmiTextBoxChanges={handleCheckboxChange}
          handleAttachmentChange={handleAttachmentChange}
          handleAttachmentFileRemove={handleAttachmentFileRemove}
          handleFilesNotesChange={handleFilesNotesChange}
          handleFilesNotesRemove={handleFilesNotesRemove}
          addPrice={addPrice}
          pricingSwitchChange={pricingSwitchChange}
          deletePricing={deletePricing}
          addYoutubeLink={addYoutubeLink}
          deleteYoutubeLink={deleteYoutubeLink}
        />

        <PricingPlanDrawer
          visible={pricingVisible}
          currentTitle={currentPricingTitle}
          formik={pricingFormik}
          frequencyData={frequencyData}
          prices={prices}
          frequencyMap={frequencyMap}
          drawerCloser={pricingDrawerCloser}
          savePricing={savePricing}
          addPrice={addPrice}
          pricingSwitchChange={pricingSwitchChange}
          deletePricing={deletePricing}
        />

        {/* Cancel Model */}

        <Modal title="Cancel Confirmation" open={isCancelModalVisible} onCancel={closeCancelModel} maskClosable={false} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeCancelModel}>Cancel</Button>
            <Button className="primary-submit-button" type="default" onClick={resetDrawerForm}>Submit</Button>
          </div>
        } >
          <p>Are you sure you want to cancel?</p>
        </Modal>

      </div>
      {
        isLoading &&
        <div className='loading-container'>
          <Loading></Loading>
        </div>
      }

      <Modal title="Confirmation" open={modalVisible} onOk={() => confirmContentChanges()} onCancel={() => setModalVisible(false)}>
        <p>Are you sure want to {action} ?</p>
      </Modal>

    </>
  );
});

export default Curriculum;