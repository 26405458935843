import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Modal,
  Select,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import * as yup from "yup";
import { Option } from "antd/lib/mentions";
import { useDispatch, useSelector } from "react-redux";
import { getExamMasterTable } from "./reducers/ExamMasterSlice";
import TableComponent from "./Table/tableComponent";
import { IconButton } from "@material-ui/core";
import DeleteImage from "../asset/image/deleteIcon.svg";
import moment from "moment";
import Loading from "./Loading";
import {
  sendPublicPushNotification,
  sendPrivatePushNotification,
  getNotificationMethod,
  getNotificationType,
  getNotificationDetails,
  getActiveStudents,
} from "../components/reducers/NotificationSlice";
import { getEncryptedLocalStorage } from '../utils/utils';

const Notification = () => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const roleId = getEncryptedLocalStorage("roleId");
  const [currentTitle, setCurrentTitle] = useState("");
  const {
    notificationMethodData,
    isLoading,
    notificationTypeData,
    notificationDetailsData,
    notificationDetailsCount,
    activestudentData,
  } = useSelector((state) => state.NotificationSlice);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const tableHeight = 320;
  const [file, setFile] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  console.log(
    "activestudentDataactivestudentDataactivestudentData",
    activestudentData
  );
  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Notification Method Type",
      dataIndex: "notificationMethodType",
      key: "notificationMethodType",
      render: (_, record) => {
        return (
          <div>
            <span style={{ textTransform: "capitalize" }}>
              {record.notificationMethodType}
            </span>
          </div>
        );
      },
    },
    {
      title: "Notification Type",
      dataIndex: "notificationType",
      key: "notificationType",
      render: (_, record) => {
        return (
          <div>
            <span style={{ textTransform: "capitalize" }}>
              {record.notificationType}
            </span>
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => {
        return (
          <div>
            <span style={{ textTransform: "capitalize" }}>{record.title}</span>
          </div>
        );
      },
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
      render: (_, record) => {
        return (
          <div>
            <span style={{ textTransform: "capitalize" }}>{record.body}</span>
          </div>
        );
      },
    },
    {
      title: "Active / InActive",
      dataIndex: "isActive",
      key: "isActive",
      render: (_, record) => {
        return (
          <div>
            <span>
              {" "}
              {record.isActive ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">Inactive</Tag>
              )}
            </span>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    dispatch(getNotificationDetails(obj));
    dispatch(getNotificationMethod());
    dispatch(getNotificationType());
    dispatch(getActiveStudents());
  }, []);
  const initialValues = {
    notification: [],
    notificationType: [],
    title: "",
    body: "",
    student: [],
    image: [],
    link: "",
    mobileNumber: [],
  };
  let validationSchema = yup.object({
    title: yup.string().required("Title is Required"),
    body: yup.string().required("Content is Required"),
    notification: yup
      .array()
      .nullable()
      .min(1, "Min one notification is Required")
      .required("Notification is Required"),
    notificationType: yup
      .array()
      .min(1, "Min one notificationType is Required")
      .required("NotificationType is Required"),
    student: yup.array().required("Student is Required"),
    // image: yup.array().min(1, "Image is Required").required("Image is Required").nullable(),
    // link: yup.string().required("Link is Required"),
  });

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: (values) => {
      let data = new FormData();
      data.append("title", formik.values.title.trim());
      data.append("body", formik.values.body.trim());
      data.append("notification", formik.values.notification);
      data.append("notificationType", formik.values.notificationType);
      data.append("student", formik?.values?.student);
      data.append("image", formik.values.image[0]?.originFileObj);
      data.append("link", formik.values.link);
      data.append("mobileNumber", formik.values.mobileNumber);

      let datas = {
        data,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        },
      };
      console.log(datas, "value");
      values.notification[0] == 1
        ? dispatch(sendPublicPushNotification(datas))
          .unwrap()
          .then((res) => {
            setVisible(false);
          })
        : dispatch(sendPrivatePushNotification(datas))
          .unwrap()
          .then((res) => {
            setVisible(false);
          });
      setSubmitModalVisible(false);
    },
  });

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    getNotificationDetails(obj);
  }, 1000);

  const drawerCloser = () => {
    setVisible(false);
  };

  const openDrawer = () => {
    setFile([]);
    setVisible(true);
    setCurrentTitle("Notification");
    formik.resetForm({ values: initialValues });
  };
  const onChangeSelect = (e, fieldHelpers) => {
    setSelectedStudent(e);
    console.log(e, "eeee");
    fieldHelpers.setValue(e);
  };

  const editData = (_data) => { };
  const deleteData = (_data) => { };

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };
  }

  const getDetails = (obj) => {
    dispatch(getNotificationDetails(obj))
      .unwrap()
      .then((res) => { });
    getDetails(obj);
  };

  const filehandleChange = (file) => {
    if (file.fileList.length) {
      if (
        file.file.type == "image/jpeg" ||
        file.file.type == "image/jpg" ||
        file.file.type == "image/png"
      ) {
        setFile(file.fileList);
        formik.values.image = file.fileList;
      } else {
        message.error("please upload jpeg, jpg and png formats only ");
      }
    } else {
      setFile(file.fileList);
      formik.values.image = file.fileList;
      formik.validateForm();
    }
  };
  return (
    <>
      <div className="exam-group-container">
        <div className="header">
          <h3 className="primary-header">Notification</h3>
          <div className="add-exam">
            {roleId == 3 ? (
              <span className="add-new-exam">
                <Button
                  type="primary"
                  className="primary-submit-button"
                  onClick={() => openDrawer()}
                >
                  {" "}
                  Add Notification
                </Button>
              </span>
            ) : null}
          </div>
        </div>
        <div className="all-table-component">
          <div className="golbalSearch">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
              style={{ width: 262, height: 32 }}
              onChange={(event) => onSearch(event.target.value)}
            />
          </div>
          <TableComponent
            dataSource={notificationDetailsData}
            columns={columns}
            tableOnChange={tableOnChange}
            tableHeight={tableHeight}
            totalData={notificationDetailsCount}
            currentPage={paginationData + 1}
            loadingStatus={isLoading}
            limit={limit}
          />
        </div>
        <Drawer
          title="Notification"
          onClose={() => setVisible(false)}
          width="518px"
          closable={false}
          closeIcon={false}
          placement="right"
          open={visible}
          footer={
            <div className="footer">
              <div className="footer-button">
                <Button
                  className="primary-cancel-button"
                  onClick={() => setVisible(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className={
                    !formik.isValid || !formik.dirty
                      ? "disabled-button"
                      : "primary-submit-button"
                  }
                  disabled={!formik.dirty || !formik.isValid}
                  onClick={showSubmitConfirmation}
                >
                  Submit
                </Button>
              </div>
            </div>
          }
        >
          <div className="basic-form">
            <>
              <Form>
                <div className="form-control">
                  <label>
                    Type of notification <span className="required">*</span>
                  </label>
                  <div>
                    <Select
                      id="notification"
                      name="notification"
                      style={{ width: "100%" }}
                      placeholder="Select notification"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => {
                        const value = e ? [e] : [];
                        onChangeSelect(
                          value,
                          formik.getFieldHelpers("notification")
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.notification}
                    >
                      {notificationMethodData.map((e, index) => (
                        <Option key={index} value={e.id}>
                          {e.type}
                        </Option>
                      ))}
                    </Select>
                    {formik.touched["notification"] &&
                      formik.errors.notification ? (
                      <div className="errorMessage" style={{ color: "red" }}>
                        {formik.errors.notification}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-control">
                  <label>
                    Type <span className="required">*</span>
                  </label>
                  <div>
                    <Select
                      id="notificationType"
                      name="notificationType"
                      style={{ width: "100%" }}
                      placeholder="Select notificationType"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => {
                        const value = e ? [e] : [];
                        onChangeSelect(
                          value,
                          formik.getFieldHelpers("notificationType")
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.notificationType}
                    >
                      {notificationTypeData.map((data, index) => (
                        <Option key={index} value={data.id}>
                          {data.type}
                        </Option>
                      ))}
                    </Select>
                    {formik.touched["notificationType"] &&
                      formik.errors.notificationType ? (
                      <div className="errorMessage" style={{ color: "red" }}>
                        {formik.errors.notificationType}
                      </div>
                    ) : null}
                  </div>
                </div>

                {formik.values.notification == 2 && (
                  <>
                    <div className="form-control">
                      <label>
                        Student Name <span className="required">*</span>
                      </label>
                      <div>
                        <Select
                          mode="multiple"
                          id="student"
                          name="student"
                          style={{ width: "100%" }}
                          placeholder="Choose Option"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            formik.setFieldValue("student", value);
                            const selectedStudents = activestudentData.filter(
                              (student) => value.includes(student.studentId)
                            );
                            const selectedMobileNumber = selectedStudents.map(
                              (student) => student.mobileNumber
                            );
                            formik.setFieldValue(
                              "mobileNumber",
                              selectedMobileNumber
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.student}
                        >
                          {activestudentData
                            ? activestudentData.map((item) => (
                              <Option
                                key={item.studentId}
                                value={item.studentId}
                              >
                                {item.name}
                              </Option>
                            ))
                            : ""}
                        </Select>
                        {formik.touched.student && formik.errors.student ? (
                          <div
                            className="errorMessage"
                            style={{ color: "red" }}
                          >
                            {formik.errors.student}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-control">
                      <label htmlFor="mobileNumber">
                        Mobile Numbers <span className="required">*</span>
                      </label>
                      <div>
                        <Select
                          mode="multiple"
                          id="mobileNumber"
                          name="mobileNumber"
                          placeholder="Choose Option"
                          onChange={(value) => {
                            formik.setFieldValue("mobileNumber", value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobileNumber}
                          disabled={true}
                        >
                          {formik.values.mobileNumber &&
                            formik.values.mobileNumber.map(
                              (mobileNumber, index) => (
                                <Option key={index} value={mobileNumber}>
                                  {mobileNumber}
                                </Option>
                              )
                            )}
                        </Select>
                        {formik.touched.mobileNumber &&
                          formik.errors.mobileNumber ? (
                          <div
                            className="errorMessage"
                            style={{ color: "red" }}
                          >
                            {formik.errors.mobileNumber}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
                <div className="form-control">
                  <label>
                    Title <span className="required">*</span>
                  </label>
                  <Input
                    id="title"
                    name="title"
                    placeholder="Enter a title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                  ></Input>
                  {formik.touched.title && formik.errors.title ? (
                    <div className="error">{formik.errors.title}</div>
                  ) : null}
                </div>

                <div className="form-control">
                  <label>
                    Body <span className="required">*</span>
                  </label>
                  <Input
                    id="body"
                    name="body"
                    placeholder="Enter a name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.body}
                  ></Input>
                  {formik.touched.body && formik.errors.body ? (
                    <div className="error">{formik.errors.body}</div>
                  ) : null}
                </div>

                <div className="exam-upload-file">
                  <label>Notification Image(Optional)</label>
                  <div className="form-control">
                    <Upload
                      className="ImageUpload"
                      listType="picture"
                      onChange={(file) => filehandleChange(file)}
                      beforeUpload={() => false}
                      fileList={file}
                      maxCount={1}
                    >
                      <Button
                        className="exam-image-upload-button"
                        name="image"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        icon={<UploadOutlined />}
                      >
                        Choose file
                      </Button>
                    </Upload>
                    {formik.touched.image && formik.errors.image ? (
                      <div className="error">{formik.errors.image}</div>
                    ) : null}
                  </div>
                </div>

                <div className="form-control">
                  <label>Link(Optional)</label>
                  <Input
                    id="link"
                    name="link"
                    placeholder="Enter a link"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.link}
                  ></Input>
                  {formik.touched.link && formik.errors.link ? (
                    <div className="error">{formik.errors.link}</div>
                  ) : null}
                </div>
              </Form>
            </>
          </div>
        </Drawer>

        <Modal
          title="Submit Confirmation"
          open={isSubmitModalVisible}
          onCancel={closeSubmitModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeSubmitModal}
              >
                Cancel{" "}
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={formik.handleSubmit}
              >
                Submit{" "}
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to submit?</p>
        </Modal>
      </div>

      {isLoading ? (
        <div className="loading-container">
          <Loading></Loading>
        </div>
      ) : null}
    </>
  );
};
export default Notification;
