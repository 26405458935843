import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from 'moment';

export const getAllTutorials = createAsyncThunk('tutorial/getAllTutorials', async (payload) => {
    try {
        let getAllData = await axios.post('tutorial/getTutorials', payload).then((res) => {
            if (res.data.success) {
                return res.data.data
            }
        })
        return getAllData
    } catch (error) {
        message.error()
    }
})

export const createTutorial = createAsyncThunk("tutorial/createTutorial", async (payload, thunk) => {
    try {
        let addTutorial = await axios.post('/tutorial/addtutorial', payload.data).then(async (res) => {
            if (res.data.status) {
                message.success(res.data.message)
                await thunk.dispatch(getAllTutorials(payload.obj))
                return res.data
            }
        })
        return addTutorial

    } catch (error) {
        message.error(error.response.data.message)
    }
})

export const getTutorialById = createAsyncThunk("tutorial/getTutorialById", async (payload) => {
    try {
        let getUser = await axios.get(`/tutorial/getTutorial/${payload}`).then((res) => {
            if (res.data.success) {
                return res.data
            }
        })
        return getUser
    } catch (error) {
    }
})

export const updateTutorial = createAsyncThunk("tutorial/updateTutorial", async (payload, thunk) => {
    try {
        let updateTutorialData = await axios.put(`/tutorial/updatetutorial/${payload.id}`, payload.data).then(async (res) => {
            if (res.data.status) {
                await thunk.dispatch(getAllTutorials(payload.obj))
                message.success(res.data.message)
                return res.data
            }
        })
        return updateTutorialData;
    } catch (error) {
        message.error(error.response.data.message)

    }
})

export const deleteTutorials = createAsyncThunk(
    "tutorial/deleteTutorials", async (payload) => {
        try {
            let deleteTutorial = axios.delete('tutorial/tutorialDetails/' + payload.id)
                .then((res) => {
                    if (res.data.success) {
                        message.success(res.data.message)
                        return res.data
                    }
                })
            return deleteTutorial;
        }
        catch (error) {
        }
    }
)


const initialState = {
    isLoading: false,
    currentDataCount: 0,
    currentData: '',
}


const Announcement = createSlice({
    name: 'Announcement',
    initialState,
    extraReducers: {
        [getAllTutorials.pending]: (state) => {
            state.isLoading = true
        },
        [getAllTutorials.fulfilled]: (state, actions) => {
            state.isLoading = false
            console.log(actions.payload,'*************');
            state.currentData = actions.payload?.getTestimonialData;
            state.currentDataCount = actions.payload.getTestimonialCount.tutoCount;
        },
        [getAllTutorials.rejected]: (state) => {
            state.isLoading = false
        },

        [createTutorial.pending]: (state, action) => {
            state.isLoading = true
        },
        [createTutorial.fulfilled]: (state, action) => {
            state.isLoading = false
            state.createTutorial = action.payload
        },
        [createTutorial.rejected]: (state, action) => {
            state.isLoading = false
        },
        [getTutorialById.pending]:(state) => {
            state.isLoading = true
        },
        [getTutorialById.fulfilled]:(state,actions) => {
            state.isLoading = false
        },
        [getTutorialById.rejected]:(state) => {
            state.isLoading = false
        },
        [updateTutorial.pending]:(state) => {
            state.isLoading = true
        },
        [updateTutorial.fulfilled]:(state,actions) => {
            state.isLoading = false
        },
        [updateTutorial.rejected]:(state) => {
            state.isLoading = false
        },
        [deleteTutorials.pending]:(state) => {
            state.isLoading = true
        },
        [deleteTutorials.fulfilled]:(state,actions) => {
            state.isLoading = false
        },
        [deleteTutorials.rejected]:(state) => {
            state.isLoading = false
        },
    }
})

export default Announcement.reducer
