import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import { useDispatch, useSelector, } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getInstitutionBatchTestResultRank, getInstitutionBatchTestMarkDetails } from './reducers/institutionMasterSlice';
import BackIcon from '../asset/image/back_icon.svg'
import ClockIcon from '../asset/image/clock_icon.svg'
import TranslateIcon from '../asset/image/translate.svg'
import CalenderIcon from '../asset/image/calendar_icon.svg'
import RankFirst from '../asset/image/rank_first.svg'
import RankSecond from '../asset/image/rank_second.svg'
import RankThird from '../asset/image/rank_third.svg'
import TotalStudentIcon from '../asset/image/student_icon.svg'
import TopScoreIcon from '../asset/image/top_score_icon.svg'
import AbsentStudentIcon from '../asset/image/absent_student_icon.svg'
import FinishedStudentIcon from '../asset/image/finished_student_icon.svg'
import TableComponent from './Table/tableComponent';
import PassedStudentIcon from '../asset/image/passed_student_icon.svg'
import FailedStudentIcon from '../asset/image/failed_student_icon.svg';
import { getEncryptedLocalStorage } from '../utils/utils';

import moment from 'moment';



const BatchTestResults = () => {
  const { firstThreeRank, isRankTableLoading, otherRankAll, getInstitutionBatchTestMark, getInstitutionBatchTestRe } = useSelector((state) => state.InstitutionSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [samplePrintJson, setSamplePrintJson] = useState([{
    name: "Aravind",
    email: "nellaiaravind@gmail.com"
  },
  {
    name: "Muni",
    email: "muni@gmail.com"
  },
  {
    name: "Maha",
    email: "maha@gmail.com"
  },
  {
    name: "Nethaji",
    email: "nethaji@gmail.com"
  }])

  useEffect(() => {
    dispatch(getInstitutionBatchTestResultRank({ batchId: params.batchId, testId: params.testId, assignId: params.assignId }));
    dispatch(getInstitutionBatchTestMarkDetails({ batchId: params.batchId, testId: params.testId, assignId: params.assignId }));

  }, [])

  const backToTestDetailsPage = async () => {
    if (getEncryptedLocalStorage('publishTest') == true || getEncryptedLocalStorage('publishTest') == 'true') {
      navigate(`/PublishedTest`)
    } else {
      navigate(`/course/${params.courseId}/batch/${params.batchId}/batchtestdetails`)
    }
  }

  /* rank table */
  let tableHeight = 320
  const [limit, setLimit] = useState(50)
  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
      width: '10%'
    },
    {
      title: 'Name of the Student ',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Test taken time',
      dataIndex: 'takeTime',
      key: 'takeTime',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
    }
  ]


  return (
    <>
      <div className='batch-test-result-list'>
        <div className='heading-back-icon'>
          <div className='image'>
            <Image onClick={() => backToTestDetailsPage()} preview={false} src={BackIcon}></Image>
            <p className='test-name'>{getInstitutionBatchTestMark.testName}</p>
          </div>
        </div>
        <div className='batch-test-result-content'>
          <div className='test-content'>
            <p className={getInstitutionBatchTestMark.examGroupName ? 'exam-group-name' : ''}>
              {getInstitutionBatchTestMark.examGroupName}
            </p>

            <p className='test-name'>{getInstitutionBatchTestMark.testName}</p>
            <div className='divition-tag'></div>
            <div className='test-details'>
              <div className='mark-language'>
                <p className='total-student'>{getInstitutionBatchTestMark.numberOfQuestions} Questions</p>
                <div className='icon-section'>
                  <div className='image'>
                    <Image preview={false} src={TranslateIcon}></Image>
                  </div>
                  <p className='tamil-english'>English,Tamil</p>
                </div>
              </div>
              <div className='clock-calender'>
                <div className='number-of-minutes icon-section'>
                  <div className='image'>
                    <Image preview={false} src={ClockIcon}></Image>
                  </div>
                  <p className='minutes'>{getInstitutionBatchTestMark.timeLimit} Mins</p>
                </div>
                <div className='test-date icon-section'>
                  <div className='image'>
                    <Image preview={false} src={CalenderIcon}></Image>
                  </div>
                  <p className='date'>{moment(getInstitutionBatchTestMark.isStartedDate).format('DD MMM YYYY')}, {getInstitutionBatchTestMark.startTime}</p>
                </div>
              </div>
              <div className='mark-section'>
                <p className='marks'>{getInstitutionBatchTestMark.totalMark}</p>
                <p className='marks-label'>Total marks</p>
              </div>
            </div>
          </div>
          <div className='mark-content'>
            <div className='section-1'>
              <div className='mark-card'>
                <div className='image'>
                  <Image preview={false} src={TotalStudentIcon}></Image>
                </div>
                <div className='text-section'>
                  <p className='label'>Total students</p>
                  <p className='value'>{getInstitutionBatchTestRe?.data?.instituionalStudentCount[0].institutionStudentCount}</p>
                </div>
              </div>
              <div className='mark-card'>
                <div className='image'>
                  <Image preview={false} src={TopScoreIcon}></Image>
                </div>
                <div className='text-section'>
                  <p className='label'>Top score</p>
                  <p className='value'>{getInstitutionBatchTestMark.totalMark}</p>
                </div>
              </div>
              <div className='mark-card'>
                <div className='image'>
                  <Image preview={false} src={AbsentStudentIcon}></Image>
                </div>
                <div className='text-section'>
                  <p className='label'>Absent students</p>
                  <p className='value'>{getInstitutionBatchTestRe?.data?.absentStudent}</p>
                </div>
              </div>
            </div>
            <div className='section-2'>
              <div className='mark-card'>
                <div className='image'>
                  <Image preview={false} src={FinishedStudentIcon}></Image>
                </div>
                <div className='text-section'>
                  <p className='label'>Finished students</p>
                  <p className='value'>{getInstitutionBatchTestRe?.data?.testDetailCount?.finishedStudentCount}</p>
                </div>

              </div>
              <div className='mark-card'>
                <div className='image'>
                  <Image preview={false} src={PassedStudentIcon}></Image>
                </div>
                <div className='text-section'>
                  <p className='label'>Pass Count</p>
                  <p className='value'>{getInstitutionBatchTestRe?.data?.testDetailCount?.passMarkCount}</p>
                </div>
              </div>
              <div className='mark-card'>
                <div className='image'>
                  <Image preview={false} src={FailedStudentIcon}></Image>
                </div>
                <div className='text-section'>
                  <p className='label'>Fail Count</p>
                  <p className='value'>{getInstitutionBatchTestRe?.data?.testDetailCount?.failCount}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='Leader-board-content'>
          <p className='heading'>Leaderboard</p>
          {
            firstThreeRank.length <= 3 ?
              <div className='first-three-rank'>
                {
                  firstThreeRank.map((e, index) => {
                    return <div className={index == 0 ? 'rank-card first-rank' : (index == 1 ? 'rank-card second-rank' : 'rank-card third-rank')}>
                      <div className='image'>
                        <Image preview={false} src={index == 0 ? RankFirst : (index == 1 ? RankSecond : RankThird)}></Image>
                      </div>
                      <div className='name-mark'>
                        <p className='name'>{e.studentName}</p>
                        <p className='mark'>{e.score}<span>/ {getInstitutionBatchTestMark.totalMark}</span></p>
                      </div>
                    </div>
                  })
                }
              </div>
              :
              null
          }

          <div className='table-section-rank'>
            <TableComponent dataSource={otherRankAll} columns={columns} tableHeight={tableHeight} limit={limit} loadingStatus={isRankTableLoading} />
          </div>
        </div>
      </div>
    </>
  )

}

export default BatchTestResults
