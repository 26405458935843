import { Tabs } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import BreadCrumb from './BreadCrumb';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { getBatchById } from './reducers/BatchMasterSlice'
import { useDispatch, useSelector } from 'react-redux';
import Loading from './Loading';
import { setEncryptedLocalStorage } from '../utils/utils';

const BatchList = () => {
  const breadCrumbData = {}
  const { batchById, isLoadingById } = useSelector((state) => (state.BatchSlice))

  const params = useParams()
  const location = useLocation();
  const [tabStatus, setTabStatus] = useState("1")
  const { TabPane } = Tabs;

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const onTabChange = (data) => {
    setTabStatus(data)
    if (data == 1) {
      navigate(`student`)
      // } else if (data == 2) {
      // navigate(`overallrank`)
    } else if (data == 2) {
      navigate(`batchtestdetails`)
    }
  }
  useEffect(() => {
    dispatch(getBatchById(+params.batchId)).unwrap().then((res) => {
      setEncryptedLocalStorage('batchCode', res.batchCode)
      if (location.pathname.split('/')[location.pathname.split('/').length - 1] == 'student') {
        onTabChange("1")
        // } else if (location.pathname.split('/')[location.pathname.split('/').length - 1] == 'overallrank') {
        //   onTabChange("2")
      } else if (location.pathname.split('/')[location.pathname.split('/').length - 1] == 'batchtestdetails') {
        onTabChange("2")
      }
    })
  }, [])

  return (
    <>
      {
        isLoadingById ? <div className='loading-container'>
          <Loading></Loading>
        </div> :
          <div className='exam-master-page'>
            <div>
              <div className='exam-header'>
                <div><h3 className='primary-header course-header'>{batchById?.name} </h3></div>

              </div>
              <div className='batchList-body-container'>
                <div>
                  <Tabs activeKey={tabStatus} onChange={onTabChange}>
                    <TabPane tab="Students" key="1">
                      <div><Outlet></Outlet></div>
                    </TabPane>
                    {/* <TabPane tab="Overall Rank Details" key="2">
                      <div><Outlet></Outlet></div>
                    </TabPane> */}
                    <TabPane tab="Test Details" key="2">
                      <div><Outlet></Outlet></div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>

          </div>
      }


    </>
  )
}
export default BatchList;
