import React, { useState, useEffect } from "react";
import {
  Image,
  Button,
  Drawer,
  Input,
  Modal,
  Upload,
  message,
  Select,
} from "antd";
import {
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TableComponent from "./Table/tableComponent";
import { useDispatch, useSelector } from "react-redux";
import DeleteImage from "../asset/image/deleteIcon.svg";
import {
  getExamMasterTable,
  updateExamMasterTable,
  deleteExamMasterTable,
} from "./reducers/ExamMasterSlice";
import { insertMockTestBanner, getMockTestBanner, getAllExam, updateMockTestBanner, deleteMockTestBanner, uploadAdvertisementBannerMockTest } from "./reducers/masterSlice";
import { getPreviousYearExam } from "./reducers/PreviousYearExamSlice";
import { useDebouncedCallback } from "use-debounce";
import moment from "moment";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from "./BreadCrumb";
import { getEncryptedLocalStorage } from '../utils/utils';
import { getTestsByExamId } from "./reducers/TestMasterSlice";
import { useFormik } from "formik";

function MockTestBanner() {
  const dispatch = useDispatch();
  const [editId, setEditId] = useState();
  const [record, setRecord] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [file, setFile] = useState([]);
  const [isApproval, setIsApproval] = useState(false);
  const [testData, setTestData] = useState([]);
  const [visible, setVisible] = useState(false);


  const roleId = getEncryptedLocalStorage("roleId");

  useEffect(() => {
    let filterObj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit,
    };
    dispatch(getMockTestBanner(filterObj));
    dispatch(getAllExam(obj));
  }, []);

  const { mockTestBannerData, mockTestDataCount, isLoading, examData, testTypeData } = useSelector(
    (state) => state.MasterSlice
  );
  let tableHeight = 320;


  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Banner Image",
      dataIndex: "bannerImage",
      key: "bannerImage",
      render: (_, record) => (
        <Image src={record.bannerImage} alt="Banner Image" style={{ width: '100px', height: 'auto' }} width="100px" />
      ),
    },
    {
      title: "Exam Type",
      dataIndex: "examType",
      key: "examType",
      render: (_, record) => {
        return (
          <div>
            <span>{record.examType || "N/A"}</span>
          </div>
        );
      },
    },
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
      render: (_, record) => {
        return (
          <div>
            <span>{record.examName || "N/A"}</span>
          </div>
        );
      },
    },
    {
      title: "Test Name",
      dataIndex: "testName",
      key: "testName",
      render: (_, record) => {
        return (
          <div>
            <span>{record.testName || "N/A"}</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (_, record) => (
        <span>
          {record?.isActive ? (
            <span style={{ color: 'green' }}>Active</span>
          ) : (
            <span style={{ color: 'red' }}>Inactive</span>
          )}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <>
            {roleId == 1 || roleId == 3 ? (
              <div className="table-action-link">
                <a
                  style={{ paddingRight: 10 }}
                  onClick={() => editData(record)}
                >
                  Edit
                </a>
                {roleId == 3 ? (
                  <Tooltip title="Delete" style={{ padding: 0 }}>
                    <IconButton>
                      <Image
                        src={DeleteImage}
                        preview={false}
                        onClick={() => deleteData(record)}
                      ></Image>
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
            ) : (
              <div>Not Authorized</div>
            )}
          </>
        );
      },
    },
  ];

  const editData = (record) => {
    setFile([{ 
        url: record.bannerImage,
        thumbUrl: record.bannerImage,
        name: record.imageOriginalName
    }]);
    setCurrentId(record.id);
    if (record.testTypeId === 1) {
      dispatch(getPreviousYearExam({ examId: record.examId, obj: { pagination: -1, searchValue: filterData, limit: limit } })).unwrap().then((response) => {
        setTestData(response.previousYearExam.map((item) => ({ id: item.id, name: item.examName })));
      }).catch((error) => {
        message.error(error?.message || "An error occurred while fetching previous year exam data.");
      });
    } else if (record.testTypeId === 2) {
      dispatch(getTestsByExamId({ examId: record.examId })).unwrap().then((response) => {
        setTestData(response);
      }).catch((error) => {
        message.error(error?.message || "An error occurred while fetching tests.");
      });
    }
    formik.setValues({
      bannerImage: [record.bannerImage],
      exam: record.examId,
      test: record.testId,
      testType: record.testTypeId,
      status: record.isActive
    });
    setVisible(true);
    setCurrentTitle("Edit Banner");
    console.log('currentId', currentId);
  };

  function onClose() {
    setVisible(false);
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };

    dispatch(getExamMasterTable(obj));
  };

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };

  const debounced = useDebouncedCallback((obj) => {
    dispatch(getExamMasterTable(obj));
  }, 1000);

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmitModalVisible(true);
  };

  /**
   * Handles the submission of the mock test banner form.
   * @param {Object} values - The form values submitted by the user.
   */
  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("bannerImage", values.bannerImage[0]?.originFileObj);
      formData.append("examId", values.exam);
      formData.append("testId", values.test); 
      formData.append("testTypeId", values.testType);
      formData.append("status", values.status);

      const payload = {
        data: formData,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        },
      };

      setSubmitModalVisible(false);

      await dispatch(insertMockTestBanner(payload)).unwrap();
      setVisible(false);
    } catch (error) {
      console.error("Error in onSubmit:", error);
      message.error(error.message || "An error occurred while inserting exam data.");
      setVisible(true);
    }
  };

  const deleteData = (record) => {
    setRecord(record);
    setIsModalVisible(true);
  };

  function confirmDelete() {
    console.log('confirmDelete', record);
    setIsModalVisible(false);
    let data = {
      id: record.id,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };

    dispatch(deleteMockTestBanner(data));
  }

  function onModalClose() {
    setIsModalVisible(false);
  }

  const initialValues = { bannerImage: [], testType: "", exam: "", test: "", status: true };

  let validationSchema = yup.object({
    bannerImage: yup
      .array()
      .when('$isEdit', {
        is: true,
        then: yup.array(),
        otherwise: yup
          .array()
          .min(1, "Image is Required")
          .required("Image is Required")
      }),
    testType: yup.string().required("Test Type is Required"),
    exam: yup.number().required("Exam is Required"),
    test: yup.number().required("Test is Required"),
    status: yup.boolean().required("Status is Required"),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const openDrawer = () => {
    setIsApproval(false);
    setFile([]);
    setCurrentId(null);
    setVisible(true);
    setCurrentTitle("Add Banner");
    formik.resetForm({ values: initialValues });
  };

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    let data = new FormData();
    console.log('data image', formik.values)
    data.append("bannerImage", formik.values.bannerImage[0]?.originFileObj);
    data.append("examId", formik.values.exam);
    data.append("testId", formik.values.test);
    data.append("testTypeId", formik.values.testType);
    data.append("status", formik.values.status);
    let datas = {
      id: currentId,
      data,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };
    setUpdateModalVisible(false);
    setVisible(false);
    dispatch(updateMockTestBanner(datas))
      .unwrap()
      .then((res) => {
        try {
          if (res.success) {
            setVisible(false);
          }
        } catch (error) {
          setVisible(true);
        }
      }).catch((error) => {
        message.error(error?.message || "An error occurred while updating exam data.");
        setVisible(true);
      });
  };

  const filehandleChange = (file) => {
    if (file.fileList.length) {
      if (
        file.file.type == "image/jpeg" ||
        file.file.type == "image/jpg" ||
        file.file.type == "image/png"
      ) {
        setFile(file.fileList);
        formik.setFieldValue("bannerImage", file.fileList);
      } else {
        message.error("please upload jpeg, jpg and png formats only ");
      }
    } else {
      setFile(file.fileList);
      formik.setFieldValue("bannerImage", file.fileList);
      formik.validateForm();
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const breadCrumbData = {};

  const handleChangeTestType = (value) => {
    console.log('value', value);
    formik.setFieldValue('testType', value);
    formik.setFieldValue('exam', '');
    formik.setFieldValue('test', '')
  }

  const handleChangeExamByTest = (value) => {
    const testType = formik.values.testType;
    formik.setFieldValue('exam', value);
    formik.setFieldValue('test', '');
    if (testType === 1) {
      dispatch(getPreviousYearExam({ examId: value, obj: { pagination: -1, searchValue: filterData, limit: limit } })).unwrap().then((response) => {
        setTestData(response.previousYearExam.map((item) => ({ id: item.id, name: item.examName })));
      }).catch((error) => {
        message.error(error?.message || "An error occurred while fetching previous year exam data.");
      });
    } else if (testType === 2) {
      dispatch(getTestsByExamId({ examId: value })).unwrap().then((response) => {
        setTestData(response);
      }).catch((error) => {
        message.error(error?.message || "An error occurred while fetching tests.");
      });
    }
  }

  const openAdvertisementBanner = async () => {
    const file = await new Promise((resolve) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.jpg,.jpeg,.png';
      input.onchange = (e) => resolve(e.target.files[0]);
      input.click();
    });

    if (!file) return;

    // Validate file format
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      message.error('Please upload jpeg, jpg or png files only');
      return;
    }

    // Show confirmation modal
    Modal.confirm({
      title: 'Upload Advertisement Banner',
      content: 'Are you sure you want to upload this banner?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          // Create form data and append file
          const formData = new FormData();
          formData.append('bannerImage', file);

          // Dispatch API call
          await dispatch(uploadAdvertisementBannerMockTest(formData)).unwrap();
          
        } catch (error) {
          message.error(error?.message || 'Failed to upload advertisement banner');
        }
      }
    });
  };

  return (
    <div className="exam-master-page">
      <div>
        <div className="breadCrumb-Data">
          <BreadCrumb data={breadCrumbData} loading={isLoading}></BreadCrumb>
        </div>
        <div className="exam-header">
          <div>
            <h3 className="primary-header">Mock Test Banner</h3>
          </div>
          <div className="download-new-exam-btn">
            <div className="add-exam">
              {roleId == 3 ? (
                <>
                  <span className="add-new-exam">
                    <Button
                      type="primary"
                      className="primary-submit-button"
                      onClick={() => openAdvertisementBanner()}
                    >
                      Upload advertisement banner
                    </Button>
                  </span>
                  <span className="add-new-exam">
                    <Button
                      type="primary"
                      className="primary-submit-button"
                      onClick={() => openDrawer()}
                    >
                      {" "}
                      Add Banner
                    </Button>
                  </span>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="table-header">
          <div></div>
          <div className="golbalSearch">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
              style={{ width: 262, height: 32 }}
              onChange={(event) => onSearch(event.target.value)}
            />
          </div>
        </div>

        <Drawer
          closable={false}
          title={currentTitle}
          placement="right"
          onClose={() => onClose()}
          open={visible}
          footer={
            <div className="footer">
              <div className="footer-button">
                <Button
                  className="primary-cancel-button"
                  style={{ marginRight: 42.5 }}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <>
                  {currentId ? (
                    <Button
                      type="primary"
                      className={
                        !formik.isValid || !formik.dirty
                          ? "disabled-button"
                          : "primary-submit-button"
                      }
                      disabled={!formik.isValid || !formik.dirty}
                      onClick={showUpdateConfirmation}
                    >
                      Update{" "}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className={
                        !formik.isValid || !formik.dirty
                          ? "disabled-button"
                          : "primary-submit-button"
                      }
                      disabled={!formik.dirty || !formik.isValid}
                      onClick={() => showSubmitConfirmation(formik.values)}
                    >
                      Submit
                    </Button>
                  )}
                </>
              </div>
            </div>
          }
        >
          <form onKeyDown={onKeyDown}>
            <div>
              <div className="exam-upload-file">
                <div className="form-control">
                  <label>
                    Banner Image<span style={{ color: "red" }}>*</span>
                  </label>
                  <Upload
                    className="ImageUpload"
                    listType="picture"
                    onChange={(file) => filehandleChange(file)}
                    fileList={file}
                    maxCount={1}
                    onRemove={() => {
                      setFile([]);
                      formik.setFieldValue('bannerImage', []);
                    }}
                    name="bannerImage"
                  >
                    <Button
                      className="exam-image-upload-button"
                      name="bannerImage" 
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      icon={<UploadOutlined />}
                    >
                      Choose file
                    </Button>
                  </Upload>
                  {formik.touched.bannerImage && formik.errors.bannerImage ? (
                    <div className="error">{formik.errors.bannerImage}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-control" style={{ height: '80px' }}>
                <label>
                  Test Type<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  id="testType"
                  name="testType"
                  placeholder="Select test type"
                  onChange={(value) => handleChangeTestType(value)}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: 0 }}
                  value={formik.values.testType}
                >
                  {testTypeData.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                {formik.touched.testType && formik.errors.testType ? (
                  <div className="error">{formik.errors.testType}</div>
                ) : null}
              </div>
              <div className="form-control" style={{ height: '80px' }}>
                <label>
                  Exam<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  id="exam"
                  name="exam"
                  placeholder="Select exam"
                  onChange={handleChangeExamByTest}
                  onBlur={formik.handleBlur}
                  value={formik.values.exam}
                  style={{ marginBottom: 0 }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {examData.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                {formik.touched.exam && formik.errors.exam ? (
                  <div className="error">{formik.errors.exam}</div>
                ) : null}
              </div>
              <div className="form-control" style={{ height: '80px' }}>
                <label>
                  Test<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  id="test"
                  name="test"
                  placeholder="Select test"
                  onChange={(value) => formik.setFieldValue('test', value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.test}
                  style={{ marginBottom: 0 }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {testData.map((test) => (
                    <Select.Option key={test.id} value={test.id}>
                      {test.name}
                    </Select.Option>
                  ))}
                </Select>
                {formik.touched.test && formik.errors.test ? (
                  <div className="error">{formik.errors.test}</div>
                ) : null}
              </div>
              <div className="form-control" style={{ height: '80px' }}>
                <label>
                  Status<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  id="status"
                  name="status"
                  placeholder="Select status"
                  onChange={(value) => formik.setFieldValue('status', value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.status}
                  style={{ marginBottom: 0 }}
                >
                  <Select.Option value={true}>Active</Select.Option>
                  <Select.Option value={false}>Inactive</Select.Option>
                </Select>
                {formik.touched.status && formik.errors.status ? (
                  <div className="error">{formik.errors.status}</div>
                ) : null}
              </div>
            </div>
          </form>
        </Drawer>

        <Modal
          title="Deletion"
          open={isModalVisible}
          onCancel={onModalClose}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={() => onModalClose()}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => confirmDelete()}
              >
                OK
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to delete?</p>
        </Modal>

        <Modal
          title="Update Confirmation"
          open={isUpdateModalVisible}
          onCancel={closeUpdateModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeUpdateModal}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={updateData}
              >
                Update
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to update?</p>
        </Modal>

        <Modal
          title="Submit Confirmation"
          open={isSubmitModalVisible}
          onCancel={closeSubmitModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeSubmitModal}
              >
                Cancel{" "}
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={() => onSubmit(formik.values)}
              >
                {" "}
                Submit{" "}
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to submit?</p>
        </Modal>

      </div>

      <div>
        <TableComponent
          dataSource={mockTestBannerData}
          columns={columns}
          tableOnChange={tableOnChange}
          tableHeight={tableHeight}
          totalData={mockTestDataCount}
          currentPage={paginationData + 1}
          loadingStatus={isLoading}
          limit={limit}
        />
      </div>
    </div>
  );
}
export default MockTestBanner;
