import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Drawer, Form, Image, Input, message, Modal, Select, Tag, Tooltip, Upload } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { useDispatch, useSelector } from 'react-redux'
import { getExamMasterTable } from './reducers/ExamMasterSlice'
import { createExamGroup, deleteExamGroup, getAllExamGroup, getExamGroupById, updateExamGroup } from './reducers/masterSlice'
import TableComponent from './Table/tableComponent'
import { IconButton } from '@material-ui/core'
import DeleteImage from '../asset/image/deleteIcon.svg'
import moment from 'moment'
import Loading from './Loading'
import { getEncryptedLocalStorage } from '../utils/utils';

const ExamGroup = () => {
    

    const dispatch = useDispatch()

    const[visible,setVisible]=useState(false)
    const [filterData, setFilterData] = useState("")
    const [paginationData, setPaginationData] = useState(0);
    const [limit, setLimit] = useState(50);
    const [file, setFile] = useState([]);
    const roleId = getEncryptedLocalStorage("roleId")
    const [currentId, setCurrentId] = useState(null)
    const [currentRecord, setCurrentRecord] = useState({})
    const [currentTitle, setCurrentTitle] = useState("")
  const {examMasterTableData}=useSelector((state)=>state.ExamMaster);
  const {examGroupData,isLoading,examGroupDataCount}=useSelector((state) => state.MasterSlice);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible,setSubmitModalVisible]=useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const tableHeight = 320

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
                return (
          <div className='index-id'>{(i+1)+(paginationData>0?(paginationData)*limit:0)}
          </div>
        )
      },
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
      render: (_, record) => {
        return (
          <div>
            <span style={{textTransform:'capitalize'}}>{record.groupName}</span>
          </div>
        )
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span>
          </div>
        )
      }
    },
    {
      title: 'Active / InActive',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, record) => {
        return (
          <div>
            <span> {record.isActive ?
            <Tag color="green">Active</Tag>:
            <Tag color="red">Inactive</Tag>

            }</span>
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
          {
            roleId == 1 || roleId == 3 ?
            <div className='table-action-link'>
            <a style={{paddingRight:10}} onClick={() => editData(record)} >Edit</a>
            {
              roleId == 3 ?
              <Tooltip title="Delete" style={{padding:0}}>
              <IconButton>
              <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
              </IconButton>
            </Tooltip>
              :null
            }

          </div>
          : <div>
            Not Authorized
          </div>
          }

          </>
    
        )
      },
    },
  ]

    const initialValues = { groupName: '', groupLogo: [] ,exams:[],isActive:false}
    let validationSchema = yup.object({
      groupName: yup
      .string()
      .required("Name is Required")
      .min(2, "Name Needed At Least Two characters")
      .max(100, "Name not more than 100 characters"),
      // .matches(/^\s*[A-Za-z0-9]+\s*( \s*[A-Za-z0-9]+\s*)*$/, "Name should only contain letters, numbers, and spaces with leading/trailing spaces"),
      groupLogo: yup.array().min(1, "Image is Required").required("Image is Required").nullable(),
      exams: yup.array().min(1, "Min one Exam is Required").required("Exams is Required"),
    })



    const closeSubmitModal = () => {
      setSubmitModalVisible(false);
    };
  
   
    const showSubmitConfirmation = (values) => {
      setSubmittedData(values);
      setSubmitModalVisible(true);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,

        onSubmit:(values)=>{
            setVisible(false);
            let data = new FormData();
            data.append("groupName", formik.values.groupName.trim())
            data.append("groupLogo", formik.values.groupLogo[0].originFileObj)
            data.append("exams", JSON.stringify(formik.values.exams))
            data.append("isActive", formik.values.isActive)
            let datas = {
              data,
              obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
              }
            }
            setSubmitModalVisible(false);
            dispatch(createExamGroup(datas)).unwrap().then((res)=>{
                if(res.status){
                  setVisible(false)
                }
            })
            .catch((error)=>{
              setVisible(true)
            })

        }
    })
    const handleFormValidation = (isValid) => {
      setIsFormValid(isValid);
    };

    const onSearch = (value) => {
        setFilterData(value)
        let obj = {
          pagination: paginationData,
          searchValue: value,
          limit :limit
    
        }
        debounced(obj)
      }
      const debounced = useDebouncedCallback((obj) =>{
        getAllGroup(obj);
        

      },1000)
      const drawerCloser = () =>{
        setVisible(false)
      }
      const filehandleChange = (file) => {

        if(file.fileList.length){
          if(file.file.type == 'image/jpeg' || file.file.type == 'image/jpg' || file.file.type == 'image/png'){
            setFile(file.fileList)
        formik.values.groupLogo = file.fileList
          }
          else{
            message.error("please upload jpeg, jpg and png formats only ")
          }
        }
        else{
          setFile(file.fileList)
          formik.values.groupLogo = file.fileList
          formik.validateForm()
        }
      }

      const openDrawer = () => {
    
        setFile([])
        setCurrentId(null)
        setVisible(true)
        setCurrentTitle("New Exam")
        formik.resetForm({ values: initialValues })
    
      }
      const onChangeSelect = (e, fieldHelpers) => {
        fieldHelpers.setValue(e)
      }
    const getAllExam = ()=>{
        let obj= {
            pagination: -1,
            searchValue: filterData,
            limit: limit
          }
          dispatch(getExamMasterTable(obj));
    }
    const editData = (_data)=>{
        setCurrentId(_data.id)
        dispatch(getExamGroupById(_data.id)).unwrap().then((res)=>{
            if(res.status){
            setFile([{ url: res?.data.groupLogo, thumbUrl: res?.data.groupLogo }])
              let tempValues = JSON.parse(JSON.stringify(res.data))
              formik.values['groupLogo'] =  [tempValues['groupLogo']]
              formik.values.groupName =  tempValues.groupName
              formik.values.exams =  tempValues.exams
              formik.values.isActive =  tempValues.isActive
              setVisible(true)
            }
          })
        setVisible(true)
        setCurrentTitle("Edit Exam")
    }
    const deleteData = (_data)=>{
        setCurrentRecord(_data)
        setIsModalVisible(true)
        setCurrentId(_data.id)

    }


    const closeUpdateModal = () => {
      setUpdateModalVisible(false);
    };
  
    const showUpdateConfirmation = () => {
      setUpdateModalVisible(true);
    };
    const updateData =()=>{
    
        let data = new FormData();
        data.append("groupLogo", formik.values.groupLogo[0]?.originFileObj)
        data.append("groupName", formik.values.groupName)
        data.append("exams", JSON.stringify(formik.values.exams))
        data.append("isActive",formik.values.isActive)

        let datas = {
          id: currentId,
          data,
          obj: {
            pagination: paginationData,
            searchValue: filterData,
            limit: limit
    
          }
        }
        setUpdateModalVisible(false);
        setVisible(false)
        dispatch(updateExamGroup(datas)).unwrap().then((res)=>{
        try{
          if(res.status){
            setVisible(false)
          }
        }
        catch(error){
            setVisible(true)
        }
        })
    
    }
    const  onModalClose=()=> {
      setIsModalVisible(false);
    }
    const confirmDelete = ()=>{
      setIsModalVisible(false)
      let data = {
        id: currentId,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      }
      dispatch(deleteExamGroup(data)).unwrap().then((res)=>{
        
      })
    }
    const tableOnChange = (pagination, filters, sort, extra) => {
      setPaginationData(pagination?.current - 1)
  
      let obj = {
        pagination: pagination?.current - 1,
        searchValue: filterData,
        limit: limit
  
      }
  
      getAllGroup(obj);
    }
    const getAllGroup = (obj) =>{
      dispatch(getAllExamGroup(obj)).unwrap().then((res)=>{
      })
    }

      useEffect(()=>{
        let obj = {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
      getAllExam();
      getAllGroup(obj);

    },[])


  return (
    <>
        <div className='exam-group-container'>
        <div className='header'>
        <h3  className='primary-header'>Exam Group</h3>
        <div className='add-exam'>
        {
           roleId == 3 ?
          <span className='add-new-exam'><Button type='primary' className='primary-submit-button' onClick={() => openDrawer()}> New Group</Button></span>
          : null
        }
          </div>
        </div>
        <div className='all-table-component'>
            <div className="golbalSearch" >
            <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event)=>onSearch(event.target.value)} />
          </div>
          <TableComponent dataSource={examGroupData}  columns={columns} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={examGroupDataCount} currentPage={paginationData + 1} loadingStatus={isLoading}   limit={limit} />

        </div>
        <Drawer title="Exam Group" onClose={() => setVisible(false)} width="518px" closable={false} closeIcon={false} placement="right" open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
                <Button className='primary-cancel-button' onClick={()=> setVisible(false)} >Cancel</Button >
          
                {
                  currentId ?
                  <Button type="primary" className={(!formik.isValid || !formik.dirty )? 'disabled-button':'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showUpdateConfirmation}>Update</Button>
                  // <Button type='primary' className={!formik.isValid?'disabled-button':'primary-submit-button'} disabled={!formik.isValid} onClick={() => updateData()}></Button>
                  :
                <Button type='primary' className={(!formik.isValid || !formik.dirty )? 'disabled-button':'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showSubmitConfirmation}>Submit</Button>   
                 }
            </div>
          </div>
        )}>
            <div className='basic-form'>
                <>
                    <Form>
                    <div className='form-control'>
              <label>Name</label>
              <Input maxLength={50} id='groupName' name='groupName' placeholder="Enter name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.groupName}></Input>
              {formik.touched.groupName && formik.errors.groupName ? <div className='error'>{formik.errors.groupName}</div> : null}
            </div>
            <div className='exam-upload-file'>
              <div className='form-control'>
                <Upload
                  className='ImageUpload'
                  listType="picture"
                  onChange={(file) => filehandleChange(file)}
                  beforeUpload={() => false}
                  fileList={file}
                  maxCount={1}
                >
                  <Button className='exam-image-upload-button'  name="groupLogo" onChange={formik.handleChange} onBlur={formik.handleBlur} icon={<UploadOutlined />}>Choose file</Button>
                </Upload>
                {formik.touched.groupLogo && formik.errors.groupLogo ? <div className='error' >{formik.errors.groupLogo}</div> : null}
              </div>
            </div>
            <div className='form-control'>
              <label>Exams</label>
              <div>
                <Select mode="multiple" id="exams" name="exams" style={{ width: "100%" }} placeholder="Select Exams" showSearch
                filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(e) => {onChangeSelect(e, formik.getFieldHelpers('exams'))}} onBlur={formik.handleBlur} value={formik.values.exams}>
                {examMasterTableData.map((data, index) => (
                <Option key={index} value={data.id}>{data.name}</Option>))}
                </Select>
                {formik.touched['exams'] && formik.errors.exams ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.exams}</div> : null}
              </div>

            </div>
            <div className='form-control'>
              <Checkbox  onChange={formik.handleChange} checked={formik.values.isActive} name="isActive" >Is Active</Checkbox>
            </div>
                </Form>
                </>
             </div>
        </Drawer>
        <Modal title='Deletion' open={isModalVisible} onCancel={onModalClose} footer={
          <div>
          <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() =>confirmDelete()}>OK</Button>
        </div>
        }>
          <p>Are you sure you want to delete <span className='highlight-text'>{currentRecord.groupName} </span>  ?</p>
        </Modal>
        {/* update Confirmation */}

        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
          <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
        </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>


      {/* submit confirmation */}
        <Modal title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel </Button>
            <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}>Submit </Button>
          </div>
        } >
        <p>Are you sure you want to submit?</p>
      </Modal>

    </div>
            {
                isLoading ? <div className='loading-container'>
        
                  <Loading></Loading>
        
                </div> : null
              }
    </>

  )
}

export default ExamGroup