import React, { useState, useEffect } from 'react';
import TableComponent from './Table/tableComponent';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Image, Select, Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCourse, coursePublishStatusChange, getCourseMaster, getCourseClusterFilter, getCourseCreatedDateFilter, removeCourseOverviewDetails } from './reducers/clusterCourseSlice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteImage from '../asset/image/deleteIcon.svg'
import { removeQuestionData } from './reducers/questionSlice';
import { useDebouncedCallback } from 'use-debounce';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import moment from 'moment';
import { handleToast } from "../utils/HelperFunction.js";
import { getEncryptedLocalStorage } from '../utils/utils';


const { Option } = Select;

function ClusterCourse() {
  //   const { listData, isDashLoading } = useSelector((state) => state.DashboardSlice)
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPublishModalVisible, setIsPublishModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [filterData, setFilterData] = useState("");
  const [clusterFilterData, setClusterFilterData] = useState("");
  const [createdAtFilterData, setCreatedAtFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const roleId = getEncryptedLocalStorage("roleId")
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [publishStatus, setPublishStatus] = useState(false);
  const [publishAction, setPublishAction] = useState('Unpublished');


  useEffect(() => {
    let obj = {
      offSet: paginationData,
      searchInput: filterData,
      limit: limit,
      clusterFilter: clusterFilterData,
      createdAtFilter: createdAtFilterData,
    };
    dispatch(getCourseMaster(obj))
    dispatch(getCourseClusterFilter());
    dispatch(getCourseCreatedDateFilter())
  }, []);

  const { getCourseData, isLoading, getCourseDataCount, clusterNameFilter, courseCreatedAt } = useSelector((state) => state.ClusterCourseSlice)
  let tableHeight = 320;

  const columns = [
    {
      title: 'Course Name',
      dataIndex: 'courseName',
      width: '25%',
      sorter: {
        compare: (a, b) => {
          if (a.courseName < b.courseName) return -1;
          if (a.courseName > b.courseName) return 1;
          return 0;
        },
        multiple: 3,
      },
      render: (_, record) => {
        return (
          <>
            <div>
              <p> {record.courseName} </p>
            </div>
          </>
        )
      }

    },
    {
      title: 'Cluster Type',
      dataIndex: 'clusterName',
      width: '14%',
      sorter: {
        compare: (a, b) => {
          if (a.clusterName < b.clusterName) return -1;
          if (a.clusterName > b.clusterName) return 1;
          return 0;
        },
        multiple: 3,
      },
      render: (_, record) => {
        return (
          <div>
            <p> {record.clusterName} </p>
          </div>
        )
      }
    },

    {
      title: 'Created Date',
      dataIndex: 'updatedAt',
      width: '10%',
      key: 'updatedAt',
      sorter: {
        compare: (a, b) => {
          if (a.updatedAt < b.updatedAt) return -1;
          if (a.updatedAt > b.updatedAt) return 1;
          return 0;
        },
        multiple: 3,
      },
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span>
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'isPublish',
      key: 'isPublish',
      width: '9%',
      sorter: {
        compare: (a, b) => {
          if (a.isPublish < b.isPublish) return -1;
          if (a.isPublish > b.isPublish) return 1;
          return 0;
        },
        multiple: 3,
      },
      render: (_, record) => {
        return (
          <div>
            <span onClick={() => publishStatusChange(record)}> {
              record?.isPublish ?
                <Tag color="green" className='tag-green'>Published</Tag> :
                <Tag color="red" className='tag-red'>Unpublished</Tag>
            }</span>
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
            {
              !params?.statusName ?
                <Tooltip title="Delete" style={{ padding: 0 }}>
                  <IconButton>
                    <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                  </IconButton>
                </Tooltip>
                : null
            }
          </div>
        )
      },
    }
  ];

  function editData(record) {
    const courseMappingId = record?.courseMappingId;
    const courseId = record?.courseId;
    navigate(`/StepperComponent/${courseMappingId}/${courseId}`);
  };

  const publishStatusChange = ({ isPublish, courseMappingId }) => {
    const action = !isPublish ?  'Published' : 'Unpublished'
    setCurrentId(courseMappingId);
    setPublishStatus(!isPublish);
    setPublishAction(action);
    setIsPublishModalVisible(true);
  };

  const deleteData = (record) => {
    setCurrentId(record?.courseMappingId);
    setIsModalVisible(true);
  };

  function handleCancel(isPublish = false) {
    !isPublish ? setIsModalVisible(false) : setIsPublishModalVisible(false);
  };

  function confirmDelete() {
    setIsModalVisible(false);

    let data = {
      id: currentId,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    };

    if (currentId) {
      dispatch(deleteCourse(data)).unwrap().then((res) => {
        handleToast(res);
        setCurrentId(null);
      });
    };
  };

  const confirmPublishStatusChange = () => {

    setIsPublishModalVisible(false);

    let data = {
      id: currentId,
      datas: {
        status: publishStatus
      },
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    };

    if (currentId) {
      dispatch(coursePublishStatusChange(data)).unwrap().then((res) => {
        handleToast(res);
        setCurrentId(null);
        setPublishStatus(false);
        setPublishAction("Unpublished");
      });
    };
  };

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      offSet: paginationData,
      searchInput: value,
      limit: limit,
      clusterFilter: clusterFilterData,
      createdAtFilter: createdAtFilterData,
    };
    debounced(obj);
  };

  const debounced = useDebouncedCallback((obj) => {
    dispatch(getCourseMaster(obj));
  }, 1000);

  const handleClusterChange = (e, value) => {
    setClusterFilterData(e);
    let obj = {
      offSet: paginationData,
      limit: limit,
      searchInput: filterData,
      clusterFilter: e,
      createdAtFilter: createdAtFilterData
    };

    dispatch(getCourseMaster(obj));
  };

  const handleCreatedAtChange = (e, value) => {
    setCreatedAtFilterData(e);
    let obj = {
      offSet: paginationData,
      limit: limit,
      searchInput: filterData,
      createdAtFilter: e,
      clusterFilter: clusterFilterData
    };
    dispatch(getCourseMaster(obj));
  };

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const tableOnChange = async (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      offSet: pagination?.current - 1,
      searchInput: filterData,
      limit: limit,
      clusterFilter: clusterFilterData,
      createdAtFilter: createdAtFilterData,
    };
  await dispatch(getCourseMaster(obj))
  };

  const addNewCourse = async () => {
    navigate('/StepperComponent');
  };

  return (
    <div className='testMaster-page'>
      <div className='test-header m-0'>
        <div className='test-header-wrapper'><h3 className='primary-header'>Courses</h3>
        </div>
        <div className='add-test'>
          {
            <Button className='primary-submit-button m-0' style={{ fontSize: 13, fontWeight: 500 }} onClick={() => addNewCourse()} >New Course</Button>
          }
        </div>
      </div>
      <div className='all-table-Component'>
        <div className="table-header">
          <div className="golbalSearch" >
            <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
          </div>
          <div className="cluster-normalPage">
            <div className="form-control">
              <label>
                <span style={{ color: "#646C7A", fontSize: "13px", fontWeight: "500" }}>Sort by</span>
              </label>
            </div>
            <div className='form-control'>
              <Select
                id="typeOfTestId"
                name="typeOfTestId"
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                placeholder="Cluster"
                onChange={handleClusterChange}
                allowClear
              >
                {clusterNameFilter?.map((item, index) => (
                  <Option key={index} value={item.clusterName}>
                    {item.clusterName}
                  </Option>
                ))}
              </Select>
            </div>
            <div className='form-control'>
              <div>
                <Select
                  id="typeOfTestId"
                  name="typeOfTestId"
                  showSearch
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  placeholder="Date Created"
                  onChange={handleCreatedAtChange}
                  allowClear
                >
                  {courseCreatedAt?.map((item, index) => (
                    <Option key={index} value={item.date}>
                      {item.date}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <TableComponent dataSource={getCourseData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={getCourseDataCount} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} />
      </div>

      <Modal title="Deletion" open={isModalVisible} onOk={() => confirmDelete()} onCancel={() => handleCancel()}>
        <p>Are you sure want to delete ?</p>
      </Modal>

      <Modal title={publishAction} open={isPublishModalVisible} onOk={() => confirmPublishStatusChange()} onCancel={() =>handleCancel(true)}>
        <p>Are you sure want to {publishAction} ?</p>
      </Modal>

    </div>
  );
}
export default ClusterCourse;