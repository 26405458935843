import React, { useState, useEffect, useRef } from 'react';
import { Image, Button,Input } from 'antd';
import { useDispatch, useSelector, } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { getInstitutionBatchTest } from './reducers/institutionMasterSlice';
import ClockIcon from '../asset/image/clock_icon.svg';
import TableComponent from './Table/tableComponent';
import moment from 'moment';
// import TestEmptyImage from '../asset/image/institution_student_test_empty.svg'
import TestEmptyImage from '../asset/image/test-details-empty-image.png'
import { useDebouncedCallback } from 'use-debounce';
import { SearchOutlined } from '@ant-design/icons';
import { setEncryptedLocalStorage,getEncryptedLocalStorage} from '../utils/utils';


const Batchtestdetails = () => {
  const { getInstitutionBatchTestList,isLoading,totalDataCount } = useSelector((state) => state.InstitutionSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [limit, setLimit] = useState(10);
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  let tableHeight = 320

  useEffect(() => {
    let obj = {
      pagination: -1,
      searchValue: '',
      limit: 10
    }
    dispatch(getInstitutionBatchTest({ data:obj,courseId: params.courseId, batchId: params.batchId ,instituteId:getEncryptedLocalStorage('institutionId')}));
    console.log("batch details",getInstitutionBatchTestList)
  }, [])

  const onSearch = (value) => {
    console.log("Search value",value)
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit:limit

    }
    debounced(obj)
  }
  const debounced = useDebouncedCallback((obj) =>{
    let instituteId=getEncryptedLocalStorage('institutionId')
    dispatch(getInstitutionBatchTest({ data:obj,courseId: params.courseId, batchId: params.batchId ,instituteId:getEncryptedLocalStorage('institutionId')}));
  },1000)

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit:limit
    }
    let instituteId=getEncryptedLocalStorage('institutionId')
    dispatch(getInstitutionBatchTest({ data:obj,courseId: params.courseId, batchId: params.batchId ,instituteId:getEncryptedLocalStorage('institutionId')}));
  }

  const viewResultTest = (data) => {
    setEncryptedLocalStorage('publishTest', false)
    navigate(`/batchTestResult/${params.courseId}/${params.batchId}/test/${data.assignTestId}/assign/${data.assignId}`)
  }
  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
                return (
          <div className='index-id'>{(i+1)+(paginationData>0?(paginationData)*limit:0)}
          </div>
        )
      },
    },
    {
      title: 'Exam Group Name',
      dataIndex: 'groupName',
      key: 'name',
      onCell: (record) => {
        return {
          onClick: () => {
            navigate(`/batchTestResult/${params.courseId}/${params.batchId}/test/${record.assignTestId}/assign/${record.assignId}`)
          }
        }
      }
    },
    {
      title: 'Test Name',
      dataIndex: 'testName',
      key: 'contactName',
      onCell: (record) => {
        return {
          onClick: () => {
            navigate(`/batchTestResult/${params.courseId}/${params.batchId}/test/${record.assignTestId}/assign/${record.assignId}`)
          }
        }
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'startedDate',
      key: 'contactName',
      onCell: (record) => {
        return {
          onClick: () => {
            navigate(`/batchTestResult/${params.courseId}/${params.batchId}/test/${record.assignTestId}/assign/${record.assignId}`)
          }
        }
      }
    },
  ]

  return (
    <>
      <div className='batch-test-list'>
        <div className='batch-test-content'>
          {/* assign test */}
          <div className='assign-test-container'>
            <div className='test-card-container' >
          <Input placeholder="Search Exam group " prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
              {
                getInstitutionBatchTestList.length!=0 ?
                  <TableComponent columns={columns} dataSource={getInstitutionBatchTestList} limit={limit} tableHeight={tableHeight} loadingStatus={isLoading} rowSelection={rowSelection} tableOnChange={tableOnChange} currentPage={paginationData + 1} totalData={totalDataCount}></TableComponent>
                  : <div className='empty-test-section'>
                    <div className='empty-image'>
                      <Image preview={false} src={TestEmptyImage}></Image>
                    </div>
                    <div className='empty-content'>
                      <p className='text'>Get Ready to Unleash Your Potential!</p>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default Batchtestdetails
