import React from 'react';
import { useDispatch } from 'react-redux';
import { Upload, Image, Tooltip, Button } from 'antd';
import "../../components/styles/CustomFileUpload.scss";
import closeIcon from '../../asset/image/close.svg';
import pdfIcon from '../../asset/image/Pdf.svg';
import videoIcon from '../../asset/image/contentImg.svg';
import { filehandleChangeHelper, formatFileSizeHelper } from '../../utils/HelperFunction.js';
import FileUpload from './FileUpload.js';
import { uploadObject } from '../reducers/MultipartUploadSlice.js';
import { MaxFileCount, imageFilter, imageFilterMessage } from '../../utils/Variable.js';
import ProgressBar from './ProgressBar';

const CustomFileUpload = ({
    filehandleChange,
    handleFileRemove,
    file = [],
    setAttachmentFile,
    allowCount,
    customButton,
    thumbnailFileRequired = false,
    uploading,
    progress,
    isCurriculumAttachment = false
}) => {

    let fileList = file || [];
    const dispatch = useDispatch();

    const getImageSrc = (type) => {
        switch (type) {
            case 'application/pdf':
                return pdfIcon;
            case 'video/mp4':
                return videoIcon;
            default:
                return null;
        }
    };


    const getImage = (type) => {
        const imageSrc = getImageSrc(type);
        return (
            imageSrc && <Image className='file-type-icon' src={imageSrc} preview={false} />
        );
    };

    const handleFileThumbnailRemove = ({ parentSelectedFile = {} }) => {

        console.log(parentSelectedFile, 'parentFile....Remove');
        const updatedFileList = fileList?.map(f => {
            if ((f['uid'] &&  f['uid'] === parentSelectedFile['uid']) || (f['id'] && f['id'] === parentSelectedFile['id'])) {
                return { ...f, thumbnail: [] };
            }
            return f;
        });
        setAttachmentFile(updatedFileList);
    };

    const fileThumbnailhandleChange = async ({ ...e }) => {

        const { file, parentSelectedFile, originFileObj } = e;

        const thumbnailFileList = fileList?.find((e) => (e['uid'] && e['uid'] === parentSelectedFile['uid']) || (e['id'] && e['id'] === parentSelectedFile['id']))?.thumbnail || {};

        console.log(thumbnailFileList, 'thumbnailFileList..');

        const fileResponse = await filehandleChangeHelper(
            imageFilter, imageFilterMessage, file, thumbnailFileList, [],
            '', 'Content Thumbnail', MaxFileCount.attachmentThumbnail,
            false, '', '', {}, true
        );

        if (fileResponse) {
            const formData = new FormData();
            formData.append('file', file);

            const response = await dispatch(uploadObject(formData)).unwrap();
            const { data, success } = response;

            if (success) {
                const updatedFileList = fileList?.map(f => {
                    if ((f['uid'] && f['uid'] === parentSelectedFile['uid']) || (f['id'] && f['id'] === parentSelectedFile['id'])) {
                        return {
                            ...f,
                            thumbnail: [
                                ...f?.thumbnail || [],
                                {
                                    uid: file['uid'],
                                    name: file['name'],
                                    size: file['size'],
                                    type: file['type'],
                                    fileLocation: data,
                                    // thumbUrl: URL.createObjectURL(originFileObj)
                                }
                            ]
                        };
                    }
                    return f;
                });
                setAttachmentFile(updatedFileList);
                console.log(updatedFileList, 'updatedFileList......');
            }
        }
    };

    const uploadProps = {
        fileList,
        beforeUpload: file => {
            return false;
        },
        onChange: info => {
            if (info.file.status !== 'removed') {
                filehandleChange(info);
            }
        },
        onRemove: file => {
            handleFileRemove(file);
            return true;
        },
        multiple: true,
        maxCount: allowCount,
    };

    const customItemRender = (originNode, file, fileList, actions) => ( 
        <div className={isCurriculumAttachment ? 'custom-thumbnail-container mt-6' : ''}>
            <div className={`custom-upload-list-item ${isCurriculumAttachment ? 'm-0' : ''}`} key={file.uid || file.id}>
                <div className='upload-file-container'>
                    <div className="file-icon">
                        {getImage(file?.type)}
                    </div>
                    <div className="file-details">
                        <Tooltip placement='top' title={file?.name}>
                            <a href={file?.url} className="file-name" target="_blank" rel="noopener noreferrer">
                                {file?.name || '-'}
                            </a>
                        </Tooltip>
                        <p className="file-size">{formatFileSizeHelper(file?.size)}</p>
                    </div>
                </div>
                <div className="remove-icon" onClick={() => actions.remove(file)}>
                    <Image src={closeIcon} className='remove-icon' preview={false} />
                </div>
            </div>

            {uploading && <ProgressBar progress={progress} />}

            {(thumbnailFileRequired && !uploading) && (
                <div className="form-control file-input-btn attachment-upload mt-6">
                    <label className='label-name'>Content Thumbnail</label>
                    <div className='attachment-upload-form'>
                        <FileUpload
                            filehandleChange={(e) => fileThumbnailhandleChange({ ...e, parentSelectedFile: file })}
                            handleFileRemove={() => handleFileThumbnailRemove({ parentSelectedFile: file })}
                            file={file['thumbnail']?.length ? file['thumbnail'] : []}
                            allowCount={MaxFileCount.attachmentThumbnail}
                            customButton={
                                <Button className="attachment-upload-button">
                                    Drop files to attach or <span className="browse-and-upload">browse and upload</span>
                                </Button>
                            }
                        />

                        {/* {file.thumbnail && (
                            <div className="image-preview">
                                <img
                                    src={file.thumbnail.thumbUrl || file.thumbnail.fileLocation}
                                    alt={file.thumbnail.name}
                                    style={{ width: '50%', height: '50%', marginTop: '10px' }}
                                />
                            </div>
                        )} */}
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <Upload
            {...uploadProps}
            itemRender={customItemRender}
            className="ImageUpload"
        >
            {customButton}
        </Upload>
    );
};

export default CustomFileUpload;
