import * as React from 'react';
import { Tooltip, Image } from 'antd';
import SubscriptionIcon from "../../asset/image/subscriptionIcon.svg";
import CloseIcon from "../../asset/image/close.svg"
import "../styles/PricingList.scss";


const PricingList = ({ prices, frequencyMap, deletePricing,onPriceChange }) => {

    const renderPricingPlan = () => {

        return (Array.isArray(prices) ? prices : []).map((item, index) => {

            const tooltipTitle = (
                <div>
                  <div>
                    ({frequencyMap[item['frequencyId']] || '-'}) Price: Rs.{item['price'] || '-'}
                  </div>
                  {item['actualPrice'] !== item['price'] && (
                        <div className='tooltip-actual-price'>
                            Actual Price:
                            <span className='strike-price'>
                               Rs.{item['actualPrice'] || '-'}
                            </span>
                        </div>
                  )}
                </div>
              );

            return (
                <div className="pricing-item" key={`${index}-pricing`}>
                    <div className="subscription-container-left">
                        <Image src={SubscriptionIcon} preview={false} />
                        <p className="subscription">Subscription</p>
                    </div>
                    <div className="subscription-container-right">
                        <Tooltip className="subscription-tooltip" placement="top" title={tooltipTitle}>
                            <span className="subscription-with-price ellipsis-text">
                                ({frequencyMap[item['frequencyId']] || '-'})
                            </span>
                            <span className="subscription-with-price ellipsis-text">
                                 Rs.{item['price'] || '-'}
                            </span>
                            {item['actualPrice'] !== item['price'] && (
                                <span className="subscription-with-price strike-price ellipsis-text">
                                    Rs.{item['actualPrice'] || '-'}
                                </span>
                            )}
                        </Tooltip>
                        <Image
                            onClick={() => {deletePricing(item, index);  onPriceChange();} }
                            className="close-icon"
                            src={CloseIcon}
                            preview={false}
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <>
            {prices?.length > 0 && (
                <div className="plan-pricing-container">
                    <p className="plan-pricing-title">Pricing Plan</p>
                    <div className="pricing-list">
                        {renderPricingPlan()}
                    </div>
                </div>
            )}
        </>
    );

};

export default PricingList;