import React, { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Menu, Layout, Divider, Modal, Popover, Button } from 'antd';
import { useState } from 'react';
import { HomeOutlined } from '@ant-design/icons'
import { Image } from 'antd';
import logoImage from '../../asset/image/Centum_App_logo.svg';
import DashboardImage from '../../asset/image/Dashboard_Icon.svg';
import chapterImage from '../../asset/image/Chapter_Master.svg';
import examImage from '../../asset/image/Exam.svg';
import questionImage from '../../asset/image/Question_Bank.svg'
import settingsImage from '../../asset/image/Settings.svg';
import userAddImage from '../../asset/image/User_add.svg';
import quizImage from '../../asset/image/Quizz.svg'
import SubscriptionImage from "../../asset/image/subcription.svg";
import TestBundle from '../../asset/image/Test_Bundles.svg';
import logoutImage from '../../asset/image/logout1.svg'
import couponImage from '../../asset/image/coupons.svg'
import userLogo from '../../asset/image/userLogo.svg';
import notifyLogo from '../../asset/image/notifyIcon.svg'
import { Tooltip } from 'antd';
import { getEncryptedLocalStorage } from '../../utils/utils';
import 'antd/dist/antd.css';


import '../../App.scss'
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../reducers/masterSlice";
import BreadCrumb from "../BreadCrumb";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const LayoutComponent = () => {
  const dispatch = useDispatch()
  const roleId = getEncryptedLocalStorage("roleId")
  const name = getEncryptedLocalStorage("name")
  const roleName = getEncryptedLocalStorage("roleName")
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentBreadCrumbData, isLoading, appInstallCount } = useSelector((state) => state.MasterSlice)

  const navigate = useNavigate()
  const logout = () => {
    setIsModalOpen(true);
  }
  const handleOk = () => {
    setIsModalOpen(false);
    localStorage.removeItem('authToken')
    localStorage.clear()
    navigate('/login')
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const content = (
    <div className='logout-container' onClick={() => logout()} >
      <span className='svg-image'>
        <Image preview={false} src={logoutImage} height={22}></Image>
      </span>
      <span className="nav-text">Logout</span>
    </div>
  )


  return (
    <div>
      <Layout
        style={{
          minHeight: '100vh',
        }}
      >
        <Sider width={220} collapsible collapsed={false} onCollapse={(value) => setCollapsed(value)} className="site-layout-slider"
          style={{
            position: 'fixed',
            display: 'contents',

          }}>
          <div className="logo">
            <Image preview={false}
              src={logoImage}
            />
          </div>
          {
            (getEncryptedLocalStorage('institutionAdmin') == true || getEncryptedLocalStorage('institutionAdmin') == 'true') ?
              <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
                <Menu.Item key="1"
                // className={(location.pathname == '/course' ? 'ant-menu-item-selected' : '')}
                >
                  <Link to="/course">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.52462 19.5005H17.4754C17.4917 19.5005 17.5084 19.4984 17.5242 19.4943C19.104 19.086 19.5 17.6638 19.5 17.0005V12.2005C19.5 12.09 19.4105 12.0005 19.3 12.0005H15.1442C15.0581 12.0005 14.9816 12.0556 14.9544 12.1372L14.1229 14.6318C14.0715 14.7861 13.8671 14.8177 13.7714 14.6862L10.1778 9.7449C10.0935 9.62901 9.91824 9.63676 9.84451 9.75964L8.55826 11.9034C8.52212 11.9636 8.45701 12.0005 8.38676 12.0005H4.7C4.58954 12.0005 4.5 12.0899 4.5 12.2004V17.0005C4.5 18.582 5.80274 19.3168 6.47666 19.4945C6.49247 19.4987 6.50827 19.5005 6.52462 19.5005Z" fill="#E1E7EA" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M7 5.50049H17C17.8284 5.50049 18.5 6.17206 18.5 7.00049V11.2505H14.8009C14.3884 11.2505 14.023 11.5167 13.8966 11.9094L13.4617 13.2607L10.6514 8.89462C10.2497 8.27059 9.31967 8.32747 8.99703 8.99579L7.90856 11.2505H5.5V7.00049C5.5 6.17206 6.17157 5.50049 7 5.50049ZM5.5 12.7505V17.0005C5.5 17.8289 6.17157 18.5005 7 18.5005H17C17.8284 18.5005 18.5 17.8289 18.5 17.0005V12.7505H15.2017L14.5332 14.8276C14.2874 15.5912 13.2643 15.7253 12.83 15.0507L9.92123 10.5317L9.1093 12.2135C8.95079 12.5418 8.61837 12.7505 8.25378 12.7505H5.5ZM4 7.00049C4 5.34363 5.34315 4.00049 7 4.00049H17C18.6569 4.00049 20 5.34363 20 7.00049V17.0005C20 18.6573 18.6569 20.0005 17 20.0005H7C5.34315 20.0005 4 18.6573 4 17.0005V7.00049Z" fill="currentColor" />
                    </svg>
                    <span className="nav-text">Course Master</span>
                  </Link>
                </Menu.Item>

                <Divider className="divider-class"></Divider>

                <Menu.Item key="2"
                //  className={(location.pathname == '/PurchasedTest' ? 'ant-menu-item-selected' : '')}
                >
                  <Link to="/PurchasedTest">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                    </svg>
                    <span className="nav-text">Unpublished Tests</span>
                  </Link>
                </Menu.Item>

                <Divider className="divider-class"></Divider>

                <Menu.Item key="3"
                // className={(location.pathname == '/PublishedTest' ? 'ant-menu-item-selected' : '')}
                >
                  <Link to="/PublishedTest">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                    </svg>
                    <span className="nav-text">Published Tests</span>
                  </Link>
                </Menu.Item>
              </Menu>
              :
              <Menu theme="light" mode="inline">
                <Menu.Item key="4" className={(location.pathname == '/Dashboard' ? 'ant-menu-item-selected' : '')}>
                  <Link to="/Dashboard">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.52462 19.5005H17.4754C17.4917 19.5005 17.5084 19.4984 17.5242 19.4943C19.104 19.086 19.5 17.6638 19.5 17.0005V12.2005C19.5 12.09 19.4105 12.0005 19.3 12.0005H15.1442C15.0581 12.0005 14.9816 12.0556 14.9544 12.1372L14.1229 14.6318C14.0715 14.7861 13.8671 14.8177 13.7714 14.6862L10.1778 9.7449C10.0935 9.62901 9.91824 9.63676 9.84451 9.75964L8.55826 11.9034C8.52212 11.9636 8.45701 12.0005 8.38676 12.0005H4.7C4.58954 12.0005 4.5 12.0899 4.5 12.2004V17.0005C4.5 18.582 5.80274 19.3168 6.47666 19.4945C6.49247 19.4987 6.50827 19.5005 6.52462 19.5005Z" fill="#E1E7EA" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M7 5.50049H17C17.8284 5.50049 18.5 6.17206 18.5 7.00049V11.2505H14.8009C14.3884 11.2505 14.023 11.5167 13.8966 11.9094L13.4617 13.2607L10.6514 8.89462C10.2497 8.27059 9.31967 8.32747 8.99703 8.99579L7.90856 11.2505H5.5V7.00049C5.5 6.17206 6.17157 5.50049 7 5.50049ZM5.5 12.7505V17.0005C5.5 17.8289 6.17157 18.5005 7 18.5005H17C17.8284 18.5005 18.5 17.8289 18.5 17.0005V12.7505H15.2017L14.5332 14.8276C14.2874 15.5912 13.2643 15.7253 12.83 15.0507L9.92123 10.5317L9.1093 12.2135C8.95079 12.5418 8.61837 12.7505 8.25378 12.7505H5.5ZM4 7.00049C4 5.34363 5.34315 4.00049 7 4.00049H17C18.6569 4.00049 20 5.34363 20 7.00049V17.0005C20 18.6573 18.6569 20.0005 17 20.0005H7C5.34315 20.0005 4 18.6573 4 17.0005V7.00049Z" fill="currentColor" />
                    </svg>
                    <span className="nav-text">Dashboard</span>
                  </Link>
                </Menu.Item>

                {
                  roleId == 1 || roleId == 3 ?
                    <>
                      <Divider className="divider-class"></Divider>

                      <SubMenu key="5" title="COCHMI">
                         <Menu.Item key="6" className={(location.pathname == '/clusterCourse' ? 'ant-menu-item-selected' : '')}>
                         <Tooltip title="Courses">
                        <Link to="/clusterCourse">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                          </svg>
                          <span className="nav-text">Courses</span>
                        </Link>
                        </Tooltip>
                      </Menu.Item>
                      <Menu.Item key="7" className={(location.pathname == '/otherVideos' ? 'ant-menu-item-selected' : '')}>
                      <Tooltip title="Other Videos">
                        <Link to="/otherVideo">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                          </svg>
                          <span className="nav-text">Other Videos</span>
                        </Link>
                        </Tooltip>
                      </Menu.Item>
                      </SubMenu>

                    </>

                    : null
                }
                {
                  roleId == 1 || roleId == 3 ?
                    <>
                      <Divider className="divider-class"></Divider>

                      <SubMenu key="8" title="CONTENT">
                        <Menu.Item key="9" className={(location.pathname == '/TestMaster' ? 'ant-menu-item-selected' : '')}>
                        <Tooltip title="Test Master">
                          <Link to="/TestMaster">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 4.00049L5 7.00049L12 10.0005L19 7.00049L12 4.00049Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                              <path d="M10.8336 9.50025L11.1291 8.8109C10.9404 8.73004 10.7268 8.73004 10.5382 8.8109L10.8336 9.50025ZM12 10.0001L11.7045 10.6895C11.8932 10.7703 12.1068 10.7703 12.2954 10.6895L12 10.0001ZM13.1664 9.50024L13.4618 8.81089C13.2731 8.73003 13.0596 8.73003 12.8709 8.81089L13.1664 9.50024ZM19 12.0004L19.2954 12.6897C19.5712 12.5715 19.75 12.3004 19.75 12.0004C19.75 11.7004 19.5712 11.4292 19.2954 11.311L19 12.0004ZM12 15.0004L11.7046 15.6897C11.8932 15.7706 12.1068 15.7706 12.2954 15.6897L12 15.0004ZM5 12.0004L4.70456 11.311C4.4288 11.4292 4.25 11.7004 4.25 12.0004C4.25 12.3004 4.4288 12.5715 4.70456 12.6897L5 12.0004ZM10.5382 10.1896L11.7045 10.6895L12.2954 9.31077L11.1291 8.8109L10.5382 10.1896ZM12.2954 10.6895L13.4618 10.1896L12.8709 8.81089L11.7045 9.31077L12.2954 10.6895ZM19.2954 11.311L13.4618 8.81089L12.8709 10.1896L18.7046 12.6897L19.2954 11.311ZM12.2954 15.6897L19.2954 12.6897L18.7046 11.311L11.7046 14.311L12.2954 15.6897ZM4.70456 12.6897L11.7046 15.6897L12.2954 14.311L5.29544 11.311L4.70456 12.6897ZM10.5382 8.8109L4.70456 11.311L5.29544 12.6897L11.1291 10.1896L10.5382 8.8109Z" fill="currentColor" />
                              <path d="M10.8333 14.5005L11.1288 13.8111C10.9401 13.7303 10.7266 13.7303 10.5379 13.8111L10.8333 14.5005ZM12 15.0005L11.7046 15.6898C11.8932 15.7707 12.1068 15.7707 12.2954 15.6898L12 15.0005ZM13.1667 14.5005L13.4621 13.8111C13.2734 13.7303 13.0599 13.7303 12.8712 13.8111L13.1667 14.5005ZM19 17.0005L19.2954 17.6898C19.5712 17.5717 19.75 17.3005 19.75 17.0005C19.75 16.7005 19.5712 16.4293 19.2954 16.3111L19 17.0005ZM12 20.0005L11.7046 20.6898C11.8932 20.7707 12.1068 20.7707 12.2954 20.6898L12 20.0005ZM5 17.0005L4.70456 16.3111C4.4288 16.4293 4.25 16.7005 4.25 17.0005C4.25 17.3005 4.4288 17.5717 4.70456 17.6898L5 17.0005ZM10.5379 15.1898L11.7046 15.6898L12.2954 14.3111L11.1288 13.8111L10.5379 15.1898ZM12.2954 15.6898L13.4621 15.1898L12.8712 13.8111L11.7046 14.3111L12.2954 15.6898ZM19.2954 16.3111L13.4621 13.8111L12.8712 15.1898L18.7046 17.6898L19.2954 16.3111ZM12.2954 20.6898L19.2954 17.6898L18.7046 16.3111L11.7046 19.3111L12.2954 20.6898ZM4.70456 17.6898L11.7046 20.6898L12.2954 19.3111L5.29544 16.3111L4.70456 17.6898ZM10.5379 13.8111L4.70456 16.3111L5.29544 17.6898L11.1288 15.1898L10.5379 13.8111Z" fill="currentColor" />
                            </svg>

                            <span className="nav-text" style={{ fontSize: 13 }}>Test Master</span>
                          </Link>
                          </Tooltip>
                        </Menu.Item>
                        <Menu.Item key="10" className={(location.pathname == '/ExamGroupSingleTest' ? 'ant-menu-item-selected' : '')}>
                        <Tooltip title="Single Test Paper">
                          <Link to="/ExamGroupSingleTest">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                          </svg>

                            <span className="nav-text" style={{ fontSize: 13 }}>Single Test Paper</span>
                          </Link>
                          </Tooltip>
                        </Menu.Item>
                      </SubMenu>

                    </>

                    : null
                }


                <Divider className="divider-class"></Divider>

                {
                  roleId != 1 && roleId != 3 ?
                    <>
                      <Divider className="divider-class"></Divider>

                      <SubMenu key="11" title="CONTENT">
                        <Menu.Item key="12" className={(location.pathname == '/ExamGroupSingleTest' ? 'ant-menu-item-selected' : '')}>
                        <Tooltip title="Single Test Paper">
                          <Link to="/ExamGroupSingleTest">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 4.00049L5 7.00049L12 10.0005L19 7.00049L12 4.00049Z" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
                              <path d="M10.8336 9.50025L11.1291 8.8109C10.9404 8.73004 10.7268 8.73004 10.5382 8.8109L10.8336 9.50025ZM12 10.0001L11.7045 10.6895C11.8932 10.7703 12.1068 10.7703 12.2954 10.6895L12 10.0001ZM13.1664 9.50024L13.4618 8.81089C13.2731 8.73003 13.0596 8.73003 12.8709 8.81089L13.1664 9.50024ZM19 12.0004L19.2954 12.6897C19.5712 12.5715 19.75 12.3004 19.75 12.0004C19.75 11.7004 19.5712 11.4292 19.2954 11.311L19 12.0004ZM12 15.0004L11.7046 15.6897C11.8932 15.7706 12.1068 15.7706 12.2954 15.6897L12 15.0004ZM5 12.0004L4.70456 11.311C4.4288 11.4292 4.25 11.7004 4.25 12.0004C4.25 12.3004 4.4288 12.5715 4.70456 12.6897L5 12.0004ZM10.5382 10.1896L11.7045 10.6895L12.2954 9.31077L11.1291 8.8109L10.5382 10.1896ZM12.2954 10.6895L13.4618 10.1896L12.8709 8.81089L11.7045 9.31077L12.2954 10.6895ZM19.2954 11.311L13.4618 8.81089L12.8709 10.1896L18.7046 12.6897L19.2954 11.311ZM12.2954 15.6897L19.2954 12.6897L18.7046 11.311L11.7046 14.311L12.2954 15.6897ZM4.70456 12.6897L11.7046 15.6897L12.2954 14.311L5.29544 11.311L4.70456 12.6897ZM10.5382 8.8109L4.70456 11.311L5.29544 12.6897L11.1291 10.1896L10.5382 8.8109Z" fill="currentColor" />
                              <path d="M10.8333 14.5005L11.1288 13.8111C10.9401 13.7303 10.7266 13.7303 10.5379 13.8111L10.8333 14.5005ZM12 15.0005L11.7046 15.6898C11.8932 15.7707 12.1068 15.7707 12.2954 15.6898L12 15.0005ZM13.1667 14.5005L13.4621 13.8111C13.2734 13.7303 13.0599 13.7303 12.8712 13.8111L13.1667 14.5005ZM19 17.0005L19.2954 17.6898C19.5712 17.5717 19.75 17.3005 19.75 17.0005C19.75 16.7005 19.5712 16.4293 19.2954 16.3111L19 17.0005ZM12 20.0005L11.7046 20.6898C11.8932 20.7707 12.1068 20.7707 12.2954 20.6898L12 20.0005ZM5 17.0005L4.70456 16.3111C4.4288 16.4293 4.25 16.7005 4.25 17.0005C4.25 17.3005 4.4288 17.5717 4.70456 17.6898L5 17.0005ZM10.5379 15.1898L11.7046 15.6898L12.2954 14.3111L11.1288 13.8111L10.5379 15.1898ZM12.2954 15.6898L13.4621 15.1898L12.8712 13.8111L11.7046 14.3111L12.2954 15.6898ZM19.2954 16.3111L13.4621 13.8111L12.8712 15.1898L18.7046 17.6898L19.2954 16.3111ZM12.2954 20.6898L19.2954 17.6898L18.7046 16.3111L11.7046 19.3111L12.2954 20.6898ZM4.70456 17.6898L11.7046 20.6898L12.2954 19.3111L5.29544 16.3111L4.70456 17.6898ZM10.5379 13.8111L4.70456 16.3111L5.29544 17.6898L11.1288 15.1898L10.5379 13.8111Z" fill="currentColor" />
                            </svg>

                            <span className="nav-text" style={{ fontSize: 13 }}>Single Test Paper</span>
                          </Link>
                          </Tooltip>
                        </Menu.Item>
                      </SubMenu>

                    </>

                    : null
                }

                <Divider className="divider-class"></Divider>

                <SubMenu key="13" title="MASTERS">
                  <Menu.Item key="14" className={(location.pathname == '/ExamMaster' ? 'ant-menu-item-selected' : '')}>
                  <Tooltip title="Exam Master">
                    <Link to="/ExamMaster">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                      </svg>
                      <span className="nav-text">Exam Master</span>
                    </Link>
                    </Tooltip>
                  </Menu.Item>
                  {
                    roleId == 3 ?
                      <Menu.Item key="15" className={(location.pathname == '/ExamGroup' ? 'ant-menu-item-selected' : '')}>
                         <Tooltip title="Exam Group">
                        <Link to="/ExamGroup">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                          </svg>
                          <span className="nav-text">Exam Group</span>
                        </Link>
                        </Tooltip>
                      </Menu.Item>
                      :
                      null
                  }
                  {
                    roleId == 3 ?
                      <Menu.Item key="16" className={(location.pathname == '/InstitutionMaster' ? 'ant-menu-item-selected' : '')}>
                         <Tooltip title="Institution Master">
                        <Link to="/InstitutionMaster">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                          </svg>
                          <span className="nav-text">Institution Master</span>
                        </Link>
                        </Tooltip>
                      </Menu.Item>
                      :
                      null
                  }

                  {
                    roleId == 3 ?
                      <Menu.Item key="17" className={(location.pathname == '/CouponMaster' ? 'ant-menu-item-selected' : '')}>
                         <Tooltip title="Coupon">
                        <Link to="/CouponMaster">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                          </svg>
                          <span className="nav-text">Coupon</span>
                        </Link>
                        </Tooltip>
                      </Menu.Item>
                      :
                      null
                  }

                  {
                    roleId == 3 ?
                      <Menu.Item key="18" className={(location.pathname == '/CouponStudent' ? 'ant-menu-item-selected' : '')}>
                        <Tooltip title="Coupon Student Mapping">
                          <Link to="/CouponStudent">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                            </svg>
                            <span className="nav-text">Coupon Student Mapping</span>
                          </Link>
                        </Tooltip>
                      </Menu.Item>
                      :
                      null
                  }
                  {
                    roleId == 3 ?
                      <Menu.Item key="19" className={(location.pathname == '/ClusterMaster' ? 'ant-menu-item-selected' : '')}>
                        <Tooltip title="Cluster Master">
                          <Link to="/ClusterMaster">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                            </svg>
                            <span className="nav-text">Cluster Master</span>
                          </Link>
                        </Tooltip>
                      </Menu.Item>
                      :
                      null
                  }

                  {
                    roleId == 3 ?
                      <Menu.Item key="20" className={(location.pathname == '/ClusterGroupMaster' ? 'ant-menu-item-selected' : '')}>
                        <Tooltip title="Group Master">
                          <Link to="/ClusterGroupMaster">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                            </svg>
                            <span className="nav-text">Cluster Group Master</span>
                          </Link>
                        </Tooltip>
                      </Menu.Item>
                      :
                      null
                  }
                  {
                    roleId == 3 ?
                      <Menu.Item key="21" className={(location.pathname == '/SingleTestPaperCategory' ? 'ant-menu-item-selected' : '')}>
                        <Tooltip title="Single Test Paper Category Master">
                          <Link to="/SingleTestPaperCategory">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                            </svg>
                            <span className="nav-text"> Single Test Paper Category Master </span>
                          </Link>
                        </Tooltip>
                      </Menu.Item>
                      :
                      null
                  }
                  {
                    roleId == 3 ?
                      <Menu.Item key="42" className={(location.pathname == '/mockTestBanner' ? 'ant-menu-item-selected' : '')}>
                        <Tooltip title="Mock Test Banner">
                          <Link to="/mockTestBanner">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                            </svg>
                            <span className="nav-text">Mock Test Banner</span>
                          </Link>
                        </Tooltip>
                      </Menu.Item>
                      :
                      null
                  }

                </SubMenu>
                <Divider className="divider-class"></Divider>

                {
                  roleId == 1 || roleId == 3 ?
                    <>
                      <SubMenu key="22" title="USER MANAGEMENT">

                        <Menu.Item key="23" className={(location.pathname == '/Admin' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Admin">
                            <Link to="Admin">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Admin</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>
                        <Menu.Item key="24" className={(location.pathname == '/EducatorMaster' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Educator Master">
                            <Link to="EducatorMaster">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Educator Master</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>
                        <Menu.Item key="25" className={(location.pathname == '/RaiseADispute' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Raise A Dispute">
                            <Link to="/RaiseADispute">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.2855 3H7.5355V3.75V4.1785H4.75H4V4.9285V19.0705C4 20.1356 4.86342 20.999 5.9285 20.999H17.7135C18.7786 20.999 19.642 20.1356 19.642 19.0705V4.9285V4.1785H18.892H16.1065V3.75V3H15.3565H8.2855ZM5.5 5.6785H7.5355V6.107C7.5355 7.17208 8.39892 8.0355 9.464 8.0355H14.178C15.2431 8.0355 16.1065 7.17208 16.1065 6.107V5.6785H18.142V19.0705C18.142 19.3072 17.9502 19.499 17.7135 19.499H5.9285C5.69185 19.499 5.5 19.3072 5.5 19.0705V5.6785ZM9.0355 6.107V4.5H14.6065V6.107C14.6065 6.34365 14.4147 6.5355 14.178 6.5355H9.464C9.22735 6.5355 9.0355 6.34365 9.0355 6.107ZM7.10692 11.5709H14.7672V10.0709H7.10692V11.5709ZM7.10692 16.2849H11.2317V14.7849H7.10692V16.2849Z" fill="currentColor" />
                              </svg>
                              <span className="nav-text">Raise A Dispute</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>
                      </SubMenu>
                    </>
                    : null
                }

                <Divider className="divider-class"></Divider>
                {
                  roleId == 3 ?
                    <>
                      <SubMenu key="26" title="WEBSITE">

                        <Menu.Item key="27" className={(location.pathname == '/Community' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Community">
                            <Link to="Community">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Community</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>
                        <Menu.Item key="28" className={(location.pathname == '/Stats' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Stats">
                            <Link to="Stats">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Stats</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>


                        <Menu.Item key="29" className={(location.pathname == '/CurrentAffairs' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Current Affairs">
                            <Link to="CurrentAffairs">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Current Affairs</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>
                        <Menu.Item key="30" className={(location.pathname == '/WebsiteNotification' ? 'ant-menu-item-selected' : '')}>
                          <Link to="WebsiteNotification">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                              <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                              <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="nav-text">Website Notification</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="31" className={(location.pathname == '/Announcement' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Announcement">
                            <Link to="Announcement">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Announcement</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>

                        <Menu.Item key="32" className={(location.pathname == '/KeyFeatures' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Key Features">
                            <Link to="KeyFeatures">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Key Features</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>


                        <Menu.Item key="33" className={(location.pathname == '/Tutorial' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Tutorial">
                            <Link to="Tutorial">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Tutorial</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>

                        <Menu.Item key="34" className={(location.pathname == '/InstitutionContactRegister' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Institution Contact Register">
                            <Link to="InstitutionContactRegister">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Institution Contact Register</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>

                        <Menu.Item key="35" className={(location.pathname == '/leads' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Leads">
                            <Link to="leads">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Leads</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>


                        <Menu.Item key="36" className={(location.pathname == '/testimonials' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Testimonials">
                            <Link to="testimonials">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Testimonials</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>

                        <Menu.Item key="37" className={(location.pathname == '/product' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Product">
                            <Link to="product">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Product</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>

                        <Menu.Item key="38" className={(location.pathname == '/leadGeneration' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Lead Generation">
                            <Link to="leadGeneration">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Lead Generation</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>

                        <Menu.Item key="39" className={(location.pathname == '/careerDetails' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Career Details">
                            <Link to="careerDetails">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Career Details</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>

                      </SubMenu>
                    </>
                    : null
                }
                <Divider className="divider-class">
                </Divider>

                {
                  roleId == 3 ?
                    <>
                      <SubMenu key="40" title="PUSH NOTIFICATION">

                        <Menu.Item key="41" className={(location.pathname == '/Notification' ? 'ant-menu-item-selected' : '')}>
                          <Tooltip title="Notification">
                            <Link to="Notification">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10.649" cy="7.64751" r="3.64702" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                                <path d="M2.80364 19.1149C2.64981 19.4995 2.83688 19.9359 3.22147 20.0898C3.60605 20.2436 4.04253 20.0565 4.19636 19.6719L2.80364 19.1149ZM12.14 15.521C12.5517 15.5667 12.9225 15.27 12.9682 14.8584C13.0139 14.4467 12.7173 14.0759 12.3056 14.0301L12.14 15.521ZM4.19636 19.6719C5.10059 17.4113 7.8658 15.0461 12.14 15.521L12.3056 14.0301C7.34388 13.4789 3.95183 16.2443 2.80364 19.1149L4.19636 19.6719Z" fill="currentColor" />
                                <path d="M16.9858 19.318C17.675 19.318 18.3359 19.0442 18.8233 18.5569C19.3106 18.0696 19.5843 17.4087 19.5843 16.7195C19.5843 16.0303 19.3106 15.3694 18.8233 14.8821C18.3359 14.3948 17.675 14.121 16.9858 14.121M16.9858 19.318C16.2967 19.318 15.6357 19.0442 15.1484 18.5569C14.6611 18.0696 14.3873 17.4087 14.3873 16.7195M16.9858 19.318V21.2327M16.9858 14.121C16.2967 14.121 15.6357 14.3948 15.1484 14.8821C14.6611 15.3694 14.3873 16.0303 14.3873 16.7195M16.9858 14.121V12.2063M14.3873 16.7195H12.4727M21.5009 16.7195H19.5862M15.0712 18.6342L13.8403 19.865M20.1332 13.5739L18.9024 14.8048M18.9024 18.6342L20.1332 19.865M13.8403 13.5739L15.0712 14.8048" stroke="currentColor" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <span className="nav-text">Notification</span>
                            </Link>
                          </Tooltip>
                        </Menu.Item>

                      </SubMenu>
                    </>
                    : null
                }

              </Menu>
          }
        </Sider>
        <Layout className="site-layout">
          {
            <Header className="site-layout-background" style={{ padding: 0 }} >
              <div className={(getEncryptedLocalStorage('institutionAdmin') == true || getEncryptedLocalStorage('institutionAdmin') == 'true') ? 'dynamic-container' : 'logo-container'}>
                {
                  (getEncryptedLocalStorage('institutionAdmin') == true || getEncryptedLocalStorage('institutionAdmin') == 'true') ? null :
                    <div style={{ width: '60%' }}>
                      <BreadCrumb data={currentBreadCrumbData} loading={isLoading} />
                    </div>
                }
                <div className="header-container-app-details-container">
                {roleId === 3 && (
                  <div className="header-container-app-details">
                    <div className="header-container-app-details-right">
                      <span className="header-container-app-details-right-title">App Install Count: </span>
                      <span className="header-container-app-details-right-value">{appInstallCount}</span>
                    </div>
                  </div>
                )}
                <div className="userDetails-container">
                  <div className="login-userDetails">
                    <span className="name">{name}</span>
                  </div>
                  <Popover content={content} trigger="hover">
                    <div className="user-image">
                      <Image preview={false} src={userLogo} className="user-logo" ></Image>
                      <span className="roleName">{roleName}</span>
                    </div>
                  </Popover>
                  </div>
                </div>
              </div>
            </Header>
          }
          <Content
            style={{
              margin: '20px 14px 2px 14px',
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <Modal title="Logout confirmation" open={isModalOpen} onCancel={handleCancel} footer={
        <div>
          <Button className='primary-cancel-button' onClick={() => handleCancel()}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() => handleOk()}>OK</Button>
        </div>
      } >
        <p>Are you sure to logout?</p>
      </Modal>
    </div>
  )
};
export default LayoutComponent;