import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from 'moment';

export const getAllAnnouncement = createAsyncThunk('announcement/getAllAnnouncement', async (payload) => {
    try {
        let getAllData = await axios.post('announcement/getAnnouncement', payload).then((res) => {
            if (res.data.success) {
                return res.data.data
            }
        })
        return getAllData
    } catch (error) {
        message.error(error?.response?.data?.message)
    }
})

export const createAnnouncement = createAsyncThunk("announcement/createAnnouncement", async (payload, thunk) => {
    try {
        let getUser = await axios.post('/announcement/addAnnouncement', payload.data).then(async (res) => {
            if (res.data.status) {
                message.success(res.data.message)
                await thunk.dispatch(getAllAnnouncement(payload.obj))
                return res.data
            }
        })
        return getUser

    } catch (error) {
        message.error(error?.response?.data?.message)
    }
})

export const getAnnouncementById = createAsyncThunk("announcement/getAnnouncementById", async (payload) => {
    try {
        let getUser = await axios.get(`/announcement/getAnnouncement/${payload}`).then((res) => {
            if (res.data.success) {
                return res.data
            }
        })
        return getUser
    } catch (error) {
    }
})

export const updateAnnouncement = createAsyncThunk("announcement/updateAnnouncement", async (payload, thunk) => {
    try {
        let updateUser = await axios.put(`/announcement/updateAnnouncement/${payload.id}`, payload.data).then(async (res) => {
            if (res.data.status) {
                await thunk.dispatch(getAllAnnouncement(payload.obj));
                message.success(res.data.message)
                return res.data
            }
        })
        return updateUser
    } catch (error) {
        message.error(error?.response?.data?.message)
    }
})

export const deleteAnnoncement = createAsyncThunk(
    "announcement/deleteAnnoncement", async (payload) => {
        try {
            let deleteAnnoncement = axios.delete('announcement/announcement/' + payload.id)
                .then((res) => {
                    if (res.data.success) {
                        message.success(res.data.message)
                        return res.data
                    }
                })
            return deleteAnnoncement;
        }
        catch (error) {
        }
    }
)


const initialState = {
    CurrentAffairs: [],
    isLoading: false,
    currentDataCount: 0,
    currentData: '',
}


const Announcement = createSlice({
    name: 'Announcement',
    initialState,
    extraReducers: {
        [getAllAnnouncement.pending]: (state) => {
            state.isLoading = true
        },
        [getAllAnnouncement.fulfilled]: (state, actions) => {
            state.isLoading = false
            state.currentData = actions.payload?.getAnnouncementData;
            state.currentDataCount = actions.payload.getAnnouncementCount.announceCount;
        },
        [getAllAnnouncement.rejected]: (state) => {
            state.isLoading = false
        },
        [createAnnouncement.pending]: (state, actions) => {
            state.isLoading = true
        },
        [createAnnouncement.fulfilled]: (state, actions) => {
            state.isLoading = false
            state.createAnnouncement = actions.payload
        },
        [createAnnouncement.rejected]: (state, actions) => {
            state.isLoading = false
        },
        [getAnnouncementById.pending]:(state) => {
            state.isLoading = true
        },
        [getAnnouncementById.fulfilled]:(state,actions) => {
            state.isLoading = false
        },
        [getAnnouncementById.rejected]:(state) => {
            state.isLoading = false
        },
        [updateAnnouncement.pending]:(state) => {
            state.isLoading = true
        },
        [updateAnnouncement.fulfilled]:(state,actions) => {
            state.isLoading = false
        },
        [updateAnnouncement.rejected]:(state) => {
            state.isLoading = false
        },
        [deleteAnnoncement.pending]:(state) => {
            state.isLoading = true
        },
        [deleteAnnoncement.fulfilled]:(state,actions) => {
            state.isLoading = false
        },
        [deleteAnnoncement.rejected]:(state) => {
            state.isLoading = false
        },
    }
})

export default Announcement.reducer
