import React, { useEffect, useState } from 'react';
import TableComponent from './Table/tableComponent';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Image, Button, Drawer, Input, Modal, Select, Tabs, message } from 'antd';
import importImage from '../asset/image/Import.svg';
import { Formik, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DeleteImage from '../asset/image/deleteIcon.svg'
import Loading from '../components/Loading';
import moment from 'moment';
import { getSubjectMasterTable, insertSubjectMasterTable, editSubjectMasterTable, deleteSubjectMasterTable, approveSubject, bulkSubjectApprove, downloadSubjectQuestionsCount } from './reducers/subjectSlice';
import { getExamMasterTable } from './reducers/ExamMasterSlice'
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate, useParams } from 'react-router-dom';
import { getSubjectByExamId } from './reducers/masterSlice';
import * as yup from 'yup';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from './BreadCrumb';
import { getAllTypeData } from './reducers/dashboardSlice';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {getEncryptedLocalStorage} from '../utils/utils';

function SubjectMaster() {
  const { Option } = Select;
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const antIcon = (<LoadingOutlined style={{ fontSize: 24, }} spin />);
  const { subjectMasterTableData, isSubjectLoading, subjectTotalData, breadCrumbData } = useSelector((state) => state.SubjectMaster)
  const { subjectDataByExam, isLoading, subjectBreadCrumbData } = useSelector((state) => state.MasterSlice)
  const { listData, isDashLoading } = useSelector((state) => state.DashboardSlice)

  let tableHeight = 320
  const [editId, setEditId] = useState();
  const [filterData, setFilterData] = useState("");
  const [chapterDetails, setChapterDetails] = useState('')
  const [record, setRecord] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const [currentId, setCurrentId] = useState(null)
  const [currentTitle, setCurrentTitle] = useState("");
  const [buttonState, setButtonState] = useState(1);
  const [approveId, setApproveId] = useState(null);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
  const [isApproval, setIsApproval] = useState(false);
  const [remarksValue, setRemarksValue] = useState(null)



  const { TextArea } = Input;
  const roleId = getEncryptedLocalStorage("roleId")
  const [status, setStatus] = useState();


  const [currentFindObj, setCurrentFindObj] = useState(() => {
    if (roleId == 1) {
      return { id: 2, statusId: 1, name: 'pending', displayName: 'Pending' }

    } else if (roleId == 3) {
      return { id: 5, statusId: 2, name: 'approved', displayName: 'Approved' }

    }
  })
  useEffect(() => {
    if (!params?.statusName) {
      setStatus(currentFindObj?.id)

      let obj = {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
        status: currentFindObj
      }
      let data = {
        examId: +params?.examId,
        obj
      }
      dispatch(getSubjectByExamId(data))
    } else {
      let payload = {
        filterType: params?.statusName,
        type: 'subject'
      }
      dispatch(getAllTypeData(payload))
    }
  }, [])

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getExamMasterTable(obj));
  }, [])

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Subject Name',
      dataIndex: 'name',
      key: 'name',
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            if (!params?.statusName) {

              navigate(`/ExamMaster/${params.examId}/${record.id}`)
            }
            else {
              editData(record)
            }

          }
        };
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span>
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            if (!params?.statusName) {

              navigate(`/ExamMaster/${params.examId}/${record.id}`)
            }
            else {
              editData(record)
            }

          }
        };
      }
    },


    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            {
              roleId == 1 || roleId == 3 ?
                <div className='table-action-link'>
                  <a onClick={() => editData(record)} >Edit</a>
                  {
                    roleId == 3 && !params.statusName ?
                      <Tooltip title="Delete" style={{ padding: 0 }}>
                        <IconButton>
                          <Image src={DeleteImage} preview={false} onClick={() => onDelete(record)}></Image>
                        </IconButton>
                      </Tooltip>
                      : null
                  }
                </div>
                : <div>
                  Not Authorized
                </div>
            }
          </>

        )
      },
    },
  ]

  const search = (value) => {

    setFilterData(value)

    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
      status: currentFindObj


    }
    let data = {
      examId: +params?.examId,
      obj
    }
    debounced(data)
  }
  const debounced = useDebouncedCallback((obj) => {
    if (!params?.statusName) {
      dispatch(getSubjectByExamId(obj))
    }
  }, 400)


  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }
  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
      status: currentFindObj

    }
    let data = {
      examId: +params?.examId,
      obj
    }
    if (!params?.statusName) {
      dispatch(getSubjectByExamId(data))
    }
  }
  const [visible, setVisible] = useState(false);
  function onClose() {
    setVisible(false);
    formik.resetForm()
  }

  const initialValues = { name: '' }
  const validationSchema = yup.object({
    name: yup.string()
      .required("Subject Name is Required")
      .min(2, "Subject Name Needed At Least Two characters")
      .max(100, " Subject Name not more than 100 characters"),
    // .matches(/^[A-Za-z0-9\s]+$/, "Name should only contain letters, numbers, and spaces").min(2,"Exam Name Needed Atleast Two characters").max(100,"Exam Name not more than 100 characters"),
  })

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };


  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setVisible(false)
      let payload = {
        name: formik.values.name,
        examId: +params?.examId,
      }
      let data = {
        payload,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
          status: currentFindObj


        }
      }
      setSubmitModalVisible(false);
      setVisible(false);
      dispatch(insertSubjectMasterTable(data));

    },
    validationSchema,
  })
  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };

  const remarkInitialValues = { remarks: '' }
  const remarkValidationSchema = yup.object({
    remarks: yup.string().required("Remarks is required")
  })

  const remarkFormik = useFormik({
    initialValues: remarkInitialValues,
    validationSchema: remarkValidationSchema
  })
  const editData = (record) => {
    console.log("record", record)
    if (record?.isApproval) {
      setIsApproval(true)
    } else {
      setIsApproval(false)
    }
    if (record?.remarks != null) {
      setRemarksValue(record?.remarks)
    } else {
      setRemarksValue(null)
    }
    setCurrentTitle("Edit Subject")
    setButtonState(2)
    setCurrentId(record.id)
    formik.values.name = record.name
    setVisible(true)
  }
  const onDelete = (record) => {
    setRecord(record)
    let id = record.id
    setIsModalVisible(true)
    setButtonState(1)
  }
  function confirmDelete() {
    setIsModalVisible(false)
    let id = record.id


    let payload = {
      id: record.id,
      examId: +params.examId,
    }
    let data = {
      payload,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    }

    dispatch(deleteSubjectMasterTable(data))
  }
  function onModalClose() {
    setIsModalVisible(false);
    setIsRejectModalVisible(false)

    remarkFormik.resetForm()
  }

  const openDrawer = () => {
    setIsApproval(false)
    setCurrentId(null)
    setVisible(true)
    setButtonState(1)
    setCurrentTitle("New Subject")
    formik.resetForm({ values: initialValues })

  }

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };
  const updateData = () => {
    let payload = {}
    if (params?.statusName) {
      payload = {
        id: currentId,
        name: formik.values.name,
        // examId: +params?.examId,
        statusName: params?.statusName,
      }
    } else {
      payload = {
        id: currentId,
        name: formik.values.name,
        examId: +params?.examId,
        statusName: params?.statusName,
      }
    }

    let data = {
      payload: payload,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
        status: currentFindObj


      }
    }
    setUpdateModalVisible(false);
    setVisible(false)
    setVisible(false)
    dispatch(editSubjectMasterTable(data)).unwrap().then(res => {
      if (res.success) {
        message.success(res.UpdateMessage)
      }
    })

  }
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const onTabsChange = (key) => {
  }

  // reject function
  const onReject = () => {
    let data = {
      subjectId: selectedRowKeys,
      statusId: 2
    }


    dispatch(bulkSubjectApprove(data)).unwrap().then((res) => {
      if (res.status) {
        setSelectedRowKeys([])
        message.success(res.message)
      }
    })
  }

  // approve funcction
  const onApprove = () => {
    let data = {
      subjectId: selectedRowKeys,
      statusId: 2
    }


    dispatch(bulkSubjectApprove(data)).unwrap().then((res) => {
      if (res.status) {
        setSelectedRowKeys([])
        message.success(res.message)
      }
    })
  }

  const viewQuestion = (record) => {
    setApproveId(record.id)
    setVisible(true);
    setButtonState(3);
    setCurrentTitle("Subject")
    formik.setFieldValue('name', record.name)
  }

  const approve = () => {
    setVisible(false)

    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
      status: currentFindObj
    }
    let getData = {
      examId: +params?.examId,
      obj
    }

    let data = {
      subjectId: currentId,
      statusId: 2,
      getData
    }
    dispatch(approveSubject(data)).unwrap().then((res) => {
      message.success(res.message)
    })
  }

  const reject = () => {
    setIsRejectModalVisible(true)
  }

  const addRemarks = () => {
    setIsRejectModalVisible(false)

    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
      status: currentFindObj
    }
    let getData = {
      examId: +params?.examId,
      statusName: params?.statusName,
      obj
    }

    let data = {
      subjectId: currentId,
      statusId: 3,
      data: remarkFormik.values,
      getData
    }
    dispatch(approveSubject(data)).unwrap().then((res) => {
      message.success(res.message)
    })
    remarkFormik.setValues(remarkInitialValues)
    setVisible(false)
  }
  const [roleArray, setRoleArray] = useState([
    { id: 2, statusId: 1, name: 'pendingForApproval', displayName: 'Pending For Approval' },
    { id: 4, statusId: 3, name: 'rejectedForApproval', displayName: 'Rejected For Approval' },
    { id: 5, statusId: 2, name: 'approved', displayName: 'Approved' }
  ]);
  const [dataRoleArray, setDataRoleArray] = useState([
    { id: 2, statusId: 1, name: 'pending', displayName: 'Pending' },
    { id: 3, statusId: 3, name: 'rejected', displayName: 'Rejected' },
    { id: 4, statusId: 2, name: 'approved', displayName: 'Approved' }
  ]);

  const statusChanged = (e) => {
    let findObj
    if (roleId == 1) {
      findObj = dataRoleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    } else if (roleId == 3) {
      findObj = roleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    } else {
    }
    setStatus(e)
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
      status: findObj
    }
    let data = {
      examId: +params?.examId,
      obj
    }
    dispatch(getSubjectByExamId(data))
  }


  const downloadQuestionsCount = () => {
    console.log("download questions count");
    let examId = +params?.examId
    console.log(examId, "examIdexamIdexamIdexamIdexamId");
    dispatch(downloadSubjectQuestionsCount(examId))
      .unwrap()
      .then((res) => {
        console.log(res, "uuuuuuuuuuuuuuuuuuuuuuuu")
        const buffer = res.data.data
        const arrayBuffer = new Uint8Array(buffer).buffer;
        const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
        const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelFile], { type: 'application/octet-stream' });
        saveAs(blob, 'Subject Questions Count.xlsx');
      });
  }
  return (
    <>
      <div className='subject-master-page'>
        <div>
          <div className='breadCrumb-Data'>
            <BreadCrumb data={subjectBreadCrumbData} loading={isLoading}></BreadCrumb>
          </div>
          <div className='subject-header'>
            <div className='subject-header-wrapper'>
              <div>
                <h3 className='primary-header'>Subject Master</h3>
              </div>
            </div>
            <div className="download-new-btn">
              <div class="count-download-btn">
                <Button
                  type="primary"
                  className="primary-submit-button"
                  onClick={() => downloadQuestionsCount()}
                >
                  {" "}
                  Download Questions Count
                </Button>
              </div>
              <div className='add-subject'>
                {
                  (roleId == 1 || roleId == 3) && !params.statusName ?
                    <span className='add-new-subject'><Button className='primary-submit-button' type='primary' onClick={() => openDrawer()}> New Subject</Button></span>

                    : null
                }
              </div>
            </div>
          </div>
          <div className="table-header">
            <div className='list-dropdown'>
              {
                roleId != 5 && roleId != 4 && !params?.statusName ?
                  <div>
                    <Select id="status" name="status" defaultValue={status} placeholder="Select Status" onChange={statusChanged} value={status}  >
                      {
                        roleId == 1 ? dataRoleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                          : roleId == 3 ? roleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                            : null
                      }
                    </Select>
                  </div>

                  : null
              }
            </div>
            {
              !params?.statusName ?
                <div className="golbalSearch" >
                  <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => search(event.target.value)} />
                </div>
                : null
            }
          </div>


          {/* Add subject drawer */}
          <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose()} open={visible} footer={(
            <div className='footer'>
              <div className='footer-button'>
                <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose()}>Cancel</Button>
                {
                  isApproval ?
                    <>
                      <Button className='reject-btn' onClick={() => reject()}>Reject</Button>
                      <Button className={!formik.isValid ? 'disabled-button' : 'approve-btn'} disabled={!formik.isValid} style={{ margin: '0px 26px' }} onClick={() => updateData()} >Approve</Button>

                    </>
                    :
                    <>
                      {
                        currentId ?
                          <Button type="primary" className={!formik.isValid ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.isValid} onClick={showUpdateConfirmation}>Update </Button>

                          // <Button className={!formik.isValid?'disabled-button':'primary-submit-button'} disabled={!formik.isValid} type='primary' onClick={()=>updateData()} >Update</Button>
                          :
                          <Button type='primary' className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} onClick={showSubmitConfirmation}>Submit</Button>

                        // <Button className={!formik.isValid || !formik.dirty ?'disabled-button':'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} type='primary' onClick={() => formik.handleSubmit()}>Submit</Button>
                      }
                    </>
                }
              </div>
            </div>
          )}>
            <form onKeyDown={onKeyDown}>
              {
                remarksValue ?
                  <div className='form-control reject-remarks'>
                    <span > ** {remarksValue}</span>
                  </div>
                  : null

              }
              <div className='form-control'>
                <label htmlFor='name'>
                  Subject Name: <span style={{ color: 'red' }}>*</span>
                </label>
                <Input maxLength={100} type='text' id='name' placeholder="Enter a Subject Name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.name} />
                {formik.touched.name && formik.errors.name ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.name}</div> : null}
              </div>
            </form>
          </Drawer>

          {/* delete modal */}

          <Modal title='Delete' open={isModalVisible} onCancel={onModalClose} footer={
            <div>
              <Button onClick={() => onModalClose()}>Cancel</Button>
              <Button type='primary' onClick={() => confirmDelete()}>OK</Button>
            </div>

          }>
            <p>Are you sure you want to delete?</p>
          </Modal>

          <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
            <div>
              <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
              <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
            </div>
          }>
            <p>Are you sure you want to update?</p>
          </Modal>



          {/* submit Confirmation */}

          <Modal
            title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
              <div>
                <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
                <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}> Submit </Button>
              </div>
            }
          >
            <p>Are you sure you want to submit?</p>
          </Modal>

          {/* reject modal */}

          <Modal title='Rejection' open={isRejectModalVisible} onCancel={onModalClose} footer={
            <div>
              <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
              <Button type='default' className='primary-submit-button' id={!remarkFormik.isValid || !remarkFormik.dirty ? 'disabled-button' : null} disabled={!remarkFormik.isValid || !remarkFormik.dirty} onClick={() => addRemarks()}>OK</Button>
            </div>
          }>
            <div>
              <label htmlFor="remarks">Remarks <span style={{ color: 'red' }}>*</span> </label>
              <TextArea id='remarks' style={{ marginTop: 10, height: 176 }} onChange={remarkFormik.handleChange} onBlur={remarkFormik.handleBlur} value={remarkFormik.values.remarks} > </TextArea>
              {remarkFormik.touched.remarks && remarkFormik.errors.remarks ? <div className='error'>{remarkFormik.errors.remarks}</div> : null}
            </div>
          </Modal>

        </div>
        <div>
          {
            !params?.statusName ?
              <TableComponent dataSource={subjectDataByExam?.getSubjectData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={subjectTotalData?.getSubjectCount?.subjectCount} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} />

              :
              <TableComponent dataSource={listData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={listData?.length ? listData.length : 0} currentPage={paginationData + 1} loadingStatus={isDashLoading} limit={limit} />
          }
        </div>
      </div>
      {
        isSubjectLoading ? <div className='loading-container'>

          <Loading></Loading>

        </div> : null
      }
    </>
  )
}


export default SubjectMaster;
