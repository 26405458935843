import React, { useState, useEffect } from 'react';
import { Button, Input, Drawer, Modal, Table, Image, Tag, Form, Checkbox, message, Upload } from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikConsumer, useFormik } from "formik";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import moment, { ISO_8601, utc } from 'moment';
import { getAllTutorials, createTutorial, updateTutorial, deleteTutorials ,getTutorialById } from "../components/reducers/TutorialSlice";
import Loading from "./Loading";



const Tutorial = () => {
    const dispatch = useDispatch()
    let tableHeight = 320;
    const [paginationData, setPaginationData] = useState(0);
    const [filterData, setFilterData] = useState("");
    const [limit, setLimit] = useState(50);
    const [currentTitle, setCurrentTitle] = useState("");
    const [visible, setVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [formDate, setFormDate] = useState(moment());
    const [record, setRecord] = useState({});
    const [file, setFile] = useState([]);

    const { currentData, isLoading, currentDataCount } = useSelector((state) => state.Tutorial)
    useEffect(() => {
        let obj = {
            pagination: paginationData,
            searchValue: filterData,
            limit: limit,
        };
        dispatch(getAllTutorials(obj))
    }, []);

    const initialValues = {
        name: "",
        examName: "",
        videoLink: "",
        orderNo: "",
        logo: [],
        isActive: false
    };
    const validationSchema = yup.object({
        name: yup
            .string()
            .required("Name is Required")
            .min(2, "Name Needed At Least Two characters")
            .max(100, "Name not more than 100 characters"),
        examName: yup
            .string()
            .required("Exam Name is Required")
            .min(2, "Exam Name Needed At Least Two characters")
            .max(100, "Exam Name not more than 100 characters"),
        videoLink: yup
            .string()
            .required("Video Link is Required"),
        orderNo: yup
            .string()
            .required("Order Number is Required")
            .matches(/^[1-9][0-9]*$/, "Order Number must be a number and cannot consist only of 0"),
        logo: yup.array().min(1, "Image is Required").required("Image is Required").nullable(),

    })

    const columns = [
        {
            title: "S.No",
            dataIndex: "index",
            key: "index",
            width: "6%",
            render: (_, record, i) => {
                return (
                    <div className="index-id">
                        {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
                    </div>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Exam Name",
            dataIndex: "examName",
            key: "examName",
        },
        {
            title: "Order No",
            dataIndex: "orderNo",
            key: "orderNo",
        },
        {
            title: "Status",
            dataIndex: "isActive",
            render: (_, record) => {
                return (
                    <div>
                        <span>{record.isActive == 1 ?
                            <Tag color="green">Active</Tag> :
                            <Tag color="red">InActive</Tag>}
                        </span>
                    </div>
                );
            },
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => {
                return (
                    <div className="table-action-link">
                        <a style={{ paddingRight: 10 }} onClick={() => editData(record)}>
                            Edit
                        </a>
                        <Tooltip style={{ padding: 0 }} title="Delete">
                            <IconButton>
                                <Image
                                    src={DeleteImage}
                                    preview={false}
                                    onClick={() => deleteData(record)}
                                ></Image>
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];



    const editData = (_data) => {
        setCurrentId(_data.id)
        setCurrentTitle("Edit Tutorial")
        dispatch(getTutorialById(_data.id)).unwrap().then((res) => {
            if (res) {
                setVisible(true)
                setFile([{ url: res?.data?.data.videoThumbnailUrl, thumbUrl: res?.data?.data.videoThumbnailUrl }])
                let tempValues = JSON.parse(JSON.stringify(res.data.data))
                formik.values['logo'] = [tempValues['videoThumbnailUrl']]
                formik.values.name = tempValues.name
                formik.values.examName = tempValues.examName
                formik.values.videoLink = tempValues.videoLink
                formik.values.orderNo = tempValues.orderNo
                formik.values.isActive = tempValues.isActive
            }
        })
    }

    const deleteData = (_data) => {
        setRecord(_data)
        setIsModalVisible(true)
        setCurrentId(_data.id)
    }

    const onSearch = (value) => {
        setFilterData(value);
        let obj = {
            pagination: paginationData,
            searchValue: value,
            limit: limit,
        };
        debounced(obj);
    };
    const debounced = useDebouncedCallback((obj) => {
        dispatch(getAllTutorials(obj))
    }, 1000);


    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (selectData) => {
        setSelectedRowKeys(selectData);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const tableOnChange = (pagination, filters, sort, extra) => {
        setPaginationData(pagination?.current - 1);

        let obj = {
            pagination: pagination?.current - 1,
            searchValue: filterData,
            limit: limit,
        };

        dispatch(getAllTutorials(obj));
    };

    const openDrawer = () => {
        setFile([])
        setCurrentId(null);
        formik.resetForm({ values: initialValues });
        setVisible(true);
        setCurrentTitle("Create Tutorial");
    };


    const closeSubmitModal = () => {
        setSubmitModalVisible(false);
    };

    const showSubmitConfirmation = (values) => {
        setSubmittedData(values);
        setSubmitModalVisible(true);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            setVisible(false);
            let data = new FormData();
            data.append("name", values.name.trim())
            data.append("examName", values.examName.trim())
            data.append("videoLink", values.videoLink.trim())
            data.append("orderNo", values.orderNo)
            data.append("logo", values.logo[0].originFileObj)
            data.append("isActive", values.isActive)
            let payload = {
                data,
                obj: {
                    pagination: paginationData,
                    searchValue: filterData,
                    limit: limit,
                },
            };
            setSubmitModalVisible(false);
            dispatch(createTutorial(payload)).unwrap().then((res) => {
                if (res.status) {
                    setVisible(false)
                }
            })
                .catch((error) => {
                    setVisible(true)
                })
        },
    });


    const closeUpdateModal = () => {
        setUpdateModalVisible(false);
    };

    const showUpdateConfirmation = () => {
        setUpdateModalVisible(true);
    };


    const updateData = () => {
        let data = new FormData();
        data.append("name", formik.values.name.trim())
        data.append("examName", formik.values.examName.trim())
        data.append("videoLink", formik.values.videoLink.trim())
        data.append("orderNo", formik.values.orderNo)
        data.append("logo", formik.values.logo[0].originFileObj)
        data.append("isActive", formik.values.isActive == 1 ? true : false)

        let payload = {
            id: currentId,
            data,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit,
            },
        };
        setUpdateModalVisible(false);
        setVisible(false)
        dispatch(updateTutorial(payload)).unwrap().then((res) => {
                if (res?.status) {
                    setVisible(false)
                }
                else {
                    setVisible(true)
                }
        })
        .catch ((error)=> {
            setVisible(true)
        })
    };

    const modalOnCancel = () => {
        setIsModalVisible(false);
    };
    const modalOnOk = () => {
        setIsModalVisible(false);
        let data = {
            id: currentId,
            obj: {
                pagination: paginationData,
                searchValue: filterData,
                limit: limit
            }
        }
        dispatch(deleteTutorials(data)).unwrap().then((res) => {
            if (res) {
                dispatch(getAllTutorials(data.obj))
            }
        })
    };
    const drawerCloser = () => {
        setVisible(false);
        formik.resetForm();
    };

    useEffect(() => {
        formik.validateForm();
    }, []);

    const filehandleChange = (file) => {

        if (file.fileList.length) {
            if (file.file.type == 'image/jpeg' || file.file.type == 'image/jpg' || file.file.type == 'image/png') {
                setFile(file.fileList)
                formik.values.logo = file.fileList
            }
            else {
                message.error("please upload jpeg, jpg and png formats only ")
            }
        }
        else {
            setFile(file.fileList)
            formik.values.logo = file.fileList
            formik.validateForm()
        }
    }


    return (
      <>
        <div className="admin-page">
            <div className="adminHeader">
                <h3 className="primary-header">Tutorial</h3>
                <div className="admin-button">
                    <Button
                        className="primary-submit-button"
                        type="primary"
                        onClick={openDrawer}
                    >
                        Add Tutorial{" "}
                    </Button>
                </div>
            </div>
            <div className="all-table-component" style={{ padding: 0, margin: 0 }}>
                <div className="golbalSearch">
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
                        style={{ width: 262, height: 32 }}
                        onChange={(event) => onSearch(event.target.value)}
                    />
                </div>
                <TableComponent
                    dataSource={currentData}
                    columns={columns}
                    rowSelection={rowSelection}
                    tableOnChange={tableOnChange}
                    tableHeight={tableHeight}
                    totalData={currentDataCount}
                    currentPage={paginationData + 1}
                    loadingStatus={isLoading}
                    limit={limit}
                />
            </div>
            <Drawer
                title={currentTitle}
                onClose={() => drawerCloser()}
                width="518px"
                closable={false}
                closeIcon={false}
                placement="right"
                open={visible}
                footer={
                    <div className="footer">
                        <div className="footer-button">
                            <Button
                                className="cancel-Button primary-cancel-button"
                                onClick={drawerCloser}
                            >
                                Cancel
                            </Button>
                            {currentId == null ? (
                                <Button
                                    className={
                                        !formik.isValid || !formik.dirty
                                            ? "disabled-button"
                                            : "primary-submit-button"
                                    }
                                    type="primary"
                                    disabled={!formik.dirty || !formik.isValid}
                                    onClick={showSubmitConfirmation}
                                >
                                    Submit{" "}
                                </Button>
                            ) : (
                                <Button type="primary"
                                    className={(!formik.isValid || !formik.dirty) ? 'disabled-button' : 'primary-submit-button'}
                                    disabled={(!formik.dirty || !formik.isValid)}
                                    onClick={showUpdateConfirmation}>Update</Button>
                            )}
                        </div>
                    </div>
                }
            >
                <Formik validateOnChange={false} validateOnBlur={false}>
                    <Form>
                        <div className="form-control">
                            <label htmlFor="name">
                                Name<span style={{ color: "red" }}> *</span>
                            </label>
                            <Input
                                type="text"
                                id="name"
                                placeholder="Enter a Name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="errorMessage" style={{ color: "red" }}>
                                    {formik.errors.name}
                                </div>
                            ) : null}
                        </div>

                        <div className="form-control">
                            <label htmlFor="examName">
                                Exam Name<span style={{ color: "red" }}> *</span>
                            </label>
                            <Input
                                type="text"
                                id="examName"
                                placeholder="Enter a Exam Name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.examName}
                            />
                            {formik.touched.examName && formik.errors.examName ? (
                                <div className="errorMessage" style={{ color: "red" }}>
                                    {formik.errors.examName}
                                </div>
                            ) : null}
                        </div>

                        <div className="form-control">
                            <label htmlFor="videoLink">
                                Video Link<span style={{ color: "red" }}> *</span>
                            </label>
                            <Input
                                type="text"
                                id="videoLink"
                                placeholder="Enter a Video Link"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.videoLink}
                            />
                            {formik.touched.videoLink && formik.errors.videoLink ? (
                                <div className="errorMessage" style={{ color: "red" }}>
                                    {formik.errors.videoLink}
                                </div>
                            ) : null}
                        </div>

                        <div className="form-control">
                            <label htmlFor="orderNo">
                                Order No<span style={{ color: "red" }}> *</span>
                            </label>
                            <Input
                                type="number"
                                id="orderNo"
                                placeholder="Enter a Order No"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.orderNo}
                            />
                            {formik.touched.orderNo && formik.errors.orderNo ? (
                                <div className="errorMessage" style={{ color: "red" }}>
                                    {formik.errors.orderNo}
                                </div>
                            ) : null}
                        </div>

                        <div className='exam-upload-file'>
                            <label htmlFor="logo">
                                Logo<span style={{ color: "red" }}> *</span>
                            </label>
                            <div className='form-control'>
                                <Upload
                                    className='ImageUpload'
                                    listType="picture"
                                    onChange={(file) => filehandleChange(file)}
                                    beforeUpload={() => false}
                                    fileList={file}
                                    maxCount={1}
                                >
                                    <Button className='exam-image-upload-button' name="logo" onChange={formik.handleChange} onBlur={formik.handleBlur} icon={<UploadOutlined />}>Choose file</Button>
                                </Upload>
                                {formik.touched.logo && formik.errors.logo ? <div className='error' >{formik.errors.logo}</div> : null}
                            </div>
                        </div>

                        <div className="form-control">
                            <Checkbox id='isActive' name='isActive'
                                checked={formik.values.isActive}
                                onChange={formik.handleChange}
                            // value={formik.values.isActive}
                            >Active</Checkbox>
                        </div>

                    </Form>
                </Formik>
            </Drawer>
            <Modal
                title="Deletion"
                open={isModalVisible}
                onCancel={modalOnCancel}
                onOk={modalOnOk}
                footer={
                    <div>
                        <Button
                            className="primary-cancel-button"
                            onClick={() => modalOnCancel()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="primary-submit-button"
                            type="default"
                            onClick={() => modalOnOk()}
                        >
                            OK
                        </Button>
                    </div>
                }
            >
                <p>Are you sure want to delete ?</p>
            </Modal>

            {/* update model */}
            <Modal
                title="Update Confirmation"
                open={isUpdateModalVisible}
                onCancel={closeUpdateModal}
                footer={
                    <div>
                        <Button
                            className="primary-cancel-button"
                            onClick={closeUpdateModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="primary-submit-button"
                            type="default"
                            onClick={updateData}
                        >
                            Update
                        </Button>
                    </div>
                }
            >
                <p>Are you sure you want to update?</p>
            </Modal>

            {/* submit modal */}

            <Modal
                title="Submit Confirmation"
                open={isSubmitModalVisible}
                onCancel={closeSubmitModal}
                footer={
                    <div>
                        <Button
                            className="primary-cancel-button"
                            onClick={closeSubmitModal}
                        >
                            Cancel{" "}
                        </Button>
                        <Button
                            className="primary-submit-button"
                            type="default"
                            onClick={formik.handleSubmit}
                        >
                            {" "}
                            Submit{" "}
                        </Button>
                    </div>
                }
            >
                <p>Are you sure you want to Submit?</p>
            </Modal>
        </div>
            {isLoading ? (
                <div className="loading-container">
                  <Loading></Loading>
                </div>
              ) : null}
              </>
    );
};

export default Tutorial;
