import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Drawer, Form, Image, Input, message, Modal, Select, Tag, Tooltip, Upload } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { useDispatch, useSelector } from 'react-redux'
import TableComponent from './Table/tableComponent'
import { IconButton } from '@material-ui/core'
import DeleteImage from '../asset/image/deleteIcon.svg'
import moment from 'moment'
import Loading from './Loading'
import { createCommunity, getAllCommunity, getCommunityById, updateCommunity, deleteCommunity,getSocialMedia } from "./reducers/CommunitySlice";
import { getEncryptedLocalStorage } from '../utils/utils';



const ExamGroup = () => {

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [filterData, setFilterData] = useState("")
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const [file, setFile] = useState([]);
  const roleId = getEncryptedLocalStorage("roleId")
  const [currentId, setCurrentId] = useState(null)
  const [currentRecord, setCurrentRecord] = useState({})
  const [currentTitle, setCurrentTitle] = useState("")
  const { socialMediaMasterData } = useSelector((state) => state.Community)
  const { communityData, isLoading, communityDataCount } = useSelector((state) => state.Community);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const tableHeight = 320

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Community Name',
      dataIndex: 'communityName',
      key: 'communityName',
      render: (_, record) => {
        return (
          <div>
            <span style={{ textTransform: 'capitalize' }}>{record.communityName}</span>
          </div>
        )
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span>
          </div>
        )
      }
    },
    {
      title: 'Active / InActive',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, record) => {
        return (
          <div>
            <span> {record.isActive ?
              <Tag color="green">Active</Tag> :
              <Tag color="red">InActive</Tag>
            }</span>
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
            {
              roleId == 1 || roleId == 3 ?
                <div className='table-action-link'>
                  <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
                  {
                    roleId == 3 ?
                      <Tooltip title="Delete" style={{ padding: 0 }}>
                        <IconButton>
                          <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                        </IconButton>
                      </Tooltip>
                      : null
                  }
                </div>
                : <div>
                  Not Authorized
                </div>
            }
          </>
        )
      },
    },
  ]

  const initialValues = { communityName: '', communityLogo: [],orderNo:'',isActive: false, socialMedia: [] }

  let validationSchema = yup.object({
    communityName: yup
      .string()
      .required("Community Name is Required")
      .min(2, "Community Name Needed At Least 2 characters")
      .max(100, "Community Name not more than 100 characters"),
    communityLogo: yup.array().min(1, "Logo is Required").required("Logo is Required").nullable(),
    orderNo: yup
      .string()
      .required("Order Number is Required")
      .matches(/^[1-9][0-9]*$/, "Order Number must be a number and cannot consist only of 0"),
    socialMedia: yup.array().of(
      yup.object().shape({
        socialMediaId: yup.string().required("Social Media id is Required").nullable(),
        socialMediaLink: yup.string().required("Social Media Link is Required")
      })
    )
  })
  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false);
      let socialMedialValue = JSON.stringify(values.socialMedia)
      let data = new FormData();
      setSubmitModalVisible(false);
      data.append("communityLogo", file[0].originFileObj)
      data.append("communityName", values.communityName)
      data.append("orderNo", values.orderNo)
      data.append("socialMedia", socialMedialValue)
      data.append("isActive", values.isActive == true ? 1 : 0)
      let datas = {
        data,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit

        }
      }
      dispatch(createCommunity(datas)).unwrap().then((res)=>{
        if(res.status){
          setVisible(false)
        }
        else{
          setVisible(true)
        }
      }).catch((error)=>{
        setVisible(true)
      })
    }
  })

  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit
    }
    debounced(obj)
  }
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getAllCommunity(obj))
  }, 1000)

  const filehandleChange = (file) => {
    if (file.fileList.length) {
      if (file.file.type == 'image/jpeg' || file.file.type == 'image/jpg' || file.file.type == 'image/png') {
        setFile(file.fileList)
        formik.values.communityLogo = file.fileList
      }
      else {
        message.error("please upload jpeg, jpg and png formats only ")
      }
    }
    else {
      setFile(file.fileList)
      formik.values.communityLogo = file.fileList
      formik.validateForm()
    }
  }

  const openDrawer = () => {
    setFile([])
    setCurrentId(null)
    setVisible(true)
    setCurrentTitle("Create Community")
    formik.resetForm({ values: initialValues })
  }

  const editData = (_data) => {
    console.log(!formik.dirty,!formik.isValid, "////////////////");
    setCurrentId(_data.communityId)
    dispatch(getCommunityById(_data.communityId)).unwrap().then((res) => {
      if (res.status) {
        setFile([{ url: res?.data.communityImageUrl, thumbUrl: res?.data.communityImageUrl }])
        let tempValues = JSON.parse(JSON.stringify(res.data))
        formik.values['communityLogo'] = [tempValues['communityImageUrl']]
        formik.values.communityName = tempValues.communityName
        formik.values.orderNo = tempValues.orderNumber
        formik.values.isActive = tempValues.isActive
        formik.values.socialMedia = tempValues.socialMedia
      }
    })
    setVisible(true)
    setCurrentTitle("Edit Community")
  }
  const deleteData = (_data) => {
    setCurrentRecord(_data)
    setIsModalVisible(true)
    setCurrentId(_data.communityId)
  }

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    let socialMedialValue = JSON.stringify(formik.values.socialMedia)
    let data = new FormData();
    data.append("communityLogo", formik.values?.communityLogo[0]?.originFileObj)
    data.append("communityName", formik.values.communityName)
    data.append("orderNo", formik.values.orderNo)
    data.append("socialMedia", socialMedialValue)
    data.append("isActive", formik.values.isActive == true ? 1 : 0)
    let datas = {
      id: currentId,
      data,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit

      }
    }
    setUpdateModalVisible(false);
    setVisible(false)
    dispatch(updateCommunity(datas)).unwrap().then((res) => {
      try {
        if (res.status) {
          setVisible(false)
        }
      }
      catch (error) {
        setVisible(true)
      }
    })
  }

  const onModalClose = () => {
    setIsModalVisible(false);
  }

  const confirmDelete = () => {
    setIsModalVisible(false)
    let data = {
      id: currentId,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    }
    dispatch(deleteCommunity(data)).unwrap().then((res) => {
    })
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getAllCommunity(obj));
  }

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getAllCommunity(obj))
    dispatch(getSocialMedia())
  }, [])

  // const socialMediaMaster = [
  //   { id: 1, name: "Facebook" },
  //   { id: 2, name: "Twitter" },
  //   { id: 3, name: "Instagram" },
  //   { id: 4, name: "Whatsapp" },
  // ];

  const addSection = () => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['socialMedia'].push({ keyId: Date.now(), socialMediaId: '', socialMediaLink: '' })
    formik.setValues(tempValues)
  }

  const onSelected = (e, i, name) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['socialMedia'][i][name] = e
    formik.setValues(tempValues)
  }

  const optionsOnChange = (i, e, name) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['socialMedia'][i][name] = e.target.value
    formik.setValues(tempValues)
  }

  return (
    <>
      <div className='community-container'>
        <div className='header'>
          <h3 className='primary-header'>Community</h3>
          <div className='add-exam'>
            {
              roleId == 3 ?
                <span className='add-new-exam'><Button type='primary' className='primary-submit-button' onClick={() => openDrawer()}>Add Community</Button></span>
                : null
            }
          </div>
        </div>
        <div className='all-table-component'>
            <div className="golbalSearch" >
              <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
            </div>
          <TableComponent dataSource={communityData} columns={columns} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={communityDataCount} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} />
        </div>

        <Drawer title={currentTitle} onClose={() => setVisible(false)} width="500px" closable={false} closeIcon={false} placement="right" open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-cancel-button' onClick={() => setVisible(false)} >Cancel</Button >
              {
                currentId ?
                  <Button type="primary" className={(!formik.isValid || !formik.dirty) ? 'disabled-button' : 'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showUpdateConfirmation}>Update</Button>
                  :
                  <Button type='primary' className={(!formik.isValid || !formik.dirty) ? 'disabled-button' : 'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showSubmitConfirmation}>Submit</Button>
              }
            </div>
          </div>
        )}>
          <div className='basic-form'>
            <>
              <Form>
                <div className='form-control'>
                  <label>Name<span style={{ color: "red" }}> *</span></label>
                  <Input maxLength={50} id='communityName' name='communityName' placeholder="Enter a Community Name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.communityName}></Input>
                  {formik.touched.communityName && formik.errors.communityName ? <div className='error'>{formik.errors.communityName}</div> : null}
                </div>
                <div className='exam-upload-file'>
                <label htmlFor="communityLogo">
                      Community Logo<span style={{ color: "red" }}> *</span>
                    </label>
                  <div className='form-control'>
                    <Upload
                      className='ImageUpload'
                      listType="picture"
                      onChange={(file) => filehandleChange(file)}
                      beforeUpload={() => false}
                      fileList={file}
                      maxCount={1}
                    >
                      <Button className='exam-image-upload-button' name="communityLogo" onChange={formik.handleChange} onBlur={formik.handleBlur} icon={<UploadOutlined />}>Choose file</Button>
                    </Upload>
                    {formik.touched.communityLogo && formik.errors.communityLogo ? <div className='error' >{formik.errors.communityLogo}</div> : null}
                  </div>
                </div>
                <div className='form-control'>
                  <label>Order Number<span style={{ color: "red" }}> *</span></label>
                  <Input type='number' id='orderNo' name='orderNo' placeholder="Enter a Order Number" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.orderNo}></Input>
                  {formik.touched.orderNo && formik.errors.orderNo ? <div className='error'>{formik.errors.orderNo}</div> : null}
                </div>
                <div className='form-control'>
                  <Checkbox onChange={formik.handleChange} checked={formik.values.isActive} name="isActive" >Active</Checkbox>
                </div>
                <div className='community-social-media'>
                  {
                    formik.values && formik.values.socialMedia ? formik.values.socialMedia.map((option, i) => {
                      return (
                        <div>

                          <div className='form-control' >
                            <label id={`socialmediaId.${i}`}>
                              Social Media<span style={{ color: "red" }}> *</span>
                            </label>
                            <div>
                              <Select id={`socialmediaId.${i}`} name={`socialmediaId.${i}`} style={{ width: "100%" }} placeholder="Select Option" onChange={(e) => { onSelected(e, i, 'socialMediaId') }} onBlur={formik.handleBlur} value={editData.socialMediaId == null ? formik.values.socialMedia[i]['socialMediaId'] : editData.socialMediaId}>
                                {socialMediaMasterData.length ?
                                  socialMediaMasterData.map((data, index) => (
                                    <Option value={data.id} key={data.id} >{data.name}</Option>
                                  )) : null}
                              </Select>
                              {formik.touched['socialMediaId'] && formik.touched['socialMediaId'][i] && formik.errors.socialMedia && formik.errors.socialMedia[i]?.socialMediaId ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.socialMedia[i]['socialMediaId']}</div> : null}
                            </div>
                          </div>
                          <div className='form-control'>
                            <label htmlFor={`socialMediaLink.${i}`}>
                              Social Media Link<span style={{ color: "red" }}> *</span>
                            </label>
                            <Input id={`socialMediaLink.${i}`} name={`socialMediaLink.${i}`} placeholder="Enter Social Media Link" onChange={(e) => { optionsOnChange(i, e, 'socialMediaLink') }} onBlur={formik.handleBlur} value={formik.values.socialMedia[i]['socialMediaLink']} />
                            {formik.touched['socialMediaLink'] && formik.touched['socialMediaLink'][i] && formik.errors.socialMedia && formik.errors.socialMedia[i]?.socialMediaLink ? <div className='errorMessage error' style={{ color: 'red' }}>{formik.errors.socialMedia[i]['socialMediaLink']}</div> : null}
                          </div>
                        </div>
                      )
                    }) : ''
                  }
                </div>
                <div className='add-selection-community'>
                  <span className='addSectionClass' onClick={() => { addSection() }}>+ Add Social Media</span>
                </div>
              </Form>
            </>
          </div>
        </Drawer>
        <Modal title='Deletion' open={isModalVisible} onCancel={onModalClose} footer={
          <div>
            <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
            <Button className='primary-submit-button' type='default' onClick={() => confirmDelete()}>OK</Button>
          </div>
        }>
          <p>Are you sure you want to delete <span className='highlight-text'>{currentRecord.communityName} </span>  ?</p>
        </Modal>
        {/* update Confirmation */}

        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
            <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
            <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
          </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>


        {/* submit confirmation */}
        <Modal title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel </Button>
            <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}>Submit </Button>
          </div>
        } >
          <p>Are you sure you want to submit?</p>
        </Modal>
      </div>
      {
        isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> : null
      }
    </>
  )
}

export default ExamGroup