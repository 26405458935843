import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { message } from "antd";
import moment from "moment";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getEncryptedLocalStorage } from "../../utils/utils";

// get test master

export const getTestMasterTable = createAsyncThunk(
  'TestMaster/getTestMasterTable', async (payload) => {
    try {
      let apiUrl = ""
      const roleId = getEncryptedLocalStorage("roleId")
      if (roleId == 1) {
        if (payload.status.name == 'all') {
          apiUrl = `testMaster/getTestBasicDetails?filterType=all`

        } else {
          apiUrl = `testMaster/getTestBasicDetails?statusId=${payload.status.statusId}&filterType=${payload.status.name}`

        }

      } else if (roleId == 3) {
        if (payload.status.name == 'all') {
          apiUrl = `testMaster/getTestBasicDetails?filterType=all`

        } else {
          apiUrl = `testMaster/getTestBasicDetails?statusId=${payload.status.statusId}&filterType=${payload.status.name}`

        }
      }
      let testTableData = await axios.post(apiUrl, payload)
        .then((res) => {
          return res.data.data;
        })

      return testTableData;

    } catch (error) {

    }

  }
)

// insert test master

export const insertTestMasterTable = createAsyncThunk(
  'TestMaster/insertTestMasterTable', async (payload) => {
    try {
      let TestTable = [];
      let InsertMesage = '';
      let success = '';
      let insertData = await axios.post('testMaster/createTest', payload)
        .then((res) => {
          // console.log(res.data)
          if (res.data.status) {
            message.success(res.data.message)
            return res.data.data;
          }
        })
      return insertData;
    }
    catch (error) {
      message.error(error.response.data.message)
    }
  }
)

// delete test master

export const deleteTestMasterTable = createAsyncThunk(
  'TestMaster/deleteTestMasterTable', async (payload) => {
    payload = Number(payload)
    let deleted = [];
    let deleteMessage = '';
    let success = '';
    let deleteData = await axios.delete('testMaster/deleteTestBasicDetails/' + payload)
      .then((res) => {
        deleted = res.data.deleteTestBasicDetails;
        deleteMessage = deleted.message;
        success = deleted.success
      })
      .catch((error) => {
      })
    return {
      id: payload,
      success: deleted.success,
      deleteMessage: deleteMessage
    };
  }
)

// get data by id for edit

export const getEditData = createAsyncThunk(
  'TestMaster/getEditData', async (payload) => {
    let editData = []
    payload = Number(payload)
    let getEditData = await axios.get('testMaster/getTestBasicDetails/' + payload)
      .then((res) => {
        if (res.data.success) {
          editData = res.data.data;
          return payload
        }


      })
      .catch((error) => {
      })
    return editData;
  }
)

// submit edited data

export const editTestMasterTable = createAsyncThunk(
  'TestMaster/editTestMasterTable', async (payload, thunk) => {
    let success = '';
    let editMessage = '';

    let editData = await axios.put('testMaster/updateTestBasicDetails/' + payload.id, payload)
      .then(async (res) => {
        success = res.data.data.success;
        editMessage = res.data.data.message
        await thunk.dispatch(getEditData(payload.id))

      })
      .catch((error) => {
      })
    return {
      payload: payload,
      success: success,
      editMessage: editMessage
    }
  }
)

// get subscription list

export const getSubscription = createAsyncThunk(
  'TestMaster/getSubscription', async (payload) => {
    let subscriptionlist = []
    let subscriptionCount = ''
    let subscriptionData = await axios.post('subscription/getSubscription', payload)
      .then((res) => {
        subscriptionlist = res.data.data.getSubscription;
        subscriptionCount = res.data.data.getSubscriptionCount.subscriptionCount
      })
      .catch((error) => {
      })
    return {
      subscriptionlist: subscriptionlist,
      subscriptionCount: subscriptionCount
    };
  }
)

// insert test master pricing 

export const insertTestMasterPricing = createAsyncThunk(
  'TestMaster/insertTestMasterPricing', async (payload) => {
    let data = {
      pricingPlan: payload.pricing,
      price: Number(payload.price),
      testId: payload.testId,
      subscriptionId: payload.subscriptions
    }
    let pricing = [];
    let InsertMesage = '';
    let success = '';
    let insertData = await axios.post('testMaster/createTestPricing', data)
      .then((res) => {
        pricing = res.data.data;
        InsertMesage = pricing.message;
        success = pricing.success
      })
      .catch((error) => {
      })
    return {
      data: pricing.data,
      InsertMesage: InsertMesage,
      success: success
    }
  }
)

// get pricing details data for edit

export const getPricingEditData = createAsyncThunk(
  'TestMaster/getPricingEditData', async (payload) => {
    let pricingEditData = []
    let getpricingEditData = await axios.get('testMaster/getTestPricingPlan/' + payload)
      .then((res) => {
        pricingEditData = res.data.data;

      })
      .catch((error) => {
      })
    return pricingEditData;
  }
)

// get All feedBack Data
export const getAllFeedBackData = createAsyncThunk('TestMaster/getAllRaiseADispute', async (payload) => {
  try {
    let getData = await axios.get("testMaster/getAllRaiseADispute").then((res) => {
      if (res.data.success) {
        return res.data
      }
    })
    return getData

  } catch (error) {
  }
})

// update feedBack
export const updateFeedBack = createAsyncThunk('TestMaster/updateRaiseADispute', async (payload, thunk) => {
  try {
    let getData = await axios.put(`testMaster/updateRaiseADispute/${payload.id}`, payload.data).then(async (res) => {
      if (res.data.updateData.status) {
        await thunk.dispatch(getAllFeedBackData())
        return res.data.updateData
      }
    })
    return getData

  } catch (error) {
  }
})
export const approveTest = createAsyncThunk(
  'TestMaster/approveTestMaster', async (payload, thunk) => {
    try {
      let approvedSubject = await axios.put(`testMaster/approvedStatusTestMaster/${+payload.testMasterId}/${payload.statusId}`, payload.data)
        .then(async (res) => {
          if (res.data.status) {
            return res.data;
          }
        })
      return approvedSubject;
    }
    catch (error) {
    }
  }
)

export const getTestById = createAsyncThunk(
  'TestMaster/GetTestById', async (payload, thunk) => {
    try {
      let getTest = await axios.get(`testMaster/getTestById/${payload}`)
        .then(async (res) => {
          if (res.data.status) {
            res.data.data['pricingPlan'] = res.data.data.pricingPlan == 0 ? false : true
            // res.data.data['pricing'] = res.data.data['pricingPlan']
            res.data.data.questionSelection.forEach((e) => {
              if (e.chapterId == null) {
                e['chapterId'] = -1
              }
            })
            return res.data.data;
          }
        })
      return getTest;
    }
    catch (error) {
    }
  }
)
export const getTestByDraftId = createAsyncThunk(
  'TestMaster/GetTestById', async (payload, thunk) => {
    try {
      let getTest = await axios.get(`testMaster/getDraftTestById/${payload}`)
        .then(async (res) => {
          if (res.data.status) {
            res.data.data['pricingPlan'] = res.data.data.pricingPlan == 0 ? false : true
            // res.data.data['pricing'] = res.data.data['pricingPlan']
            res.data.data.questionSelection.forEach((e) => {
              if (e.chapterId == null) {
                e['chapterId'] = -1
              }
            })
            return res.data.data;
          }
        })
      return getTest;
    }
    catch (error) {
    }
  }
)

export const updateTestById = createAsyncThunk(
  'TestMaster/updateTestById', async (payload, thunk) => {
    try {
      // console.log("payload",payload)
      let editData = await axios.put('testMaster/updateTestById/' + payload.id, payload).then((res) => {
        // console.log(res)
        if (res.data.status) {
          return res.data.data;
        }
      })
      return editData
    } catch (error) {

    }

  }
)

export const updateDraftTestById = createAsyncThunk(
  'TestMaster/updateTestById', async (payload, thunk) => {
    try {
      // console.log("payload",payload)
      let editData = await axios.put('testMaster/updateDraftTestById/' + payload.id, payload).then((res) => {
        // console.log(res)
        if (res.data.status) {
          return res.data.data;
        }

      })
      return editData
    } catch (error) {

    }

  }
)


export const insertDraftTestMasterTable = createAsyncThunk(
  'TestMaster/insertTestMasterTable', async (payload) => {
    try {
      let TestTable = [];
      let InsertMesage = '';
      let success = '';
      let insertData = await axios.post('testMaster/createDraftTest', payload)
        .then((res) => {
          // console.log(res.data)
          if (res.data.status) {
            return res.data.data;
          }
        })
      return insertData;
    }
    catch (error) {
      // console.log(error ,"errror")
      message.error(error.response.data.message)
    }
  }
)

export const questionAvailability = createAsyncThunk(
  'TestMaster/questionAvailability', async (payload, thunk) => {
    try {
      let check = await axios.get(`testMaster/checkQuestionAvailability/${payload.subjectId}/${payload.chapterId}/${payload.isFree}`).then((res) => {

        // console.log("resss ",res)
        if (res.data.status) {
          return res.data
        }
      })
      return check
    }
    catch (error) {
    }
  }
)
export const checkAvailableQuestionSelection = createAsyncThunk('TestMaster/checkAvailableQuestionSelection', async (payload) => {
  try {
    let check = await axios.post(`testMaster/checkAvailableQuestionSelection`, payload).then((res) => {
      if (res.data.length) {
        return res.data
      }
    })
    return check

  } catch (error) {

  }
})
export const discardDraftTest = createAsyncThunk(
  'TestMaster/questionAvailability', async (payload, thunk) => {
    try {
      let check = await axios.get(`testMaster/discardTest/${payload}`).then((res) => {

        // console.log("resss ",res)
        if (res.data.status) {
          return res.data
        }
      })
      return check
    }
    catch (error) {
    }
  }
)

export const updateIsActiveStatusTestMaster = createAsyncThunk(
  'TestMaster/updateIsActiveStatusTestMaster', async (payload, thunk) => {
    try {
      let updateStatus = await axios.put(`testMaster/inActiveTestMaster/${payload.id}`, { status: payload.status }).then((res) => {
        if (res.data.status) {
          return res.data.data;
        }
      })
      return updateStatus
    }
    catch (error) {
      console.log('error', error)
    }
  }
)

export const updateIsActiveStatusDraftTest = createAsyncThunk(
  'TestMaster/updateIsActiveStatusDraftTest', async (payload, thunk) => {
    try {
      let updateStatus = await axios.put(`testMaster/inActiveDraftTestMaster/${payload.id}`, { status: payload.status }).then((res) => {
        if (res.data.status) {
          return res.data.data;
        }
      })
      return updateStatus
    }
    catch (error) {
      console.log('error', error)
    }
  }
)

/**
 * Downloads the student leaderboard for a specific test master.
 * @param {Object} payload - The payload containing the test master ID.
 * @param {string} payload.id - The ID of the test master.
 * @returns {Promise<Object>} The downloaded leaderboard data.
 * @throws {Error} If there's an error during the download process.
 */
export const downloadLeaderBoardTestMaster = createAsyncThunk(
  "testMaster/downloadStudentLeaderBoardTestMaster",
  async (payload) => {
    try {
      const response = await axios.get(`/testMaster/downloadStudentLeaderBoardTestMaster/${payload.id}`);
      if (response.data.success) {
        message.success(response.data.message);
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message);
      } else if (error.request) {
        message.error("No response received from the server");
      } else {
        message.error("Error occurred while processing the request");
      }
      throw error;
    }
  }
);

/**
 * Fetches tests associated with a specific exam ID.
 * @param {Object} payload - The payload containing the exam ID and other optional parameters.
 * @param {string|number} payload.examId - The ID of the exam to fetch tests for.
 * @param {Object} [payload.filters] - Optional filters for the query.
 * @param {number} [payload.page] - Optional page number for pagination.
 * @param {number} [payload.limit] - Optional limit of items per page.
 * @returns {Promise<Object>} The fetched test data.
 */
export const getTestsByExamId = createAsyncThunk(
  "TestMaster/getTestsByExamId",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`/testMaster/getTestsByExamId/${payload.examId}`);
      if (response.data.success) {
        return response.data.data;
      } else {
        return thunkAPI.rejectWithValue(response.data.message || "Failed to fetch tests");
      }
    } catch (error) {
      console.error("Error fetching tests by exam ID:", error);
      return thunkAPI.rejectWithValue(error.response?.data?.message || "An error occurred while fetching tests");
    }
  }
);




const initialState = {
  testMasterTableData: [],
  editData: {
    name: '',
    instructions: '',
    timeLimit: '',
    typeOfTestId: "",
    examId: "",
    subjectId: null,
    eachQuestionMark: "",
    isNegativeMarkContain: false,
    negativeMark: 0,
    questionSelection: [],
    pricingPlan: false,
    price: null
  },
  isTestMasterLoading: false,
  isAddTest: true,
  subscriptionList: [],
  editPricingData: {},
  currentDataId: null,
  basicTestDetails: {},
  totalDataCount: 0,
  feedBackData: [],
  totalFeedBackCount: 0,
  isApproval: false,
  currentRemarks: '',
  newtestChanges: {},
  testDataByExamId: []

}
const TestMasterSlice = createSlice({
  name: 'TestMaster',
  initialState,
  reducers: {
    removeEditItem: (state, action) => {
      // console.log("remove Edit item")
      state.editData = {
        name: '',
        instructions: '',
        timeLimit: '',
        typeOfTestId: "",
        examId: "",
        subjectId: null,
        eachQuestionMark: "",
        isNegativeMarkContain: false,
        negativeMark: 0,
        questionSelection: [],
        pricingPlan: false,
        price: null
      };
      state.isAddTest = true
      state.currentDataId = null
    },
    editItem: (state, action) => {
      state.isAddTest = false
      // console.log("state,",action)
      state.editData.id = action.payload.id
    },
    approvalState: (state, action) => {
      state.isApproval = action.payload
    },
    showRemarks: (state, action) => {
      state.currentRemarks = action.payload
    }

  },
  extraReducers: {
    [getTestMasterTable.pending]: (state) => {
      state.isTestMasterLoading = true;
      state.testMasterTableData = []
      state.totalDataCount = 0
    },
    [getTestMasterTable.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
      actions.payload?.getTestMasterData.map((item) => {
        item.createdAt = moment(item.createdAt).format("DD MMM YYYY")
        item['draftTestCreatedAt'] = item.draftTestCreatedAt ? moment(item?.draftTestCreatedAt).format("DD MMM YYYY") : null
      })
      state.testMasterTableData = actions.payload?.getTestMasterData
      state.totalDataCount = +actions.payload?.getTestCount?.testCount

    },
    [getTestMasterTable.rejected]: (state) => {
      state.isTestMasterLoading = false;
    },
    [insertTestMasterTable.pending]: (state) => {
      state.isTestMasterLoading = true;
    },
    [insertTestMasterTable.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
    [insertTestMasterTable.rejected]: (state) => {
      state.isTestMasterLoading = false;
    },
    [deleteTestMasterTable.pending]: (state) => {
      state.isTestMasterLoading = true;
    },
    [deleteTestMasterTable.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
      if (actions.payload.success == true) {
        state.testMasterTableData = state.testMasterTableData.filter((item) => item.id != actions.payload.id)
        message.success(actions.payload.deleteMessage)
      }
      if (actions.payload.success == false) {
        message.error(actions.payload.deleteMessage);
      }
    },
    [deleteTestMasterTable.rejected]: (state) => {
      state.isTestMasterLoading = false;
    },
    [getEditData.pending]: (state, actions) => {
      state.isTestMasterLoading = true;
    },
    [getEditData.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false
      // state.editData=actions.payload

    },
    [getEditData.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;
    },

    [editTestMasterTable.pending]: (state, actions) => {
      state.isTestMasterLoading = true
    },
    [editTestMasterTable.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false

      if (actions.payload.success == true) {
        message.success(actions.payload.editMessage)
      }
    },
    [getSubscription.fulfilled]: (state, actions) => {
      state.subscriptionList = actions.payload.subscriptionlist.map((item) => {
        let value = item.id
        let label = item.name
        return {
          value: value,
          label: label
        }
      })
    },
    [insertTestMasterPricing.pending]: (state, actions) => {
      state.isTestMasterLoading = true

    },
    [insertTestMasterPricing.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false

      if (actions.payload.success == true) {
        message.success(actions.payload.InsertMesage);
      }
    },
    [insertTestMasterPricing.rejected]: (state, actions) => {
      state.isTestMasterLoading = false

    },
    [getPricingEditData.fulfilled]: (state, actions) => {

      let data = {
        pricingPlan: actions.payload.pricingPlan == 0 ? false : true,
        price: actions.payload.price,

      }
      state.editPricingData = data
    },
    [editTestMasterTable.rejected]: (state, actions) => {
      state.isTestMasterLoading = false
    },
    [getAllFeedBackData.pending]: (state, actions) => {
      state.isTestMasterLoading = true;

    },
    [getAllFeedBackData.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;

      let ExamData = actions.payload.data.map((e) => {
        e['editShow'] = true
        return e
      })
      state.feedBackData = [...ExamData]
      state.feedBackData = actions.payload.data.map((e) => {
        e['editShow'] = true
      })

      state.feedBackData = actions.payload.data



      // state.totalFeedBackCount = actions.payload.data

    },
    [getAllFeedBackData.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;

    },
    [updateFeedBack.pending]: (state, actions) => {
      state.isTestMasterLoading = true;

    },
    [updateFeedBack.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;


    },
    [updateFeedBack.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;

    },
    [approveTest.pending]: (state, actions) => {
      state.isTestMasterLoading = true;

    },
    [approveTest.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
      state.isApproval = false


    },
    [approveTest.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;

    },
    [getTestById.pending]: (state, actions) => {
      state.isTestMasterLoading = true;

    },
    [getTestById.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
      // debugger
      state.editData = actions.payload


    },
    [getTestById.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;

    },
    [getTestByDraftId.pending]: (state, actions) => {
      state.isTestMasterLoading = true;

    },
    [getTestByDraftId.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
      // debugger
      state.editData = actions.payload


    },
    [getTestByDraftId.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;

    },
    [insertDraftTestMasterTable.pending]: (state) => {
      state.isTestMasterLoading = true;
    },
    [insertDraftTestMasterTable.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
    [insertDraftTestMasterTable.rejected]: (state) => {
      state.isTestMasterLoading = false;
    },

    [updateTestById.pending]: (state) => {
      state.isTestMasterLoading = true;
    },
    [updateTestById.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
    [updateTestById.rejected]: (state) => {
      state.isTestMasterLoading = false;
    },
    [updateDraftTestById.pending]: (state) => {
      state.isTestMasterLoading = true;
    },
    [updateDraftTestById.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
    [updateDraftTestById.rejected]: (state) => {
      state.isTestMasterLoading = false;
    },
    [questionAvailability.pending]: (state, actions) => {
      state.isTestMasterLoading = true;

    },
    [questionAvailability.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;


    },
    [questionAvailability.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;

    },
    [checkAvailableQuestionSelection.pending]: (state, actions) => {
      state.isTestMasterLoading = true;

    },
    [checkAvailableQuestionSelection.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;


    },
    [checkAvailableQuestionSelection.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;

    },
    [discardDraftTest.pending]: (state, actions) => {
      state.isTestMasterLoading = true;

    },
    [discardDraftTest.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
      state.testMasterTableData = state.testMasterTableData.filter((item) => item.testDraftId != actions.payload.id)
      // message.success(actions.payload.deleteMessage)


    },
    [discardDraftTest.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;

    },
    /* Update isActive Status in Test */
    [updateIsActiveStatusTestMaster.pending]: (state, actions) => {
      state.isTestMasterLoading = true;
    },
    [updateIsActiveStatusTestMaster.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
    [updateIsActiveStatusTestMaster.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;
    },


    /* Update isActive Status in Draft Test */
    [updateIsActiveStatusDraftTest.pending]: (state, actions) => {
      state.isTestMasterLoading = true;
    },
    [updateIsActiveStatusDraftTest.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
    [updateIsActiveStatusDraftTest.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;
    },

    /* Update isActive Status in Draft Test */
    [downloadLeaderBoardTestMaster.pending]: (state, actions) => {
      state.isTestMasterLoading = true;
    },
    [downloadLeaderBoardTestMaster.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
    [downloadLeaderBoardTestMaster.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
    [getTestsByExamId.pending]: (state, actions) => {
      state.isTestMasterLoading = true;
    },
    [getTestsByExamId.fulfilled]: (state, actions) => {
      state.isTestMasterLoading = false;
      state.testDataByExamId = actions.payload;
    },
    [getTestsByExamId.rejected]: (state, actions) => {
      state.isTestMasterLoading = false;
    },
  }
})
export const { removeEditItem, editItem, approvalState, showRemarks } = TestMasterSlice.actions;
export default TestMasterSlice.reducer;