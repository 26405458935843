import React, { useState, useEffect } from 'react';
import TableComponent from './Table/tableComponent';
import { SearchOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Image, Select, Tag } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getTestMasterTable, deleteTestMasterTable, removeEditItem, editItem, approvalState, showRemarks, getTestByDraftId, getTestById, discardDraftTest, updateIsActiveStatusTestMaster, updateIsActiveStatusDraftTest, downloadLeaderBoardTestMaster } from './reducers/TestMasterSlice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteImage from '../asset/image/deleteIcon.svg'
import { removeQuestionData } from './reducers/questionSlice';
import { useDebouncedCallback } from 'use-debounce';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { getAllTypeData } from './reducers/dashboardSlice';
import { getEncryptedLocalStorage } from '../utils/utils';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const { Option } = Select;


function TestMaster() {
  const { listData, isDashLoading } = useSelector((state) => state.DashboardSlice)
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [record, setRecord] = useState();
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const roleId = getEncryptedLocalStorage("roleId")
  const [status, setStatus] = useState();
  const [isApproval, setIsApproval] = useState(false)

  const [currentFindObj, setCurrentFindObj] = useState(() => {
    if (roleId == 1) {
      return { id: 1, statusId: 0, name: 'all', displayName: 'All' }

    } else {
      return { id: 1, statusId: 0, name: 'all', displayName: 'All' }
    }

  })



  const [roleArray, setRoleArray] = useState([
    { id: 1, statusId: 0, name: 'all', displayName: 'All' },

    { id: 2, statusId: 1, name: 'pendingForApproval', displayName: 'Pending For Approval' },
    { id: 3, statusId: 3, name: 'rejectedForApproval', displayName: 'Rejected For Approval' },

  ]);
  const [dataRoleArray, setDataRoleArray] = useState([
    { id: 1, statusId: 0, name: 'all', displayName: 'All' },
    { id: 2, statusId: 1, name: 'pending', displayName: 'Pending' },
    { id: 3, statusId: 3, name: 'rejected', displayName: 'Rejected' },
    { id: 4, statusId: 2, name: 'approved', displayName: 'Approved' }

  ]);


  const statusChanged = (e) => {
    let findObj
    if (roleId == 1) {
      findObj = dataRoleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    } else if (roleId == 3) {
      findObj = roleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    } else {
    }
    setStatus(e)

    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
      status: findObj
    }
    dispatch(getTestMasterTable(obj))
  }

  useEffect(() => {
    if (!params?.statusName) {
      setStatus(currentFindObj?.id)
      let obj = {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
        status: currentFindObj
      }
      dispatch(getTestMasterTable(obj))
    }
    else {
      let payload = {
        filterType: params?.statusName,
        type: 'test'
      }
      dispatch(getAllTypeData(payload))
    }
  }, [])
  const { testMasterTableData, isTestMasterLoading, totalDataCount } = useSelector((state) => state.TestMaster)
  let tableHeight = 320
  const [chapterDetails, setChapterDetails] = useState('');

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Test Name',
      dataIndex: 'name',
      width: '20%',
      render: (_, record) => {
        return (
          <>
            <div>
              <p>  {record.draftTestId ? <p><span className='draft-state'>Draft</span> <span>{record.draftTestName}</span> </p> : <>{record.name}</>}  </p>
            </div>
          </>
        )
      }

    },
    {
      title: 'No of Questions',
      dataIndex: 'numberOfQuestions',
      width: '11%',
      render: (_, record) => {
        return (
          <div>
            <p>  {record.draftTestId ? <> <span>{record.draftTestNumberOfQuestions}</span> </> : <>{record.numberOfQuestions}</>}  </p>
          </div>
        )
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      width: '10%',
      render: (_, record) => {
        return (
          <div>
            <p>  {record.draftTestId ? <><span>{record.draftTestCreatedAt}</span> </> : <>{record.createdAt}</>}  </p>
          </div>
        )
      }
    },
    {
      title: 'Time limit',
      dataIndex: 'timeLimit',
      width: '10%',
      render: (_, record) => {
        return (
          <div>
            <p>  {record.draftTestId ? <> <span>{record.draftTimeLimit}</span> </> : <>{record.timeLimit}</>}  </p>
          </div>
        )
      }
    },
    {
      title: 'Active Status',
      dataIndex: 'inActive',
      key: 'inActive',
      width: '10%',
      render: (_, record) => {
        return (
          <div>
            <span> {
              ((record.isActive == 0 && record.draftIsActive == null) || (record.draftIsActive == 0)) ?
                <Tag color="red">inActive</Tag> :
                <Tag color="green">Active</Tag>
            }</span>
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
            {
              !params?.statusName ?
                <Tooltip title="Delete" style={{ padding: 0 }}>
                  <IconButton>
                    <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                  </IconButton>
                </Tooltip>
                : null
            }
          </div>
        )
      },
    },
    {
      title: '',
      key: '',
      with: '10%',
      render: (_, record) => (
        <div className='publish-button'>
          {
            <>
              {
                ((record.isActive == 0 && record.draftIsActive == null) || (record.draftIsActive == 0)) ?
                  <Button
                    className={`primary-submit-button status-active active-status`}
                    type='default'
                    onClick={() => activeTestMasterBtn(record, true)}
                  >
                    Active
                  </Button>
                  :
                  <Button
                    className={`primary-submit-button status-active inactive-status`}
                    type='default'
                    onClick={() => activeTestMasterBtn(record, false)}
                  >
                    inActive
                  </Button>
              }
              <Button
                className={`primary-submit-button status-active inactive-status`}
                type='default'
                onClick={() => downloadStudentLeaderBoard(record)}
              >
                Download
              </Button>
            </>
          }
        </div>
      ),
    }
  ]
  function editData(record) {
    // debugger
    // record['isDraft'] = record['isDraft'] == '1' ? true : false;
    if (record?.isApproval) {

      setIsApproval(true)
      dispatch(approvalState(true))
    } else {
      setIsApproval(false)
      dispatch(approvalState(false))

    }
    if (record?.remarks != null) {
      dispatch(showRemarks(record?.remarks))
    } else {
      dispatch(showRemarks(null))

    }
    // let id=record.id

    // dispatch(editItem(id))
    // debugger

    if (record?.draftTestId) {
      dispatch(editItem(record?.draftTestId))

      dispatch((getTestByDraftId(record?.draftTestId))).unwrap().then((data) => {
        // debugger

        navigate('/EditTest/draft/' + data.id)

      })

    } else {
      dispatch(editItem(record?.id))

      dispatch((getTestById(record?.id))).unwrap().then((data) => {
        // debugger
        if (params?.statusName) {
          navigate(`/EditTest/${data.id}/${params?.statusName}`)

        } else {
          navigate('/EditTest/' + data.id)

        }

      })
    }



    // dispatch(getPricingEditData(id))
  }

  function activeTestMasterBtn(record, value) {
    let data = {}
    if (record.id != null && record.draftTestId == null) {
      console.log('test master')
      data = {
        id: record.id,
        status: value
      }
      dispatch((updateIsActiveStatusTestMaster(data))).unwrap().then((res) => {
        let obj = {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
          status: currentFindObj
        }
        dispatch(getTestMasterTable(obj))
      })
    } else if (record.draftTestId != null) {
      data = {
        id: record.draftTestId,
        status: value
      }
      dispatch((updateIsActiveStatusDraftTest(data))).unwrap().then((res) => {
        let obj = {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
          status: currentFindObj
        }
        dispatch(getTestMasterTable(obj))
      })
    }
  }
  const deleteData = (record) => {
    setRecord(record)
    setIsModalVisible(true)
  }
  function handleCancel() {
    setIsModalVisible(false)
  }
  function confirmDelete() {
    setIsModalVisible(false)
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
      status: currentFindObj
    }
    let id = record.id ? record.id : record.draftTestId
    if (record.id) {
      dispatch(deleteTestMasterTable(record.id)).unwrap().then(() => {
        dispatch(getTestMasterTable(obj))
      })
    } else {
      dispatch(discardDraftTest(record.draftTestId))

    }
  }
  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
      status: currentFindObj
    }

    debounced(obj)
  }
  const debounced = useDebouncedCallback((obj) => {
    if (!params?.statusName) {

      dispatch(getTestMasterTable(obj))
    }

  }, 1000)

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }
  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
      status: currentFindObj

    }
    if (!params?.statusName) {

      dispatch(getTestMasterTable(obj))
    }
  }
  const addNewTest = () => {
    dispatch(removeEditItem())
    dispatch(removeQuestionData())
    navigate('/NewTest')
  }

  /**
   * Downloads the student leaderboard for a specific test.
   * @param {Object} record - The test record containing the test ID.
   */
  const downloadStudentLeaderBoard = (record) => {
    let data = {
      id: record.id,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    }

    dispatch(downloadLeaderBoardTestMaster(data))
      .unwrap()
      .then((res) => {
        try {
          const buffer = res.data;
          const arrayBuffer = new Uint8Array(buffer).buffer;
          const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
          const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const blob = new Blob([excelFile], { type: 'application/octet-stream' });
          saveAs(blob, 'Exam Questions Count.xlsx');
        } catch (error) {
          console.error('Error processing Excel file:', error);
          // You may want to show an error message to the user here
        }
      })
      .catch((error) => {
        console.error('Error downloading leaderboard:', error);
        // You may want to show an error message to the user here
      });
  }



  return (
    <div className='testMaster-page'>
      <div className='test-header'>
        <div className='test-header-wrapper'><h3 className='primary-header'>Test Master</h3>
        </div>
        <div className='add-test'>
          {
            !params?.statusName ?
              <Button className='primary-submit-button' style={{ marginRight: 20, fontSize: 13, fontWeight: 500 }} onClick={() => addNewTest()} >Add Test </Button>

              :
              null
          }
        </div>
      </div>
      {/* <div className='test-header'>
          <div className='test-header-wrapper'><h3  className='primary-header'>Test Master</h3>
          </div>
          <div className='add-test'>
          {
            !params?.statusName ?
            <Button  className='primary-submit-button' style={{marginRight:20,fontSize:13,fontWeight:500}}  onClick={() => addNewTest() } >Add Test </Button>

            :
            null
          }
          </div>
        </div> */}
      <div className='all-table-Component'>

        <div className="table-header">
          <div className='list-dropdown'>
            {
              (roleId != 4 || roleId != 5) && !params?.statusName ?
                <div>
                  <Select id="status" name="status" defaultValue={status} placeholder="Select Status" onChange={statusChanged} value={status}  >
                    {
                      roleId == 1 ? dataRoleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                        : roleId == 3 ? roleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                          : null
                    }
                  </Select>
                </div>

                : null
            }


          </div>
          {
            !params?.statusName ?
              <div className="golbalSearch" >
                <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
              </div>
              : null}

        </div>

        {
          !params.statusName ?
            <TableComponent dataSource={testMasterTableData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={totalDataCount} currentPage={paginationData + 1} loadingStatus={isTestMasterLoading} limit={limit} />

            :
            <TableComponent dataSource={listData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={listData?.length ? listData.length : 0} currentPage={paginationData + 1} loadingStatus={isDashLoading} limit={limit} />

        }
      </div>
      <Modal title="Deletion" open={isModalVisible} onOk={() => confirmDelete()} onCancel={handleCancel}>
        <p>Are you sure want to delete ?</p>
      </Modal>
    </div>
  );
}
export default TestMaster;