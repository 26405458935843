import { React, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import topicImage from '../asset/image/topicImage.svg';
import subTopicImg from '../asset/image/subTopicimg.svg';
import settingIcon from '../asset/image/Setting_fill.svg';
import classImg from '../asset/image/classImage.svg';
import contentImg from '../asset/image/contentImg.svg';
import * as yup from "yup";
import { Collapse, Button, Modal } from "antd";
import '../components/styles/pricing.scss';
import { Tooltip } from "antd";
import { insertCurriculumPricingDetails, updateCurriculumPricingDetails, getPricingDetailsById } from '../components/reducers/PricingSlice';
import { getFrequency } from "./reducers/ClusterGroupMasterSlice.js";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import CoursePriceIcon from '../../src/asset/image/CoursePricing.svg'
import Loading from "./Loading";
import PricingPlanDrawer from "./Table/PricingPlanDrawer";
import { EQuickActionsType, EFieldStatus } from "../utils/Variable.js";
import { formValidationSubjects, deletePricingPlanHelper, leastPriceHelper, constructFrequencyHelper } from '../utils/HelperFunction';
import { setSaveStatus } from './reducers/clusterCourseSlice';
import BeforeUnloadHandler from './Table/BeforeUnloadHandler';

const Price = forwardRef(({ tempCourseMappingId, handleSaveAndNextChanges }, ref) => {

  useImperativeHandle(ref, () => ({
    insertPrice,
    updatePrice,
  }));

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getFrequency())
      .unwrap()
      .then((data) => {
        constructFrequencyHelper(data, setFrequencyMap);
      });
  }, []);

  const navigate = useNavigate();

  const { courseMappingId } = useParams();
  const [isEditAction, setEditAction] = useState(true);
  const { isLoading, priceplanDetails } = useSelector((state) => state.PricingSlice);
  const { frequencyData } = useSelector((state) => state.ClusterGroupMasterSlice);
  const { pricingSaveStatus } = useSelector(
    (state) => state.ClusterCourseSlice
  );
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isCoursePricing, setIsCoursePricing] = useState(true);
  const [pricingVisible, setPricingVisible] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);
  const [data, setData] = useState([]);
  const [courseData, setCourseData] = useState({});
  const [currentPricingTitle, setCurrentPricingTitle] = useState("Set a Pricing Plan");
  const [prices, setPrices] = useState([]);
  const [previousPricing, setPreviousPricing] = useState([]);
  const [frequencyMap, setFrequencyMap] = useState({});
  const [currentTopicId, setTopicCurrentId] = useState(null);
  const [currentSubTopicId, setSubTopicCurrentId] = useState(null);
  const [currentClassId, setClassCurrentId] = useState(null);
  const [currentContentId, setContentCurrentId] = useState(null);
  const [currentSelectedType, setCurrentSelectedType] = useState(null);

  const [courseDeletedPricing, setCourseDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [topicDeletedPricing, setTopicDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [subTopicDeletedPricing, setSubTopicDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [classDeletedPricing, setClassDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });
  const [contentDeletedPricing, setContentDeletedPricing] = useState({ mappingIds: [], pricingIds: [] });

  const [currentMappingId, setMappingId] = useState(null);
  const [pricingName, setPricingName] = useState('');
  const [pricingChildsDetails, setPricingChildsDetails] = useState('');
  const [pendingDeletions, setPendingDeletions] = useState({ mappingIds: [], pricingIds: [] });

  useEffect(() => {
    if (tempCourseMappingId || courseMappingId) {
      dispatch(getPricingDetailsById(tempCourseMappingId || courseMappingId));
    }
  }, [tempCourseMappingId, courseMappingId, dispatch]);

  useEffect(() => {
    if (priceplanDetails) {
      const { topicArray, ...rest } = priceplanDetails;
      setCourseData(rest);
      setData(topicArray);
    }
  }, [priceplanDetails]);

  const insertPrice = async () => {

    setPreviousPricing([]);
    let payload = await constructPricing(data);
    dispatch(insertCurriculumPricingDetails(payload)).unwrap()
      .then((res) => {
        if (res?.resSuccess) {
          navigate('/clusterCourse');
          setPendingDeletions({ mappingIds: [], pricingIds: [] });
          dispatch(getPricingDetailsById(tempCourseMappingId)).unwrap();
          dispatch(setSaveStatus({ field: 'pricingSaveStatus', value: false }));
          handleSaveAndNextChanges();
          resetDeletePricingDetails();
        };
      }).catch((error) => { });
  };

  const updatePrice = async () => {

    setPreviousPricing([]);
    let payload = {
      data: await constructPricing(data)
    };
    dispatch(updateCurriculumPricingDetails(payload)).unwrap()
      .then((res) => {
        if (res?.resSuccess) {
          navigate('/clusterCourse');
          setPendingDeletions({ mappingIds: [], pricingIds: [] });
          dispatch(getPricingDetailsById(courseMappingId || tempCourseMappingId));
          handleSaveAndNextChanges();
          resetDeletePricingDetails();
        };
      }).catch((error) => { });
  };

  const resetDeletePricingDetails = () => {
    setCourseDeletedPricing({ mappingIds: [], pricingIds: [] });
    // setTopicDeletedPricing({ mappingIds: [], pricingIds: [] });
    // setSubTopicDeletedPricing({ mappingIds: [], pricingIds: [] });
    // setClassDeletedPricing({ mappingIds: [], pricingIds: [] });
    // setContentDeletedPricing({ mappingIds: [], pricingIds: [] });
  };

  const pricingInitialValues = {
    actualPrice: "",
    price: "",
    frequencyId: "",
    isFree: false,
    pricingId: '',
    fieldStatus: EFieldStatus.NEW,
    mappingId: ''
  };

  const validationSchema = yup.object({
    actualPrice: yup.number()
      .required("Actual price is Required")
      .positive('Actual price must be a positive integer')
      .integer('Actual price must be an integer'),
    price: yup.number()
      .required("Price is Required")
      .positive('Price must be a positive integer')
      .integer('Price must be an integer')
      .when('actualPrice', (actualPrice, schema) =>
        schema.max(actualPrice, 'Price must be less than or equal to the actual price')
      ),
    frequencyId: yup.string().required('Frequency is Required'),
    isFree: yup.boolean(),
  });

  const pricingFormik = useFormik({
    initialValues: pricingInitialValues,
    validationSchema,
    onSubmit: async (values) => {

      const { isFree, ...rest } = values;

      let planPricing = [
        rest,
        ...prices
      ];

      planPricing = planPricing?.map(item => {

        let newItem = { ...item };

        if (newItem.fieldStatus === EFieldStatus.NEW) {
          delete newItem.pricingId;
        } else {
          const matchingPrevious = previousPricing.find(
            element =>
              element.pricingId &&
              element.pricingId === item.pricingId &&
              item.fieldStatus === EFieldStatus.NONE &&
              (element.actualPrice !== item.actualPrice || element.price !== item.price || element.frequencyId !== item.frequencyId)
          );

          if (matchingPrevious) {
            newItem.fieldStatus = EFieldStatus.EDITED;
          }
        }

        return newItem;
      });

      const { mappingIds = [], pricingIds = [] } = pendingDeletions || {};

      if (mappingIds?.length && pricingIds?.length) {
        await processPendingDeletions();
      };

      await handlePricing(planPricing);
      await resetPricingForm();
    },
  });

  const resetPricingForm = async () => {
    // setTopicCurrentId(null);
    // setSubTopicCurrentId(null);
    // setClassCurrentId(null);
    // setContentCurrentId(null);
    setCurrentSelectedType(null);
    setPricingVisible(false);
    setMappingId(null);
    setPricingName(null);
    setPricingChildsDetails(null);
    setPrices([]);
    setPendingDeletions({ mappingIds: [], pricingIds: [] });
    pricingFormik.resetForm({ values: pricingInitialValues });
  };

  useEffect(() => {
    formValidationSubjects.price.next(pricingFormik.isValid);
  }, [pricingFormik.values, pricingFormik.errors, formValidationSubjects.price]);

  const handlePricing = async (values) => {

    let newData;

    switch (currentSelectedType) {
      // case EQuickActionsType.topic:
      //   newData = data?.map((topic, topicIndex) => {
      //     if (topicIndex !== currentTopicId) return topic;
      //     return { ...topic, planPricing: values };
      //   });
      //   break;

      // case EQuickActionsType.subTopic:
      //   newData = data?.map((topic, topicIndex) => {
      //     if (topicIndex !== currentTopicId) return topic;

      //     const updatedSubTopics = topic.subTopicArray.map((subTopic, subIndex) => {
      //       if (subIndex !== currentSubTopicId) return subTopic;
      //       return { ...subTopic, planPricing: values };
      //     });

      //     return {
      //       ...topic,
      //       subTopicArray: updatedSubTopics
      //     };
      //   });
      //   break;

      // case EQuickActionsType.class:
      //   newData = data?.map((topic, topicIndex) => {
      //     if (topicIndex !== currentTopicId) return topic;

      //     const updatedSubTopics = topic.subTopicArray.map((subTopic, subIndex) => {
      //       if (subIndex !== currentSubTopicId) return subTopic;

      //       const updatedClasses = subTopic.classArray.map((cls, classIndex) => {
      //         if (classIndex !== currentClassId) return cls;
      //         return { ...cls, planPricing: values };
      //       });

      //       return {
      //         ...subTopic,
      //         classArray: updatedClasses
      //       };
      //     });

      //     return {
      //       ...topic,
      //       subTopicArray: updatedSubTopics
      //     };
      //   });
      //   break;

      // case EQuickActionsType.content:
      //   newData = data?.map((topic, topicIndex) => {
      //     if (topicIndex !== currentTopicId) return topic;

      //     const updatedSubTopics = topic.subTopicArray.map((subTopic, subIndex) => {
      //       if (subIndex !== currentSubTopicId) return subTopic;

      //       const updatedClasses = subTopic.classArray.map((cls, classIndex) => {
      //         if (classIndex !== currentClassId) return cls;

      //         const updatedContentArray = cls.contentArray.map((content, contentIndex) => {
      //           if (contentIndex !== currentContentId) return content;

      //           return { ...content, planPricing: values };
      //         });

      //         return {
      //           ...cls,
      //           contentArray: updatedContentArray
      //         };
      //       });

      //       return {
      //         ...subTopic,
      //         classArray: updatedClasses
      //       };
      //     });

      //     return {
      //       ...topic,
      //       subTopicArray: updatedSubTopics
      //     };
      //   });
      //   break;

      case EQuickActionsType.course:
        setCourseData({ ...courseData, planPricing: values });
        break;

      default:
        return;
    };

    if (currentSelectedType !== EQuickActionsType.course) {
      setData(newData);
    };

  };

  // const openDrawer = async (event, { course, data }, selectedType, topicIndex = null, subTopicIndex = null, classIndex = null, contentIndex = null) => {
  const openDrawer = async (event, { course, data }, selectedType, topicIndex = null, subTopicIndex = null, contentIndex = null) => {

    let { name, counts, planPricing, mappingId } = data;

    event.stopPropagation();
    await resetPricingForm()

    if (selectedType !== EQuickActionsType.course) {
      setTopicCurrentId(topicIndex);
      setSubTopicCurrentId(subTopicIndex);
      // setClassCurrentId(classIndex);
      setContentCurrentId(contentIndex);
    };

    setIsCoursePricing(course);
    setPricingName(name);
    setPricingChildsDetails(counts);
    setPreviousPricing(planPricing);
    await handlePricingData(planPricing, mappingId);
    setCurrentSelectedType(selectedType);
    setPricingVisible(true);
  };

  const handlePricingData = async (pricingData, mappingId) => {

    setMappingId(mappingId);

    const { actualPrice = "", price = "", frequencyId = "", isFree = false, pricingId = "", fieldStatus = EFieldStatus.NEW } = pricingData?.[0] || pricingInitialValues;

    pricingFormik.setValues({
      actualPrice,
      price,
      frequencyId,
      isFree,
      pricingId,
      fieldStatus,
      mappingId
    });

    if (pricingData?.length > 1) {
      const remainingPrices = pricingData.slice(1).map(({ actualPrice = "", price = '', frequencyId = '', isFree = false, pricingId = '', fieldStatus = EFieldStatus.NEW }) => ({
        actualPrice,
        price,
        frequencyId,
        isFree,
        pricingId,
        fieldStatus,
        mappingId
      }));

      setPrices(remainingPrices);
    };
  };

  const drawerCloser = () => {
    pricingFormik.resetForm({ values: pricingInitialValues });
  };

  const showSubmitConfirmation = (values) => {
    setSubmitModalVisible(true);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const updateData = () => { };

  const pricingDrawerCloser = () => {
    setPricingVisible(false);
    setPreviousPricing([]);
    pricingFormik.resetForm({ values: pricingInitialValues });
  };

  const addPrice = (values) => {
    const { isFree, ...rest } = values;
    const newPrice = { ...rest };
    setPrices([...prices, newPrice]);
    let initialValues = {
      ...pricingInitialValues,
      mappingId: rest?.mappingId
    };
    pricingFormik.resetForm({ values: initialValues });
  };

  const pricingSwitchChange = (value) => {
    pricingFormik.setFieldValue("isFree", value);
  };

  const savePricing = () => {
    pricingFormik.handleSubmit();
  };

  const deletePricing = async (values, index) => {
    setPrices(prevPrices => {
      const updatedPrices = prevPrices.filter((_, i) => i !== index);
      return updatedPrices;
    });

    if (values?.fieldStatus !== EFieldStatus.NEW && values?.pricingId && currentMappingId) {

      setPendingDeletions(prevPendingDeletions => {

        const mappingIds = prevPendingDeletions?.mappingIds || [];
        const pricingIds = prevPendingDeletions?.pricingIds || [];

        const mappingIdExists = mappingIds.includes(currentMappingId);
        const pricingIdExists = pricingIds.includes(values.pricingId);

        if (!mappingIdExists) {
          mappingIds.push(currentMappingId);
        };

        if (!pricingIdExists) {
          pricingIds.push(values.pricingId);
        };

        return {
          mappingIds: mappingIds,
          pricingIds: pricingIds
        };
      });
    }
  };

  const processPendingDeletions = async () => {

    const { mappingIds, pricingIds } = pendingDeletions;

    switch (currentSelectedType) {

      case EQuickActionsType.course:
        deletePricingPlanHelper(courseDeletedPricing, setCourseDeletedPricing, currentMappingId, pricingIds);
        break;

      // case EQuickActionsType.topic:
      //   deletePricingPlanHelper(topicDeletedPricing, setTopicDeletedPricing, mappingIds, pricingIds);
      //   break;

      // case EQuickActionsType.subTopic:
      //   deletePricingPlanHelper(subTopicDeletedPricing, setSubTopicDeletedPricing, mappingIds, pricingIds);
      //   break;

      // case EQuickActionsType.class:
      //   deletePricingPlanHelper(classDeletedPricing, setClassDeletedPricing, mappingIds, pricingIds);
      //   break;

      // case EQuickActionsType.content:
      //   deletePricingPlanHelper(contentDeletedPricing, setContentDeletedPricing, mappingIds, pricingIds);
      //   break;
      default:
        break;
    };

    setPendingDeletions({ mappingIds: [], pricingIds: [] });
  };

  const constructPricing = async (data) => {
    const tempTopicPricing = [];
    const tempSubTopicPricing = [];
    const tempClassPricing = [];
    const tempContentPricing = [];

    // await Promise.all(data.map(async (topic) => {
    //   const { mappingId, planPricing, subTopicArray } = topic;

    //   if (planPricing) {
    //     const filteredTopicPricing = await filterAndMapPricing(planPricing, mappingId);
    //     if (filteredTopicPricing?.length) {
    //       tempTopicPricing.push(...filteredTopicPricing);
    //     }
    //   }

    //   await Promise.all(subTopicArray.map(async (subtopic) => {
    //     const { mappingId: subMappingId, planPricing: subPlanPricing, classArray } = subtopic;

    //     if (subPlanPricing) {
    //       const filteredSubTopicPricing = await filterAndMapPricing(subPlanPricing, subMappingId);
    //       if (filteredSubTopicPricing?.length) {
    //         tempSubTopicPricing.push(...filteredSubTopicPricing);
    //       }
    //     }

    //     await Promise.all(classArray.map(async (cls) => {
    //       const { mappingId: classMappingId, planPricing: classPlanPricing, contentArray } = cls;

    //       if (classPlanPricing) {
    //         const filteredClassPricing = await filterAndMapPricing(classPlanPricing, classMappingId);
    //         if (filteredClassPricing?.length) {
    //           tempClassPricing.push(...filteredClassPricing);
    //         }
    //       }

    //       await Promise.all(contentArray.map(async (content) => {
    //         const { mappingId: contentMappingId, planPricing: contentPlanPricing } = content;

    //         if (contentPlanPricing) {
    //           const filteredContentPricing = await filterAndMapPricing(contentPlanPricing, contentMappingId);
    //           if (filteredContentPricing?.length) {
    //             tempContentPricing.push(...filteredContentPricing);
    //           }
    //         }
    //       }));
    //     }));
    //   }));
    // }));

    let { mappingId, planPricing } = courseData;

    const filterCoursePricing = planPricing?.filter((item) => item.fieldStatus !== EFieldStatus.NONE) || [];

    return {
      courseMappingId: courseMappingId || tempCourseMappingId,
      ...(filterCoursePricing?.length && {
        coursePricing: {
          mappingId,
          planPricing: filterCoursePricing
        },
      }),
      // ...(tempTopicPricing?.length && { topicPricing: tempTopicPricing }),
      // ...(tempSubTopicPricing?.length && { subTopicPricing: tempSubTopicPricing }),
      // ...(tempClassPricing?.length && { classPricing: tempClassPricing }),
      // ...(tempContentPricing?.length && { contentPricing: tempContentPricing }),
      ...((isEditAction || !pricingSaveStatus) &&
      {
        ...(courseDeletedPricing?.pricingIds?.length && { courseDeletedPricing }),
        // ...(topicDeletedPricing?.pricingIds?.length && { topicDeletedPricing }),
        // ...(subTopicDeletedPricing?.pricingIds?.length && { subTopicDeletedPricing }),
        // ...(classDeletedPricing?.pricingIds?.length && { classDeletedPricing }),
        // ...(contentDeletedPricing?.pricingIds?.length && { contentDeletedPricing })
      })
    };
  };

  // const filterAndMapPricing = async (planPricing, mappingId) => {
  //   const result = [];

  //   planPricing.forEach(item => {
  //     if (item.fieldStatus === EFieldStatus.NONE) return;

  //     const existingMapping = result.find((mapping) => mapping?.mappingId === mappingId);

  //     if (existingMapping) {
  //       existingMapping.planPricing.push(item);
  //     } else {
  //       result.push({ mappingId, planPricing: [item] });
  //     }
  //   });

  //   return result;
  // };

  return (
    <>

      <BeforeUnloadHandler isFormModified={isFormModified} />

      <div className='price-container'>

        {
          courseData?.name && (
            <div className='price-head'>
              <div className='price-head-content'>
                <img src={CoursePriceIcon} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                <p className='price-head-text capitalize'>{courseData?.name}</p>
              </div>
              <div>
                <Tooltip placement="topLeft" title="Payment Configuration">
                  <span onClick={(event) => openDrawer(event, { course: true, data: courseData }, EQuickActionsType.course)}>
                    <img src={settingIcon} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                  </span>
                </Tooltip>
              </div>
            </div>
          )
        }

        <div className='price-accord'>
          <Collapse>
            {(Array.isArray(data) ? data : [])?.map((topic, topicIndex) => (
              <Collapse.Panel
                header={
                  <div className='price-topic-panel'>
                    <div className='price-topic-head'>
                      <img src={topicImage} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                      <p className='capitalize'>{topic?.name}</p>
                    </div>
                    <div className='price-topic-month'>
                      {/* {leastPriceHelper(topic?.planPricing, frequencyMap)}
                      <Tooltip placement="topLeft" title="Payment Configuration">
                        <span onClick={(event) => openDrawer(event, { course: false, data: topic }, EQuickActionsType.topic, topicIndex)}>
                          <img src={settingIcon} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                        </span>
                      </Tooltip> */}
                    </div>
                  </div>
                }
                key={topicIndex.toString()}
              >

                {/* previous code  */}
                {/* <Collapse >
                  {topic?.subTopicArray?.map((subtopic, subtopicIndex) => (
                    <Collapse.Panel
                      style={{
                        paddingLeft: 10
                      }}
                      header={
                        <div className='price-subtopic-panel'>
                          <div className='price-subtopic-head'>
                            <img src={subTopicImg} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                            <p className='capitalize'>{subtopic?.name}</p>
                          </div>
                          <div className='price-subtopic-month' >
                            {leastPriceHelper(subtopic?.planPricing, frequencyMap)}
                            <Tooltip placement="topLeft" title="Payment Configuration">
                              <span onClick={(event) => openDrawer(event, { course: false, data: subtopic }, EQuickActionsType.subTopic, topicIndex, subtopicIndex)}>
                                <img src={settingIcon} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      }
                      key={subtopicIndex.toString()}
                    >

                      <Collapse>
                        {subtopic?.classArray?.map((classes, classIndex) => (
                          <Collapse.Panel
                            style={{
                              paddingLeft: 10
                            }}
                            header={
                              <div className='price-class-panel'>
                                <div className='price-class-head '>
                                  <img src={classImg} alt="Image Icon" style={{ paddingRight: 12 }} className="settingIcon-icon" />
                                  <p className='capitalize'>{classes?.name}</p>
                                </div>
                                <div className='price-class-month'>
                                  {leastPriceHelper(classes?.planPricing, frequencyMap)}
                                  <Tooltip placement="topLeft" title="Payment Configuration">
                                    <span onClick={(event) => openDrawer(event, { course: false, data: classes }, EQuickActionsType.class, topicIndex, subtopicIndex, classIndex)}>
                                      <img src={settingIcon} alt="Image Icon" style={{ paddingRight: 12 }} className="settingIcon-icon" />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            }
                            key={classIndex.toString()}
                          >
                            <Collapse >

                              {classes?.contentArray?.map((content, contentIndex) => (
                                <Collapse.Panel
                                  style={{
                                    paddingLeft: 40
                                  }}
                                  showArrow={false}
                                  header={
                                    <div className='price-content-panel'>
                                      <div className='price-content-head'>
                                        <img src={contentImg} alt="Image Icon" style={{ paddingRight: 12 }} className="settingIcon-icon" />
                                        <p className='capitalize'>{content?.name}</p>
                                      </div>
                                      <div className='price-content-month'>
                                        {leastPriceHelper(content?.planPricing, frequencyMap)}
                                        <Tooltip placement="topLeft" title="Payment Configuration">
                                          <span onClick={(event) => openDrawer(event, { course: false, data: content }, EQuickActionsType.content, topicIndex, subtopicIndex, classIndex, contentIndex)}>
                                            <img src={settingIcon} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  }
                                  key={contentIndex.toString()}
                                ></Collapse.Panel>
                              ))}
                            </Collapse>
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    </Collapse.Panel>
                  ))}
                </Collapse> */}

                <Collapse >
                  {topic?.subTopicArray?.map((subtopic, subtopicIndex) => (
                    <Collapse.Panel
                      style={{
                        paddingLeft: 10
                      }}
                      header={
                        <div className='price-subtopic-panel'>
                          <div className='price-subtopic-head'>
                            <img src={subTopicImg} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                            <p className='capitalize'>{subtopic?.name}</p>
                          </div>
                          <div className='price-subtopic-month' >
                            {/* {leastPriceHelper(subtopic?.planPricing, frequencyMap)}
                            <Tooltip placement="topLeft" title="Payment Configuration">
                              <span onClick={(event) => openDrawer(event, { course: false, data: subtopic }, EQuickActionsType.subTopic, topicIndex, subtopicIndex)}>
                                <img src={settingIcon} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                              </span>
                            </Tooltip> */}
                          </div>
                        </div>
                      }
                      key={subtopicIndex.toString()}
                    >

                      <Collapse >

                        {subtopic?.contentArray?.map((content, contentIndex) => (
                          <Collapse.Panel
                            style={{
                              paddingLeft: 40
                            }}
                            showArrow={false}
                            header={
                              <div className='price-content-panel'>
                                <div className='price-content-head'>
                                  <img src={contentImg} alt="Image Icon" style={{ paddingRight: 12 }} className="settingIcon-icon" />
                                  <p className='capitalize'>{content?.name}</p>
                                </div>
                                <div className='price-content-month'>
                                  {/* {leastPriceHelper(content?.planPricing, frequencyMap)}
                              <Tooltip placement="topLeft" title="Payment Configuration">
                                <span onClick={(event) => openDrawer(event, { course: false, data: content }, EQuickActionsType.content, topicIndex, subtopicIndex, contentIndex)}>
                                  <img src={settingIcon} alt="Image Icon" style={{ paddingRight: '12px' }} className="settingIcon-icon" />
                                </span>
                              </Tooltip> */}
                                </div>
                              </div>
                            }
                            key={contentIndex.toString()}
                          ></Collapse.Panel>
                        ))}
                      </Collapse>

                    </Collapse.Panel>
                  ))}
                </Collapse>

              </Collapse.Panel>
            ))}
          </Collapse>
        </div>

        <PricingPlanDrawer
          visible={pricingVisible}
          currentTitle={currentPricingTitle}
          formik={pricingFormik}
          showHeader={true}
          currentSelectedType={currentSelectedType}
          frequencyData={frequencyData}
          prices={prices}
          frequencyMap={frequencyMap}
          pricingName={pricingName}
          pricingChildsDetails={pricingChildsDetails}
          drawerCloser={pricingDrawerCloser}
          savePricing={savePricing}
          addPrice={addPrice}
          pricingSwitchChange={pricingSwitchChange}
          deletePricing={deletePricing}
        />

        {/* update model */}
        <Modal
          title="Update Confirmation"
          open={isUpdateModalVisible}
          onCancel={closeUpdateModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeUpdateModal}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={updateData}
              >
                Update
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to update?</p>
        </Modal>

        {/* submit modal */}

        <Modal
          title="Submit Confirmation"
          open={isSubmitModalVisible}
          onCancel={closeSubmitModal}
          footer={
            <div>
              <Button
                className="primary-cancel-button"
                onClick={closeSubmitModal}
              >
                Cancel
              </Button>
              <Button
                className="primary-submit-button"
                type="default"
                onClick={pricingFormik.handleSubmit}
                disabled={!pricingFormik.isValid || !pricingFormik.dirty}
              >
                Submit
              </Button>
            </div>
          }
        >
          <p>Are you sure you want to Submit?</p>
        </Modal>

      </div>

      {
        isLoading &&
        <div className='loading-container'>
          <Loading></Loading>
        </div>
      }
    </>
  )
});

export default Price;