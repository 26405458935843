import React, { useState, useEffect } from "react";
import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Drawer, Form, Image, Input, message, Modal, Select, Tag, Tooltip, Upload } from 'antd'
import { useFormik } from 'formik'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { useDispatch, useSelector } from 'react-redux'
import { getExamMasterTable } from './reducers/ExamMasterSlice'
import TableComponent from './Table/tableComponent'
import { IconButton } from '@material-ui/core'
import DeleteImage from '../asset/image/deleteIcon.svg'
import moment from 'moment'
import Loading from './Loading'
import { getAllTestimonials,addtestimonials,getTestimonialById,updateTestimonials,deleteTestimonials} from "./reducers/TestimonialsSlice";
import {getEncryptedLocalStorage} from '../utils/utils';
const { TextArea } = Input;


const Testimonials = () => {
  const dispatch = useDispatch();
  let tableHeight = 320;
  const [data, setData] = useState([]);
  const roleId = getEncryptedLocalStorage("roleId")
  const [loading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({})
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [file, setFile] = useState([]);
  const { testimonialsData, isLoading, totalDataCount } = useSelector((state) => state.Testimonials);
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const initialValues = { name: '',location: '', orderNo: '',content: '',designation:'',testimonialLogo: [] ,isActive:false}

  let validationSchema = yup.object({
    name: yup
    .string()
    .required("Name is Required")
    .min(2, "Name Needed At Least Two characters")
    .max(100, "Name not more than 100 characters"),
    location: yup
    .string()
    .required("Location is Required")
    .min(2, "Location Needed At Least Two characters")
    .max(100, "Location not more than 100 characters"),
    orderNo: yup
    .string()
    .required("Order No is Required")
    .matches(/^[1-9][0-9]*$/, "Order Number must be a number and cannot consist only of 0"),
    content: yup
    .string()
    .required("Content is Required"),
    designation: yup
    .string()
    .required("Designation is Required"),
    testimonialLogo: yup.array().min(1, "Image is Required").required("Image is Required").nullable(),

  })



  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
                return (
          <div className='index-id'>{(i+1)+(paginationData>0?(paginationData)*limit:0)}
          </div>
        )
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        return (
          <div>
            <span style={{textTransform:'capitalize'}}>{record.name}</span>
          </div>
        )
      }
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        render: (_, record) => {
          return (
            <div>
              <span style={{textTransform:'capitalize'}}>{record.location}</span>
            </div>
          )
        }
      },
      {
        title: 'Order No',
        dataIndex: 'orderNo',
        key: 'orderNo',
        render: (_, record) => {
          return (
            <div>
              <span style={{textTransform:'capitalize'}}>{record.orderNo}</span>
            </div>
          )
        }
      },
      {
        title: 'Content',
        dataIndex: 'content',
        key: 'content',
        render: (_, record) => {
          return (
            <div>
              <span style={{textTransform:'capitalize'}}>{record.content}</span>
            </div>
          )
        }
      },

    {
      title: 'Active / InActive',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, record) => {
        return (
          <div>
            <span> {record.isActive ?
            <Tag color="green">Active</Tag>:
            <Tag color="red">InActive</Tag>

            }</span>
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <>
          {
            roleId == 1 || roleId == 3 ?
            <div className='table-action-link'>
            <a style={{paddingRight:10}} onClick={() => editData(record)} >Edit</a>
            {
              roleId == 3 ?
              <Tooltip title="Delete" style={{padding:0}}>
              <IconButton>
              <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
              </IconButton>
            </Tooltip>
              :null
            }

          </div>
          : <div>
            Not Authorized
          </div>
          }

          </>
    
        )
      },
    },
  ]


  
  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    dispatch(getAllTestimonials(obj));
  }, []);

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const deleteData = (_data)=>{
    setCurrentRecord(_data)
    setIsModalVisible(true)
    setCurrentId(_data.id)

}
const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit:(values)=>{
        setVisible(false);
        let data = new FormData();
        data.append("name", formik.values.name.trim())
        data.append("location", formik.values.location.trim())
        data.append("designation", formik.values.designation.trim())
        data.append("orderNo", formik.values.orderNo.trim())
        data.append("content", formik.values.content.trim())
        data.append("testimonialLogo", formik.values.testimonialLogo[0].originFileObj)
        data.append("isActive", formik.values.isActive)

        let datas = {
          data,
          obj: {
            pagination: paginationData,
            searchValue: filterData,
            limit: limit
          }
        }
        setSubmitModalVisible(false);
        dispatch(addtestimonials(datas)).unwrap().then((res)=>{
            if(res.status){
              setVisible(false)
            }
        })
        .catch((error)=>{
          setVisible(true)
        })

    }
})

const openDrawer = () => {
    
    setFile([])
    setCurrentId(null)
    setVisible(true)
    setCurrentTitle("Create Testimonials")
    formik.resetForm({ values: initialValues })

  }

  const filehandleChange = (file) => {

    if(file.fileList.length){
      if(file.file.type == 'image/jpeg' || file.file.type == 'image/jpg' || file.file.type == 'image/png'){
        setFile(file.fileList)
    formik.values.testimonialLogo = file.fileList
      }
      else{
        message.error("please upload jpeg, jpg and png formats only ")
      }
    }
    else{
      setFile(file.fileList)
      formik.values.testimonialLogo = file.fileList
      formik.validateForm()
    }
  }
  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const updateData =()=>{
    setVisible(false);
    let data = new FormData();
    data.append("name", formik.values.name.trim())
    data.append("location", formik.values.location.trim())
    data.append("orderNo", formik.values.orderNo)
    data.append("designation", formik.values.designation.trim())
    data.append("content", formik.values.content.trim())
    data.append("testimonialLogo", formik.values.testimonialLogo[0]?.originFileObj)
    data.append("isActive", formik.values.isActive)

    let datas = {
      id: currentId,
      data,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit

      }
    }
    setUpdateModalVisible(false);
    setVisible(false)
    dispatch(updateTestimonials(datas)).unwrap().then((res)=>{
      if(res.status){
        setVisible(false)
      }
    })
    .catch((error)=>{
      setVisible(true)
    })

}

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };
  const editData = (record)=>{
    setCurrentId(record.id)
    dispatch(getTestimonialById(record.id)).unwrap().then((res)=>{
        if(res.status){

        setFile([{ url: res?.data.data.profileImageUrl, thumbUrl: res?.data.profileImageUrl }])
          let tempValues = JSON.parse(JSON.stringify(res.data.data))
          formik.values['testimonialLogo'] =  [tempValues['profileImageUrl']]
          formik.values.name =  tempValues.name
          formik.values.location =  tempValues.location
          formik.values.orderNo =  tempValues.orderNo
          formik.values.designation =  tempValues.designation
          formik.values.content =  tempValues.content
          formik.values.isActive =  tempValues.isActive
          setVisible(true)
        }
      })
    setVisible(true)
    setCurrentTitle("Edit Testimonials")
}
const  onModalClose=()=> {
  setIsModalVisible(false);
}

const confirmDelete = ()=>{
  setIsModalVisible(false)
  let data = {
    id: currentId,
    obj: {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
  }
  dispatch(deleteTestimonials(data)).unwrap().then((res)=>{
    
  })
}

  
const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit

    }

    dispatch(getAllTestimonials(obj));
  }
  const onChangeSelect = (e, fieldHelpers) => {
    fieldHelpers.setValue(e)
  }
  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getAllTestimonials(obj));
  }, 1000);


  return (
    <>
        <div className='exam-group-container'>
        <div className='header'>
        <h3  className='primary-header'>Testimonials</h3>
        <div className='add-exam'>
        {
           roleId == 3 ?
          <span className='add-new-exam'><Button type='primary' className='primary-submit-button' onClick={() => openDrawer()}> Add Testimonials</Button></span>
          : null
        }
          </div>
        </div>
        <div className='all-table-component'>
            <div className="golbalSearch" >
            <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event)=>onSearch(event.target.value)} />
            </div>

          <TableComponent dataSource={testimonialsData}  columns={columns} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={totalDataCount} currentPage={paginationData + 1} loadingStatus={isLoading}   limit={limit} />

        </div>
        <Drawer title={currentTitle} onClose={() => setVisible(false)} width="518px" closable={false} closeIcon={false} placement="right" open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
                <Button className='primary-cancel-button' onClick={()=> setVisible(false)} >Cancel</Button >
          
                {
                  currentId ?
                  <Button type="primary" className={(!formik.isValid || !formik.dirty )? 'disabled-button':'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showUpdateConfirmation}>Update</Button>
                  :
                <Button type='primary' className={(!formik.isValid || !formik.dirty )? 'disabled-button':'primary-submit-button'} disabled={(!formik.dirty || !formik.isValid)} onClick={showSubmitConfirmation}>Submit</Button>   
                 }
            </div>
          </div>
        )}>
            <div className='basic-form'>
                <>
                    <Form>
                    <div className='form-control'>
              <label>Name<span style={{ color: "red" }}> *</span></label>
              <Input maxLength={50} id='name' name='name' placeholder="Enter a Name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
              {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
            </div>
            <div className='form-control'>
              <label>Location<span style={{ color: "red" }}> *</span></label>
              <Input maxLength={50} id='location' name='location' placeholder="Enter a Location" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.location}></Input>
              {formik.touched.location && formik.errors.location ? <div className='error'>{formik.errors.location}</div> : null}
            </div>
            <div className='form-control'>
              <label>Designation<span style={{ color: "red" }}> *</span></label>
              <Input maxLength={50} id='designation' name='designation' placeholder="Enter a Designation" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.designation}></Input>
              {formik.touched.designation && formik.errors.designation ? <div className='error'>{formik.errors.designation}</div> : null}
            </div>
            <div className='form-control'>
              <label>Order No<span style={{ color: "red" }}> *</span></label>
              <Input maxLength={50} id='orderNo' name='orderNo' placeholder="Enter a Order No" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.orderNo}></Input>
              {formik.touched.orderNo && formik.errors.orderNo ? <div className='error'>{formik.errors.orderNo}</div> : null}
            </div>
            <div className='form-control'>
                <label htmlFor='content'>Content<span style={{ color: "red" }}> *</span></label>
                <TextArea type='text' id='content' placeholder="Type Here" style={{ height: 100, resize: 'none' }} onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.content} maxLength={500} />
                {formik.touched.content && formik.errors.content ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.content}</div> : null}

              </div>
            <div className='exam-upload-file'>
            <label htmlFor="testimonialLogo">Testimonial Logo<span style={{ color:  "red" }}> *</span></label>
              <div className='form-control'>
                <Upload
                  className='ImageUpload'
                  listType="picture"
                  onChange={(file) => filehandleChange(file)}
                  beforeUpload={() => false}
                  fileList={file}
                  maxCount={1}
                >
                  <Button className='exam-image-upload-button'  name="testimonialLogo" onChange={formik.handleChange} onBlur={formik.handleBlur} icon={<UploadOutlined />}>Choose file</Button>
                </Upload>
                {formik.touched.testimonialLogo && formik.errors.testimonialLogo ? <div className='error' >{formik.errors.testimonialLogo}</div> : null}
              </div>
            </div>
            <div className='form-control'>
              <Checkbox  onChange={formik.handleChange} checked={formik.values.isActive} name="isActive" >Active</Checkbox>
            </div>
                </Form>
                </>
             </div>
        </Drawer>
        <Modal title='Deletion' open={isModalVisible} onCancel={onModalClose} footer={
          <div>
          <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() =>confirmDelete()}>OK</Button>
        </div>
        }>
          <p>Are you sure you want to delete <span className='highlight-text'>{currentRecord.groupName} </span>  ?</p>
        </Modal> 
        {/* update Confirmation */}

        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
          <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={updateData}>Update</Button>
        </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>


      {/* submit confirmation */}
        <Modal title="Submit Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel </Button>
            <Button className="primary-submit-button" type="default" onClick={formik.handleSubmit}>Submit </Button>
          </div>
        } >
        <p>Are you sure you want to submit?</p>
      </Modal>

    </div>
            {
                isLoading ? <div className='loading-container'>
        
                  <Loading></Loading>
        
                </div> : null
              }
    </>

  )
            }

export default Testimonials;