import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import axios from "axios";
import { addBreadCrumbData } from "./masterSlice";
import { getAllTypeData } from "./dashboardSlice";
import {getEncryptedLocalStorage} from "../../utils/utils";


//  get All Question Bank Page
export const getAllQuestion = createAsyncThunk("QuestionBank/getAllData", async (payload,thunk) => {
  try {
    let url = ''
    let questionType = payload?.questionTypeId == 2 ? 'getComprehenQuestion' : 'getQuestion';
    let roleId = getEncryptedLocalStorage("roleId");
    if(payload.status?.statusId){
    if(roleId == 5){
      url = `/question/${questionType}/${payload.examId}/${payload.subjectId}/${payload.chapterId}?statusId=${payload.status.statusId}`
    }
    else{
      url = `/question/${questionType}/${payload.examId}/${payload.subjectId}/${payload.chapterId}?statusId=${payload.status.statusId}&filterType=${payload.status.name}`

    }
  }else{
      url =`/question/${questionType}/${payload.examId}/${payload.subjectId}/${payload.chapterId}`
    }
    let getAll = await axios.post(url,payload.obj).then((res) => {
      if (res.data.success) {
        thunk.dispatch(addBreadCrumbData(res.data.data.breadcrumbs))
        return res.data.data
      }
    })
    return getAll

  } catch (error) {
  }
})



// Add Question Master Data
export const addQuestionData = createAsyncThunk('QuestionBank/addData', async (payload, thunk) => {
  try {
    let payloadData = []
    let arr=[]
    let  questionData = new FormData();
    if(payload.payload.questionTypeId == 1){
      questionData.append("file", payload.payload.file)
      questionData.append("subjectId",payload.payload.subjectId)
      questionData.append("chapterId",payload.payload.chapterId)
      questionData.append("difficultyLevelId",payload.payload.difficultyLevelId)
      questionData.append("examId",payload.payload.examId)
      questionData.append("questionText",payload.payload.questionText)
      questionData.append("solution",payload.payload.solution)
      questionData.append("questionOption",JSON.stringify(payload.payload.questionOption))
      questionData.append("isFree",payload.payload.isFree)
      questionData.append("questionTypeId",payload.payload.questionTypeId)
    }else{
      questionData.append("file", payload.payload.file)
      questionData.append("subjectId",payload.payload.subjectId)
      questionData.append("chapterId",payload.payload.chapterId)
      questionData.append("difficultyLevelId",payload.payload.difficultyLevelId)
      questionData.append("examId",payload.payload.examId)
      questionData.append("isFree",payload.payload.isFree)
      questionData.append("questionTypeId",payload.payload.questionTypeId)
      questionData.append("questionParagraphText",payload.payload.questionParagraphText)
      questionData.append("questions",JSON.stringify(payload.payload.questions))
    }
    const insertData = await axios.post('/question/createQuestion', questionData).then(async (res) => {
      if (res.data.data.status) {
        let data = {
          obj : payload.obj,
          examId : payload.payload.examId,
          chapterId : payload.payload.chapterId,
          subjectId : payload.payload.subjectId,
          questionTypeId : payload.payload.questionTypeId,
          status:payload.obj.status
        } 
          await thunk.dispatch(getAllQuestion(data))
        message.success(res.data.data.message)
        return res.data.createAdminUser.data
      } else {
        message.error(res.data.createAdminUser.message)
      }
    })
    return payloadData
  }
   catch (error) {
  }


})
//  get All Question Bank  get By Id Page
export const getQuestionById = createAsyncThunk("QuestionBank/getById", async (payload) => {
  try {
    let getById = await axios.get('question/getQuestion/' + payload).then((res) => {
      if (res.data.success) {
      return res.data.data
      }
    })
    return getById

  } catch (error) {
  }
})
export const updateQuestionData = createAsyncThunk('QuestionBank/updateData', async (data, thunk) => {
  try {
    let questionType = data.payload.data.questionTypeId == 2 ? 'updateComprehensiveQuestion' : 'updateQuestion';
    var questionUpdate = new FormData();
    if(data.payload.data.questionTypeId == 1){
      // questionUpdate.append("file", data.payload.data.file)
      questionUpdate.append("subjectId",data.payload.data.subjectId)
      questionUpdate.append("chapterId",data.payload.data.chapterId)
      questionUpdate.append("difficultyLevelId",data.payload.data.difficultyLevelId)
      questionUpdate.append("examId",data.payload.data.examId)
      questionUpdate.append("questionText",data.payload.data.questionText)
      questionUpdate.append("solution",data.payload.data.solution)
      questionUpdate.append("questionOption",JSON.stringify(data.payload.data.questionOption))
      questionUpdate.append("isFree",data.payload.data.isFree)
      questionUpdate.append("questionTypeId",data.payload.data.questionTypeId)
    }else{
      // questionUpdate.append("file", data.payload.data.file)
      questionUpdate.append("subjectId",data.payload.data.subjectId)
      questionUpdate.append("chapterId",data.payload.data.chapterId)
      questionUpdate.append("difficultyLevelId",data.payload.data.difficultyLevelId)
      questionUpdate.append("examId",data.payload.data.examId)
      questionUpdate.append("isFree",data.payload.data.isFree)
      questionUpdate.append("questionTypeId",data.payload.data.questionTypeId)
      questionUpdate.append("questionParagraphText",data.payload.data.questionParagraphText)
      questionUpdate.append("questions",JSON.stringify(data.payload.data.questions))
    }
    const payloadData = await axios.put(`/question/${questionType}/` + data.payload.id, questionUpdate).then(async (res) => {
      if (res.data.data.success) {
        let payload = {
          obj : data.obj,
          examId : data.payload.data.examId,
          chapterId : data.payload.data.chapterId,
          subjectId : data.payload.data.subjectId,
          statusName : data.payload.data.statusName,
          questionTypeId : data.payload.data.questionTypeId,
          status: data.obj.status
        }

        if(data.payload.data?.statusName){
          let typePayload={
            filterType: data.payload.data?.statusName,
            type:'question',
            questionType : data.payload.data.questionTypeId
          }

          await thunk.dispatch(getAllTypeData(typePayload))
        }else{
          await thunk.dispatch(getAllQuestion(payload))

        }
        message.success(res.data.data.message)
        return res.data.data.data
      } else {
        message.error(res.data.data.message)
      }
    })
    return payloadData
  } catch (error) {
  }


})
export const deleteQuestionData = createAsyncThunk('QuestionBank/deleteData', async (payload, thunk) => {
  try {
    let payloadId = payload
    const deleteData = await axios.delete('/question/deleteQuestion/' + payload).then(async (res) => {
      if (res.data.data.status) {
        message.success(res.data.data.message)
        return res.data.data.data
      } else {
        message.error(res.data.data.message)
      }
    })
    return payloadId
  } catch (error) {
  }


})
// Add Question selection Master Data
export const addQuestionSelectionData = createAsyncThunk('QuestionSelection/addData', async (payload, thunk) => {
  try {
    const insertData = await axios.post('/testMaster/createTestQuestionSelection', payload).then(async (res) => {
      if (res.data.data.status) {
        message.success(res.data.data.message)
        return res.data.data.data
      } else {
        message.error(res.data.data.message)
      }
    })
    return insertData
  } catch (error) {
  }


})

// get Question selection by test id Master Data
export const getQuestionSelectionByTestId = createAsyncThunk('QuestionSelection/GetByTestId', async (payload, thunk) => {
  try {
    const getData = await axios.get('testMaster/getQuestionSelectionByTestId/' + payload).then(async (res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getData
  } catch (error) {
  }


})


// question bank bulk upload

export const excelUpload = createAsyncThunk(
  'AdminMaster/excelUpload', async (payload,thunk) => {
    try {
      let excel = await axios.put(`question/questionExcel/${payload.examId}/${payload.subjectId}/${payload.chapterId}`,payload.excelFile).then(async(res) => {
      
        if(res?.data?.success){
  
          message.success(res.data.message)
          let data = {
            obj : {
              pagination: 0,
              searchValue: "",
              limit:10
      
            },
            examId : payload.examId,
            chapterId : payload.chapterId,
            subjectId : payload.subjectId,
            status: payload.status
          }
          await thunk.dispatch(getAllQuestion(data))
        }




      })
    } catch (error) {
    }
   

  }
)


// get Question selection by test id Master Data 
export const ApprovalQuestionStatus = createAsyncThunk('QuestionSelection/ApprovalQuestionStatus', async (payload, thunk) => {
  try {
    let questionTypeUrl  = payload.questionTypeId == 2 ? 'approvedStatusComprehensiveQuestion' : 'approvedStatusQuestion';
    const getData = await axios.put(`question/${questionTypeUrl}/${payload.questionId}/${payload.statusId}`,payload.data).then(async (res) => {
      if (res.data.success) {
        if(payload.getAllData?.statusName)
        {
          let typePayload ={
            filterType: payload.getAllData?.statusName,
            type:'question',
            questionType : payload.questionTypeId

          }
          await thunk.dispatch(getAllTypeData(typePayload))

        }else{
          await thunk.dispatch(getAllQuestion(payload.getAllData))
        }
        return res.data
        
      }
    })
    return getData
  } catch (error) {
  }


})

//  get All Question Bank  get By Id Page
export const getComprehensionById = createAsyncThunk("QuestionBank/getComprehensionById", async (payload) => {
  try {
    let getById = await axios.get('question/getComprehenQuestion/' + payload).then((res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getById

  } catch (error) {
  }
})

export const deleteComprehensionData = createAsyncThunk('QuestionBank/deleteData', async (payload, thunk) => {
  try {
    let payloadId = payload
    const deleteData = await axios.delete('/question/deleteQuestion/' + payload).then(async (res) => {
      if (res.data.data.status) {
        message.success(res.data.data.message)
        return res.data.data.data
      } else {
        message.error(res.data.data.message)
      }
    })
    return payloadId
  } catch (error) {
  }


})

export const getAllExamList = createAsyncThunk('getAllExamList',async(payload,thunk)=>{
  try {
    let getAllData = await axios.get(`masters/getAllExamList`).then((res)=>{
      if(res.data.status){
        return res.data.data
      }
    })
    return getAllData
    
  } catch (error) {
    
  }
  })
  export const getAllSubjectList = createAsyncThunk('getAllSubjectList',async(payload,thunk)=>{
    try {
      let getAllData = await axios.get(`masters/getAllSubjectList/${payload}`).then((res)=>{
        if(res.data.status){
          return res.data.data
        }
      })
      return getAllData
      
      
    } catch (error) {
      
    }
    })
    export const getAllChapterList = createAsyncThunk('getAllChapterList',async(payload,thunk)=>{
      try {
        let getAllData = await axios.get(`masters/getAllChapterList/${payload}`).then((res)=>{
          if(res.data.status){
            return res.data.data
          }
        })
        return getAllData
        
        
      } catch (error) {
        
      }
      })

export const movingQuestion = createAsyncThunk('QuestionMovement',async(payload,thunk)=>{
try {
  const moveData = await axios.post(`question/movingQuestion`,payload.data).then(async(res)=>{
  // console.log("res data == ",res.data)
    
    if(res.data.status){
      message.success(res.data.message)
      let data = payload.getAllData
      await thunk.dispatch(getAllQuestion(data))
    }
  })
  return
  
} catch (error) {
  
}
})
export const copyAndPasteQuestion = createAsyncThunk('copyAndPasteQuestion',async(payload,thunk)=>{
  try {
    const moveData = await axios.post(`question/copyAndPasteQuestion`,payload.data).then(async(res)=>{
    // console.log("res data == ",res.data)
      
      if(res.data.status){
        message.success(res.data.message)
        let data = payload.getAllData
        await thunk.dispatch(getAllQuestion(data))
      }
    })
    return
    
  } catch (error) {
    
  }
  })



const initialState = {
  questionData: [],
  isQuestionLoading: false,
  currentQuestion: {},
  questionSelection: {
    questionSelection: []
  },
  totalDataCount:0,
  breadCrumbData:{},
  getAllExamListData:[],
  getAllSubjectListData:[],
  getAllChapterListData:[],

}

const QuestionSlice = createSlice({
  name: 'AdminMaster',
  initialState,
  reducers: {
    removeQuestionData: (state, action) => {
      state.questionData = [];

    },
    removeQuestionSelection: (state, action) => {
      state.questionSelection.questionSelection = [];

    },
  },
  extraReducers: {
    [getAllQuestion.pending]: (state, action) => {
      state.isQuestionLoading = true
      state.questionData =  [];
      state.totalDataCount = 0
    },
    [getAllQuestion.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.questionData = action.payload?.getQuestion
      state.totalDataCount = +action.payload?.getQuestionCount?.questionCount
      state.breadCrumbData =  action.payload?.breadcrumbs


    }, [getAllQuestion.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [addQuestionData.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [addQuestionData.fulfilled]: (state, action) => {
      state.isQuestionLoading = false

    }, [addQuestionData.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [getQuestionById.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [getQuestionById.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.currentQuestion = action.payload

    }, [getQuestionById.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [updateQuestionData.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [updateQuestionData.fulfilled]: (state, action) => {
      state.isQuestionLoading = false

    }, [updateQuestionData.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [deleteQuestionData.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [deleteQuestionData.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.questionData = state.questionData.filter((e) => e.id !== action.payload)

    }, [deleteQuestionData.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [getQuestionSelectionByTestId.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [getQuestionSelectionByTestId.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      action.payload.forEach((e)=>{
        if(e.chapterId == null){
          e['chapterId'] = -1
        }
      })
      state.questionSelection.questionSelection = action.payload
    }, 
    [getQuestionSelectionByTestId.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [addQuestionSelectionData.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [addQuestionSelectionData.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
    }, 
    [addQuestionSelectionData.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [excelUpload.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [excelUpload.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
    }, 
    [excelUpload.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [getComprehensionById.pending]: (state, action) => {
      state.isQuestionLoading = true
      state.currentQuestion = {}
    },
    [getComprehensionById.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.currentQuestion = action.payload

    }, [getComprehensionById.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [deleteComprehensionData.pending]: (state, action) => {
      state.isQuestionLoading = true

    },
    [deleteComprehensionData.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.questionData = state.questionData.filter((e) => e.id !== action.payload)

    }, [deleteComprehensionData.rejected]: (state, action) => {
      state.isQuestionLoading = false

    },
    [getAllExamList.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [getAllExamList.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.getAllExamListData = action.payload
    }, 
    [getAllExamList.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [getAllSubjectList.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [getAllSubjectList.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.getAllSubjectListData = action.payload
    }, 
    [getAllSubjectList.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [getAllChapterList.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [getAllChapterList.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
      state.getAllChapterListData = action.payload
    }, 
    [getAllChapterList.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [movingQuestion.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [movingQuestion.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
    }, 
    [movingQuestion.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },
    [copyAndPasteQuestion.pending]: (state, action) => {
      state.isQuestionLoading = true
    },
    [copyAndPasteQuestion.fulfilled]: (state, action) => {
      state.isQuestionLoading = false
    }, 
    [copyAndPasteQuestion.rejected]: (state, action) => {
      state.isQuestionLoading = false
    },

  }
})
export const { removeQuestionData , removeQuestionSelection } = QuestionSlice.actions

export default QuestionSlice.reducer