import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { insertBatchMasterTable, getBatchMasterTable, getBatchById, updateBatch, deleteBatch } from './reducers/BatchMasterSlice'
import { useSelector, useDispatch } from 'react-redux';
import Loading from './Loading';
import { Image, Row, Col, Popover, Modal, Drawer, Input, Button } from 'antd';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import EllipseIcon from '../asset/image/ellipse_icon.svg'
import EditIcon from '../asset/image/edit_icon.svg'
import DeleteIcon from '../asset/image/delete_icon.svg';
import { getEncryptedLocalStorage } from '../utils/utils';


const BatchDetails = () => {
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50)
  const dispatch = useDispatch();
  const params = useParams()
  const institutionId = getEncryptedLocalStorage("institutionId");
  const [currentTitle, setCurrentTitle] = useState('');
  const [visible, setVisible] = useState(false);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [selectedBatch, setSelectBatch] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);

  const navigate = useNavigate()

  const onBatchClick = (data) => {
    navigate(`/course/${data.courseId}/batch/${data.id}/student`)
  }
  const { batchMasterData, isLoading, batchDataCount } = useSelector((state) => (state.BatchSlice))

  useEffect(() => {

    dispatch(getBatchMasterTable(params.courseId));
  }, [])

  const editData = (event) => {
    console.log('edit data')
    event.preventDefault();
    event.stopPropagation();
    setSelectedBatchId(null);
    dispatch(getBatchById(selectedBatchId)).unwrap().then((res) => {
      if (res) {
        formik.setValues(res)
        setVisible(true)
      }
    })
  }

  let validationSchema = yup.object({
    name: yup.string().required("Batch Name is Required").min(2, "Batch Name Needed Atleast Two characters").max(100, "Batch Name not more than 100 characters"),
    // matches(/^[A-Za-z0-9_@ ]*$/, 'Batch Name can only contain alphabetic characters, numbers, "@" symbol, and underscore'),
    batchCode: yup.string().required("Batch code is Required").trim().matches(/^[^\s@\-_][\w\s@\-_]*$/, {
      message: 'Invalid format. The input should not start with @, -, or _',
      excludeEmptyString: true,
    })
    
  })

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };
  function updateData(values) {
    setVisible(false);
    let datas = {
      id: values.id,
      data: {

        name: values.name,
        batchCode:values.batchCode,
        institutionDetailId: +institutionId,
        courseId: +params.courseId
      }
    }
    setUpdateModalVisible(false);
    dispatch(updateBatch(datas))
  }
  function onClose(values) {
    setVisible(false);
  }
  const initialValues = { name: '' ,batchCode:''}
  const formik = useFormik({
    initialValues,
    onSubmit: updateData,
    validationSchema,
  })
  const handleOpenChange = (visible, data) => {
    if (visible) {
      setSelectBatch(data)
      setSelectedBatchId(data.id);
    } else {
      setSelectedBatchId(null);
    }
  };
  const deleteConfirmationModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDeleteId(selectedBatchId)
    setSelectedBatchId(null);
    setConfirmationModalOpen(true);
  };
  const deleteCourseOk = () => {
    setConfirmationModalOpen(false);
    /* api call send */
    console.log('deleteId', deleteId);
    let data = {
      id: deleteId,
      institutionId: institutionId,
      courseId: +params.courseId
    }
    dispatch(deleteBatch(data))


  };

  const deleteBatchCancel = () => {
    setConfirmationModalOpen(false);
  };

  const handlePopoverClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const content = (
    <div>
      <div className='edit menu-option-icon' onClick={(event) => editData(event, selectedBatch)}>
        <Image src={EditIcon} preview={false}></Image>
        <p>Edit</p>
      </div>
      <div className='delete menu-option-icon' onClick={(event) => deleteConfirmationModal(event)}>
        <Image src={DeleteIcon} preview={false}></Image>
        <p>Delete</p>
      </div>
    </div>
  );

  return (
    <>
      {
        isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> :
          <>
            <div className='batch-container'>
              <Row gutter={[16, 16]}>
                <>
                  {
                    batchMasterData?.map((data, index) => {
                      return (
                        <Col xs={6} sm={6} md={8} lg={6}>
                          <div className='batch-body-container' >
                            <div className='batch-body'>
                              <div className='batch-content' onClick={() => onBatchClick(data)}>
                                <p className='batch-name'>{data.name}</p>
                                <p className='batch-total'>No. of Students: <span>{data.studentCount}</span></p>
                              </div>
                              <div className='batch-icon'>
                                <Popover
                                  content={content}
                                  placement="bottomRight"
                                  trigger="click"
                                  visible={selectedBatchId === data.id}
                                  onVisibleChange={(visible) => handleOpenChange(visible, data)}
                                >
                                  <div className='course-ellipse' onClick={handlePopoverClick}>
                                    <Image src={EllipseIcon} preview={false}></Image>
                                  </div>
                                </Popover>
                              </div>
                            </div>
                            
                        {/* delete confirmation */}
                       <Modal title='Deletion Confirmation' open={isConfirmationModalOpen} onCancel={deleteBatchCancel} footer={
                       <div>
                       <Button className='primary-cancel-button' onClick={() => deleteBatchCancel()}>Cancel</Button>
                        <Button className='primary-submit-button' type='default' onClick={() => deleteCourseOk()}>OK</Button>
                       </div>
                       }>
                        <p>Are you sure you want to delete?</p>
                       </Modal>
                          </div>
                        </Col>
                      )
                    })
                  }
                </>
              </Row>
            </div>
            <div>
              <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose(formik.values)} open={visible} footer={(
                <div className='footer'>
                  <div className='footer-button'>
                    <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose(formik.values)}>Cancel</Button>
                    <Button type='primary' className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} onClick={showUpdateConfirmation}>Update</Button>

                  </div>
                </div>
              )}>
                <form>
                  <div>

                    <div className='form-control'>
                      <label>Batch Name <span style={{ color: 'red' }}>*</span></label>
                      <Input maxLength={50} id='name' name='name' placeholder='Enter Batch Name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
                      {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
                    </div>
                    <div className='form-control'>
                      <label>Batch Code <span style={{ color: 'red' }}>*</span></label>
                      <Input maxLength={50} id='batchCode' name='batchCode' placeholder='Enter Batch Code' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.batchCode}></Input>
                      {formik.touched.batchCode && formik.errors.batchCode ? <div className='error'>{formik.errors.batchCode}</div> : null}
                    </div>
                  </div>
                </form>
              </Drawer>

                {/* update model */}
        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
          <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() =>updateData(formik.values)} >Update</Button>
        </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>
            </div>
          </>
      }

    </>
  )
}
export default BatchDetails