import { SearchOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Drawer, Form, Input, message, Modal, Select, Tag, Upload } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { useDispatch, useSelector } from 'react-redux'
import { getExamMasterTable } from './reducers/ExamMasterSlice'
import { createExamGroup, deleteExamGroup, getAllExamGroup, getExamGroupById, updateExamGroup } from './reducers/masterSlice'
import { useNavigate } from "react-router-dom";
import TableComponent from './Table/tableComponent'
import moment from 'moment'
import Loading from './Loading'
import { getEncryptedLocalStorage } from '../utils/utils';

const ExamGroupSingleTest = () => {


  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false)
  const [filterData, setFilterData] = useState("")
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const roleId = getEncryptedLocalStorage("roleId")
  const { examMasterTableData } = useSelector((state) => state.ExamMaster);
  const { examGroupData, isLoading, examGroupDataCount } = useSelector((state) => state.MasterSlice);
  const tableHeight = 320

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
      render: (_, record) => {
        return (
          <div>
            <span style={{ textTransform: 'capitalize' }}>{record.groupName}</span>
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/ExamMasterSingleTest/" + record.id);
          },
        };
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        return (
          <div>
            <span>{moment(record.updatedAt).format('DD MMM YYYY')}</span>
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/ExamMasterSingleTest/" + record.id);
          },
        };
      },
    },
    {
      title: 'Active / InActive',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, record) => {
        return (
          <div>
            <span> {record.isActive ?
              <Tag color="green">Active</Tag> :
              <Tag color="red">Inactive</Tag>

            }</span>
          </div>
        )
      },
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            navigate("/ExamMasterSingleTest/" + record.id);
          },
        };
      },
    }
  ]

  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit
    }
    debounced(obj)
  }
  const debounced = useDebouncedCallback((obj) => {
    getAllGroup(obj);
  }, 1000)

  const getAllExam = () => {
    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getExamMasterTable(obj));
  }

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit
    }
    getAllGroup(obj);
  }
  const getAllGroup = (obj) => {
    dispatch(getAllExamGroup(obj)).unwrap().then((res) => {
    })
  }

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
    getAllExam();
    getAllGroup(obj);
  }, [])


  return (
    <>
      <div className='exam-group-container'>
        <div className='header'>
          <h3 className='primary-header'>Exam Group</h3>
        </div>
        <div className='all-table-component'>
            <div className="golbalSearch" >
              <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
            </div>
          <TableComponent dataSource={examGroupData} columns={columns} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={examGroupDataCount} currentPage={paginationData + 1} loadingStatus={isLoading} limit={limit} />
        </div>
      </div>
      {
        isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> : null
      }
    </>
  )
}

export default ExamGroupSingleTest