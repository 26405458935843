import { Col, Divider, Row } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { getData } from './reducers/dashboardSlice';
import { getAppDetails } from './reducers/masterSlice';
import './styles/dashboard.scss';

function Dashboard() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {isDashLoading ,currentData} = useSelector((state) => state.DashboardSlice)
  useEffect(() => {
    dispatch(getData())
    dispatch(getAppDetails())
  }, []);
  useEffect(() => {

  }, [currentData]);
  return (
    <>
    <div className='dashboard-page-container'>
      <div className='dashboard-page-wrapper'>
      <div className='header-content' >
        <p>Dashboard</p>
      </div>
      <div className='body-content' >
        <div className='body-wrapper'>
        {
            currentData?.comprehensiveQuestionStatus
            ?
            <div className='question-container'>
            <div className='headers'>
              <p>Comprehension Question</p>
            </div>
            <div className='card-content'>
              <Row>
              <Col span={8}>
                <div className='col-wrapper'>
                <div className='pending-for-approval' onClick={()=>navigate("/Questions/pendingForApproval?questionTypeId=2")}>
                  <p className='status'>Waiting for Approval</p>
                  <p className='total'>{ currentData?.comprehensiveQuestionStatus?.pendingForApproval ? currentData?.comprehensiveQuestionStatus?.pendingForApproval : 0 }</p>
                </div>
                <div className='divider'>
                  <Divider type="vertical" />
                </div>
                </div>
                </Col>
                <Col span={8}>
                <div className='col-wrapper'>
                  <div  className='pending'  onClick={()=>navigate("/Questions/pending?questionTypeId=2")}>
                    <p  className='status'>Pending</p>
                    <p  className='total'>{ currentData?.comprehensiveQuestionStatus?.pending ? currentData?.comprehensiveQuestionStatus?.pending : 0 } </p>
                </div>
                <div className='divider'>
                  <Divider type="vertical" />
                </div>
                </div>

                </Col>
                <Col span={8}>
                <div className='col-wrapper'>
                  <div  className='rejected'  onClick={()=>navigate("/Questions/rejected?questionTypeId=2")}>
                    <p  className='status'>Rejected </p>
                    <p  className='total'> { currentData?.comprehensiveQuestionStatus?.rejected ? currentData?.comprehensiveQuestionStatus?.rejected : 0 } </p>
                </div>
                </div>
                </Col>
              </Row>
            </div>

          </div>

            : null

          }


          {
            currentData?.questionStatus
            ?
            <div className='question-container'>
            <div className='headers'>
              <p>Question</p>
            </div>
            <div className='card-content'>
              <Row>
              <Col span={8}>
                <div className='col-wrapper'>
                <div className='pending-for-approval' onClick={()=>navigate("/Questions/pendingForApproval?questionTypeId=1")}>
                  <p className='status'>Waiting for Approval</p>
                  <p className='total'>{ currentData?.questionStatus?.pendingForApproval ? currentData?.questionStatus?.pendingForApproval : 0 }</p>
                </div>
                <div className='divider'>
                  <Divider type="vertical" />
                </div>
                </div>
                </Col>
                <Col span={8}>
                <div className='col-wrapper'>
                  <div  className='pending'  onClick={()=>navigate("/Questions/pending?questionTypeId=1")}>
                    <p  className='status'>Pending</p>
                    <p  className='total'>{ currentData?.questionStatus?.pending ? currentData?.questionStatus?.pending : 0 } </p>
                </div>
                <div className='divider'>
                  <Divider type="vertical" />
                </div>
                </div>

                </Col>
                <Col span={8}>
                <div className='col-wrapper'>
                  <div  className='rejected'  onClick={()=>navigate("/Questions/rejected?questionTypeId=1")}>
                    <p  className='status'>Rejected </p>
                    <p  className='total'> { currentData?.questionStatus?.rejected ? currentData?.questionStatus?.rejected : 0 } </p>
                </div>
                </div>
                </Col>
              </Row>
            </div>

          </div>

            : null

          }

          {
            currentData?.chapterStatus
            ?
            <div  className='chapter-container'>
            <div  className='headers'>
                <p>Chapter</p>
              </div>
              <div className='card-content'>
              <Row>
                <Col span={8}>
                  <div className='col-wrapper'>
                  <div className='pending-for-approval'  onClick={()=>navigate("/ChapterMaster/pendingForApproval")}>
                    <p className='status'>Waiting for Approval</p>
                    <p className='total'>{ currentData?.chapterStatus?.pendingForApproval ? currentData?.chapterStatus?.pendingForApproval : 0 }</p>
                  </div>
                  <div className='divider'>
  
                  <Divider type="vertical" />
                  </div>
                  </div>
                  </Col>
                  <Col span={8}>
                  <div className='col-wrapper'>
  
                    <div  className='pending' onClick={()=>navigate("/ChapterMaster/pending")}>
                      <p  className='status'>Pending</p>
                      <p  className='total'>{ currentData?.chapterStatus?.pending ? currentData?.chapterStatus?.pending : 0 }</p>
                  </div>
                  <div className='divider'>
  
                  <Divider type="vertical" />
                  </div>
                  </div>
  
                  </Col>
                  <Col span={8}>
                  <div className='col-wrapper'>
  
                    <div  className='rejected'  onClick={()=>navigate("/ChapterMaster/rejected")}>
                      <p  className='status'>Rejected </p>
                      <p  className='total'> { currentData?.chapterStatus?.rejected ? currentData?.chapterStatus?.rejected : 0 } </p>
                  </div>
                  </div>
                  </Col>
                </Row>
              </div>
  
            </div>
            : null }


            {
              currentData?.subjectStatus
 
              ?
              <div  className='subject-container'>
              <div  className='headers'>
                  <p>Subject</p>
                </div>
                <div className='card-content'>
                <Row>
                  <Col span={8}>
                    <div className='col-wrapper'>
                    <div className='pending-for-approval' onClick={()=>navigate("/SubjectMaster/pendingForApproval")}>
                      <p className='status'>Waiting for Approval</p>
                      <p className='total'>{ currentData?.subjectStatus?.pendingForApproval ? currentData?.subjectStatus?.pendingForApproval : 0 }</p>
                    </div>
                    <div className='divider'>
    
                    <Divider type="vertical" />
                    </div>
                    </div>
                    </Col>
                    <Col span={8}>
                    <div className='col-wrapper'>
    
                      <div  className='pending' onClick={()=>navigate("/SubjectMaster/pending")}>
                        <p  className='status'>Pending</p>
                        <p  className='total'>{ currentData?.subjectStatus?.pending ? currentData?.subjectStatus?.pending : 0 }</p>
                    </div>
                    <div className='divider'>
    
                    <Divider type="vertical" />
                    </div>
                    </div>
    
                    </Col>
                    <Col span={8}>
                    <div className='col-wrapper'>
    
                      <div  className='rejected'  onClick={()=>navigate("/SubjectMaster/rejected")}>
                        <p  className='status'>Rejected </p>
                        <p  className='total'> { currentData?.subjectStatus?.rejected ? currentData?.subjectStatus?.rejected : 0 } </p>
                    </div>
                    </div>
                    </Col>
                  </Row>
                </div>
    
              </div>
              : null

            }

{
  currentData?.examStatus ?
  <div  className='exam-container'>
  <div  className='headers'>
      <p>Exam</p>
    </div>
    <div className='card-content'>
    <Row>
      <Col span={8}>
        <div className='col-wrapper'>
        <div className='pending-for-approval' onClick={()=>navigate("/ExamList/pendingForApproval")}>
          <p className='status'>Waiting for Approval</p>
          <p className='total'> { currentData?.examStatus?.pendingForApproval ? currentData?.examStatus?.pendingForApproval : 0 }</p>
        </div>
        <div className='divider'>

        <Divider type="vertical" />
        </div>
        </div>
        </Col>
        <Col span={8}>
        <div className='col-wrapper'>

          <div  className='pending' onClick={()=>navigate("/ExamList/pending")}>
            <p  className='status'>Pending</p>
            <p  className='total'> { currentData?.examStatus?.pending ? currentData?.examStatus?.pending : 0 }</p>
        </div>
        <div className='divider'>

        <Divider type="vertical" />
        </div>
        </div>

        </Col>
        <Col span={8}>
        <div className='col-wrapper'>

          <div  className='rejected' onClick={()=>navigate("/ExamList/rejected")}>
            <p  className='status'>Rejected </p>
            <p  className='total'>  { currentData?.examStatus?.rejected ? currentData?.examStatus?.rejected : 0 }</p>
        </div>
        </div>
        </Col>
      </Row>
    </div>

  </div>
  : null
}


{
  currentData?.testStatus
  ?

  <div  className='test-container'>
  <div  className='headers'>
      <p>Test</p>
    </div>
    <div className='card-content'>
    <Row>
      <Col span={8}>
        <div className='col-wrapper'>
        <div className='pending-for-approval'  onClick={()=>navigate("/TestMaster/pendingForApproval")}>
          <p className='status'>Waiting for Approval</p>
          <p className='total'> { currentData?.testStatus?.pendingForApproval ? currentData?.testStatus?.pendingForApproval : 0 }</p>
        </div>
        <div className='divider'>

        <Divider type="vertical" />
        </div>
        </div>
        </Col>
        <Col span={8}>
        <div className='col-wrapper'>

          <div  className='pending'  onClick={()=>navigate("/TestMaster/pending")}>
            <p  className='status'>Pending</p>
            <p  className='total'>{ currentData?.testStatus?.pending ? currentData?.testStatus?.pending : 0 }</p>
        </div>
        <div className='divider'>

        <Divider type="vertical" />
        </div>
        </div>

        </Col>
        <Col span={8}>
        <div className='col-wrapper'>

          <div  className='rejected'  onClick={()=>navigate("/TestMaster/rejected")}>
            <p  className='status'>Rejected </p>
            <p  className='total'>{ currentData?.testStatus?.rejected ? currentData?.testStatus?.rejected : 0 }</p>
        </div>
        </div>
        </Col>
      </Row>
    </div>

  </div>
  : null
}


        </div>

      </div>
      </div>
      
    </div>
    {
          isDashLoading ? <div className='loading-container'>
  
            <Loading></Loading>
  
          </div> : null
        }
    </>
  );
}
export default Dashboard;