import html2canvas from "html2canvas";
import axios from "axios";
import { message } from "antd";
import CryptoJS from "crypto-js";


const exportAsImage = async (el, imageFileName, quill) => {
    const canvas = await html2canvas(el);
    canvas.toBlob(async function (blob) {
        const formData = new FormData();
        formData.append('file', blob, `${Date.now()}_image.png`);
        await axios.post("/question/questionImageUpload", formData).then((res) => {
            if (res.status == 200) {
                const editor = quill.current.getEditor();
                var range = editor.getSelection();
                if(res.data.url){
                    range = !range ? editor.getLength() : range.index
                        editor.insertEmbed(range, 'image', res.data.url);
                }

            } else {
                message.error("Error in inserting equation")
            }

        })
    });
};

const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
};


// const secretKey = process.env.REACT_APP_ENCRYPT_SECRET_KEY;

const secretKey = "q*2N5?qyg2Q`hilns";

export const setEncryptedLocalStorage = (key, value) => {
    try {
      const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
      localStorage.setItem(key, encryptedValue);
    } catch (error) {
      console.error("Failed to encrypt and set localStorage item:", error);
    }
  };

export const getEncryptedLocalStorage = (key) => {
    try {
      const encryptedValue = localStorage.getItem(key);
      if (!encryptedValue) return null;

      const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, secretKey).toString(CryptoJS.enc.Utf8);
      return decryptedValue ? JSON.parse(decryptedValue) : null;
    } catch (error) {
      console.error("Failed to decrypt or parse localStorage item:", error);
      return null;
    }
  };

export default exportAsImage;