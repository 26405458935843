import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {getEncryptedLocalStorage} from '../utils/utils';

const RequiredLoginAuth = ({ children }) => {
 const navigate = useNavigate()
 let token  = getEncryptedLocalStorage('authToken') ? true : false
 useEffect(()=>{
  if (token) {
   return navigate('/')
  }
 },[])

 return children
}

export default RequiredLoginAuth