import React, { useState, useEffect } from "react";
import {
  Image,
  Button,
  Drawer,
  Input,
  Modal,
  Upload,
  message,
  Select,
} from "antd";
import {
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import TableComponent from "./Table/tableComponent";
import { useDispatch, useSelector } from "react-redux";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { useDebouncedCallback } from "use-debounce";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useFormik } from "formik";
import { createInstituteData, getinstitutionMasterTable, getInstitutionById, updateInstitution, deleteInstitutionData, updateInstitutionMail } from './reducers/institutionMasterSlice';

const InstitutionMaster = () => {
  const dispatch = useDispatch();
  const [editId, setEditId] = useState();
  const [record, setRecord] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [file, setFile] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isVerified, setIsVerified] = useState(true);

  const { instituteTableData, isLoading, institutionDataCount } = useSelector((state) => state.InstitutionSlice);

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getinstitutionMasterTable(obj));
  }, []);

  let tableHeight = 320;

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Institution Logo",
      dataIndex: "institutionLogo",
      key: "institutionLogo",
      render: (_, record) => (
        <Image src={record.institutionLogo} alt="Institution Logo" style={{ width: '100px', height: 'auto' }} width="100px" />
      ),
    },
    {
      title: "Institution Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text && text.replace(/\b\w/g, (c) => c.toUpperCase()),
    },
    {
      title: "Admin",
      dataIndex: "contactName",
      key: "contactName"
    },
    {
      title: "No of Students",
      dataIndex: "headCount",
      key: "headCount"
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="table-action-link">
            <a onClick={() => editData(record)}>Edit</a>
            <Tooltip title="Delete" style={{ padding: 0 }}>
              <IconButton>
                <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const search = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };

  const debounced = useDebouncedCallback((obj) => {
    dispatch(getinstitutionMasterTable(obj));
  }, 400);

  const initialValues = {
    name: "",
    email: "",
    address: "",
    phoneNumber: "",
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    headCount: "",
    institutionCode: "",
    institutionLogo: [],
    status: true
  };

  const validationSchema = yup.object({
    name: yup.string()
      .required("Institute Name is Required")
      .min(2, "Institute Name Needed At Least Two characters")
      .max(100, "Institute Name not more than 100 characters"),
    institutionCode: yup.string()
      .required("Institute code is Required")
      .trim()
      .min(2, "Institute Name Needed At Least Two characters")
      .max(100, "Institute Name not more than 100 characters"),
    email: yup.string()
      .email("Email must be a valid one")
      .required('Email is required'),
    address: yup.string()
      .required("Address is Required")
      .matches(/^[-/\\(),\w\s]*$/, 'Invalid characters. Only /, \\, (, ), -, and comma are allowed.'),
    phoneNumber: yup.string()
      .required("Phone Number is Required")
      .matches('^[0-9 ]+$', "Invalid Phone Number")
      .trim()
      .min(10, 'Phone Number Must Be Exactly 10')
      .max(10, 'Phone Number Must Be Exactly 10')
      .nullable(),
    contactName: yup.string()
      .required("Contact Person name is Required")
      .trim()
      .min(2, "Contact Person name Needed At Least Two characters")
      .max(100, "Contact Person name not more than 100 characters"),
    contactNumber: yup.string()
      .required("Mobile Number is Required")
      .matches('^[0-9 ]+$', "Invalid Mobile Number")
      .trim()
      .min(10, 'Mobile Number Must Be Exactly 10')
      .max(10, 'Mobile Number Must Be Exactly 10')
      .nullable(),
    contactEmail: yup.string()
      .email("Email must be a valid")
      .required('Email is required')
      .nullable(),
    headCount: yup.number()
      .required('Count is required')
      .max(99999, 'Number of Questions is 0 - 99999')
      .positive('Number of Questions must be a positive integer')
      .integer('Number of Questions must be a positive integer')
      .nullable(),
    institutionLogo: yup.array()
      .when('$isEdit', {
        is: true,
        then: yup.array(),
        otherwise: yup.array()
          .min(1, "Image is Required")
          .required("Image is Required")
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const handleSubmit = (values) => {
    setVisible(false);
    let datas = {
      data: (() => {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("address", values.address);
        formData.append("phoneNumber", values.phoneNumber);
        formData.append("contactName", values.contactName);
        formData.append("contactNumber", values.contactNumber);
        formData.append("contactEmail", values.contactEmail);
        formData.append("headCount", values.headCount);
        formData.append("institutionCode", values.institutionCode);
        formData.append("institutionLogo", values.institutionLogo[0]?.originFileObj);
        formData.append("status", values.status);
        return formData;
      })(),
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    };
    setSubmitModalVisible(false);
    dispatch(createInstituteData(datas));
    formik.resetForm();
  };

  const editData = (_data) => {
    setFile([{
      url: _data.institutionLogo,
      thumbUrl: _data.institutionLogo,
      name: _data.institutionLogo
    }]);
    setCurrentId(_data.id);
    dispatch(getInstitutionById(_data.id)).unwrap().then((res) => {
      setIsVerified(res.isVerified);
      console.log('res', res);
      if (res) {
        formik.setValues({
          name: res.name,
          email: res.email,
          address: res.address,
          phoneNumber: res.phoneNumber,
          contactName: res.contactName,
          contactNumber: res.contactNumber,
          contactEmail: res.contactEmail,
          headCount: res.headCount,
          institutionCode: res.institutionCode,
          institutionLogo: [res.institutionLogo],
          status: res.status
        });
        setVisible(true);
      }
    });
    setCurrentTitle("Edit Institute");
  };

  const deleteData = (_data) => {
    setRecord(_data);
    setCurrentId(_data.id);
    setIsModalVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    formik.resetForm();
  };

  const updateMail = (values) => {
    let datas = {
      id: currentId,
      data: {
        name: values.name,
        email: values.email
      },
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    };
    let resendMail = "resendMail";
    dispatch(updateInstitutionMail({ datas, resendMail: resendMail }));
    setVisible(false);
  };

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    setVisible(false);
    let datas = {
      id: currentId,
      data: (() => {
        const formData = new FormData();
        formData.append('name', formik.values.name);
        formData.append('email', formik.values.email);
        formData.append('address', formik.values.address);
        formData.append('phoneNumber', +formik.values.phoneNumber);
        formData.append('contactName', formik.values.contactName);
        formData.append('contactNumber', +formik.values.contactNumber); 
        formData.append('contactEmail', formik.values.contactEmail);
        formData.append('headCount', formik.values.headCount);
        formData.append('institutionCode', formik.values.institutionCode);
        formData.append('status', formik.values.status);
        if (formik.values.institutionLogo?.[0]?.originFileObj) {
          formData.append('institutionLogo', formik.values.institutionLogo[0].originFileObj);
        }
        return formData;
      })(),
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    };
    setUpdateModalVisible(false);
    let updateInstite = 'updateInstite';
    dispatch(updateInstitution({ datas, updateInstite: updateInstite }));
  };

  const openDrawer = () => {
    setIsVerified(null);
    setCurrentId(null);
    setVisible(true);
    setCurrentTitle("Institution creation");
    formik.resetForm();
    setFile([]);
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);
    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit
    };
    dispatch(getinstitutionMasterTable(obj));
  };

  const onModalClose = () => {
    setIsModalVisible(false);
  };

  const filehandleChange = (file) => {
    if (file.fileList.length) {
      if (
        file.file.type === "image/jpeg" ||
        file.file.type === "image/jpg" ||
        file.file.type === "image/png"
      ) {
        setFile(file.fileList);
        formik.setFieldValue('institutionLogo', file.fileList);
      } else {
        message.error("please upload jpeg, jpg and png formats only ");
      }
    } else {
      setFile(file.fileList);
      formik.setFieldValue('institutionLogo', file.fileList);
    }
  };

  const confirmDelete = () => {
    setIsModalVisible(false);
    let data = {
      id: currentId,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    };
    dispatch(deleteInstitutionData(data));
  };

  return (
    <div className="institution-master-page">
      <div className="institution-header">
        <p className="header-name">Institution Master</p>
        <div className="create-institution">
          <Button className="create-button" onClick={openDrawer}>Create Institution</Button>
        </div>
      </div>
      <div className="institution-content">
        <div className="golbalSearch">
          <Input
            placeholder="Search"
            prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
            style={{ width: 262, height: 32 }}
            onChange={(e) => search(e.target.value)}
            value={filterData}
          />
        </div>
        <div className="table-component">
          <TableComponent
            columns={columns}
            dataSource={instituteTableData}
            tableOnChange={tableOnChange}
            tableHeight={tableHeight}
            totalData={institutionDataCount}
            currentPage={paginationData + 1}
            loadingStatus={isLoading}
            limit={limit}
          />
        </div>

        <Drawer
          className="institution-drawer"
          closable={false}
          title={currentTitle}
          placement="right"
          onClose={onClose}
          open={visible}
          footer={
            <div className="footer">
              <div className="footer-button">
                <Button
                  className="primary-cancel-button"
                  style={{ marginRight: 42.5 }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                {isVerified === false && (
                  <Button
                    className="primary-submit-button"
                    type="primary"
                    onClick={() => updateMail(formik.values)}
                  >
                    Resend Mail
                  </Button>
                )}
                {currentId ? (
                  <Button
                    className="primary-submit-button"
                    type="primary"
                    onClick={showUpdateConfirmation}
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    className="primary-submit-button"
                    type="primary"
                    onClick={() => setSubmitModalVisible(true)}
                    disabled={!formik.isValid || !formik.dirty}
                  >
                    Create
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="institute-drawer-form">
              <div>
                <p className="body-header"><span>Institution Details</span></p>
                <div className="institutionDetails-content">
                  <div>
                    <div className="form-control">
                      <label>Institution Name<span style={{ color: 'red' }}>*</span></label>
                      <Input
                        type="text"
                        maxLength={50}
                        name="name"
                        placeholder="Enter Institution Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="error">{formik.errors.name}</div>
                      )}
                    </div>

                    <div className="form-control">
                      <label>Address<span style={{ color: 'red' }}>*</span></label>
                      <Input
                        type="text"
                        style={{ height: '58px' }}
                        maxLength={100}
                        name="address"
                        placeholder="Enter Address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.address && formik.errors.address && (
                        <div className="error">{formik.errors.address}</div>
                      )}
                    </div>

                    <div className="form-control">
                      <label>Institution Code<span style={{ color: 'red' }}>*</span></label>
                      <Input
                        disabled={currentId !== null}
                        type="text"
                        maxLength={50}
                        name="institutionCode"
                        placeholder="Enter Institution Code"
                        value={formik.values.institutionCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.institutionCode && formik.errors.institutionCode && (
                        <div className="error">{formik.errors.institutionCode}</div>
                      )}
                    </div>

                    <div className="form-control">
                      <label>Institution Logo</label>
                      <Upload
                        className="ImageUpload"
                        listType="picture"
                        onChange={(file) => filehandleChange(file)}
                        beforeUpload={() => false}
                        fileList={file}
                        maxCount={1}
                        onRemove={() => {
                          setFile([]);
                          formik.setFieldValue('institutionLogo', []);
                        }}
                        name="institutionLogo"
                      >
                        <Button
                          className="institution-logo-upload-button"
                          icon={<UploadOutlined />}
                        >
                          Choose file
                        </Button>
                      </Upload>
                      {formik.touched.institutionLogo && formik.errors.institutionLogo && (
                        <div className="error">{formik.errors.institutionLogo}</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="form-control">
                      <label>Email ID<span style={{ color: 'red' }}>*</span></label>
                      <Input
                        disabled={currentId !== null}
                        type="email"
                        maxLength={50}
                        name="email"
                        placeholder="Enter mail"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="error">{formik.errors.email}</div>
                      )}
                    </div>

                    <div className="form-control">
                      <label>Phone number<span style={{ color: 'red' }}>*</span></label>
                      <Input
                        type="text"
                        maxLength={50}
                        name="phoneNumber"
                        placeholder="Enter phone number"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                        <div className="error">{formik.errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="divider-line"></div>
              </div>

              <div style={{ paddingTop: '20px' }}>
                <p className="body-header"><span>Contact person Details</span></p>
                <div className="institutionDetails-content">
                  <div>
                    <div className="form-control">
                      <label>Contact person name<span style={{ color: 'red' }}>*</span></label>
                      <Input
                        type="text"
                        maxLength={50}
                        name="contactName"
                        placeholder="Enter name"
                        value={formik.values.contactName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.contactName && formik.errors.contactName && (
                        <div className="error">{formik.errors.contactName}</div>
                      )}
                    </div>

                    <div className="form-control">
                      <label>Email<span style={{ color: 'red' }}>*</span></label>
                      <Input
                        type="email"
                        maxLength={50}
                        name="contactEmail"
                        placeholder="Enter mail"
                        value={formik.values.contactEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.contactEmail && formik.errors.contactEmail && (
                        <div className="error">{formik.errors.contactEmail}</div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="form-control">
                      <label>Mobile number<span style={{ color: 'red' }}>*</span></label>
                      <Input
                        type="text"
                        maxLength={50}
                        name="contactNumber"
                        placeholder="Enter mobile number"
                        value={formik.values.contactNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.contactNumber && formik.errors.contactNumber && (
                        <div className="error">{formik.errors.contactNumber}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="divider-line"></div>
              </div>

              <div style={{ paddingTop: '20px' }}>
                <p className="body-header"><span>Student Details</span></p>
                <div style={{ paddingTop: '16px' }}>
                  <p>Head Count<span style={{ color: 'red' }}>*</span></p>
                  <Input
                    type="number"
                    maxLength={50}
                    name="headCount"
                    placeholder="Enter head count"
                    value={formik.values.headCount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.headCount && formik.errors.headCount && (
                    <div className="error">{formik.errors.headCount}</div>
                  )}
                </div>
                <div className="divider-line"></div>
              </div>
            </div>
          </form>
        </Drawer>

        <Modal
          title="Deletion"
          open={isModalVisible}
          onOk={confirmDelete}
          onCancel={onModalClose}
        >
          <p>Are you sure want to delete ?</p>
        </Modal>

        <Modal
          title="Update Confirmation"
          open={isUpdateModalVisible}
          onCancel={closeUpdateModal}
          footer={
            <div>
              <Button className="primary-cancel-button" onClick={closeUpdateModal}>Cancel</Button>
              <Button className="primary-submit-button" type="default" onClick={updateData}>Update</Button>
            </div>
          }
        >
          <p>Are you sure you want to update?</p>
        </Modal>

        <Modal
          title="Create Confirmation"
          open={isSubmitModalVisible}
          onCancel={() => setSubmitModalVisible(false)}
          footer={
            <div>
              <Button className="primary-cancel-button" onClick={() => setSubmitModalVisible(false)}>Cancel</Button>
              <Button className="primary-submit-button" type="default" onClick={() => handleSubmit(formik.values)}>Create</Button>
            </div>
          }
        >
          <p>Are you sure you want to Create?</p>
        </Modal>

      </div>
    </div>
  );
};

export default InstitutionMaster;