import { Button, Checkbox, Col, DatePicker, Divider, Drawer, Image, Input, Modal, Row, Select, Radio } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import centumLogo from '../asset/image/centumlogocoloured.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getAllUnAssignTest, assignunPublishedTest, assignunPublishedTestPerticularStudent } from './reducers/institutionMasterSlice';
import { getCourseMasterTable } from './reducers/CourseMasterSlice'
import { getBatchMasterTable, getStudentByBatch } from './reducers/BatchMasterSlice'
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import moment from 'moment';
import QuestionAndAnswerPDF from './printPdf';
import { getEncryptedLocalStorage } from '../utils/utils';


const PurchasedTest = () => {
  const { isLoading } = useSelector((state) => (state.InstitutionSlice))
  const { courseMasterTableData } = useSelector((state) => (state.CourseSlice))
  const { batchMasterData = [], studentDetails } = useSelector((state) => (state.BatchSlice))
  const [currentTitle, setCurrentTitle] = useState("")
  const [visible, setVisible] = useState(false);
  const [getUnAssignTest, setGetUnAssigneTest] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const institutionId = getEncryptedLocalStorage("institutionId")
  const [drawerValues, setDrawerValues] = useState({})
  const [isSingleTestPaperValue, SetIsSingleTestPaperValue] = useState(false);
  const [checkBatchStudent, setCheckBatchStudent] = useState(1);
  const navigate = useNavigate()

  const { Option } = Select;
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllUnAssignTest(institutionId)).unwrap().then((resp) => setGetUnAssigneTest(resp));
    dispatch(getCourseMasterTable(institutionId));
  }, [])

  const handleDataFromChild = (data) => {
    if (data) {
      dispatch(getAllUnAssignTest(institutionId)).unwrap().then((resp) => setGetUnAssigneTest(resp));
    }
  };

  const openDrawer = (values) => {
    setVisible(true)
    setDrawerValues(values);
    formik.resetForm({ values: initialValues })
    SetIsSingleTestPaperValue(values.isSingleTestPaper)
  }
  const onModalClose = () => {
    setIsModalVisible(false);
  }
  const onModalOpen = () => {
    setIsModalVisible(true);
  }
  const initialValues = {
    assignToCourse: '',
    batch: '',
    testDate: '',
    testTime: '',
    passMark: '',
    testTakenTime: '',
    isActive: '',
    testId: '',
    student: []
  }
  let validationSchema = yup.object({
    assignToCourse: yup.string().required("Course is Required"),
    batch: yup.string().required("Batch is Required"),
    testDate: yup.string().required("Test valid Date is Required"),
    testTime: yup.string().required("Test Start Time  is Required"),
    passMark: yup.number()
      .integer('Mark must be an integer')
      .positive('Mark must be a positive number')
      .required('Mark is required')
  })


  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })
  function onSubmit(values) {
    if (checkBatchStudent == 1) {
      setIsModalVisible(false);
      let datas = {
        institutionId: institutionId,
        testId: drawerValues.testId,
        id: drawerValues?.inspurtestId,
        data: {
          courseId: +values.assignToCourse,
          batchId: +values.batch,
          testDate: values.testDate,
          startTime: values.testTime,
          passMark: +values.passMark,
          testTakenTime: +values.testTakenTime,
          shareViaEmail: values.isActive,
          isSingleTestPaper: isSingleTestPaperValue == 1 ? true : false,
          allStudent: true
        }
      }
      dispatch(assignunPublishedTest(datas)).unwrap().then((res) => {
        if (res.tabledata.success) {
          setVisible(false);
          formik.resetForm({ values: initialValues })
          navigate('/PublishedTest')
        }
      })
    } else {
      let datas = {
        institutionId: institutionId,
        testId: drawerValues.testId,
        id: drawerValues?.inspurtestId,
        data: {
          courseId: +values.assignToCourse,
          batchId: +values.batch,
          testDate: values.testDate,
          startTime: values.testTime,
          passMark: +values.passMark,
          testTakenTime: +values.testTakenTime,
          shareViaEmail: values.isActive,
          student: values.student,
          isSingleTestPaper: isSingleTestPaperValue == 1 ? true : false,
          allStudent: false
        }
      }
      dispatch(assignunPublishedTestPerticularStudent(datas)).unwrap().then((res) => {
        console.log('res', res);
        if (res.tabledata.success) {
          setVisible(false);
          formik.resetForm({ values: initialValues })
          navigate('/PublishedTest')
        }
      })
    }
  }
  function onClose() {
    setVisible(false);
  }
  const onChangeSelect = (e, fieldHelpers) => {
    fieldHelpers.setValue(e)
  }
  const onChangeCheckBatchStudent = (e) => {
    console.log('radio checked', e.target.value);
    if (e.target.value == 2) {
      let obj = {
        pagination: -1,
        searchValue: '',
        limit: 50
      }
      dispatch(getStudentByBatch({ data: obj, institutionId: institutionId, courseId: formik.values.assignToCourse, batchId: formik.values.batch }));
    }
    setCheckBatchStudent(e.target.value);
  };

  useEffect(() => {
    dispatch(getBatchMasterTable(formik.values.assignToCourse));
  }, [formik.values.assignToCourse]);
  return (
    <>{
      isLoading ? <div className='loading-container'>
        <Loading></Loading>
      </div>
        :
        <div className='exam-master-page'>
          <div>
            <div className='exam-header'>
              <div><h3 className='primary-header course-header'>Unpublished Tests </h3></div>
            </div>
            <div className='purchasedTest-container'>
              <div className='purchasedTest-wrapper'>
                <Row>
                  <>
                    {
                      getUnAssignTest?.map((data, index) => {
                        return (
                          <Col xs={12} sm={12} md={8} lg={8}>
                            <div className='purchased-container'>
                              <div className='purchased-body'>
                                <p className='test-name'><span>Group</span></p>
                                <p className='test-group-name'><span>{data.testName}</span></p>
                                <div className='split-container'>
                                  <p className='total-questions'>{data.numberOfQuestions} Questions</p>
                                  <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                                  <p className='total-questions'>{data.totalMark} Marks</p>
                                  <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                                  <p className='total-questions'>{data.timeLimit} Mins</p>

                                </div>
                                <Divider type="horizontal" className='unpublished-divider' />
                                <div className='published-footer'>
                                  <div className='publish-footer'>
                                    <Button className='primary-submit-button publish-button' onClick={() => openDrawer(data)}>Assign</Button>
                                  </div>
                                  <div className='test-remains-container'>
                                    <p >Test Remains : <span>{data.unAssignCount}/{data.numberOfTest}</span></p>
                                  </div>
                                </div>
                                {
                                  data ? <div className='print-out-question'>
                                    <QuestionAndAnswerPDF data={data} sendDataToParent={handleDataFromChild} />
                                  </div> : ''
                                }
                              </div>
                            </div>
                          </Col>
                        )
                      })
                    }

                  </>
                </Row>
              </div>
              <Drawer className='test-batch-assign' closable={false} title={currentTitle} placement="right" onClose={() => onClose()} open={visible} footer={(
                <div className='footer'>
                  <div className='footer-button'>
                    <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose()}>Cancel</Button>
                    <Button type='primary' className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} onClick={() => onModalOpen()} >Publish</Button>
                  </div>
                </div>
              )}>
                <div>
                  <div className='assign-test-container'>
                    <div className='assign-test-wrapper'>
                      <div className='assign-test-body'>
                        <p className='test-name'><span>{drawerValues.groupName}</span></p>
                        <p className='test-group-name'><span>{drawerValues.testName}</span></p>
                        <div className='split-container'>
                          <p className='total-questions'>{drawerValues.numberOfQuestions} Questions</p>
                          <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                          <p className='total-questions'>{drawerValues.totalMark} Marks</p>
                          <Divider style={{ background: '#7A8B94', marginTop: 3 }} type='vertical'></Divider>
                          <p className='total-questions'>{drawerValues.timeLimit} Mins</p>
                        </div>
                      </div>
                      <div className='image-body'>
                        <Image src={centumLogo} preview={false}></Image>
                      </div>
                    </div>
                    <div className='test-remains-body'>
                      <p>Test Remains : {drawerValues.unAssignCount}/{drawerValues.numberOfTest}</p>
                    </div>
                  </div>
                  <div>
                    <form>
                      <div>
                        <div className='form-control'>
                          <label>Assign to Course <span style={{ color: 'red' }}>*</span></label>
                          <Select mode="single" id="assignToCourse" name="assignToCourse" placeholder="Select a Course" onChange={(e) => { onChangeSelect(e, formik.getFieldHelpers('assignToCourse')) }} onBlur={formik.handleBlur} value={formik.values.assignToCourse} >
                            {courseMasterTableData.map((data, index) => (
                              <Option key={index} value={data?.id}>{data.name}</Option>
                            ))}
                          </Select>
                          {formik.touched['assignToCourse'] && formik.errors.assignToCourse ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.assignToCourse}</div> : null}
                        </div>
                        <div className='form-control'>
                          <label>Batch <span style={{ color: 'red' }}>*</span></label>
                          <Select mode="single" id="batch" name="batch" placeholder="Select Batch" onChange={(e) => { onChangeSelect(e, formik.getFieldHelpers('batch')) }} onBlur={formik.handleBlur} value={formik.values.batch} >
                            {batchMasterData?.map((data, index) => (
                              <Option key={index} value={data?.id}>{data.name}</Option>
                            ))}
                          </Select>
                          {formik.touched['batch'] && formik.errors.batch ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.batch}</div> : null}
                        </div>
                        <div className='check-radio-button'>
                          <Radio.Group onChange={onChangeCheckBatchStudent} value={checkBatchStudent}>
                            <Radio value={1}>All</Radio>
                            <Radio value={2}>Single</Radio>
                          </Radio.Group>
                        </div>
                        {
                          checkBatchStudent == 2 ? <>
                            <div className='form-control'>
                              <label>Student <span style={{ color: 'red' }}>*</span></label>
                              <Select mode="multiple" id="student" name="student" placeholder="Select a Student" onChange={(e) => { onChangeSelect(e, formik.getFieldHelpers('student')) }} onBlur={formik.handleBlur} value={formik.values.student} >
                                {studentDetails?.map((data, index) => (
                                  <Option key={index} value={data?.id}>{data.name}</Option>
                                ))}
                              </Select>
                            </div>
                          </> : ''
                        }
                        <div className='assign-date-container'>
                          <div style={{ width: '50%' }}>
                            <div className='form-control'>
                              <label>Test Date <span style={{ color: 'red' }}>*</span></label>
                              <Input type='date' maxLength={50} placeholder='Select a Date' id='testDate' name='testDate' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.testDate} min={moment().format('YYYY-MM-DD')}></Input>
                              {formik.touched.testDate && formik.errors.testDate ? <div className='error'>{formik.errors.testDate}</div> : null}
                            </div>
                          </div>
                          <div>
                            <div className='form-control test-valid-content'>
                              <label>Start Time <span style={{ color: 'red' }}>*</span></label>
                              <Input type='time' maxLength={50} id='testTime' name='testTime' placeholder='Enter a Start Time' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.testTime} min={moment().format('HH:mm')}></Input>
                              {formik.touched.testTime && formik.errors.testTime ? <div className='error'>{formik.errors.testTime}</div> : null}
                            </div>
                          </div>
                        </div>
                        <div className='form-control'>
                          <label>Pass Mark <span style={{ color: 'red' }}>*</span></label>
                          <Input type='text' id='passMark' placeholder='Enter pass mark' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.passMark} ></Input>
                          {formik.touched['passMark'] && formik.errors.passMark ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.passMark}</div> : null}
                        </div>
                       
                          <div className='form-control'>
                            <label>How many times student can take test?
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Input type='text' id='testTakenTime' placeholder='Enter TakeTest Times ' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.testTakenTime} ></Input>
                            {formik.touched['testTakenTime'] && formik.errors.testTakenTime ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.testTakenTime}</div> : null}
                          </div>
                      
                        <div className='form-control'>
                          <Checkbox onChange={formik.handleChange} checked={formik.values.isActive} name="isActive" >Share via email to students <span style={{ color: 'red' }}>*</span></Checkbox>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Drawer>
              <Modal title='Confirmation' open={isModalVisible} onCancel={onModalClose} footer={
                <div>
                  <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
                  <Button className='primary-submit-button' type='default' onClick={() => onSubmit(formik.values)}>Confirm</Button>
                </div>
              }>
                <p>Are you sure do you want to Publish the test<span className='highlight-text'> </span>  ?</p>
              </Modal>
            </div>
          </div>
        </div>
    }

    </>
  )
}
export default PurchasedTest;