
import { uploadPart, completeMultipartUpload } from '../components/reducers/MultipartUploadSlice.js';
import { EFieldStatus } from './Variable.js';

export const uploadFilePartsHelper = async (file, objectKey, uploadId, totalParts, partSize, dispatch) => {
    const uploadPromises = [];

    for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        const start = (partNumber - 1) * partSize;
        const end = partNumber * partSize;
        const partBlob = file?.slice(start, end);

        uploadPromises.push(
            dispatch(uploadPart({ file: partBlob, objectKey, uploadId, partNumber })).unwrap()
        );
    }

    return await Promise.all(uploadPromises);
};

export const completeFileUploadHelper = async (objectKey, uploadId, parts, dispatch) => {
    const uniqueParts = Array.from(new Set(parts.map(part => part.PartNumber)))
        .map(item => parts.find(part => part.PartNumber === item))
        .sort((a, b) => a.PartNumber - b.PartNumber);

    return await dispatch(completeMultipartUpload({ objectKey, uploadId, parts: uniqueParts })).unwrap();
};

export const constructFormData = async ({ file, objectKey, uploadId, partNumber }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('objectKey', objectKey);
    formData.append('uploadId', uploadId);
    formData.append('partNumber', partNumber.toString());

    return formData;
};

export const updateFileLocationHelper = async (selectedFile, fileList, setFile, response, formik, fieldName) => {

    const fileIndex = fileList?.findIndex(f => f?.uid === selectedFile?.uid);

    if (fileIndex !== -1) {

        const updatedFile = {
            uid: fileList[fileIndex].uid,
            name: fileList[fileIndex].name,
            size: fileList[fileIndex].size,
            type: fileList[fileIndex].type,
            fileLocation: { ...response }
        };

        await new Promise(resolve => {
            setFile(prevFiles => {
                const updatedFiles = [...prevFiles];
                updatedFiles[fileIndex] = updatedFile;
                resolve(updatedFiles);
                return updatedFiles;
            });
        });

        const getFile = await new Promise(resolve => {
            setFile(prevFiles => {
                resolve(prevFiles);
                return prevFiles;
            });
        });

        await formik.setFieldValue(fieldName, getFile);
    };
};

export const removePrefix = (objectKey) => {
    if (!objectKey) {
        return objectKey;
    }

    const index = objectKey.indexOf('/');
    if (index !== -1) {
        return objectKey.substring(index + 1);
    };

    return objectKey;
};

export const extractFileLocations = async (files) => {
  let fileMapping =
    files?.map((f) => {
      const {
        fileLocation,
        size = 0,
        type = "",
        name = "",
        thumbnail = [],
      } = f || {};

      let thumbnailData = thumbnail?.[0] || {};
      const { fileLocation: thumbnailFileLocation = {} } = thumbnailData || {};      

      if (fileLocation) {
        const { eTag, ...rest } = fileLocation;
        const { duration = "" } = rest || {};
        const { eTag: thumbnailETag, ...thumbnailRest } = thumbnailFileLocation || {};

        if (Object.values(thumbnailFileLocation || {})?.length) {
          thumbnailData = {
            ...thumbnailRest,
            size: thumbnailData["size"] || 0,
            type: thumbnailData["type"] || "",
            name: thumbnailData["name"] || "",
          };
        }

        return {
            ...rest,
            size,
            type,
            name,
            fieldStatus: EFieldStatus.NEW,
            ...(duration && { duration: Number(duration?.toFixed(4)) }),
            ...(Object.values(thumbnailData)?.length && {
            thumbnail: thumbnailData,
          }),
        };
      } else if (Object.values(thumbnailFileLocation)?.length || {}) {

        const { eTag, ...thumbnailRest } = thumbnailFileLocation || {};

        const thumbnail = {
          ...thumbnailRest,
          id: f["id"],
          size: thumbnailData["size"] || 0,
          type: thumbnailData["type"] || "",
          name: thumbnailData["name"] || "",
        };

        return {
          fieldStatus: EFieldStatus.EDITED,
          thumbnail: thumbnail,
        };
      }

      return null;
    }) || [];

  fileMapping = fileMapping?.filter((fileLocation) => fileLocation);

  return fileMapping;
};
