import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";

export const insertGroupClusterTable = createAsyncThunk(
  'coachmi/insertGroupClusterTable', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    await axios.post('coachmi/createGroupCluster', payload.data)
      .then(async (res) => {
        const { success, message } = res?.data;
        if (success) {
          resMessage = message;
          await thunk.dispatch(getGroupClusterTable(payload.obj));
          await thunk.dispatch(getClusterGroupFilter());
          await thunk.dispatch(getClusGroupDateFilter());
        resSuccess = success;
        };

        return {
          resSuccess,
          resMessage
        };
      })
      .catch((error) => {
        resMessage  = error?.response?.data?.message
        message.error(resMessage)
      });
 }
);

export const updateClusterGroupDetails = createAsyncThunk(
  'coachmi/updateClusterGroupDetails', async (payload, thunk) => {
    let resSuccess = false;
    let resMessage = '';
    await axios.put('coachmi/updateClusterGroupDetails/' + payload.id + '/' + payload?.mappingId, payload.data)
      .then(async (res) => {
        const { success, message } = res?.data;
        if (success) {
          resMessage = message;
          resSuccess = success;
          await thunk.dispatch(getGroupClusterTable(payload.obj));
          await thunk.dispatch(getClusterGroupFilter());
          await thunk.dispatch(getClusGroupDateFilter());
        };
        return { resSuccess, resMessage };
      })
      .catch((error) => {
        resMessage = error?.response?.data?.message
        message.error(resMessage)
      })
      
 }
);

export const deleteGroupClusterTable = createAsyncThunk("coachmi/deleteGroupClusterTable", async (payload, thunk) => {
  let resSuccess = false;
  let resMessage = '';
  try {
     await axios.put(`coachmi/deleteGroupCluster/${payload.id}`).then(async (res) => {
      const { success, message } = res?.data;
      resSuccess = success;
      resMessage = message;
      if (success) {
        await thunk.dispatch(getGroupClusterTable(payload.obj));
        await thunk.dispatch(getClusterGroupFilter());
        await thunk.dispatch(getClusGroupDateFilter());
      }
    })
  } catch (error) {
    resMessage = error?.response?.data?.message
  }
  return { resSuccess, resMessage };
 }
);

export const getGroupClusterTable = createAsyncThunk(
  'coachmi/getGroupClusterTable',
  async (payload, thunk) => {
    try {
      let tableData = [];
      let tableTotalCount;
      let resSuccess = false;
      let resMessage = '';
      await axios.post('coachmi/getAllClusterGroup', payload)
        .then(async (res) => {
          const { success, data, message } = res.data;
          const { listData, dataCount } = data;
          resSuccess = success
          resMessage = message;
          tableData = listData;
          tableTotalCount = dataCount;
        })
        .catch((error) => {
          resMessage = error?.response?.data?.message
        })
        return { tableData, tableTotalCount, resSuccess, resMessage };
      } catch (error) {
      throw error;
    };
 }
);

export const getClusterGroupById = createAsyncThunk("coachmi/getClusterGroupById", async (payload) => {
  try {
    let getUser = await axios.get(`/coachmi/getClusterGroupById/${payload}`).then((res) => {
      return res?.data;
    }).catch((error)=> {
    })
    return getUser
  } catch (error) {
  }
 } 
);

export const getClusterGroupFilter = createAsyncThunk(
  'coachmi/getClusterGroupFilter', async (payload, thunk) => {
    try {
      let response = [];
      await axios.get('coachmi/getUniqueClusterGroupName')
        .then(async (res) => {
          const { data } = res?.data;
          response = data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {
    }
 }
);

export const getFrequency = createAsyncThunk('pricing/getFrequency', async (payload, thunk) => {
  try {
    const Frequency = await axios.get('masters/getCoachmiFrequency').then(async (res) => {
      if (res.data) {
        return res.data.data
      };
    })
    return Frequency
  } catch (error) {

  }
 }
);

export const getClusterName = createAsyncThunk('masters/getClusterName', async (payload, thunk) => {
  try {
    const ClusterName = await axios.get('masters/getAllCluster').then(async (res) => {
      const { status, data } = res?.data;
        return data
    })
    return ClusterName
  } catch (error) {
  }
 }
);

export const getClusGroupDateFilter = createAsyncThunk(
  'cluster/getClusGroupDateFilter', async (payload, thunk) => {
    try {
      let response = [];
      const publicData = await axios.get('coachmi/getUniqueClusGroupDate')
        .then(async (res) => {
          const { status, data } = res?.data;
          response = data;
        })
        .catch((error) => {
        })
      return response;
    } catch (error) {
    }
 }
);

const initialState = {
  clusterGroupMasterTableData: [],
  isLoading: false,
  examDataCount: 0,
  tableTotalCount: 0,
  frequencyData: [],
  getClusterNameData: [],
  clusterGroupCreatedAt: [],
  clusterGroupNameFilter: [],
  clusterGroupById: {}
};

const ClusterGroupMaster = createSlice({
  name: 'GroupMaster',
  initialState,
  extraReducers: {
    [insertGroupClusterTable.pending]: (state) => {
      state.isLoading = true
    },
    [insertGroupClusterTable.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [insertGroupClusterTable.rejected]: (state) => {
      state.isLoading = false
    },
    [getFrequency.pending]: (state) => {
      state.isLoading = true
    },
    [getFrequency.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.frequencyData = actions?.payload
    },
    [getFrequency.rejected]: (state) => {
      state.isLoading = false
    },
    [getClusterName.pending]: (state) => {
      state.isLoading = true
    },
    [getClusterName.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.getClusterNameData = actions.payload
    },
    [getClusterName.rejected]: (state) => {
      state.isLoading = false
    },
    [getGroupClusterTable.pending]: (state) => {
      state.isLoading = true
    },
    [getGroupClusterTable.fulfilled]: (state, actions) => {
      state.isLoading = false
      const { tableData, tableTotalCount } = actions.payload;
      state.clusterGroupMasterTableData = tableData;
      state.examDataCount = tableTotalCount;
    },
    [getGroupClusterTable.rejected]: (state) => {
      state.isLoading = false
    },
    [getClusterGroupById.pending]: (state, action) => {
      state.clusterGroupById = {}
      state.isLoading = true
    },
    [getClusterGroupById.fulfilled]: (state, action) => {
      state.clusterGroupById = action?.payload?.data
      state.isLoading = false
    },
    [getClusterGroupById.rejected]: (state, action) => {
      state.isLoading = false
    },
    [updateClusterGroupDetails.pending]: (state) => {
      state.isLoading = true
    },
    [updateClusterGroupDetails.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [updateClusterGroupDetails.rejected]: (state) => {
      state.isLoading = false
    },
    [deleteGroupClusterTable.pending]: (state) => {
      state.isLoading = true
    },
    [deleteGroupClusterTable.fulfilled]: (state, actions) => {
      state.isLoading = false
    },
    [deleteGroupClusterTable.rejected]: (state) => {
      state.isLoading = false
    },
    [getClusterGroupFilter.pending]: (state) => {
      state.isLoading = true
    },
    [getClusterGroupFilter.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.clusterGroupNameFilter = actions?.payload
    },
    [getClusterGroupFilter.rejected]: (state) => {
      state.isLoading = false
    },
    [getClusGroupDateFilter.pending]: (state) => {
      state.isLoading = true
    },
    [getClusGroupDateFilter.fulfilled]: (state, actions) => {
      state.isLoading = false
      state.clusterGroupCreatedAt = actions?.payload
    },
    [getClusGroupDateFilter.rejected]: (state) => {
      state.isLoading = false
    },

  }
 }
);

export default ClusterGroupMaster.reducer;