/* eslint-disable */
import { DeleteOutlined, DownloadOutlined, MailOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Form, Input, Modal, Select, Spin, Image, Tabs, message, Menu, Collapse } from 'antd'
import { FieldArray, Field, Formik, getIn, useFormik } from 'formik';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestionData, deleteQuestionData, getAllQuestion, getQuestionById, updateQuestionData, excelUpload, ApprovalQuestionStatus, getComprehensionById, deleteComprehensionData, movingQuestion, getAllExamList, getAllSubjectList, getAllChapterList, copyAndPasteQuestion } from './reducers/questionSlice';
import TableComponent from './Table/tableComponent';
import * as yup from 'yup';
import { Option } from 'antd/lib/mentions';
import { LoadingOutlined, ToTopOutlined } from '@ant-design/icons';
import DeleteImage from '../asset/image/deleteIcon.svg';
import './styles/questionBank.scss'

import { getAllChapter, getAllDifficulty, getAllExam, getAllQuestionType, getAllSubject, getChapterBySubject, getSubjectByExamId } from './reducers/masterSlice';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Loading from './Loading';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Editor from "./Editor";
import { addStyles, EditableMathField, StaticMathField } from "react-mathquill";
import 'react-quill/dist/quill.snow.css';
import exportAsImage from '../utils/utils';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import BreadCrumb from './BreadCrumb';
import { getAllTypeData } from './reducers/dashboardSlice';
import file from '../asset/file/sampleFile.xlsx'
import axios from "axios";
// import MathType from './mathType';
import DynamicQuesionArray from './dynamicQuesionArray';
import FormList from 'antd/lib/form/FormList';

import { getExamMasterTable } from './reducers/ExamMasterSlice';

import BuildEditor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { getEncryptedLocalStorage } from '../utils/utils';


addStyles();


const { Panel } = Collapse;
const CUSTOM_OPERATORS = [
  ["\\pm", "\\pm"],
  ["\\sqrt{x}", "\\sqrt"],
  ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
  ["\\sqrt[n]{x}", "\\nthroot"],
  ["\\frac{x}{y}", "\\frac"],
  ["\\sum^{s}_{x}{d}", "\\sum"],
  ["\\prod^{s}_{x}{d}", "\\prod"],
  ["\\coprod^{s}_{x}{d}", "\\coprod"],
  ["\\int^{s}_{x}{d}", "\\int"],
  ["\\binom{n}{k}", "\\binom"]
];

const { TextArea } = Input;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const QuestionBank = () => {
  const arrayOfQuestion = useRef([]);
  const arrayOfSolution = useRef([]);
  const exportRef = useRef();
  const questionQuill = useRef();
  const comprehensionQuill = useRef();
  const solutionQuill = useRef();
  const [latex, setLatex] = useState("\\frac{1}{\\sqrt{2}}\\cdot 2");
  // const editorContainerRef = useRef();
  let realEditor;

  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const paramsQuestionTypeId = searchParams?.get('questionTypeId')

  const dispatch = useDispatch()
  const hiddenFile = useRef(null);
  const hiddenImage = useRef(null);
  const [currentId, setCurrentId] = useState(null)
  const [currentTitle, setCurrentTitle] = useState("")
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const [filterData, setFilterData] = useState("");
  const [questionTypeData, setQuestionTypeData] = useState([{ id: 1, name: "Normal" }, { id: 2, name: "Comprehension", }]);
  const [error, setError] = useState(0);
  const [imageOpened, setImageOpened] = useState(false);
  const [isQuestionFormula, setIsQuestionFormula] = useState(false);
  const [uploadImage, setUploadImage] = useState();
  const [image, setImage] = useState(null);
  const [arrayAnswer, setArrayAnswer] = useState([]);
  const [buttonState, setButtonState] = useState(1);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false)
  const [isOpenMath, setIsOpenMath] = useState(false)
  const [isApproval, setIsApproval] = useState(false)
  const [showRemarks, setShowRemarks] = useState(false)
  const [remarksValue, setRemarksValue] = useState(null)
  const [queryParams, setQueryParams] = useState(0)
  const [queryParamsName, setQueryParamsName] = useState('')
  const [difficulty, setDifficulty] = useState()
  const [defaultQuestionTypeId, setDefaultQuestionTypeId] = useState(1)
  const location = useLocation()
  const [editorContent, setEditorContent] = useState('');
  const [isClickedMoved, setIsClickedMoved] = useState(false)
  const [isClickedCopy, setIsClickedCopy] = useState(false)
  const [isMoveTitle, setIsMoveTitle] = useState('')


  const [dummyImage, setDummyImage] = useState(null);
  const imageUrl = "https://centumapp.s3.ap-south-1.amazonaws.com/public/Image1679308352753_bill-receipt.jpg"
  const init = {
    questionTypeId: defaultQuestionTypeId,
    difficultyLevelId: difficulty,
    isFree: false,
    questionText: '',
    questionOption: [],
    solution: ''
  }

  const [initialValues, setInitialValues] = useState(init)

  const { TextArea } = Input;
  const options = { displayHistory: true, operators: CUSTOM_OPERATORS };
  const roleId = getEncryptedLocalStorage("roleId")
  const [status, setStatus] = useState();
  const [currentFindObj, setCurrentFindObj] = useState(() => {
    if (roleId == 5) {
      return { id: 1, statusId: 1, name: 'pending', displayName: "Pending" }
    } else {
      return { id: 5, statusId: 2, name: 'approved', displayName: "Approved" }
    }
  })
  const [currentEquation, setCurrentEquation] = useState(questionQuill);
  const { questionData, currentQuestion, isQuestionLoading, totalDataCount, breadCrumbData, getAllExamListData, getAllSubjectListData, getAllChapterListData } = useSelector((state) => state.QuestionSlice)
  const { subjectData, difficultLevelData, chapterData, examData, typeOfQuestion, subjectDataByExam, objSubjectByChapter } = useSelector((state) => state.MasterSlice)
  const { listData, isDashLoading } = useSelector((state) => state.DashboardSlice)



  const { examMasterTableData } = useSelector((state) => state.ExamMaster);

  let [defaultOption, setDefaultOption] = useState(0)
  let nodeComprehensive = React.createRef();
  let nodeNormal = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      nodeComprehensive.current
    ]);
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      nodeNormal.current
    ]);
  }

  
  useEffect(() => {
    dispatch(getAllDifficulty())

    if (!params?.statusName) {
      setStatus(currentFindObj.id)
      let obj = {
        pagination: -1,
        searchValue: filterData,
        limit: limit
      }
      let data = {
        obj,
        examId: params.examId,
        subjectId: params.subId,
        chapterId: params.chapterId,
        questionTypeId: defaultQuestionTypeId,
        status: currentFindObj


      }
      let masterObj = {
        pagination: -1,
        searchValue: "",
        limit: 0
      }
      dispatch(getAllQuestion(data))
      dispatch(getAllSubject(masterObj))
      dispatch(getAllExam(masterObj))
    }
    else {
      setDefaultQuestionTypeId(paramsQuestionTypeId)
      let payload = {
        filterType: params?.statusName,
        type: 'question',
        questionType: paramsQuestionTypeId

      }
      dispatch(getAllTypeData(payload))
    }


  }, [])

  useEffect(() => {
    setInitialValues(currentQuestion)
  }, [currentQuestion])

  //   useEffect(() => {
  //   const wirisEditor = com.wiris.jsEditor.JsEditor.newInstance({'language': 'en','toolbar':'chemistry'});
  //   if(editorContainerRef?.current){
  //     wirisEditor.insertInto(editorContainerRef?.current);
  //   }
  //    realEditor =  wirisEditor
  // },[isOpenMath]);

  const [validationSchema, setValidationSchema] = useState(() => {
    return yup.object().shape({
      questionTypeId: yup.number().notRequired(),
      difficultyLevelId: yup.number().required("Difficulty Level is Required"),
      // isFree: yup.boolean().required("isFree is Required"),
      questionParagraphText: yup.string().when('questionTypeId', {
        is: 2,
        then: yup.string().required("Comprehension Text is Required"),
        otherwise: yup.string().notRequired()
      }),
      questions: yup.array().when('questionTypeId', {
        is: 2,
        then: yup.array().of(
          yup.object().shape({
            questionText: yup.string().required("Question Text is Required"),
            questionOption: yup.array().min(2).of(
              yup.object().shape({
                questionOption: yup.string().required("Question Option is Required")
              })
            ).required("Question Option is Required"),
            solution: yup.string().required("Solution is Required")
          })
        ).min(1, "At least one question is required"),
        otherwise: yup.array().notRequired()
      }),
      questionText: yup.string().when('questionTypeId', {
        is: 1,
        then: yup.string().required("Question Text is Required"),
        otherwise: yup.string().notRequired()
      }),
      questionOption: yup.array().when('questionTypeId', {
        is: 1,
        then: yup.array().min(2, "At least two options are required").of(
          yup.object().shape({
            questionOption: yup.string().required("Question Option is Required")
          })
        ).required("Question Option is Required"),
        otherwise: yup.array().notRequired()
      }),
      solution: yup.string().when('questionTypeId', {
        is: 1,
        then: yup.string().required("Solution is Required"),
        otherwise: yup.string().notRequired()
      })
    });
  })




  // const validationSchema = yup.object({
  //   difficultyLevelId: yup.string().required("Difficulty Level is Required"),
  //   questionText: yup.string().required("Question Text is Required"),
  //   questionOption: yup.array().of(
  //     yup.object().shape({
  //       questionOption: yup.string().required("Question Option is Required")
  //     })
  //   ),
  //   solution: yup.string().required("Question Text is Required"),



  // })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsVisible(false)
      let questionData = {}
      if (defaultQuestionTypeId == 1) {
        questionData = {
          subjectId: params?.subId,
          chapterId: params?.chapterId,
          examId: params?.examId,
          statusName: params?.statusName,
          difficultyLevelId: values.difficultyLevelId,
          questionText: values.questionText,
          questionOption: values.questionOption,
          questionTypeId: defaultQuestionTypeId,
          solution: values.solution,
          isFree: values.isFree ? true : false,
          file: image
        }

      } else {
        questionData = {
          subjectId: params?.subId,
          chapterId: params?.chapterId,
          examId: params?.examId,
          statusName: params?.statusName,
          difficultyLevelId: values.difficultyLevelId,
          questionParagraphText: values.questionParagraphText,
          questions: values.questions,
          isFree: values.isFree ? true : false,
          questionTypeId: defaultQuestionTypeId,
          file: image
        }
      }


      let data = {
        payload: questionData,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
          status: currentFindObj

        }
      }
      // console.log("this is submitted data == ",data)
      dispatch(addQuestionData(data))
      formik.resetForm()
      setUploadImage(null)


    },
    enableReinitialize: true
  })


  const remarkInitialValues = { remarks: '' }
  const remarkValidationSchema = yup.object({
    remarks: yup.string().required("Remarks is required")
  })

  const remarkFormik = useFormik({
    initialValues: remarkInitialValues,
    validationSchema: remarkValidationSchema
  })


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMoveModalVisible, setIsMoveModalVisible] = useState(false);
  const [isCopyModalVisible, setIsCopyModalVisible] = useState(false);
  const [isModalFile, setIsModalFile] = useState(false);
  const [isVisible, setIsVisible] = useState(false)
  const [isMoveVisible, setIsMoveVisible] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [excel, setExcel] = useState()

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: '6%',
      render: (_, record, i) => {
        return (
          <div className='index-id'>{(i + 1) + (paginationData > 0 ? (paginationData) * limit : 0)}
          </div>
        )
      },
    },
    {
      title: defaultQuestionTypeId == 1 ? "Question Text" : "Comprehension Text",
      dataIndex: defaultQuestionTypeId == 1 ? "questionText" : "questionParagraphText",
      key: defaultQuestionTypeId == 1 ? "questionText" : "questionParagraphText",
      render: (_, record) => {
        return (
          <div className='question-text-wrapper' dangerouslySetInnerHTML={{ __html: defaultQuestionTypeId == 1 ? record.questionText : record.questionParagraphText }}>
          </div>
        )
      },
    },
    {
      title: "Subject",
      dataIndex: "subjectName",
      key: "subjectName",
    },
    {
      title: "Chapter",
      dataIndex: "chapterName",
      key: "chapterName",
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)} >Edit</a>
            {
              roleId == 3 && !params.statusName ?
                <Tooltip title="Delete" style={{ padding: 0 }}>
                  <IconButton>
                    <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
                  </IconButton>
                </Tooltip>
                : null
            }

          </div>
        )
      },
    },

  ]


  let tableHeight = 320;

  const editData = (record) => {

    if (record?.isApproval) {
      setIsApproval(true)
    } else {
      setIsApproval(false)
    }
    if (record?.remarks != null) {
      setRemarksValue(record?.remarks)
    } else {
      setRemarksValue(null)
    }

    setUploadImage(record.questionImage)
    setCurrentId(record.id)
    setButtonState(2)
    setCurrentTitle("Edit Question")
    if (defaultQuestionTypeId == 1) {
      dispatch(getQuestionById(record.id)).unwrap().then((res) => {
        setIsVisible(true)
      })
    } else {
      dispatch(getComprehensionById(record.id)).unwrap().then((res) => {
        setIsVisible(true)
      })
    }

    let selectQuestion = currentQuestion
  }
  const deleteData = (record) => {
    setIsModalVisible(true)
    setCurrentId(record.id)
  }


  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit

    }
    let data = {
      obj,
      examId: params?.examId,
      subjectId: params?.subId,
      chapterId: params?.chapterId,
      statusName: params?.statusName,
      questionTypeId: defaultQuestionTypeId,
      status: currentFindObj

    }
    if (!params?.statusName) {
      dispatch(getAllQuestion(data))
    }


  }

  const onSearch = (value) => {
    setFilterData(value)
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit

    }
    let data = {
      obj,
      examId: params?.examId,
      subjectId: params?.subId,
      chapterId: params?.chapterId,
      statusName: params?.statusName,
      questionTypeId: defaultQuestionTypeId,
      status: currentFindObj

    }
    debounced(data)

  }
  const debounced = useDebouncedCallback((data) => {
    if (!params?.statusName) {
      dispatch(getAllQuestion(data))
    }

  }, 1000)

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const openDrawer = () => {
    setRemarksValue(null)
    if (defaultQuestionTypeId == 2) {
      setInitialValues({
        questionTypeId: defaultQuestionTypeId,
        difficultyLevelId: '',
        isFree: false,
        questionParagraphText: '',
        questions: []
      });
    } else {
      setInitialValues({
        questionTypeId: defaultQuestionTypeId,
        difficultyLevelId: '',
        isFree: false,
        questionText: '',
        questionOption: [],
        solution: '',
      });
    }
    setDifficulty()
    setIsApproval(false)
    setUploadImage()
    setButtonState(1)

    setCurrentId(null)
    setIsVisible(true)
    setCurrentTitle("New Question")
    formik.resetForm()
    setArrayAnswer([])
  }

  const modalOnCancel = () => {
    setIsModalVisible(false)
  }
  const modalOnOk = () => {
    setIsModalVisible(false)
    if (defaultQuestionTypeId == 1) {
      dispatch(deleteQuestionData(currentId))
    } else {
      dispatch(deleteComprehensionData(currentId))

    }

  }

  const onCancelFileModal = () => {
    setIsModalFile(false)
  }
  const onOkFileModal = () => {
    setIsModalFile(false)

  }

  const drawerCloser = () => {
    setIsVisible(false)
  }
  const onChangeDifficulty = (e, fieldHelpers) => {
    fieldHelpers.setValue(e)
    setDifficulty(e)
  }
  const onChangeQuestionType = (e) => {
    setDefaultQuestionTypeId(e)
    if (e == 2) {
      setInitialValues({
        questionTypeId: 2,
        difficultyLevelId: difficulty,
        isFree: false,
        questionParagraphText: '',
        questions: []
      });
    } else {
      setInitialValues({
        questionTypeId: 1,
        difficultyLevelId: difficulty,
        isFree: false,
        questionText: '',
        questionOption: [],
        solution: ''
      });
    }


    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit
    }
    let data = {
      obj,
      examId: params.examId,
      subjectId: params.subId,
      chapterId: params.chapterId,
      questionTypeId: e,
      status: currentFindObj

    }
    dispatch(getAllQuestion(data))

  }
  const optionsOnChange = (i, e) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['questionOption'][i]['questionOption'] = e.target.value
    formik.setValues(tempValues)
  }
  const optionsDynamicOnChange = (index, i, e) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues.questions[index]['questionOption'][i]['questionOption'] = e.target.value
    formik.setValues(tempValues)
  }
  const optionsOnChecked = (i, e) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues.questionOption.forEach((option, index) => {
      if (e.target.checked) {
        if (index != i) {
          option['value'] = false
        }
      }
    })
    tempValues['questionOption'][i]['value'] = e.target.checked
    formik.setValues(tempValues)
    if (!e.target.checked) {
      formik.isValid = false;
    }
  }
  const optionsDynamicOnChecked = (index, i, e) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues.questions[index].questionOption.forEach((option, index) => {
      if (e.target.checked) {
        if (index != i) {
          option['value'] = false
        }
      }
    })
    tempValues.questions[index]['questionOption'][i]['value'] = e.target.checked
    if (e.target.checked) {
      // setAnswer(true)
      setArrayAnswer(
        arrayAnswer.map((item, i) => (i === index ? e.target.checked : false))
      );
    }
    // else{
    //   setAnswer(false)

    // }

    formik.setValues(tempValues)
  }
  const addNewRow = () => {
    // console.log(formik.values)
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    if (tempValues['questionOption']?.length) {
      tempValues['questionOption'].push({ questionOption: '', id: null, value: false })

    } else {
      tempValues['questionOption'] = []
      tempValues['questionOption'].push({ questionOption: '', id: null, value: false })

    }
    formik.setValues(tempValues)
  }
  const addDynamicNewRow = (index) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues?.questions[index]['questionOption'].push({ questionOption: '', id: null, value: false })
    formik.setValues(tempValues)
  }
  const addQuestions = () => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    if (tempValues['questions']?.length) {
      tempValues['questions'].push({ questionId: null, questionText: '', questionOption: [], solution: '' })
    } else {
      tempValues['questions'] = []
      tempValues['questions'].push({ questionId: null, questionText: '', questionOption: [], solution: '' })
    }
    formik.setValues(tempValues)
    setArrayAnswer([...arrayAnswer, false]);
  }
  const dynamicRemove = (index, i) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues.questions[index]['questionOption'][i]['value'].splice(index, 1)
    formik.setValues(tempValues)

  }
  const remove = (index) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues['questionOption'].splice(index, 1)
    formik.setValues(tempValues)

  }
  const updateData = () => {

    let questionUpdate = {}
    if (defaultQuestionTypeId == 1) {
      questionUpdate = {
        subjectId: params?.subId ? params?.subId : currentQuestion.subjectId,
        chapterId: params?.chapterId ? params?.chapterId : currentQuestion.chapterId,
        examId: params?.examId ? params?.examId : currentQuestion.examId,
        statusName: params?.statusName,
        difficultyLevelId: formik.values.difficultyLevelId,
        questionText: formik.values.questionText,
        questionOption: formik.values.questionOption,
        questionTypeId: defaultQuestionTypeId,
        solution: formik.values.solution,
        isFree: formik.values.isFree ? true : false,
        // file: image
      }

    } else {
      questionUpdate = {
        subjectId: params?.subId ? params?.subId : currentQuestion.subjectId,
        chapterId: params?.chapterId ? params?.chapterId : currentQuestion.chapterId,
        examId: params?.examId ? params?.examId : currentQuestion.examId,
        statusName: params?.statusName,
        difficultyLevelId: formik.values.difficultyLevelId,
        questionParagraphText: formik.values.questionParagraphText,
        questions: formik.values.questions,
        isFree: formik.values.isFree ? true : false,
        questionTypeId: defaultQuestionTypeId,
        // file: image
      }
    }
    let payload = {
      id: currentId,
      data: questionUpdate
    }
    setIsVisible(false)
    let data = {
      payload: payload,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
        status: currentFindObj


      }
    }
    if (params.statusName) {

    }
    dispatch(updateQuestionData(data))

  }
  function excelFileUpload(event) {
    var fileFilter = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
    if (fileFilter.includes(event.target.files[0].type)) {
      var excelFile = new FormData();
      excelFile.append("excelFile", event.target.files[0])

      let data = {
        excelFile: excelFile,
        examId: params.examId,
        subjectId: params.subId,
        chapterId: params.chapterId,
        status: currentFindObj
      }
      dispatch(excelUpload(data))
      setIsModalFile(false)
    }
    else {
      message.warn("excel file only allowed")

    }
  }

  // image upload

  function imageUpload(e) {
    var fileFilter = ["image/png", "image/jpg", "image/jpeg"]
    let imageSize = (e.target.files[0].size) / 1000000
    let uploadImage = URL.createObjectURL(e.target.files[0])
    setUploadImage(uploadImage)
    if (fileFilter.includes(e.target.files[0].type) && imageOpened == true && imageSize < 1) {
      setImage(e.target.files[0])
      setError(false)
    }
    if (!fileFilter.includes(e.target.files[0].type)) {
      setError(1)
      setImage(null)
    }
    if (imageSize > 1) {
      setError(2)
      setImage(null)
    }
  }

  function imageTouched() {
    setImageOpened(true)
    hiddenImage.current.click()
  }

  function onDrawerClose() {
    setIsVisible(false)
    setUploadImage(null)
  }

  const enterEquation = () => {
    exportAsImage(exportRef.current, "test", questionQuill)
    setIsQuestionFormula(false)

  }
  const onQuestionCancel = () => {
    setIsQuestionFormula(false)

  }
  const onQuestionOk = () => {
    setIsQuestionFormula(false)

  }

  const onTabsChange = (key) => {
  }

  // reject function
  const onReject = () => {
  }

  // approve funcction
  const onApprove = () => {
  }

  const viewQuestion = (record) => {
    setIsVisible(true);
    setButtonState(3);
    setCurrentTitle("Questions");
    setUploadImage(record.questionImage)
    setCurrentId(record.id)
    dispatch(getQuestionById(record.id)).unwrap().then((res) => {
      setIsVisible(true)

    })
    let selectQuestion = currentQuestion
  }

  const reject = () => {
    setIsModalVisible(true)
    setIsRejectModalVisible(true)
  }

  const approve = () => {
    setIsVisible(false)

    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit
    }
    let data = {
      obj,
      examId: params.examId,
      subjectId: params.subId,
      chapterId: params.chapterId,
      status: currentFindObj
    }

    let payload = {
      questionId: currentId,
      statusId: 2,
      data: {
        remarks: "strings"
      },
      getAllData: data
    }
    dispatch(ApprovalQuestionStatus(payload))

  }

  const addRemarks = () => {
    setIsModalVisible(false)
    setIsRejectModalVisible(false)
    remarkFormik.resetForm()
    setIsVisible(false)
    let obj = {
      pagination: -1,
      searchValue: filterData,
      limit: limit
    }
    let data = {
      obj,
      examId: params?.examId,
      subjectId: params?.subId,
      chapterId: params?.chapterId,
      statusName: params?.statusName,
      status: currentFindObj

    }
    let payload = {
      questionId: currentId,
      statusId: 3,
      questionTypeId: defaultQuestionTypeId,
      data: {
        remarks: remarkFormik.values.remarks
      },
      getAllData: data
    }
    dispatch(ApprovalQuestionStatus(payload)).unwrap().then(res => {
      if (res.status) {
        message.success(res.message)
      }
    })

  }

  const [superAdminRoleArray, setSuperAdminRoleArray] = useState([
    { id: 1, statusId: 1, name: 'pendingForApproval', displayName: "Pending For Approval" },
    { id: 3, statusId: 3, name: 'rejectedForApproval', displayName: "Rejected For Approval" },
    { id: 5, statusId: 2, name: 'approved', displayName: "Approved" }
  ]);

  const [roleArray, setRoleArray] = useState([
    { id: 1, statusId: 1, name: 'pendingForApproval', displayName: "Pending For Approval" },
    { id: 2, statusId: 1, name: 'pending', displayName: "Pending" },
    { id: 3, statusId: 3, name: 'rejectedForApproval', displayName: "Rejected For Approval" },
    { id: 4, statusId: 3, name: 'rejected', displayName: "Rejected" },
    { id: 5, statusId: 2, name: 'approved', displayName: "Approved" }
  ]);
  const [dataRoleArray, setDataRoleArray] = useState([
    { id: 1, statusId: 1, name: 'pending', displayName: "Pending" },
    { id: 2, statusId: 3, name: 'rejected', displayName: "Rejected" },
    { id: 3, statusId: 2, name: 'approved', displayName: "Approved" }
  ]);


  const statusChanged = (e) => {
    let findObj
    if (roleId == 5) {
      findObj = dataRoleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)
    } else {
      findObj = roleArray.find((data) => data.id == e)
      setCurrentFindObj(findObj)

    }
    setStatus(e)
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
    let data = {
      obj,
      examId: params.examId,
      subjectId: params.subId,
      chapterId: params.chapterId,
      questionTypeId: defaultQuestionTypeId,
      status: findObj

    }
    dispatch(getAllQuestion(data))
  }

  const onModalClose = () => {
    setIsModalVisible(false);
    setIsRejectModalVisible(false)
    remarkFormik.resetForm()
  }
  const comprehensionImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.addEventListener('change', async (e) => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      insertUploadImage(comprehensionQuill, formData)
    });
    input.click();
  }

  const questionTextImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.addEventListener('change', async (e) => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      insertUploadImage(questionQuill, formData)
    });
    input.click();
  }
  const solutionImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.addEventListener('change', async (e) => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      insertUploadImage(solutionQuill, formData)
    });
    input.click();
  }
  const arrayOfQuestionTextImage = (index) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.addEventListener('change', async (e) => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      insertUploadImage(arrayOfQuestion, formData)
    });
    input.click();
  }
  const arrayOfSolutionTextImage = (index) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.addEventListener('change', async (e) => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      insertUploadImage(arrayOfSolution, formData)
    });
    input.click();
  }

  const questionEquation = () => {
    setIsQuestionFormula(true)
    setCurrentEquation(questionQuill)
    setIsOpenMath(true)

  }
  const comprehensionEquation = () => {
    setIsQuestionFormula(true)
    setCurrentEquation(comprehensionQuill)
    setIsOpenMath(true)

  }
  const solutionEquation = () => {
    setIsOpenMath(true)
    setIsQuestionFormula(true)
    setCurrentEquation(solutionQuill)

  }

  const container = [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'formula'],
    ['clean']
  ]
  const questionModule = useMemo(() => (
    {
      toolbar: {
        container,
        handlers: {
          image: questionTextImage,
          formula: questionEquation,
        }
      }
    }
  ), [])
  const comprehensionModule = useMemo(() => (
    {
      toolbar: {
        container,
        handlers: {
          image: comprehensionImage,
          formula: comprehensionEquation,
        }
      }
    }
  ), [])
  const solutionModule = useMemo(() => (
    {
      toolbar: {
        container,
        handlers: {
          image: solutionImage,
          formula: solutionEquation,

        }
      }
    }
  ), [])

  const arrayOfQuestionModule = useMemo(() => (
    {
      toolbar: {
        container,
        handlers: {
          image: arrayOfQuestionTextImage,
          formula: questionEquation,
        }
      }
    }
  ), [])

  const arrayOfSolutionModule = useMemo(() => (
    {
      toolbar: {
        container,
        handlers: {
          image: arrayOfSolutionTextImage,
          formula: questionEquation,
        }
      }
    }
  ), [])

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'formula'
  ]

  const insertUploadImage = async (editorRef, formData) => {
    await axios.post('/masters/customFileUpload', formData).then((res) => {
      const imageAppend = res.data.data.url
      if (res.data.status) {
        const editor = editorRef.current.editor;
        const imageElement = new editor.model.schema.nodes.image({
          src: imageAppend,
          alt: 'image',
        });
        editor.model.insertContent(imageElement);

      }
      return { default: res.data.data.url }
    }).catch((error) => {
    })
  }

  const prevsShowData = async () => {
    setIsQuestionFormula(false)
    setIsOpenMath(false)

    await axios.post('question/equationUpload', { data: realEditor.getMathML() }).then((res) => {
      const questionEditor = currentEquation.current.getEditor();
      var range = questionEditor.getSelection();
      if (res.data.status) {
        range = !range ? questionEditor.getLength() : range.index
        questionEditor.insertEmbed(range, 'image', res.data.url);
      }
    })
  }

  const showData = async () => {
    setIsQuestionFormula(false)
    setIsOpenMath(false)
    await axios.post('question/equationUpload', { data: realEditor.getMathML() }).then((res) => {
      if (res.data.status) {
        const imageUrl = res.data.url;
        const editor = currentEquation.current.editor;
        editor.execute('insertImage', { source: imageUrl });
      }
    })
  }

  const openEquationModal = () => {
    setIsQuestionFormula(true)
  }
  const arrayOfQuestionOnChange = (value, index) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues.questions[index]['questionText'] = value
    formik.setValues(tempValues)

  }
  const arrayOfSolutionOnChange = (value, index) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))
    tempValues.questions[index]['solution'] = value
    formik.setValues(tempValues)

  }
  const findIndexValue = (index) => {
  }
  const checkArrayAnswer = (data, indexArg, i) => {
    setArrayAnswer(prevArray => {
      const newArray = [...prevArray]; // create a copy of the previous array
      newArray[indexArg] = data ? data : false; // update the value at the specified index
      return newArray; // r

    });

  }

  const keyPressed = (index) => {
  }
  const checkAllArr = () => {
    const allTrue = arrayAnswer.every((value) => {
      return value === true;
    });
    return allTrue
  }
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const questionRemove = (i) => {
    let tempValues = JSON.parse(JSON.stringify(formik.values))

    tempValues['questions'].splice(i, 1)
    setArrayAnswer(prevArray => {
      const newArray = [...prevArray]; // create a copy of the previous array
      newArray.splice(i, 1); // remove the element at the specified index
      return newArray;
    });
    formik.setValues(tempValues)

    // setArrayAnswer([...arrayAnswer, false]);
  }
  const openMoveDrawer = () => {
    setIsClickedMoved(true)
    setIsClickedCopy(false)
    setIsMoveTitle('Move Question')

    setIsMoveVisible(true)
    moveFormik.resetForm()
  }
  const openCopyDrawer = () => {
    setIsMoveTitle('Copy And Paste Question')

    setIsClickedCopy(true)
    setIsClickedMoved(false)

    setIsMoveVisible(true)

    // setIsCopyModalVisible(true)

    moveFormik.resetForm()
  }
  const onMoveDrawerClose = () => {
    setIsMoveVisible(false)

  }
  const onMoveSubmitted = () => {
    if (!moveFormik.dirty || !moveFormik.isValid) {
      moveFormik.setTouched({
        examId: true,
        subjectId: true,
        chapterId: true
      })
    } else {
      setIsMoveModalVisible(true)
    }
  }

  const onCopySubmitted = () => {
    if (!moveFormik.dirty || !moveFormik.isValid) {
      moveFormik.setTouched({
        examId: true,
        subjectId: true,
        chapterId: true
      })
    } else {
      setIsCopyModalVisible(true)
    }
  }

  const modalMoveOnCancel = () => {
    setIsMoveModalVisible(false)
  }
  const modalMoveOnOk = () => {
    console.log("move")
    setIsClickedMoved(false)
    setIsMoveModalVisible(false)
    setIsMoveVisible(false)
    let obj = {
      data: {
        currentExamId: +moveFormik.values.examId,
        currentSubjectId: +moveFormik.values.subjectId,
        currentChapterId: +moveFormik.values.chapterId,
        prevExamId: +params.examId,
        prevSubjectId: +params.subId,
        prevChapterId: +params.chapterId,
        questionIds: selectedRowKeys
      },
      getAllData: {
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        },
        examId: params.examId,
        subjectId: params.subId,
        chapterId: params.chapterId,
        status: currentFindObj


      }
    }
    dispatch(movingQuestion(obj))
    setIsClickedMoved(false)

    setSelectedRowKeys([])
  }

  useEffect(() => {
    let obj2 = {
      pagination: -1,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getAllExamList());
  }, [])


  const moveInit = {
    examId: '',
    subjectId: '',
    chapterId: '',
  }
  const [moveInitialValues, setMoveInitialValues] = useState(moveInit)
  const moveValidationSchema = yup.object({
    examId: yup.string().required("Exam is Required"),
    subjectId: yup.string().required("Subject is Required"),
    chapterId: yup.string().required("Chapter is Required")
  })

  const moveFormik = useFormik({
    initialValues: moveInitialValues,
    validationSchema: moveValidationSchema,
    onSubmit: (values) => {
    },
    enableReinitialize: true
  })

  const onExamChange = (e) => {
    moveFormik.setFieldValue("examId", e)

    let obj = {
      pagination: -1,
      searchValue: "",
      limit: 10,
      status: { statusId: -1 }
    }
    let data = {
      examId: e,
      obj
    }
    dispatch(getAllSubjectList(e))
    moveFormik.values.chapterId = ""
    moveFormik.values.subjectId = ""

  }
  const onChangeSubject = (e) => {
    moveFormik.setFieldValue("subjectId", e)

    let url = {
      examId: moveFormik.values.examId,
      subId: e,
    }
    let obj = {
      pagination: -1,
      searchValue: "",
      limit: limit,
    }
    let data = {
      url, obj
    }
    dispatch(getAllChapterList(e))
    moveFormik.values.chapterId = ""

  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append("file", file);
            axios
              .post("/masters/customFileUpload", formData)
              .then((res) => {
                const imageUrl = res.data.data.url;
                setUploadImage(imageUrl);
                resolve({ default: imageUrl });
              })
              .catch((error) => {
                reject(error);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const insertUploadImage11 = (imageUrl) => {
    const editor = questionQuill.current.editor;
    const imageElement = new editor.model.schema.nodes.image({
      src: imageUrl,
      alt: "image",
    });
    editor.model.insertContent(imageElement);
  };
  const addEquation = () => {
    setIsQuestionFormula(true)
    // setCurrentEquation(questionQuill)
    setIsOpenMath(true)

  }

  // const addSolutionEquation = ()=>{
  //   setIsQuestionFormula(true)
  //   // setCurrentEquation(solutionQuill)
  //   setIsOpenMath(true)

  // }

  const checkButton = () => {
    const imageUrl = "https://centumapp.s3.ap-south-1.amazonaws.com/public/Image1680249863746_two.jpg";
    const editor = questionQuill.current.editor;

    editor.execute('insertImage', { source: "https://centumapp.s3.ap-south-1.amazonaws.com/public/Image1680249863746_two.jpg" });
  }

  const modalCopyOnCancel = () => {
    setIsCopyModalVisible(false)
  }

  const modalCopyOnOk = () => {
    console.log("copy")
    setIsCopyModalVisible(false)
    setIsClickedCopy(false)
    setIsMoveVisible(false)

    let obj = {
      data: {
        currentExamId: +moveFormik.values.examId,
        currentSubjectId: +moveFormik.values.subjectId,
        currentChapterId: +moveFormik.values.chapterId,
        questionIds: selectedRowKeys
      },
      getAllData: {
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        },
        examId: params.examId,
        subjectId: params.subId,
        chapterId: params.chapterId,
        status: currentFindObj


      }
    }
    dispatch(copyAndPasteQuestion(obj))
    setIsClickedCopy(false)

    setSelectedRowKeys([])
  }


  return (
    <>
      <div className='questionBank-page'>
        <div className='breadCrumb-Data'>
          <BreadCrumb data={breadCrumbData} loading={isQuestionLoading}></BreadCrumb>
        </div>
        <div className='questionBankHeader'>

          <div className='questionBankHeaderWrapper'>
            <h3 className='primary-header' >Question Bank</h3>

          </div>
          <div className='quesitonBank-button' >
            {
              !params.statusName ?
                <>
                  {
                    defaultQuestionTypeId == 1 ?
                      <Button style={{ marginBottom: 20 }} className='primary-submit-button' onClick={() => setIsModalFile(true)} >Bulk Upload</Button>
                      :
                      null
                  }

                  <Button style={{ marginBottom: 20 }} id="newQuestion" className='primary-submit-button' onClick={openDrawer}>New Question </Button>
                </>
                : null

            }
          </div>
        </div>
        <div className='all-table-component' style={{ padding: 0, margin: 0 }}>
          <div className="filter-tabs">
          </div>
          <div className="table-header">
            <div className='list-dropdown' >
              {
                !params?.statusName ?
                  <div>

                    <Select id="status" name="status" className='' placeholder="Select Status" defaultValue={roleId == 5 ? 0 : 5} onChange={statusChanged} value={status}  >
                      {
                        roleId == 5 ? dataRoleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                          : roleId == 3 ? superAdminRoleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                            : roleArray.map((e, i) => <Option value={e.id} key={i} >{e.displayName}</Option>)
                      }
                    </Select>
                  </div>

                  : null
              }
              {
                !params?.statusName ?
                  <div>

                    <Select id="questionTypeId" name="questionTypeId" style={{ width: "100%", marginLeft: 10 }} placeholder="Choose Option" defaultActiveFirstOption={true} defaultValue={defaultQuestionTypeId} onChange={(e) => onChangeQuestionType(e)} value={defaultQuestionTypeId} >
                      {questionTypeData.map((data, index) => (
                        <Option key={index} value={data.id}>{data.name}</Option>
                      ))}
                    </Select>
                  </div>

                  : null
              }

            </div>
            {
              !params?.statusName ?
                <div className="golbalSearch" >
                  {
                    selectedRowKeys.length && (roleId == 3 || roleId == 1) && defaultQuestionTypeId == 1 ?
                      <>
                        <Button prefix={<Image src={<DeleteOutlined />}></Image>} className='primary-cancel-button' onClick={() => openMoveDrawer()}>Move</Button>
                        <Button prefix={<Image src={<DeleteOutlined />}></Image>} className='primary-cancel-button' onClick={() => openCopyDrawer()}>Copy And Paste</Button>
                      </>
                      :
                      null
                  }

                  <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(event) => onSearch(event.target.value)} />
                </div>
                : null
            }

          </div>
          {
            !params.statusName ?
              <TableComponent dataSource={questionData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={totalDataCount} currentPage={paginationData + 1} loadingStatus={isQuestionLoading} limit={limit} isCheck={(roleId == 3 || roleId == 1) && defaultQuestionTypeId == 1 ? true : false} />
              :
              <TableComponent dataSource={listData} columns={columns} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={listData?.length ? listData.length : 0} currentPage={paginationData + 1} loadingStatus={isDashLoading} limit={limit} />

          }

        </div>
        <Drawer title={currentTitle} onClose={() => onDrawerClose()} width="2000px" closable={false} closeIcon={false} placement="right" open={isVisible} footer={
          (
            <div className='footer'>
              <div className='footer-button'>
                <Button className='primary-cancel-button' onClick={drawerCloser} >Cancel</Button>
                {
                  isApproval ?
                    <>
                      <Button className='reject-btn' onClick={() => reject()}>Reject</Button>
                      <Button className='approve-btn' style={{ margin: '0px 26px' }} onClick={updateData}>Approve</Button>
                    </>
                    :
                    <>
                      {
                        currentId ?
                          <>
                            {
                              roleId == 3 ? <Button className='reject-btn' onClick={() => reject()}>Reject</Button> : null
                            }
                            <Button className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} type="primary" disabled={!formik.isValid} onClick={updateData} >Update </Button>
                          </>
                          :
                          <>   {
                            defaultQuestionTypeId == 1 ?
                              <Button className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} type="primary" disabled={!formik.isValid} onClick={formik.handleSubmit} >Save </Button> :
                              <Button className={!formik.isValid || !formik.dirty || !checkAllArr() ? 'disabled-button' : 'primary-submit-button'} type="primary" disabled={!formik.isValid || !checkAllArr()} onClick={formik.handleSubmit} >Save </Button>

                          }

                          </>
                      }
                    </>
                }
              </div>
            </div>
          )
        } >
          <Form>
            {
              remarksValue ?
                <div className='form-control reject-remarks'>

                  <span > ** {remarksValue}</span>
                </div>
                : null

            }
            <br></br>
            <div className='form-control'>
              <Checkbox onChange={formik.handleChange} checked={formik.values.isFree} name="isFree" >Is Free</Checkbox>
            </div>
            <div className='form-control' >
              <label htmlFor='difficultyLevelId'>
                Difficulty Level :
              </label>
              <div>
                <Select id="difficultyLevelId" name="difficultyLevelId" style={{ width: "100%" }} placeholder="Choose Option" onChange={(e) => { onChangeDifficulty(e, formik.getFieldHelpers('difficultyLevelId')) }} onBlur={formik.handleBlur} value={formik.values.difficultyLevelId} >
                  {difficultLevelData.map((data, index) => (
                    <Option value={data.id}>{data.name}</Option>
                  ))}
                </Select>
              </div>
              {formik.touched.difficultyLevelId && formik.errors.difficultyLevelId ? <div className='errorMessage' style={{ color: 'red' }}>{formik.errors.difficultyLevelId}</div> : null}
            </div>
            {
              defaultQuestionTypeId == 1 ?
                <>
                  <div>
                  </div>

                  <div className='form-control'>
                    <label htmlFor='questionText'>
                      Question Text  :
                    </label>
                    <CKEditor
                      editor={BuildEditor}
                      name="questionText"
                      id='questionText'
                      ref={questionQuill}
                      config={{
                        removePlugins: ['CKFinder'],
                        extraPlugins: [uploadPlugin],

                      }}

                      onReady={editor => {
                      }}

                      data={formik.values.questionText}
                      onChange={(event, editor) => {
                        const questionDataText = editor.getData();
                        formik.setFieldValue("questionText", questionDataText)
                      }
                      }
                      onFocus={(event, editor) => {
                        setCurrentEquation(questionQuill)
                      }}


                    />
                    <div className="add-equation-wrapper">
                      {/* <Button type="primary" className='primary-submit-button' onClick={() => addEquation()}>Add Equation </Button> */}
                      {/* <Button type="primary" className='primary-submit-button' onClick={() => checkButton()}>Check Button </Button> */}
                    </div>

                    {/* <Modal title="Equation"  className='save-equation' open={isQuestionFormula} onCancel={onQuestionCancel} onOk={onQuestionOk}  >
                <div ref={editorContainerRef} />    
                <Button type="primary" className='primary-submit-button' onClick={() => showData()}>Save Equation</Button>

              </Modal> */}

            {
                      formik.values && formik.values.questionOption?.length && !formik.values.questionOption.some(option => option.value)?
                        <span className='answer-option-error-message'> Answer Must be select in the below option</span>
                        : null
                    }

                    {
                      formik.values && formik.values.questionOption ?
                        formik.values.questionOption.map((option, i) => {
                          const questionOption = `questionOption[${i}]`;
                          const touchedQuestion = getIn(formik.touched, questionOption);
                          const errorQuestion = getIn(formik.errors, `questionOption[${i}].questionOption`);
                          return (
                            <div className='question-option-wrapper' key={i}>
                              <div className="formik-question-option">
                                <Checkbox style={{ marginLeft: 10, marginRight: 10 }} onChange={(e) => { optionsOnChecked(i, e) }} checked={formik.values['questionOption'][i]['value']}  ></Checkbox>
                                <div className='formik-input-field'>
                                  <Input type="text" id={`questionOption.${i}`} name={`questionOption.${i}`} onChange={(e) => { optionsOnChange(i, e) }} onBlur={formik.handleBlur} placeholder="Options" value={formik.values['questionOption'][i]['questionOption']} ></Input>
                                </div>
                                <DeleteOutlined style={{ marginLeft: 10, marginRight: 10 }} onClick={() => remove(i)} />
                              </div>
                              {touchedQuestion && errorQuestion ? <div className='errorMessage answer-option-error-message' style={{ color: 'red' }}>{errorQuestion}</div> : null}

                            </div>
                          )
                        })
                        : ''
                    }
                    <span className='add-option' onClick={() => { addNewRow() }}>+ Add Option</span>
                  </div>
                  <div className='form-control solution'>
                    <label htmlFor='solution'>
                      Solution  :
                    </label>
                    <CKEditor
                      editor={BuildEditor}
                      name="solution"
                      id='solution'
                      ref={solutionQuill}
                      config={{
                        removePlugins: ['CKFinder'],
                        extraPlugins: [uploadPlugin],

                      }}

                      onReady={editor => {
                      }}

                      data={formik.values.solution}
                      onChange={(event, editor) => {
                        const solutionDataText = editor.getData();
                        formik.setFieldValue("solution", solutionDataText)
                      }
                      }

                      onFocus={(event, editor) => {
                        setCurrentEquation(solutionQuill)
                      }}
                    />

                    {/* <div>
                  <Button type="primary" className='primary-submit-button' onClick={() => addSolutionEquation()}>Add Equation </Button>
                  </div> */}
                    {/* <ReactQuill name="solution" ref={solutionQuill} theme="snow" id='solution' modules={solutionModule} formats={formats} placeholder="Solution Text" onChange={(value) => formik.setFieldValue("solution", value)} value={formik.values.solution} /> */}
                  </div>
                </>
                : defaultQuestionTypeId == 2 ?
                  <>
                    <div className='form-control'>
                      <label htmlFor='questionParagraphText'>
                        Comprehension  :
                      </label>
                      <CKEditor
                        editor={BuildEditor}
                        name="questionParagraphText"
                        id='questionParagraphText'
                        ref={comprehensionQuill}
                        config={{
                          removePlugins: ['CKFinder'],
                          extraPlugins: [uploadPlugin],

                        }}

                        onReady={editor => {
                        }}

                        data={formik.values.questionParagraphText}
                        onChange={(event, editor) => {
                          const comprehensiveDataText = editor.getData();
                          formik.setFieldValue("questionParagraphText", comprehensiveDataText)
                        }
                        }

                        onFocus={(event, editor) => {
                          setCurrentEquation(comprehensionQuill)
                        }}
                      />
                      {/* <ReactQuill name="comprehensionText" ref={comprehensionQuill} theme="snow" id='comprehensionText' modules={comprehensionModule} formats={formats} placeholder="Comprehension Text" onChange={(value) => formik.setFieldValue("questionParagraphText", value)} value={formik.values.questionParagraphText} /> */}
                      {/* <div  className="add-equation-wrapper">
                <Button type="primary" className='primary-submit-button' onClick={() => addEquation()}>Add Equation </Button> */}
                      {/* <Button type="primary" className='primary-submit-button' onClick={() => checkButton()}>Check Button </Button> */}
                      {/* </div>
              <Modal title="Equation"  className='save-equation' open={isQuestionFormula} onCancel={onQuestionCancel} onOk={onQuestionOk}  >
                <div ref={editorContainerRef} />    
                <Button type="primary" className='primary-submit-button' onClick={() => showData()}>Save Equation</Button>

              </Modal> */}

                    </div>
                    {
                      formik.values?.questions?.length ?

                        formik.values?.questions.map((e, index) => {
                          return (
                            <>
                              <Collapse accordion expandIconPosition='end'>
                                <Panel header={` Question ${index + 1}`} key={index + 1}>
                                  <div>

                                    <DynamicQuesionArray key={index} formik={formik} index={index} onCheckArr={checkArrayAnswer} editCheckAnswer={[true]}></DynamicQuesionArray>

                                    <p className="remove-question" onClick={() => questionRemove(index)} >Remove</p>

                                  </div>
                                </Panel>
                              </Collapse>
                            </>
                          )
                        })
                        :
                        null
                    }
                    <span className='add-option' onClick={() => { addQuestions() }}>+ Add Question</span>
                  </>
                  :
                  <>
                  </>
            }

          </Form>

        </Drawer>
        <Modal title='Delete' open={isModalVisible} onCancel={modalOnCancel} onOk={() => modalOnOk()}  >
          <p>Are you sure want to delete?</p>
        </Modal>

        <Modal className="question-upload-excel" title='File Upload' open={isModalFile} onCancel={onCancelFileModal} onOk={() => onOkFileModal()}  >
          <div>
            <div className='file-upload'>
              <Button onClick={() => hiddenFile.current.click()} style={{ background: '#f0f2f5', border: 'none', boxShadow: 'none', fontSize: 13, fontWeight: 500 }} icon={<DownloadOutlined />}>Import</Button>
              <input type="file" ref={hiddenFile} onChange={(event) => excelFileUpload(event)} hidden={true}></input>

            </div>
            <div className='download-upload' >
              <span>sample excel <a href={file} download>Download</a></span>
            </div>
          </div>
        </Modal>

        {/* reject modal */}

        <Modal title='Rejection' visible={isRejectModalVisible} onCancel={onModalClose} footer={
          <div>
            <Button className='primary-cancel-button' onClick={() => onModalClose()}>Cancel</Button>
            <Button type='default' className='primary-submit-button' id={!remarkFormik.isValid || !remarkFormik.dirty ? 'disabled-button' : null} disabled={!remarkFormik.isValid || !remarkFormik.dirty} onClick={() => addRemarks()}>OK</Button>
          </div>
        }>
          <div>
            <label htmlFor="remarks">Remarks <span style={{ color: 'red' }}>*</span> </label>
            <TextArea id='remarks' style={{ marginTop: 10, height: 176 }} onChange={remarkFormik.handleChange} onBlur={remarkFormik.handleBlur} value={remarkFormik.values.remarks} > </TextArea>
            {remarkFormik.touched.remarks && remarkFormik.errors.remarks ? <div className='error'>{remarkFormik.errors.remarks}</div> : null}
          </div>
        </Modal>

        {/* question movement */}

        <Drawer title={isMoveTitle} onClose={() => onMoveDrawerClose()} width="518px" closable={false} closeIcon={false} placement="right" open={isMoveVisible} footer={
          (
            <div className='footer'>
              <div className='footer-button'>
                <Button className='primary-cancel-button' onClick={() => onMoveDrawerClose()} >Cancel</Button>
                {
                  isClickedMoved ?
                    <Button className='approve-btn' style={{ margin: '0px 26px' }} onClick={() => onMoveSubmitted()}  >Move</Button>
                    :
                    null
                }
                {
                  isClickedCopy ?

                    <Button className='approve-btn' style={{ margin: '0px 26px' }} onClick={() => onCopySubmitted()}  >Copy and Paste</Button>
                    :
                    null
                }
                {/* <Button className='approve-btn' style={{margin:'0px 26px'}} onClick={()=> }  >Move</Button> */}

              </div>
            </div>
          )
        } >
          <Form>
            <div className='form-control' >
              <label htmlFor="examId">
                Exam
              </label>
              <div>
                <Select id={`examId`} name={`examId`} style={{ width: "100%" }} placeholder="Select Option" onChange={(e) => { onExamChange(e) }} onBlur={moveFormik.handleBlur} value={moveFormik.values.examId}   >
                  {getAllExamListData?.length ?
                    getAllExamListData?.map((data, index) => (
                      <Option value={data.id} key={data.id} >{data.name}</Option>
                    ))
                    : null}
                </Select>
                {moveFormik.touched?.examId && moveFormik.errors?.examId ? <div className='errorMessage' style={{ color: 'red' }}>{moveFormik.errors?.examId}</div> : null}

              </div>
            </div>
            <div className='form-control' >
              <label htmlFor='subjectId'>
                Subject <span style={{ color: 'red' }}>*</span>
              </label>
              <div>
                <Select id="subjectId" name="subjectId" placeholder="Select Option" onChange={(e) => onChangeSubject(e)} onBlur={moveFormik.handleBlur} value={moveFormik.values.subjectId} disabled={moveFormik.values.examId == ""}   >
                  {getAllSubjectListData.length ? getAllSubjectListData.map((data, index) => (
                    <Option value={data.id} key={data.id} >{data.name}</Option>
                  )) : null}
                </Select>
                {moveFormik.touched?.subjectId && moveFormik.errors?.subjectId ? <div className='errorMessage' style={{ color: 'red' }}>{moveFormik.errors?.subjectId}</div> : null}

              </div>
            </div>
            <div className='form-control' >
              <label htmlFor="chapterId">
                Chapter
              </label>
              <div>
                <Select id={`chapterId`} name={`chapterId`} style={{ width: "100%" }} placeholder="Select Option" onChange={(e) => moveFormik.setFieldValue("chapterId", e)} onBlur={moveFormik.handleBlur} value={moveFormik.values.chapterId} disabled={moveFormik.values.subjectId == ""}  >
                  {getAllChapterListData.length ?
                    getAllChapterListData.map((data, index) => (
                      <Option value={data.id} key={data.id} >{data.name}</Option>
                    ))
                    : null}
                </Select>
                {moveFormik.touched?.chapterId && moveFormik.errors?.chapterId ? <div className='errorMessage' style={{ color: 'red' }}>{moveFormik.errors?.chapterId}</div> : null}

              </div>
            </div>



          </Form>

        </Drawer>
        <Modal title='Confirmation' open={isMoveModalVisible} onCancel={modalMoveOnCancel} onOk={() => modalMoveOnOk()}  >
          <p>Are you sure to Move?</p>
        </Modal>
        <Modal title='Confirmation' open={isCopyModalVisible} onCancel={modalCopyOnCancel} onOk={() => modalCopyOnOk()}  >
          <p>Are you sure to Copy and Paste ?</p>
        </Modal>
      </div>
    </>
  )
}

export default QuestionBank