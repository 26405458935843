import React, { useState, useEffect, useRef } from 'react';
import { Image, Button, Drawer, Input, Row, Col, Popover, Modal } from 'antd';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import book from '../asset/image/Group.svg'
import Loading from './Loading';
import EllipseIcon from '../asset/image/ellipse_icon.svg'
import EditIcon from '../asset/image/edit_icon.svg'
import DeleteIcon from '../asset/image/delete_icon.svg'
import { getCourseMasterTable, insertCourseMasterTable, getCourseById, updateCourse, deleteCourse } from './reducers/CourseMasterSlice'
import { getEncryptedLocalStorage } from '../utils/utils';



const CourseMaster = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const params = useParams()
  const [currentTitle, setCurrentTitle] = useState("")
  const [visible, setVisible] = useState(false);
  const roleId = getEncryptedLocalStorage("roleId")
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50)
  const institutionId = getEncryptedLocalStorage("institutionId")
  const institutionAdmin = getEncryptedLocalStorage("institutionAdmin")

  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedCourse, setSelectCourse] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [isSubmitModalVisible,setSubmitModalVisible]=useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const openDrawer = () => {
    setSelectCourse(null)
    setVisible(true)
    setCurrentTitle("Course Creation")
    formik.resetForm({ values: initialValues })

  }
  const { courseMasterTableData, isLoading, courseDataCount } = useSelector((state) => (state.CourseSlice))

  useEffect(() => {

    dispatch(getCourseMasterTable(institutionId));
    // dispatch(addBreadCrumbData({}))
  }, [])

  const initialValues = { name: '' }
  let validationSchema = yup.object({
    name: yup.string()
      .required("Course Name is Required")
      .min(2, "Course Name Needed At Least Two characters")
      .max(100, "Course Name not more than 100 characters")
      // .matches(/^[A-Za-z0-9_@][A-Za-z0-9_@ ]*$/, 'Course Name can only contain alphabetic characters, numbers, "@" symbol, and underscore. Spaces are allowed only after the first character.'),
  })

  
  
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  })


  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

 
  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };
  function onSubmit(values) {
    setVisible(false);
    let data = {
      name: values.name,
      institutionDetailId: +institutionId
    }
    setSubmitModalVisible(false);
    dispatch(insertCourseMasterTable(data))
  }

  const handleFormValidation = (isValid) => {
    setIsFormValid(isValid);
  };
  function onClose(values) {
    setVisible(false);
  }
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const editData = (event) => {
    setSelectedCourseId(null);
    event.preventDefault();
    event.stopPropagation();
    dispatch(getCourseById(selectedCourseId)).unwrap().then((res) => {
      if (res) {
        formik.setValues(res)
        setVisible(true)
      }
    })
    setCurrentTitle("Edit Course")

  }
  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateCourseById = (values) => {
    setVisible(false)
    let datas = {
      id: values.id,
      data: {
        name: values.name,
        institutionDetailId: values.institutionDetailId,
        courseId: values.id
      }
    }
    setUpdateModalVisible(false);
    dispatch(updateCourse(datas)).unwrap().then((res) => {
      formik.setValues(initialValues)
      formik.resetForm()
    })
  }

  const handleOpenChange = (visible, data) => {
    if (visible) {
      setSelectCourse(data)
      setSelectedCourseId(data.id);
    } else {
      setSelectedCourseId(null);
    }
  };
  const deleteConfirmationModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDeleteId(selectedCourseId)
    setSelectedCourseId(null);
    setConfirmationModalOpen(true);
  };
  const deleteCourseOk = () => {
    setConfirmationModalOpen(false);
    /* api call send */
    console.log('deleteId', deleteId);
    let data = {
      id: deleteId,
      institutionId: institutionId
    }
    dispatch(deleteCourse(data)).unwrap().then((res) => {

    })

  };

  const deleteCourseCancel = () => {
    setConfirmationModalOpen(false);
  };

  function batchPageRedirect(courseId) {
    navigate(`/course/${courseId}/batch`)
  }

  const handlePopoverClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };


  const content = (
    <div>
      <div className='edit menu-option-icon' onClick={(event) => editData(event, selectedCourse)}>
        <Image src={EditIcon} preview={false}></Image>
        <p>Edit</p>
      </div>
      <div className='delete menu-option-icon' onClick={(event) => deleteConfirmationModal(event)}>
        <Image src={DeleteIcon} preview={false}></Image>
        <p>Delete</p>
      </div>
    </div>
  );

  return (
    <>
      {
        isLoading ? <div className='loading-container'>
          <Loading></Loading>
        </div> :
          <div className='exam-master-page'>
            <div>
              <div className='exam-header'>
                <div><h3 className='primary-header course-header'>Course </h3></div>
                <div className='add-exam'>
                  <span className='add-new-exam'><Button type='primary' className='primary-submit-button' onClick={() => openDrawer()}> Add Course</Button></span>
                </div>
              </div>
              <div className='course-body-container'>
                <div className='course-body-wrapper'>
                  <Row>
                    <>
                      {
                        courseMasterTableData?.map((data, index) => {
                          return (
                            <Col xs={12} sm={12} md={8} lg={6} >
                              <div className='new-course-container' onClick={() => batchPageRedirect(data.id)}>
                                <div className='course-name'>
                                  <p className='name'>{data.name}</p>
                                  <p className='number-batch'>Batches : {data.batchCound}</p>
                                </div>
                                <div className='right-image-icon'>
                                  <Popover
                                    content={content}
                                    placement="bottomRight"
                                    trigger="click"
                                    visible={selectedCourseId === data.id}
                                    onVisibleChange={(visible) => handleOpenChange(visible, data)}
                                  >
                                    <div className='course-ellipse' onClick={handlePopoverClick}>
                                      <Image src={EllipseIcon} preview={false}></Image>
                                    </div>
                                  </Popover>
                                  <div className='course-image'>
                                    <Image src={book} preview={false}></Image>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )
                        })
                      }
                    </>
                  </Row>
                </div>
              </div>

              <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose(formik.values)} open={visible} footer={(
                <div className='footer'>
                  <div className='footer-button'>
                    <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose(formik.values)}>Cancel</Button>
                    {
                      !selectedCourse ?
                       <Button className={!formik.isValid || !formik.dirty ?'disabled-button':'primary-submit-button'} type="primary" disabled={!formik.dirty || !formik.isValid} onClick={showSubmitConfirmation} >Add </Button> 

                        // <Button type='primary' className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} onClick={() => onSubmit(formik.values)}>Add</Button>
                        :
                        <Button type='primary' className={!formik.isValid || !formik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!formik.dirty || !formik.isValid} onClick={showUpdateConfirmation}>Update</Button>
                     
                    }

                  </div>
                </div>
              )}>
                <form onKeyDown={onKeyDown}>
                  <div>

                    <div className='form-control'>
                      <label>Course Name <span style={{ color: 'red' }}>*</span></label>
                      <Input maxLength={50} id='name' name='name' placeholder='Enter Course Name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}></Input>
                      {formik.touched.name && formik.errors.name ? <div className='error'>{formik.errors.name}</div> : null}
                    </div>
                  </div>
                </form>
              </Drawer>


     {/* add modal */}

       <Modal
        title="Add Confirmation" open={isSubmitModalVisible} onCancel={closeSubmitModal} footer={
          <div>
            <Button className="primary-cancel-button" onClick={closeSubmitModal}>Cancel  </Button>
            <Button className="primary-submit-button" type="default" onClick={() => onSubmit(formik.values)}> Add </Button>
          </div>
        }
      >
        <p>Are you sure you want to Add?</p>
      </Modal>
            {/* update model */}
        <Modal title='Update Confirmation' open={isUpdateModalVisible} onCancel={closeUpdateModal} footer={
          <div>
          <Button className='primary-cancel-button' onClick={closeUpdateModal}>Cancel</Button>
          <Button className='primary-submit-button' type='default' onClick={() =>updateCourseById(formik.values)} >Update</Button>
        </div>
        }>
          <p>Are you sure you want to update?</p>
        </Modal>


        {/* deletetion modal */}
        <Modal title='Deletion Confirmation' open={isConfirmationModalOpen} onCancel={deleteCourseCancel} footer={
          <div>
            <Button className='primary-cancel-button' onClick={() => deleteCourseCancel()}>Cancel</Button>
            <Button className='primary-submit-button' type='default' onClick={() => deleteCourseOk()}>OK</Button>
          </div>
        }>
          <p>Are you sure you want to delete?</p>
        </Modal>
            </div>
          </div>
      }
    </>
  );
}
export default CourseMaster;