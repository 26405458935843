import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Drawer,
  Modal,
  Table,
  Image,
  Tag,
  Form,
  Checkbox,
  message,
  Upload,
} from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikConsumer, useFormik } from "formik";
import * as yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteImage from "../asset/image/deleteIcon.svg";
import { useDebouncedCallback } from "use-debounce";
import TableComponent from "./Table/tableComponent";
import moment, { ISO_8601, utc } from "moment";
import {
  createAnnouncement,
  getAllAnnouncement,
  getAnnouncementById,
  updateAnnouncement,
  deleteAnnoncement,
} from "../components/reducers/AnnouncementSlice";
import Loading from "./Loading";

const Announcement = () => {
  const dispatch = useDispatch();
  let tableHeight = 320;
  const [paginationData, setPaginationData] = useState(0);
  const [filterData, setFilterData] = useState("");
  const [limit, setLimit] = useState(50);
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setSubmitModalVisible] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);
  const [isUpdateModalVisible, setUpdateModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [formDate, setFormDate] = useState(moment());
  const [record, setRecord] = useState({});
  const [file, setFile] = useState([]);

  const { currentData, isLoading, currentDataCount } = useSelector(
    (state) => state.Announcement
  );
  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit,
    };
    dispatch(getAllAnnouncement(obj));
  }, []);

  const initialValues = {
    announcementType: "",
    groupLogo: [],
    isActive: false,
  };
  const validationSchema = yup.object({
    announcementType: yup
      .string()
      .required("Announcement Type is Required")
      .min(2, "Announcement Type Needed At Least Two characters")
      .max(100, "Announcement Type not more than 100 characters"),
    groupLogo: yup
      .array()
      .min(1, "Image is Required")
      .required("Image is Required")
      .nullable(),
  });

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: "6%",
      render: (_, record, i) => {
        return (
          <div className="index-id">
            {i + 1 + (paginationData > 0 ? paginationData * limit : 0)}
          </div>
        );
      },
    },
    {
      title: "Announcement Type",
      dataIndex: "announcementType",
      key: "announcementType",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (_, record) => {
        return (
          <div>
            <span>
              {record.isActive == 1 ? (
                <Tag color="green">Active</Tag>
              ) : (
                <Tag color="red">InActive</Tag>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="table-action-link">
            <a style={{ paddingRight: 10 }} onClick={() => editData(record)}>
              Edit
            </a>
            <Tooltip style={{ padding: 0 }} title="Delete">
              <IconButton>
                <Image
                  src={DeleteImage}
                  preview={false}
                  onClick={() => deleteData(record)}
                ></Image>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const editData = (_data) => {
    setCurrentId(_data.id);
    setCurrentTitle("Edit Announcement");
    dispatch(getAnnouncementById(_data.id))
      .unwrap()
      .then((res) => {
        if (res) {
          setVisible(true);
          setFile([
            {
              url: res?.data?.data.imageUrl,
              thumbUrl: res?.data?.data.imageUrl,
            },
          ]);
          let tempValues = JSON.parse(JSON.stringify(res.data.data));
          formik.values["groupLogo"] = [tempValues["imageUrl"]];
          formik.values.announcementType = tempValues.announcementType;
          formik.values.isActive = tempValues.isActive;
        }
      });
  };

  const deleteData = (_data) => {
    setRecord(_data);
    setIsModalVisible(true);
    setCurrentId(_data.id);
  };

  const onSearch = (value) => {
    setFilterData(value);
    let obj = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    };
    debounced(obj);
  };
  const debounced = useDebouncedCallback((obj) => {
    dispatch(getAllAnnouncement(obj));
  }, 1000);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1);

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit,
    };

    dispatch(getAllAnnouncement(obj));
  };

  const openDrawer = () => {
    setFile([]);
    setCurrentId(null);
    formik.resetForm({ values: initialValues });
    setVisible(true);
    setCurrentTitle("Create Announcement");
  };

  const closeSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const showSubmitConfirmation = (values) => {
    setSubmittedData(values);
    setSubmitModalVisible(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false);
      let data = new FormData();
      data.append("announcementType", values.announcementType.trim());
      data.append("groupLogo", values.groupLogo[0].originFileObj);
      data.append("isActive", values.isActive);
      let payload = {
        data,
        obj: {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit,
        },
      };
      setSubmitModalVisible(false);
      dispatch(createAnnouncement(payload))
        .unwrap()
        .then((res) => {
          if (res.status) {
            setVisible(false);
          }
        })
        .catch((error) => {
          setVisible(true);
        });
    },
  });

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
  };

  const showUpdateConfirmation = () => {
    setUpdateModalVisible(true);
  };

  const updateData = () => {
    let data = new FormData();
    data.append("announcementType", formik.values.announcementType.trim());
    data.append("groupLogo", formik.values.groupLogo[0].originFileObj);
    data.append("isActive", formik.values.isActive == 1 ? true : false);
    let payload = {
      id: currentId,
      data,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };
    setUpdateModalVisible(false);
    setVisible(false);
    dispatch(updateAnnouncement(payload))
      .unwrap()
      .then((res) => {
          if (res?.status) {
            setVisible(false);
          }
          else{
            setVisible(true);
          }
      })
      .catch((error) => {
        setVisible(true);
      })
  };

  const modalOnCancel = () => {
    setIsModalVisible(false);
  };
  const modalOnOk = () => {
    setIsModalVisible(false);
    let data = {
      id: currentId,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit,
      },
    };
    dispatch(deleteAnnoncement(data))
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(getAllAnnouncement(data.obj));
        }
      });
  };
  const drawerCloser = () => {
    setVisible(false);
    formik.resetForm();
  };

  useEffect(() => {
    formik.validateForm();
  }, []);

  const filehandleChange = (file) => {
    if (file.fileList.length) {
      if (
        file.file.type == "image/jpeg" ||
        file.file.type == "image/jpg" ||
        file.file.type == "image/png"
      ) {
        setFile(file.fileList);
        formik.values.groupLogo = file.fileList;
      } else {
        message.error("please upload jpeg, jpg and png formats only ");
      }
    } else {
      setFile(file.fileList);
      formik.values.groupLogo = file.fileList;
      formik.validateForm();
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <Loading></Loading>
        </div>
      ) : (
        <div className="admin-page">
          <div className="adminHeader">
            <h3 className="primary-header">Announcement</h3>
            <div className="admin-button">
              <Button
                className="primary-submit-button"
                type="primary"
                onClick={openDrawer}
              >
                Add Announcement{" "}
              </Button>
            </div>
          </div>
          <div
            className="all-table-component"
            style={{ padding: 0, margin: 0 }}
          >
            <div className="golbalSearch">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ paddingRight: 5 }} />}
                style={{ width: 262, height: 32 }}
                onChange={(event) => onSearch(event.target.value)}
              />
            </div>
            <TableComponent
              dataSource={currentData}
              columns={columns}
              rowSelection={rowSelection}
              tableOnChange={tableOnChange}
              tableHeight={tableHeight}
              totalData={currentDataCount}
              currentPage={paginationData + 1}
              loadingStatus={isLoading}
              limit={limit}
            />
          </div>
          <Drawer
            title={currentTitle}
            onClose={() => drawerCloser()}
            width="518px"
            closable={false}
            closeIcon={false}
            placement="right"
            open={visible}
            footer={
              <div className="footer">
                <div className="footer-button">
                  <Button
                    className="cancel-Button primary-cancel-button"
                    onClick={drawerCloser}
                  >
                    Cancel
                  </Button>
                  {currentId == null ? (
                    <Button
                      className={
                        !formik.isValid || !formik.dirty
                          ? "disabled-button"
                          : "primary-submit-button"
                      }
                      type="primary"
                      disabled={!formik.dirty || !formik.isValid}
                      onClick={showSubmitConfirmation}
                    >
                      Submit{" "}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      className={
                        !formik.isValid || !formik.dirty
                          ? "disabled-button"
                          : "primary-submit-button"
                      }
                      disabled={!formik.dirty || !formik.isValid}
                      onClick={showUpdateConfirmation}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </div>
            }
          >
            <Formik validateOnChange={false} validateOnBlur={false}>
              <Form>
                <div className="form-control">
                  <label htmlFor="announcementType">
                    Announcement Type<span style={{ color: "red" }}> *</span>
                  </label>
                  <Input
                    type="text"
                    id="announcementType"
                    placeholder="Enter a Announcement Type"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.announcementType}
                  />
                  {formik.touched.announcementType &&
                  formik.errors.announcementType ? (
                    <div className="errorMessage" style={{ color: "red" }}>
                      {formik.errors.announcementType}
                    </div>
                  ) : null}
                </div>

                <div className="exam-upload-file">
                  <label htmlFor="announcementLogo">
                    Announcement Logo<span style={{ color: "red" }}> *</span>
                  </label>
                  <div className="form-control">
                    <Upload
                      className="ImageUpload"
                      listType="picture"
                      onChange={(file) => filehandleChange(file)}
                      beforeUpload={() => false}
                      fileList={file}
                      maxCount={1}
                    >
                      <Button
                        className="exam-image-upload-button"
                        name="groupLogo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        icon={<UploadOutlined />}
                      >
                        Choose file
                      </Button>
                    </Upload>
                    {formik.touched.groupLogo && formik.errors.groupLogo ? (
                      <div className="error">{formik.errors.groupLogo}</div>
                    ) : null}
                  </div>
                </div>
                <div className="form-control">
                  <Checkbox
                    id="isActive"
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    // value={formik.values.isActive}
                  >
                    Active
                  </Checkbox>
                </div>
              </Form>
            </Formik>
          </Drawer>
          <Modal
            title="Deletion"
            open={isModalVisible}
            onCancel={modalOnCancel}
            onOk={modalOnOk}
            footer={
              <div>
                <Button
                  className="primary-cancel-button"
                  onClick={() => modalOnCancel()}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-submit-button"
                  type="default"
                  onClick={() => modalOnOk()}
                >
                  OK
                </Button>
              </div>
            }
          >
            <p>Are you sure want to delete ?</p>
          </Modal>

          {/* update model */}
          <Modal
            title="Update Confirmation"
            open={isUpdateModalVisible}
            onCancel={closeUpdateModal}
            footer={
              <div>
                <Button
                  className="primary-cancel-button"
                  onClick={closeUpdateModal}
                >
                  Cancel
                </Button>
                <Button
                  className="primary-submit-button"
                  type="default"
                  onClick={updateData}
                >
                  Update
                </Button>
              </div>
            }
          >
            <p>Are you sure you want to update?</p>
          </Modal>

          {/* submit modal */}

          <Modal
            title="Submit Confirmation"
            open={isSubmitModalVisible}
            onCancel={closeSubmitModal}
            footer={
              <div>
                <Button
                  className="primary-cancel-button"
                  onClick={closeSubmitModal}
                >
                  Cancel{" "}
                </Button>
                <Button
                  className="primary-submit-button"
                  type="default"
                  onClick={formik.handleSubmit}
                >
                  {" "}
                  Submit{" "}
                </Button>
              </div>
            }
          >
            <p>Are you sure you want to Submit?</p>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Announcement;
