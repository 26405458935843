import { message, Tag } from 'antd';
import moment from "moment";
import { BehaviorSubject } from 'rxjs';
import { EFieldStatus } from '../utils/Variable.js';

export const costomEllipsize = (text, maxLength = 20) => {
    if (text.length <= maxLength) {
        return text;
    }
    return `${text.substring(0, maxLength)}...`;
};

export const capitalize = (str) => {
    if (!str) {
        return str
    };
    return str.replace(/\b\w/g, char => char.toUpperCase());
};

export const handleToast = ({ resSuccess, resMessage }, errorToastOnly = false) => {

    if (!resSuccess) {
        return message.error(resMessage);
    };

    if (!errorToastOnly) {
        return message.success(resMessage);
    };
};

export const filehandleChangeHelper = async (
    fileFilter, errorMessage, file, fileList = [],
    setFile, formik, fieldName, allowCount, validateFileSize = false,
    maxSize = '', sizeErrorMessage = '', validateDimensionsObject = {}, isThumbnail = false
) => {

    if (!file) return false;

    const fileType = file?.type;

    const { validateDimensions = false, expectedWidth = 0, expectedHeight = 0, validateAspectRatio = false, expectedAspectRatio = 1, aspectRatioToString = '' } = validateDimensionsObject;

    const isValidType = fileFilter.includes(fileType) || !fileType;
    const isValidSize = !validateFileSize || checkFileSize(file, maxSize);

    if (!isValidType || !fileType) {
        message.error(errorMessage);
        return false;
    };

    if (!isValidSize) {
        message.error(sizeErrorMessage);
        return false;
    };

    if (fileList?.length >= allowCount) {
        message.error(`You can only upload up to ${allowCount} files.`);
        return false;
    };

    if (validateAspectRatio) {
        const isAspectRatioValid = await checkAspectRatio(file, expectedAspectRatio);
        if (!isAspectRatioValid) {
            message.error(`The aspect ratio of the image is incorrect. Please upload an image with an aspect ratio of ${aspectRatioToString}.`);
            return false;
        }
    };

    if (validateDimensions) {
        const areDimensionsValid = await checkDimensions(file, expectedWidth, expectedHeight);
        if (!areDimensionsValid) {
            message.error(`The dimensions of the image are incorrect. Please upload an image with dimensions ${expectedWidth}px by ${expectedHeight}px.`);
            return false;
        }
    };

    if (isValidType && isValidSize) {

        if (!isThumbnail) {
            await new Promise(resolve => {
                setFile(prevFiles => {
                    const updatedFiles = [...prevFiles, file];
                    resolve(updatedFiles);
                    return updatedFiles;
                });
            });

            await formik?.setFieldValue(fieldName, [...fileList, file]);
        }

        return true;
    };
};

export const handleFileRemoveHelper = async (file, setDeletedAttachment, deletedAttachment, setFile, formik, fieldName) => {
    if (file?.id) {
        await setDeletedAttachment([...deletedAttachment, file.id]);
    };

    await setFile(prevList => prevList.filter(item => item?.uid !== file?.uid));
    await formik.setFieldValue(fieldName, formik?.values[fieldName]?.filter(item => item?.uid !== file?.uid));
};

export const checkFileSize = (file, maxSize) => {
    return file?.size <= maxSize;
};

export const getFileName = (attachmentKey) => {
    return attachmentKey?.split('_')?.[1];
};

export const getCurrentDate = () => {
    return moment().format('YYYY-MM-DD');
};

export const handleAddItemHelper = (formik, field, value) => {
    formik.setFieldValue(field, [...formik?.values[field], value]);
};

export const handleRemoveItemHelper = (formik, field, index) => {
    const newArray = [...formik?.values[field]];
    newArray.splice(index, 1);
    formik.setFieldValue(field, newArray);
};

export const filteredDataHelper = (filterData, keysToSearch, searchInput) => {

    return filterData.filter(item =>
        keysToSearch.some(key =>
            item[key]?.toString()?.toLowerCase().includes(searchInput.trim().toLowerCase())
        )
    );
};

const formValidationSubjects = {
    courseOverview: new BehaviorSubject(false),
    curriculum: new BehaviorSubject(false),
    price: new BehaviorSubject(false),
};

export { formValidationSubjects };

export const deletePricingPlanHelper = (deletedPricing, setDeletedPricing, mappingId, pricingId) => {
    const { mappingIds, pricingIds } = deletedPricing

    mappingId = Array.isArray(mappingId) ? mappingId : [mappingId];
    pricingId = Array.isArray(pricingId) ? pricingId : [pricingId];

    const mappingIdExists = mappingIds?.includes(mappingId);
    const updatedDeletedPricing = {
        mappingIds: mappingIdExists ? mappingIds : [...mappingIds, ...mappingId],
        pricingIds: [...pricingIds, ...pricingId]
    };

    setDeletedPricing(updatedDeletedPricing);
};

export const hasErrorsInPriceAndFrequencyHelper = (isEdit = false, formik) => {
    if (!formik) {
        return true;
    };

    const { touched = {}, errors = {} } = formik;

    if (isEdit) {
        return !!errors?.price || !!errors.frequencyId;
    };

    return !(touched?.price && touched?.frequencyId) || !!errors?.price || !!errors?.frequencyId;
};

export const handlePricingDataHelper = async (formik, setPrices, planPricing) => {

    const { actualPrice = '', price = '', frequencyId = '' } = planPricing?.[0] || {};

    formik.setValues({
        actualPrice,
        price,
        frequencyId
    });

    if (planPricing?.length > 1) {
        const remainingPrices = planPricing.slice(1).map(({ actualPrice = '', price = '', frequencyId = '' }) => ({
            actualPrice,
            price,
            frequencyId,
        }));

        setPrices(remainingPrices);
    };
};

export const constructFrequencyHelper = (data, setFrequencyMap) => {

    const map = data?.reduce((acc, item) => {
        acc[item?.id] = item?.name;
        return acc;
    }, {}) || {};

    setFrequencyMap(map);
};

export const leastPriceHelper = (planPricing, frequencyMap) => {

    const leastPlan = Array.isArray(planPricing) && planPricing?.length ? planPricing?.reduce((prev, curr) => {

        const prevPrice = Number(prev.price);
        const currPrice = Number(curr.price);

        return isNaN(currPrice) || (!isNaN(prevPrice) && prevPrice <= currPrice) ? prev : curr;
    }) : [];

    const { price = '-', frequencyId = '-' } = leastPlan;

    return (
        <p className='capitalize least-price'>
            <span> ₹ {price} / </span>
            <span> {frequencyMap[frequencyId] || '-'} </span>
        </p>
    );
};

export const formatFileSizeHelper = (sizeInBytes) => {
    if (sizeInBytes === 0 || !sizeInBytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));
    return parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const renderPublishStatusHelper = (isPublish) => {
    return (
        <span> {
            isPublish ?
                <Tag color="green">Published</Tag> :
                <Tag color="red">Unpublished</Tag>
        }</span>
    );
};

export const updateFieldStatusHelper = (item, status, isPublishRequired = true) => {
    const isPublishSame = item.isPublish === status;

    return {
        ...item,
        ...(isPublishRequired && !isPublishSame && { isPublish: status }),
        fieldStatus: (isPublishRequired && !isPublishSame)
            ? (item.fieldStatus === EFieldStatus.NONE ? EFieldStatus.EDITED : item.fieldStatus)
            : item.fieldStatus,
    };
};

const checkAspectRatio = (file, expectedAspectRatio) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            resolve(Math.abs(aspectRatio - expectedAspectRatio) < 0.01);
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });
};

const checkDimensions = (file, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const isWidthValid = img.width === expectedWidth;
            const isHeightValid = img.height === expectedHeight;
            resolve(isWidthValid && isHeightValid);
        };
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });
};

export const extractVideoDurationHelper = (file) => {
    return new Promise((resolve, reject) => {
        const videoElement = document.createElement('video');
        const fileURL = URL.createObjectURL(file);
        videoElement.src = fileURL;
        videoElement.preload = 'metadata';
        videoElement.onloadedmetadata = () => {
            URL.revokeObjectURL(fileURL);
            resolve(videoElement.duration);
        };

        videoElement.onerror = (error) => {
            URL.revokeObjectURL(fileURL);
            reject(null);
        };
    });
};