import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Image, Input, Drawer, Select, Modal, DatePicker, Checkbox, message } from 'antd';
import DeleteImage from '../asset/image/deleteIcon.svg';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import TableComponent from './Table/tableComponent';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import emptyImage from '../asset/image/createBundle.svg';
import './styles/couponMaster.scss';
import { deletePrivateCoupon, updatePrivateCoupon, getCouponMappingId, getCouponPrivateActive, couponStudentMappingDetails, getCouponDetails, getEditData, deleteCouponDetails, updateCouponDetails, getCouponStudentDetails, studentDetailsActive, excelBulkUploadCouponStudent } from './reducers/couponSlice';
import moment, { ISO_8601, utc } from 'moment';
import Loading from './Loading';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import xlIcon from '../asset/image/xl-icon.svg'
import closeIcon from '../asset/image/deleteIcon.svg'
import file from '../asset/file/bulk_student_coupon_count_mapping.xlsx';
import { useDebouncedCallback } from 'use-debounce';



const CouponStudentMapping = () => {

  const dispatch = useDispatch()

  const { Option } = Select;

  const { couponType, couponDetails, isCouponLoading, couponPrivateAll, couponPrivateStudent, studentDetailsData } = useSelector((state) => state.Coupon)
  const [currentTitle, setCurrentTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [record, setRecord] = useState({});
  const [filterData, setFilterData] = useState("");
  const [paginationData, setPaginationData] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dateFormat = 'YYYY-MM-DD';
  const [formDate, setFormDate] = useState(moment());
  const [defaultValue, setDefaultValue] = useState()

  const [selectedOption, setSelectedOption] = useState(null);

  /* bulk upload */
  const [visibleBulk, setVisibleBulk] = useState(false);
  const [bulkUploadFileName, setBulkUploadFileName] = useState("");
  const [uploadButtonValid, setUploadButtonValid] = useState(false)
  const [recordBulk, setRecordBulk] = useState({})
  const hiddenFile = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    let obj = {
      pagination: paginationData,
      searchValue: filterData,
      limit: limit
    }
    dispatch(getCouponPrivateActive(obj)).unwrap().then((res) => {
    })

    dispatch(getCouponStudentDetails(obj)).unwrap().then((res) => {
    })

    dispatch(studentDetailsActive(obj)).unwrap().then((res) => {
    })

  }, [])

  let tableHeight = 320




  const columns = [
    {
      title: 'Coupon Name',
      dataIndex: 'couponName',
      key: 'couponName',
    },
    {
      title: 'Student name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return (
          <div className='table-action-link'>
            {/* <a onClick={() => editData(record)}>Edit</a> */}
            <Tooltip title="Delete" style={{ padding: 0 }}>
              <IconButton>
                <Image src={DeleteImage} preview={false} onClick={() => deleteData(record)}></Image>
              </IconButton>
            </Tooltip>
          </div>
        )
      },
    },
  ]



  // edit data

  const editData = (record) => {
    setCurrentTitle("Edit Coupon")
    setIsDisabled(true);
    setCurrentId(record.couponMapId)
    dispatch(getCouponMappingId(record.couponMapId)).unwrap().then((res) => {
      setVisible(true)
      setFormDate(res.data.validDateTime)
      res.data.isActive == 1 ? res.data.isActive = true : res.data.isActive = false
      res.data.isPublic == 1 ? res.data.isPublic = true : res.data.isPublic = false
      couponFormik.values.count = res.data.data[0].count
      couponFormik.values.studentId = res.data.data[0].userId
      couponFormik.values.couponPrivateId = res.data.data[0].couponDetailId
      couponFormik.values.mobileNumber = res.data.data[0].mobileNumber
    })
  }

  // delete data
  const deleteData = (record) => {
    setCurrentId(record.couponMapId)
    setRecord(record)
    setIsModalVisible(true)
  }

  // open drawer
  const openDrawer = () => {
    setCurrentId(null)
    setVisible(true)
    setIsDisabled(false);
    setCurrentTitle("New Coupon")
    couponFormik.resetForm({ values: initialValues })
  }

  const search = (value) => {
    // setFilterData(value)
    console.log("aaaaaaa", filterData);
    setFilterData(value)
    let data = {
      pagination: paginationData,
      searchValue: value,
      limit: limit,
    }
    debounced(data)
    // debounced(data)
  }

  const debounced = useDebouncedCallback((obj) => {
    dispatch(getCouponStudentDetails(obj))
  }, 400)

  const tableOnChange = (pagination, filters, sort, extra) => {
    setPaginationData(pagination?.current - 1)

    let obj = {
      pagination: pagination?.current - 1,
      searchValue: filterData,
      limit: limit

    }
    dispatch(getCouponStudentDetails(obj))
  }

  // onClose
  const onClose = () => {
    setVisible(false);
    couponFormik.setValues(initialValues)
    couponFormik.resetForm()
  }

  // update data
  const updateData = () => {
    setVisible(false);
    const payload = {
      id: currentId,
      values: couponFormik.values,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    }
    dispatch(updatePrivateCoupon(payload)).unwrap().then((res) => {
      let obj = {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
      dispatch(getCouponDetails(obj)).unwrap().then((res) => {
      })
      message.success(res.updatePrivateCoupon.message)
    })
    couponFormik.setValues(initialValues)
    couponFormik.resetForm()
  }

  // onChange select
  const onChangeSelect = (e, fieldHelpers) => {
    setSelectedStudent(e);
    fieldHelpers.setValue(e)
  }

  const onChangeSelectCoupon = (value) => {
    console.log('Selected value:', value);
    setSelectedOption(value);
  };

  const onSelectChange = (selectData) => {
    setSelectedRowKeys(selectData)
  }


  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  // confirm delete
  const confirmDelete = () => {
    setIsModalVisible(false)
    let payload = {
      currentId,
      obj: {
        pagination: paginationData,
        searchValue: filterData,
        limit: limit
      }
    }
    dispatch(deletePrivateCoupon(payload)).unwrap().then((res) => {
      if (res.deletePrivateCoupon.status) {
        message.success(res.deletePrivateCoupon.message)
      }
    })
  }

  // on modal close
  const onModalClose = () => {
    setIsModalVisible(false);
  }

  // Datepicker

  const onCalenderChange = (date, dateString) => {
    couponFormik.setFieldValue('validDateTime', dateString)
    setFormDate(dateString)
  }

  // Coupons formik

  const initialValues = {
    couponPrivateId: null,
    studentId: [],
    count: null,
    mobileNumber: []
  }

  const validationSchema = yup.object({
    couponPrivateId: yup.number().required("Coupon type is required").nullable(),
    studentId: yup.number().required("Student is required").nullable(),
    count: yup.number().required("Count is required").nullable(),
    mobileNumber: yup.array()
      // .min(10, 'Mobile Number Must Be Exactly 10')
      // .max(10, 'Mobile Number Must Be Exactly 10')
      .required("Mobile Number is required").nullable()
  })

  const couponFormik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setVisible(false)
      dispatch(couponStudentMappingDetails(values)).unwrap().then((res) => {
        if (res?.success) {
          message.success(res?.data?.message)
        }
        else {
          message.error(res?.data?.data?.message)
        }
        let obj = {
          pagination: paginationData,
          searchValue: filterData,
          limit: limit
        }
        dispatch(getCouponStudentDetails(obj))
        couponFormik.resetForm({ values: initialValues })
      })
    }
  })

  const bulkUploadDrawerClose = () => {
    setVisibleBulk(false)
    setBulkUploadFileName("")
    setUploadButtonValid(false)
  }


  // bulk file upload 
  const excelChooseFile = (event) => {
    var fileFilter = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
    if (fileFilter.includes(event.target.files[0].type)) {
      var excelFile = new FormData();
      excelFile.append("excelFile", event.target.files[0])
      setBulkUploadFileName(event.target.files[0].name)
      setUploadButtonValid(true)
      let data = {
        excelFile: excelFile,
      }
      setRecordBulk(data)
      dispatch(excelBulkUploadCouponStudent(data)).unwrap().then((res) => {
        message.success(res.data.message)
        setVisibleBulk(false);
        setBulkUploadFileName("")
        setUploadButtonValid(false)
      })
    }
    else {
      message.warn("Only excel files are allowed")
      setUploadButtonValid(false)
    }
  }

  function onCloseBulk(values) {
    setVisibleBulk(false);
    setBulkUploadFileName("")
    setUploadButtonValid(false)
  }

  const openBulkDrawer = () => {
    setVisibleBulk(true)
    setCurrentTitle("Coupon bulk upload")
    // formik.resetForm()
  }

  const removeFile = () => {
    setBulkUploadFileName("")
    setUploadButtonValid(false)
  }

  const bulkExcelUpload = () => {
    hiddenFile.current.click()
  }


  return (
    <div className="coupon-master-page">
      <div className="coupon-header">
        <p className="header-name">Privte Coupon Student</p>
        <div className='coupon-header-devision'>
          <span><Button onClick={() => openBulkDrawer()}>Bulk Upload</Button></span>
          <div className="create-coupon"><Button className='create-button' onClick={() => openDrawer()}>Create coupon</Button></div>
        </div>
      </div>
      <div className="coupon-content">
        {couponPrivateStudent.length == 0 && !filterData ?
          <div className="coupon-empty">
            <div>
              <div className="coupon-empty-image"><Image preview={false} src={emptyImage}></Image></div>
              <p className="coupon-empty-header">Map Private Coupon Student</p>
              <p className="coupon-empty-content">You’re moments away from Create Chapter Master</p>
              <Button className='coupon-empty-button'>+Map Private Coupon Student</Button>
            </div>
          </div> :

          <div>
            <div className="golbalSearch" >
              <Input placeholder="Search" prefix={<SearchOutlined style={{ paddingRight: 5 }} />} style={{ width: 262, height: 32 }} onChange={(e) => search(e.target.value)} value={filterData} />
            </div>
            <div className="table-component">
              <TableComponent columns={columns} dataSource={couponPrivateStudent} rowSelection={rowSelection} tableOnChange={tableOnChange} tableHeight={tableHeight} totalData={couponPrivateStudent} currentPage={paginationData + 1} loadingStatus={isCouponLoading} limit={limit} />
            </div>
          </div>
        }

        {/* drawer */}

        <Drawer closable={false} title={currentTitle} placement="right" onClose={() => onClose()} open={visible} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onClose()}>Cancel</Button>
              {
                currentId == null ?
                  <Button className={!couponFormik.isValid || !couponFormik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!couponFormik.dirty || !couponFormik.isValid} type='primary' onClick={() => couponFormik.handleSubmit()}>Submit</Button>
                  :
                  <Button className={!couponFormik.isValid || !couponFormik.dirty ? 'disabled-button' : 'primary-submit-button'} disabled={!couponFormik.isValid} type='primary' onClick={() => updateData()} >Update</Button>
              }
            </div>
          </div>
        )}>
          <form>
            <div className='form-control'>
              <div>
                <div className="discount-value">
                  <label>
                    Coupon Name <span className='required'>*</span>
                  </label>
                  <div>
                    <Select id="couponPrivateId" name="couponPrivateId" style={{ width: "100%" }} placeholder="Choose Option" onChange={(e) => { onChangeSelect(e, couponFormik.getFieldHelpers('couponPrivateId')) }} onBlur={couponFormik.handleBlur} value={couponFormik.values.couponPrivateId} >
                      {couponPrivateAll ? couponPrivateAll.map((item) =>
                        <Option value={item.id}>{item.name}</Option>
                      ) : ''}
                    </Select>
                    {couponFormik.touched.couponPrivateId && couponFormik.errors.couponPrivateId ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.couponPrivateId}</div> : null}
                  </div>
                </div>
              </div>

              <div className="mobileNumber">
                <label htmlFor='mobileNumber'>
                  Mobile Number <span className='required'>*</span>
                </label>
                <div>
                  <Select
                    id="mobileNumber"
                    name="mobileNumber"
                    mode="multiple"
                    placeholder="Choose Option"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(values) => {
                      couponFormik.setFieldValue('mobileNumber', values);

                      const selectedStudents = studentDetailsData.filter(student => values.includes(student.mobileNumber));
                      const studentNames = selectedStudents.map(student => student.name);
                      couponFormik.setFieldValue('studentName', studentNames);

                      const studentIds = selectedStudents.map(student => student.studentId);
                      couponFormik.setFieldValue('studentId', studentIds);
                    }}
                    onBlur={couponFormik.handleBlur}
                    value={couponFormik.values.mobileNumber}
                  >
                    {studentDetailsData && studentDetailsData.map((item) => (
                      <Option key={item.studentId} value={item.mobileNumber}>
                        {item.mobileNumber}
                      </Option>
                    ))}
                  </Select>
                  {couponFormik.touched.mobileNumber && couponFormik.errors.mobileNumber ? (
                    <div className='errorMessage' style={{ color: 'red' }}>
                      {couponFormik.errors.mobileNumber}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="discount-value">
                <label>
                  Student Name <span className='required'>*</span>
                </label>
                <div>
                  <Select
                    mode="tags"
                    id="studentName"
                    name="studentName"
                    placeholder="Choose Option"
                    onChange={(value) => {
                      couponFormik.setFieldValue('studentName', value);
                    }}
                    onBlur={couponFormik.handleBlur}
                    value={couponFormik.values.studentName}
                    disabled={true}
                  >
                    {studentDetailsData && studentDetailsData.map((student) => (
                      <Option key={student.studentId} value={student.name}>{student.name}</Option>
                    ))}
                  </Select>
                  {couponFormik.touched.studentName && couponFormik.errors.studentName ? (
                    <div className='errorMessage' style={{ color: 'red' }}>
                      {couponFormik.errors.studentName}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="count">
                <label htmlFor='count'>
                  Count <span className='required'>*</span>
                </label>
                <div>
                  <Input type='number' id='count' onBlur={couponFormik.handleBlur} placeholder="Enter a Count" onChange={couponFormik.handleChange} value={couponFormik.values.count} />
                  {couponFormik.touched.count && couponFormik.errors.count ? <div className='errorMessage' style={{ color: 'red' }}>{couponFormik.errors.count}</div> : null}
                </div>
              </div>
            </div>
          </form>
        </Drawer>
        {/* delete modal */}

        <Modal title="Deletion" open={isModalVisible} onOk={() => confirmDelete()} onCancel={() => onModalClose()}>
          <p>Are you sure want to delete ?</p>
        </Modal>

        {/* bulk upload */}
        <Drawer closable={false} title={currentTitle} placement="right" onClose={() => bulkUploadDrawerClose()} open={visibleBulk} footer={(
          <div className='footer'>
            <div className='footer-button'>
              <Button className='primary-cancel-button' style={{ marginRight: 42.5 }} onClick={() => onCloseBulk()}>Cancel</Button>
            </div>
          </div>
        )}>
          <form>
            <div>
              <div className='upload-container'>
                <div className='text-upload'>
                  <p>Upload a file to import members into your batch</p>
                </div>
                <div className='choose-button'>
                  {bulkUploadFileName.length != 0 ? <div className='institute-bulk-upload-filename'><Image src={xlIcon} preview={false} /> <span>{bulkUploadFileName}</span> <Image src={closeIcon} preview={false} onClick={() => removeFile()} /> </div> : <Button className='primary-submit-button' onClick={() => bulkExcelUpload()}>Choose File</Button>}
                  <input type="file" ref={hiddenFile} hidden={true} onChange={(event) => excelChooseFile(event)}></input>
                </div>
              </div>
            </div>
            <div className='footer-text'>
              <p>Import users with your own file, or <a href={file} download>download existing users (CSV file)</a> </p>
            </div>
          </form>
        </Drawer>

      </div>
      <div>
        {isCouponLoading ? <Loading></Loading> : null}
      </div>
    </div>
  )
}

export default CouponStudentMapping;